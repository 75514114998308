import isWithinInterval from 'date-fns/isWithinInterval';
import parse from 'date-fns/parse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

type BankDirectMaintenanceConfig = {
  startDate: string;
  endDate: string;
  jaMessage: string;
  enMessage: string;
};

export default function useMaintenanceCheck() {
  const ldFlags = useFlags();

  return useMemo(() => {
    return {
      checkBankDirectMaintenance: () => {
        if (!ldFlags || !ldFlags.bankDirectMaintenance) {
          return {
            isBankDirectUnderMaintenance: false,
            jaMessage: '',
            enMessage: '',
          };
        }

        try {
          const { startDate, endDate, jaMessage, enMessage } =
            ldFlags.bankDirectMaintenance as BankDirectMaintenanceConfig;
          const startJST = parse(
            `${startDate}+09`,
            'yyyy-MM-dd HH:mmX',
            new Date()
          );
          const endJST = parse(
            `${endDate}+09`,
            'yyyy-MM-dd HH:mmX',
            new Date()
          );

          const now = new Date();

          // Check if the current JST time is within the start and end times
          const isBankDirectUnderMaintenance = isWithinInterval(now, {
            start: startJST,
            end: endJST,
          });

          return { isBankDirectUnderMaintenance, jaMessage, enMessage };
        } catch (error) {
          return {
            isBankDirectUnderMaintenance: false,
            jaMessage: '',
            enMessage: '',
          };
        }
      },
    };
  }, [ldFlags]);
}
