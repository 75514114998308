import type { AppStore } from '..';
import { pinCodeTransformer } from '../components/Form/utils';
import { refreshToken, updateAuthPairs } from '../redux/auth';
import axiosInstance from './axios-instance';
import publicAxiosInstance from './public-axios-instance';

const setup = (store: AppStore) => {
  let token = '';

  const handleChange = () => {
    const newToken = store.getState()?.auth?.accessToken;

    if (newToken != null && newToken !== token) {
      token = newToken;
    }
  };

  store.subscribe(handleChange);

  // Add Bearer token
  axiosInstance.interceptors.request.use(
    (config) => {
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  // Setup retry if got 401
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      const redirectToLogin = () => {
        const url = new URL(store.getState()?.misc?.loginURL);

        url.searchParams.set('error', 'session.expired');
        window.location.href = url.href;
      };

      if (!originalRequest._retry) {
        switch (error.response.status) {
          // Refresh token if it is needed
          case 401: {
            if (store.getState()?.misc?.rememberMe) {
              originalRequest._retry = true;

              const resultAction = await store.dispatch(refreshToken());

              if (refreshToken.fulfilled.match(resultAction)) {
                return axiosInstance(originalRequest);
              }

              return redirectToLogin();
            }

            if (!store.getState()?.misc?.rememberMe) {
              return redirectToLogin();
            }

            break;
          }
          // 50x, perform a retry
          case 500: // Internal Server Error
          case 502: // Bad Gateway
          case 503: // Service Unavailable
          case 504: /* Gateway Timeout */ {
            originalRequest._retry = true;

            return axiosInstance(originalRequest);
          }
          default:
            throw error;
        }
      }

      throw error;
    }
  );

  publicAxiosInstance.interceptors.response.use((response) => {
    const testOTPSecret: string = response.headers?.['smartpay-otp'];

    if (testOTPSecret) {
      store.dispatch(
        updateAuthPairs({ testOTPSecret: pinCodeTransformer(testOTPSecret) })
      );
    } else {
      /**
       * TODO: let's find a better way to avoid doing this in every request
       * it's important to reset this value so it doesn't affect any following real registrations
       */
      store.dispatch(updateAuthPairs({ testOTPSecret: '' }));
    }

    return response;
  });
};

export default setup;
