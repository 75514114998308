import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import transactionCompleted from '../../assets/transaction-completed.json';
import Button from '../../components/Form/Button';
import MainLayout from '../../components/Layout';
import useAppSelector from '../../hooks/use-app-selector';
import { onRestartDemo } from '../../utils';
import styles from './PaymentApprovalSuccessScreen.module.scss';

const REDIRECTING_TIMEOUT = 1500; // Automatically redirect after 1.5 secs

const PaymentApprovalSuccessScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const refBtnRedirect = useRef<HTMLButtonElement>(null);

  const { 'merchant-name': demoMerchantName } = useAppSelector(
    (state) => state.demo
  );
  const { referrer, merchantName, isDemoSite } = useAppSelector(
    (state) => state.misc
  );
  const successURL = useAppSelector((state) => state.good.successURL);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const onRedirect = useCallback(() => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Redirect to Merchant',
    });

    // back to merchant's site
    const target = successURL || referrer;

    if (
      (target && process.env.REACT_APP_ENV !== 'prod') ||
      (target && target.match(/^https?:\/\//))
    ) {
      setIsRedirecting(true);
      window.location.href = target;
    }
  }, [referrer, successURL]);

  const onRestart = useCallback(() => {
    setIsRedirecting(true);
    onRestartDemo();
  }, []);

  useLayoutEffect(() => {
    refBtnRedirect?.current?.focus();

    setTimeout(() => {
      if (isDemoSite) {
        onRestart();
      } else {
        onRedirect();
      }
    }, REDIRECTING_TIMEOUT);
  }, [isDemoSite, onRestart, onRedirect, refBtnRedirect]);

  const RedirectButton = () => (
    <Button
      type="button"
      className="btn"
      onClick={onRedirect}
      inputRef={refBtnRedirect}
      label={`${demoMerchantName || merchantName}に戻ります`}
      disabled={isRedirecting}
    />
  );

  const RestartButton = () => (
    <Button
      type="button"
      className="btn"
      onClick={onRestart}
      inputRef={refBtnRedirect}
      label="一からやり直す"
      disabled={isRedirecting}
    />
  );

  return (
    <div className="rwd-wrapper">
      <MainLayout
        hasBack={false}
        showFooter={false}
        className={styles.layout}
        logoHeight={35.95}
      >
        <div className={styles.content}>
          <div className={styles['lottie-wrapper']}>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: transactionCompleted,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={256}
              width={256}
              isClickToPauseDisabled
            />
          </div>
          {isDemoSite ? <RestartButton /> : <RedirectButton />}
        </div>
      </MainLayout>
    </div>
  );
};

export default PaymentApprovalSuccessScreen;
