import { Link, navigate, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { FC, SyntheticEvent, useState } from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import useAppSelector from '../../hooks/use-app-selector';
import useOnLogin from '../../hooks/use-on-login';
import { AuthPairs, loginAttempt, updateAuthPairs } from '../../redux/auth';
import { updateMiscPairs } from '../../redux/misc';
import styles from './LoginScreen.module.scss';

const PasswordScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();

  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const sessionIdWithSignature = useAppSelector(
    (state) => state.misc.sessionIdWithSignature
  );
  const email = useAppSelector((state) => state.auth.email);
  const password = useAppSelector((state) => state.auth.password);
  const [errorMessage, setErrorMessage] = useState('');
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { onLogin } = useOnLogin({
    onSuccess: () => {
      dispatch(updateMiscPairs({ rememberMe: checked }));
    },
    onFailure: (errorCode) => {
      switch (errorCode) {
        case 'live.test.mismatch':
          navigate(`/${sessionIdWithSignature}`, {
            state: { errorCode },
            replace: true,
          });
          break;
        default:
          setErrorMessage(
            ERROR_MESSAGES.SHARED[errorCode] || ERROR_MESSAGES.SHARED.unknown
          );
      }
    },
  });

  const onCheckToggle = () => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: `Remember Me - Opt ${checked ? 'out' : 'in'}`,
    });

    setChecked(!checked);
  };

  const onUpdateAuthPairs = (pairs: AuthPairs) => {
    setErrorMessage('');
    dispatch(updateAuthPairs(pairs));
  };

  const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Submit',
    });

    if (password) {
      onUpdateAuthPairs({ password });
      setIsLoading(true);

      const loginResultAction = await dispatch(
        loginAttempt({ email, password })
      );

      if (loginAttempt.fulfilled.match(loginResultAction)) {
        await onLogin(loginResultAction.payload);
      } else {
        setErrorMessage(ERROR_MESSAGES.SHARED['password.invalid']);
      }

      setIsLoading(false);
    }
  };

  return (
    <div
      className={cx('rwd-wrapper', 'login', isTokenFlow ? 'token-flow' : '')}
    >
      <aside>
        <Header />
        <MerchantHeader />
      </aside>
      <MainLayout
        greetings="おかえりなさい"
        desc1="パスワードを入力してください。"
      >
        <form className={styles.form} onSubmit={onSubmit}>
          <fieldset>
            <TextInput
              name="password"
              type="password"
              value={password}
              aria-label="パスワード"
              updatePairs={onUpdateAuthPairs}
              placeholder="パスワード"
              autoFocus
              autoCorrect="off"
              autoCapitalize="false"
              errorMessage={errorMessage}
            />
          </fieldset>
          <Button
            id="btn_submit"
            loading={isLoading}
            type="submit"
            label="次へ"
            disabled={!password}
          />
          <label className={styles['check-label']}>
            <input type="checkbox" onChange={onCheckToggle} checked={checked} />
            <i />
            <span>このデバイスを記憶する</span>
          </label>
          <Link
            id="recover_link"
            to="/recover"
            replace
            className={styles['forgot-password']}
          >
            パスワードをお忘れですか？
          </Link>
        </form>
      </MainLayout>
    </div>
  );
};

export default PasswordScreen;
