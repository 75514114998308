import { navigate, useLocation } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import { parse, stringifyUrl } from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../..';
import OpenInApp from '../../assets/open-in-app.svg';
import useAppSelector from '../../hooks/use-app-selector';
import { Platform, updateMiscPairs } from '../../redux/misc';
import createOneLink from '../../utils/create-one-link';
import Button from '../Form/Button';
import Backdrop from '../Modal/Backdrop';
import Modal from '../Modal/Modal';
import styles from './PreferredPlatformModal.module.scss';

const screen = 'Preferred Platform Prompt';

const PreferredPlatformModal = ({
  preferredPlatform = 'web',
  visible = false,
  onDismiss,
}: {
  preferredPlatform?: Platform;
  visible?: boolean;
  onDismiss?: () => void;
}) => {
  const location = useLocation();
  const params = parse(location.search);

  const dispatch = useAppDispatch();
  const { sessionIdWithSignature, isTokenFlow } = useAppSelector(
    (state) => state.misc
  );

  const { isDemoSite } = useAppSelector((state) => state.misc);
  const [remeberPreference, setRemeberPreference] = useState(
    preferredPlatform === 'app'
  );

  const launchApp = useCallback(() => {
    if (sessionIdWithSignature) {
      // Use the 'app-launched' qs param to avoid browser navigates
      // to the OneLink again when they use the browser's back button.
      navigate(
        stringifyUrl({
          url: location.href,
          query: { 'app-launched': 1 },
        }),
        { replace: true }
      );

      document.location.href = createOneLink(
        `checkout/${sessionIdWithSignature}`
      );
    }
  }, [location.href, sessionIdWithSignature]);

  const onClickLaunchApp = useCallback(() => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Launch App',
      screen,
      props: {
        remeberPreference,
      },
    });

    dispatch(
      updateMiscPairs({
        // set preferredPlatform only when the checkbox is checked
        preferredPlatform: remeberPreference ? 'app' : undefined,
      })
    );

    launchApp();
  }, [dispatch, remeberPreference, launchApp]);

  const onClickCancel = useCallback(() => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Not Launch OTP',
      screen,
      props: {
        remeberPreference,
      },
    });

    dispatch(
      updateMiscPairs({
        // set preferredPlatform only when the checkbox is checked
        preferredPlatform: remeberPreference ? 'web' : undefined,
      })
    );

    onDismiss?.();
  }, [dispatch, remeberPreference, onDismiss]);

  /**
   * If there is a preference and it is 'app'
   * Launch the App automatically if:
   * - is not demo site
   * - and is not token order
   * - and app-launched is not exists
   *
   * PS. Check isDemoSite and isTokenFlow again because the prompt can be
   *     controlled by feature flag
   */
  useEffect(() => {
    if (
      !isDemoSite &&
      !isTokenFlow &&
      !params['app-launched'] &&
      preferredPlatform === 'app'
    ) {
      launchApp();
    }
  }, [isDemoSite, isTokenFlow, params, preferredPlatform, launchApp]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Backdrop />
      <Modal>
        <img src={OpenInApp} className={styles.figure} alt="" height="200" />
        <h3 className={styles.heading}>
          Smartpayアプリで <br />
          お支払いを続けますか？
        </h3>
        <p className={styles.desc}>
          Smartpayアプリをお持ちの場合、アプリでスムーズにお支払いが完了できます。アプリをお持ちでない場合、App
          Storeを開きます。
        </p>
        <label className={styles['remember-preference']}>
          <input
            type="checkbox"
            onChange={() => {
              setRemeberPreference(!remeberPreference);
            }}
            checked={remeberPreference}
          />
          <i />
          <span>次回も同じ選択をする</span>
        </label>
        <div className={styles.buttons}>
          <Button
            label="いいえ"
            id="web_flow"
            variant="outline"
            onClick={onClickCancel}
          />
          <Button
            label="アプリを開く"
            id="app_flow"
            onClick={onClickLaunchApp}
          />
        </div>
      </Modal>
    </>
  );
};

export default PreferredPlatformModal;
