import cx from 'classnames';
import { MouseEventHandler, ReactNode, useState } from 'react';
import IconMinus from '../../assets/icon-minus.svg';
import IconPlus from '../../assets/icon-plus.svg';
import styles from './Accordion.module.scss';

export interface AccordionProps {
  title: string;
  className?: string;
  children: ReactNode;
  onClickOpened?: MouseEventHandler<HTMLDivElement>;
}

const Accordion = ({
  title,
  children,
  className,
  onClickOpened,
}: AccordionProps) => {
  const [isOpened, setIsOpened] = useState(false);

  return !isOpened ? (
    <div className={cx(styles.wrapper, className)} onClick={onClickOpened}>
      <div onClick={() => setIsOpened(true)} className={styles.row}>
        <h1>{title}</h1>
        <img src={IconPlus} alt="Back" width={24} height={24} />
      </div>
    </div>
  ) : (
    <div className={cx(styles.wrapper, className)}>
      <div onClick={() => setIsOpened(false)} className={styles.row}>
        <h1 className={styles.active}>{title}</h1>
        <img src={IconMinus} alt="Back" width={24} height={24} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Accordion;
