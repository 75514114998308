import { navigate, RouteComponentProps } from '@reach/router';
import { createMachine } from '@xstate/fsm';
import { useMachine } from '@xstate/react/fsm';
import cx from 'classnames';
import { stringifyUrl } from 'query-string';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { io, Socket } from 'socket.io-client';
import ERROR_MESSAGES from '../../api/error-messages';
import Logotype from '../../assets/logotype.svg';
import NoQRCode from '../../assets/noqrcode.svg';
import transactionLoading from '../../assets/transaction-loading.json';
import Button from '../../components/Form/Button';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import useAppSelector from '../../hooks/use-app-selector';
import useOnLogin from '../../hooks/use-on-login';
import styles from './QRCodeLoginScreen.module.scss';

type AuthorizationEvent = {
  eventData: {
    accessToken: string;
    anonymousId: string;
  };
};

const CODE_SIZE = 164;
const MIN_LOADING_TIME = 1.5 * 1000;
const TIMEOUT_TIME = 10 * 1000;

const codeStateMachine = createMachine({
  id: 'codeState',
  initial: 'loading',
  states: {
    loading: {
      on: {
        MIN_LOADING: 'min-loading',
        CODE_READY: 'code-ready',
        TIMEOUT: 'timeout',
      },
    },
    'min-loading': {
      on: {
        CODE_READY: 'loaded',
        TIMEOUT: 'timeout',
        RESET: 'loading',
      },
    },
    'code-ready': {
      on: {
        MIN_LOADING: 'loaded',
        TIMEOUT: 'timeout',
        RESET: 'loading',
      },
    },
    loaded: {
      on: {
        RESET: 'loading',
      },
    },
    timeout: {
      on: {
        RESET: 'loading',
      },
    },
  },
});

const QRCodeLoginScreen: FC<RouteComponentProps> = () => {
  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);

  const socketRef = useRef<Socket>();
  const [codeState, send] = useMachine(codeStateMachine);

  const [anonymousId, setAnonymousId] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [qrCodeImageURL, setQRCodeImageURL] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { onLogin } = useOnLogin({
    onFailure: (errorCode) =>
      setErrorMessage(
        ERROR_MESSAGES.SHARED[errorCode] || ERROR_MESSAGES.SHARED.unknown
      ),
  });

  useEffect(() => {
    if (qrCodeImageURL) {
      const img = new Image();

      img.onload = () => {
        send('CODE_READY');
      };
      img.src = qrCodeImageURL;
    }
  }, [send, qrCodeImageURL]);

  const onConnect = useCallback(() => {
    setQRCodeImageURL(
      stringifyUrl({
        // eslint-disable-next-line max-len
        url: `https://connect.${process.env.REACT_APP_BASE_DOMAIN}/qrcode/login-via-app/${socketRef.current?.id}`,
        query: {
          w: CODE_SIZE,
        },
      })
    );
  }, []);

  const onAuthorization = useCallback(
    async ({ eventData }: AuthorizationEvent) => {
      setAccessToken(eventData.accessToken);
      setAnonymousId(eventData.anonymousId);
    },
    []
  );

  useEffect(() => {
    if (codeState.value === 'timeout') {
      setErrorMessage(ERROR_MESSAGES.LOGIN['connect.timeout']);
    }
  }, [codeState]);

  useEffect(() => {
    setTimeout(() => {
      send('MIN_LOADING');
    }, MIN_LOADING_TIME);

    setTimeout(() => {
      send('TIMEOUT');
    }, TIMEOUT_TIME);

    socketRef.current = io(
      `https://connect.${process.env.REACT_APP_BASE_DOMAIN}/login-via-app`,
      {
        withCredentials: true,
        transports: ['websocket'],
      }
    );

    socketRef.current.on('connect', onConnect);
    socketRef.current.on('authorization', onAuthorization);

    return () => {
      socketRef.current?.off('connect', onConnect);
      socketRef.current?.off('authorization', onAuthorization);
      socketRef.current?.close();

      send('RESET');
    };
  }, [send, onConnect, onAuthorization]);

  useEffect(() => {
    (async () => {
      if (accessToken && anonymousId) {
        await onLogin({ accessToken, anonymousId });
      }
    })();
  }, [anonymousId, accessToken, onLogin]);

  return (
    <div
      className={cx('rwd-wrapper', 'login', isTokenFlow ? 'token-flow' : '')}
    >
      <aside>
        <Header />
        <MerchantHeader className={styles['merchant-header']} />
      </aside>
      <MainLayout
        showRibbon={false}
        showLogo={false}
        showFooter={false}
        className={styles.wrapper}
        contentClassName={styles.content}
      >
        <div className={styles.card}>
          <img
            className={styles.logo}
            src={Logotype}
            alt="Smartpay"
            height={27}
          />
          {['loading', 'min-loading', 'code-ready'].includes(
            codeState.value
          ) && (
            <div className={styles.loading}>
              <Lottie
                options={{
                  autoplay: true,
                  animationData: transactionLoading,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={128}
                width={128}
                isClickToPauseDisabled
              />
            </div>
          )}
          {['loaded'].includes(codeState.value) && (
            <img
              className={styles['qr-code-image']}
              src={qrCodeImageURL}
              alt=""
              height={128}
              width={128}
            />
          )}
          {codeState.value === 'timeout' && (
            <img
              className={styles['qr-code-image']}
              src={NoQRCode}
              alt=""
              height={128}
              width={128}
            />
          )}
        </div>
        {!errorMessage && <h1>アプリでスキャンしてください</h1>}
        {errorMessage && (
          <div className={styles.error}>
            <div className={styles['error-wrapper']}>
              <p>{errorMessage}</p>
            </div>
            <Button
              type="button"
              label="戻る"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        )}
      </MainLayout>
    </div>
  );
};

export default QRCodeLoginScreen;
