import { navigate, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import mapValues from 'lodash.mapvalues';
import {
  FC,
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import {
  postalCodeMaskOption,
  postalCodeTransformer,
} from '../../components/Form/utils';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import useAppSelector from '../../hooks/use-app-selector';
import { updateAuthPairs } from '../../redux/auth';
import {
  ProfilePairs,
  setIsPrefectureFieldReadOnly,
  submitProfile,
  updateProfilePairs,
} from '../../redux/profile';
import { debouncedPostalCodReq, sanitizePostalCode } from '../../utils';
import { rules } from '../../utils/validator';
import styles from './ProfileScreen.module.scss';
import useFormRegister, {
  DOB_PLACEHOLDER,
  getIsFormValid,
} from './use-form-register';

const ProfileScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const RefBtnSubmit = useRef<HTMLButtonElement>(null);

  const dispatch = useAppDispatch();
  const onUpdateProfilePairs = (pairs: ProfilePairs) =>
    dispatch(updateProfilePairs(pairs));

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribedMarketingEmails, setIsSubscribedMarketingEmails] =
    useState(true);

  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const profile = useAppSelector((appState) => appState.profile.data);
  const shippingInfoAddress = useAppSelector(
    (appState) => appState.good.data.shippingInfo?.address
  );
  const isPrefectureFieldReadOnly = useAppSelector(
    (appState) => appState.profile.isPrefectureFieldReadOnly
  );
  const rememberMe = useAppSelector((state) => state.misc.rememberMe);

  const profileData = {
    ...profile,
    ...(!profile.postalCode && {
      postalCode:
        profile.postalCode ||
        shippingInfoAddress?.postalCode?.replace?.(/-/g, '') ||
        '',
      state: profile.state || shippingInfoAddress?.administrativeArea || '',
      city: profile.city || shippingInfoAddress?.locality || '',
      address1: profile.address1 || shippingInfoAddress?.line1 || '',
      address2: profile.address2 || shippingInfoAddress?.line2 || '',
    }),
  };

  const {
    lastName,
    firstName,
    firstNameKana,
    lastNameKana,
    postalCode,
    state,
    city,
    address1,
    address2,
    gender,
    dateOfBirth,
  } = profileData;

  const {
    lastNameRegister,
    firstNameRegister,
    lastNameKanaRegister,
    firstNameKanaRegister,
    genderRegister,
    postalCodeRegister,
    stateRegister,
    cityRegister,
    address1Register,
    address2Register,
    dateOfBirthRegister,
    errors,
    dirtyFields,
    handleSubmit,
    setValue,
  } = useFormRegister(profileData);

  useLayoutEffect(() => {
    RefBtnSubmit?.current?.focus();
  }, []);

  const onSubmit = async () => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Submit',
    });

    setIsLoading(true);

    const resultAction = await dispatch(
      submitProfile({
        data: {
          lastName,
          firstName,
          firstNameKana,
          lastNameKana,
          dateOfBirth: dateOfBirth.replace(/\//g, '-'),
          legalGender: gender,
          address: {
            administrativeArea: state,
            locality: city,
            line1: address1,
            postalCode: sanitizePostalCode(postalCode),
            country: 'jp',
            ...(address2 && {
              line2: address2,
            }),
          },
          marketingOptIn: isSubscribedMarketingEmails,
        },
      })
    );

    if (submitProfile.fulfilled.match(resultAction)) {
      navigate('/payment', { replace: true });
    } else {
      setErrorMessage(ERROR_MESSAGES.SHARED.unknown);
    }
    setIsLoading(false);
  };

  const onPostalCodeChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    if (!rules.mustBePostalCode(value)) {
      return;
    }

    debouncedPostalCodReq({
      updateProfilePairs: onUpdateProfilePairs,
      successCallback: (result) => {
        if (result) {
          dispatch(setIsPrefectureFieldReadOnly(true));
          setValue('state', result.state, {
            shouldDirty: true,
            shouldValidate: true,
          });
          setValue('city', result.city, {
            shouldDirty: true,
            shouldValidate: true,
          });
          setValue('address1', result.address1, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue('address2', result.address2, { shouldValidate: true });
        } else {
          dispatch(setIsPrefectureFieldReadOnly(false));
        }
      },
      value,
    });

    postalCodeRegister.onChange(event);
  };

  const onGenderChange = (event: SyntheticEvent<HTMLSelectElement>) => {
    onUpdateProfilePairs({ gender: event.currentTarget.value });

    genderRegister.onChange(event);
  };

  const onToggleMarketingEmailSubscription = () => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: `Subscribe Marketing Emails - ${
        isSubscribedMarketingEmails ? 'UnChecked' : 'Checked'
      }`,
    });

    setIsSubscribedMarketingEmails(!isSubscribedMarketingEmails);
  };

  useEffect(() => {
    if (!rememberMe) {
      dispatch(updateAuthPairs({ email: '' }));
    }
  }, [dispatch, rememberMe]);

  return (
    <div
      className={cx('rwd-wrapper', 'profile', isTokenFlow ? 'token-flow' : '')}
    >
      <aside>
        <Header />
        <MerchantHeader />
      </aside>
      <MainLayout>
        <h2 className="main-form-title">個人情報を入力してください。</h2>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <fieldset className={styles['col-2']}>
            <TextInput
              label="姓"
              placeholder="田中"
              value={lastName}
              autoComplete="family-name"
              updatePairs={onUpdateProfilePairs}
              errorMessage={errors.lastName?.message}
              autoFocus
              {...lastNameRegister}
            />
            <TextInput
              label="名"
              placeholder="かおる"
              value={firstName}
              autoComplete="given-name"
              updatePairs={onUpdateProfilePairs}
              errorMessage={errors.firstName?.message}
              {...firstNameRegister}
            />
          </fieldset>
          <fieldset className={styles['col-2']}>
            <TextInput
              label="姓（カナ）"
              placeholder="タナカ"
              value={lastNameKana}
              updatePairs={onUpdateProfilePairs}
              errorMessage={errors.lastNameKana?.message}
              {...lastNameKanaRegister}
            />
            <TextInput
              label="名（カナ）"
              placeholder="カオル"
              value={firstNameKana}
              updatePairs={onUpdateProfilePairs}
              errorMessage={errors.firstNameKana?.message}
              {...firstNameKanaRegister}
            />
          </fieldset>
          <fieldset className={styles['gender-n-dob']}>
            <div className={cx('select-box', styles.gender)}>
              <label
                htmlFor="select_gender"
                {...(errors.gender?.message && {
                  className: 'label-error',
                })}
              >
                性別
              </label>
              <select
                id="select_gender"
                value={gender}
                {...(errors.gender?.message && {
                  className: 'select-error',
                })}
                {...genderRegister}
                onChange={onGenderChange} // must override onChange of genderRegister
              >
                <option value="">ーー</option>
                <option value="male" selected={gender === 'male'}>
                  男性
                </option>
                <option value="female" selected={gender === 'female'}>
                  女性
                </option>
              </select>
              {errors.gender?.message && (
                <div className="error-wrapper">
                  <p>{errors.gender?.message}</p>
                </div>
              )}
            </div>
            <div className={styles.dob}>
              <TextInput
                label="誕生日"
                placeholder={DOB_PLACEHOLDER}
                updatePairs={(pairs: ProfilePairs) => {
                  onUpdateProfilePairs(
                    mapValues(pairs, (value, key) => {
                      return key === 'dateOfBirth' && value
                        ? value.replace(/Y|M|D|\s/g, '')
                        : value;
                    })
                  );
                }}
                max="9999-12-31"
                value={dateOfBirth}
                autoComplete="bday"
                type="date"
                errorMessage={errors.dateOfBirth?.message}
                {...dateOfBirthRegister}
              />
            </div>
          </fieldset>
          <fieldset className={styles['zip-n-state']}>
            <TextInput
              label="郵便番号"
              pattern="\d{3}-\d{4}"
              placeholder="123-4567"
              value={postalCodeTransformer(postalCode)} // from '1234567' to '123-4567'
              autoComplete="postal-code"
              updatePairs={(pairs: ProfilePairs) => {
                if (pairs.postalCode !== undefined) {
                  onUpdateProfilePairs({ postalCode: pairs.postalCode });
                }

                if (!rules.mustBePostalCode(pairs.postalCode)) {
                  dispatch(setIsPrefectureFieldReadOnly(false));
                }
              }}
              className={styles.zip}
              maskOption={postalCodeMaskOption}
              errorMessage={errors.postalCode?.message}
              {...postalCodeRegister}
              onChange={onPostalCodeChange} // must override onChange of zipRegister
            />
            <TextInput
              label="都道府県"
              placeholder="東京都"
              value={state}
              autoComplete="address-level1"
              updatePairs={onUpdateProfilePairs}
              className={styles.state}
              errorMessage={errors.state?.message}
              {...(isPrefectureFieldReadOnly && { readOnly: true })}
              {...stateRegister}
            />
          </fieldset>
          <fieldset>
            <TextInput
              label="市区町村"
              placeholder="新宿区"
              value={city}
              autoComplete="address-level2"
              updatePairs={onUpdateProfilePairs}
              errorMessage={errors.city?.message}
              {...cityRegister}
            />
          </fieldset>
          <fieldset>
            <TextInput
              label="住所"
              placeholder="北沢○-○○"
              value={address1}
              autoComplete="address-line-1"
              updatePairs={onUpdateProfilePairs}
              errorMessage={errors.address1?.message}
              {...address1Register}
            />
          </fieldset>
          <fieldset className={styles.building}>
            <TextInput
              label="建物名・部屋番号"
              labelCaption="（任意）"
              placeholder="○○ビル○○号室"
              value={address2}
              autoComplete="address-line-2"
              updatePairs={onUpdateProfilePairs}
              {...address2Register}
            />
          </fieldset>
          <label className={styles['check-label']}>
            <input
              type="checkbox"
              onChange={onToggleMarketingEmailSubscription}
              checked={isSubscribedMarketingEmails}
            />
            <i />
            <span>Smartpayからお得な情報を受け取る</span>
          </label>
          {errorMessage && (
            <div className={styles['error-wrapper']}>
              <p>{errorMessage}</p>
            </div>
          )}
          <Button
            id="btn_submit"
            inputRef={RefBtnSubmit}
            loading={isLoading}
            type="submit"
            label="安全な登録を完了する"
            className={styles.btn}
            disabled={!getIsFormValid({ dirtyFields, errors })}
          />
        </form>
      </MainLayout>
    </div>
  );
};

export default ProfileScreen;
