import { stringifyUrl } from 'query-string';
import { LooseObject } from '../types';

const ONE_LINK_TMPL_BASE = 'https://smartpayco.onelink.me/12ky';

const createOneLink = (path: string, params: LooseObject = {}) =>
  stringifyUrl({
    url: ONE_LINK_TMPL_BASE,
    query: {
      deep_link_value: path,
      af_dp: `smartpay://${path}`,
      af_force_deeplink: true,
      pid: '',
      ...params,
    },
  });

export default createOneLink;
