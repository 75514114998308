import { RouteComponentProps } from '@reach/router';
import { FC } from 'react';
import Message from '../../components/Message/Message';
import useAppSelector from '../../hooks/use-app-selector';
import useUnloadStopper from '../../hooks/use-unload-stopper';
import { currencyFormatter } from '../../utils';
import getTotalAmount from '../../utils/get-total-amount';
import { getAmountForTheFirst } from '../../utils/installments';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import styles from './PaymentLoadingScreen.module.scss';

const PaymentLoadingScreen: FC<RouteComponentProps> = () => {
  const goods = useAppSelector((state) => state.good.data);
  const promotion = useAppSelector((state) => state.misc.promotion);
  const hasAppliedPromotionCode = useAppSelector(
    (state) => state.good.hasAppliedPromotionCode
  );
  const discountAmount = useAppSelector(
    (state) => state.paymentMethod.discountAmount
  );

  const { totalAmount } = getTotalAmount(
    goods,
    hasAppliedPromotionCode,
    discountAmount,
    promotion
  );

  useUnloadStopper();

  return (
    <LoadingScreen>
      <div className={styles['information-block']}>
        <Message
          type="info"
          message="オンラインショップの注文完了ページを確認後に注文が完了します。"
        />

        <p className={styles.status}>
          <span>
            <b className="price-amount">
              {currencyFormatter(getAmountForTheFirst(totalAmount))}
            </b>{' '}
            お支払い中
          </span>
        </p>
      </div>
    </LoadingScreen>
  );
};

export default PaymentLoadingScreen;
