import { navigate } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { useCallback, useState } from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import { APIPayload } from '../../api/types';
import OTPLayout, {
  getErrorMessage,
} from '../../components/OTPLayout/OTPLayout';
import useAppSelector from '../../hooks/use-app-selector';
import useOnLogin from '../../hooks/use-on-login';
import { loginWithOtp, sendLoginOTP } from '../../redux/auth';

const TwoFAScreen = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();
  const loginAttemptId = useAppSelector((state) => state.auth.loginAttemptId);
  const sessionIdWithSignature = useAppSelector(
    (state) => state.misc.sessionIdWithSignature
  );
  const [errorMessage, setErrorMessage] = useState<string>();

  const { onLogin } = useOnLogin({
    onFailure: (errorCode) => {
      switch (errorCode) {
        case 'live.test.mismatch':
          navigate(`/${sessionIdWithSignature}`, {
            state: { errorCode },
            replace: true,
          });
          break;
        default:
          setErrorMessage(
            ERROR_MESSAGES.SHARED[errorCode] || ERROR_MESSAGES.SHARED.unknown
          );
      }
    },
  });

  const onResendOtp = useCallback(
    (method) =>
      loginAttemptId && dispatch(sendLoginOTP({ loginAttemptId, method })),
    [dispatch, loginAttemptId]
  );

  const onSubmit = useCallback(
    async ({ otpSecret }) => {
      Mixpanel.trackAction({
        action: 'Click',
        itemName: 'Submit',
      });

      const loginWithOtpResultAction = await dispatch(
        loginWithOtp({
          loginAttemptId,
          otpSecret,
        })
      );

      if (loginWithOtp.fulfilled.match(loginWithOtpResultAction)) {
        await onLogin(loginWithOtpResultAction.payload);
      } else {
        const payload = loginWithOtpResultAction.payload as APIPayload;

        throw new Error(getErrorMessage(payload?.errorCode), {
          cause: payload?.details,
        });
      }
    },
    [dispatch, loginAttemptId, onLogin]
  );

  return (
    <OTPLayout
      onSubmit={onSubmit}
      onResendOtp={onResendOtp}
      errorMessage={errorMessage}
    />
  );
};

export default TwoFAScreen;
