import { navigate } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import { detectIncognito } from 'detectincognitojs';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '..';
import ERROR_MESSAGES from '../api/error-messages';
import { CheckoutType } from '../api/payment';
import { refreshToken } from '../redux/auth';
import { Platform, updateMiscPairs } from '../redux/misc';
import useAppSelector from './use-app-selector';
import useOnLogin from './use-on-login';

type DefaultPreferParams = {
  channel?: string;
  isIOS: boolean;
  isAndroid: boolean;
  isForcePlatformPromptFeatureEnabled: boolean;
  preferredPlatform?: Platform;
  isDemoSite?: boolean;
  isTokenFlow: boolean;
  checkoutType?: CheckoutType;
};

// https://stackoverflow.com/a/9039885
const isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
// https://stackoverflow.com/a/6031480
const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;

/**
 * Show platform preference prompt if:
 * - force feature is enabled
 * - or
 *   - is not demo site
 *   - and is not token order
 *   - and is iOS or is Android
 *   - and is not in incognito(private) mode
 *   - and checkoutType is not webOnly
 *   - and
 *     - preferredPlatform is not setted(=== undefined)
 *     - or preferredPlatform is 'app'
 */
const getDefaultPreferredPlatform = async ({
  isDemoSite,
  isForcePlatformPromptFeatureEnabled,
  isIOS, // eslint-disable-line @typescript-eslint/no-shadow
  isAndroid, // eslint-disable-line @typescript-eslint/no-shadow
  preferredPlatform,
  isTokenFlow,
  checkoutType,
}: DefaultPreferParams) => {
  if (isForcePlatformPromptFeatureEnabled) {
    return true;
  }

  if (isDemoSite) {
    return false;
  }

  if (isTokenFlow) {
    return false;
  }

  // Not iOS or Android, not show prompt
  if (!isIOS && !isAndroid) {
    return false;
  }

  const { isPrivate } = await detectIncognito();

  if (isPrivate) {
    return false;
  }

  // Disable the prompt for some merchants when we are in webview
  if (checkoutType === 'webOnly') {
    return false;
  }

  // undefined: No preference, show prompt
  // 'app': Consumer prefers app, show prompt
  // 'web': Opposite of above
  // any other value: Unexpected value, show prompt
  return preferredPlatform !== 'web';
};

const usePrepareApp = () => {
  const dispatch = useAppDispatch();
  const email = useAppSelector((state) => state.auth.email);
  const {
    sessionIdWithSignature,
    loading,
    rememberMe,
    isTestSession,
    isDemoSite,
    preferredPlatform,
    isTokenFlow,
    checkoutType,
  } = useAppSelector((state) => state.misc);
  const { isForcePlatformPromptFeatureEnabled } = useAppSelector(
    (state) => state.featureFlag
  );

  const [showPreferredPlatformPrompt, setShowPreferredPlatformPrompt] =
    useState<boolean>(false);
  const [nextRoute, setNextRoute] = useState<string>();
  const [errorCode, setErrorCode] =
    useState<keyof typeof ERROR_MESSAGES.SHARED>();

  const { onLogin } = useOnLogin({
    onSuccess: () => {
      Mixpanel.setUserProperties({ 'Is Returning User': true });
    },
    onFailure: (loginErrorCode) => {
      setErrorCode(loginErrorCode);
      dispatch(updateMiscPairs({ rememberMe: false }));
    },
  });

  useEffect(() => {
    if (!loading) {
      (async () => {
        setShowPreferredPlatformPrompt(
          await getDefaultPreferredPlatform({
            isIOS,
            isAndroid,
            isForcePlatformPromptFeatureEnabled,
            isDemoSite,
            preferredPlatform,
            isTokenFlow,
            checkoutType,
          })
        );
      })();
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps
  // preferredPlatform is not dep because this is only for initial value

  useEffect(() => {
    Mixpanel.setUserProperties({ 'Remember Me': rememberMe });
  }, [rememberMe]);

  useEffect(() => {
    (async () => {
      if (
        !showPreferredPlatformPrompt &&
        !isDemoSite &&
        email &&
        sessionIdWithSignature &&
        rememberMe
      ) {
        const refreshResultAction = await dispatch(refreshToken());

        if (refreshToken.fulfilled.match(refreshResultAction)) {
          await onLogin(refreshResultAction.payload);
        } else {
          // Refresh token is not working
          dispatch(updateMiscPairs({ rememberMe: false }));
          setNextRoute('/password');
        }
      }
    })();
  }, [
    dispatch,
    showPreferredPlatformPrompt,
    email,
    isDemoSite,
    sessionIdWithSignature,
    isTestSession,
    rememberMe,
    onLogin,
  ]);

  useEffect(() => {
    if (!loading && !showPreferredPlatformPrompt && nextRoute) {
      navigate(nextRoute);
    }
  }, [loading, showPreferredPlatformPrompt, nextRoute]);

  return {
    loading,
    preferredPlatform,
    showPreferredPlatformPrompt,
    setShowPreferredPlatformPrompt,
    errorCode,
  };
};

export default usePrepareApp;
