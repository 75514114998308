import cx from 'classnames';
import LoadingIcon from '../assets/loading.svg';
import styles from './Loading.module.scss';

const DEFAULT_LOADING_ICON_SIZE = 100;

const Loading = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => (
  <div className={cx(styles['loading-wrapper'], className)}>
    <img
      src={LoadingIcon}
      alt="Loading"
      width={size || DEFAULT_LOADING_ICON_SIZE}
      height={size || DEFAULT_LOADING_ICON_SIZE}
    />
  </div>
);

export default Loading;
