import Mixpanel from '@smartpay/mixpanel';
import {
  loadVGSCollect,
  VGSCollect,
  VGSForm,
  VGSFormState,
} from '@vgs/collect-js';
import cx from 'classnames';
import { useLayoutEffect, useState } from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import { APIPayload } from '../../api/types';
import ccPlaceholder from '../../assets/cc-vendors/placeholder.svg';
import IconInfoGray from '../../assets/icon-info-gray.svg';
import Button from '../../components/Form/Button';
import Popup from '../../components/Popup/Popup';
import useAppSelector from '../../hooks/use-app-selector';
import {
  addPaymentMethod,
  getPaymentMethods,
} from '../../redux/payment-method';
import ccVendors from '../../utils/cc-vendors';
import createEventHandler from '../../utils/create-event-handler';

import styles from './AddCardForm.module.scss';
import selectorStyles from './MethodSelector.module.scss';
import screenStyles from './PaymentScreen.module.scss';

const font =
  // eslint-disable-next-line max-len
  "400 14px/14px 'A-OTF UD Shin Go Pr6N', a-otf-ud-shin-go-pr6n, -apple-system, BlinkMacSystemFont, 'Hiragino Sans', Osaka, sans-serif";
const css = {
  height: '48px',
  width: '100%',
  boxSizing: 'border-box',
  color: '#2C2E57',
  font,
  '&::placeholder': {
    font,
    color: '#C7C8CC',
  },
};

const AddCardForm = ({
  errorMessage,
  onSubmitStart,
  onSubmitEnd,
  onAddCardError,
  submitButtonLabel,
}: {
  errorMessage?: string;
  onSubmitStart?: Function;
  onSubmitEnd?: Function;
  onAddCardError?: Function;
  submitButtonLabel?: string;
}) => {
  const dispatch = useAppDispatch();
  const getPaymentMethodError = useAppSelector(
    (state) => state.paymentMethod.getPaymentMethodError
  );
  const { isDemoSite } = useAppSelector((state) => state.misc);
  const [_errorMessage, setErrorMessage] = useState(
    getPaymentMethodError ? '保存されたカード情報を取得できませんでした' : ''
  );
  const [form, setForm] = useState<VGSForm>();
  const [formState, setFormState] = useState<VGSFormState>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formStateValues = Object.values(formState);

  const initForm = (vgsCollect: VGSCollect) => {
    const _form = vgsCollect.init((state) => {
      setFormState(state);

      if (
        state.card_number?.isValid &&
        ![
          'visa',
          'mastercard',
          'amex',
          'dinersclub',
          'discover',
          'jcb',
        ].includes(state.card_number?.cardType || '')
      ) {
        setErrorMessage(
          '現在のところ、Visa、Mastercard、Amex、Diners Club、JCBのクレジットカードがご利用いただけます。'
        );
      } else {
        setErrorMessage('');
      }
    });

    const name = _form
      .field('#card_name', {
        type: 'text',
        name: 'card_holder',
        placeholder: 'カードの名義人',
        validations: ['required'],
        autoComplete: 'cc-name',
        css: {
          ...css,
          padding: '0 16px',
        },
      })
      .replacePattern('/[^a-zA-Z\\s]+/g');

    const cardNumber = _form.field('#card_number', {
      type: 'card-number',
      name: 'card_number',
      errorColor: '#D4388A',
      placeholder: 'カード番号',
      validations: ['required', 'validCardNumber'],
      autoComplete: 'cc-number',
      showCardIcon: {
        left: '13px',
        width: '33px',
        height: '22px',
      },
      css: {
        ...css,
        paddingLeft: '53px',
        paddingRight: '10px',
      },
      icons: ccVendors,
    });

    const cardCVC = _form.field('#card_cvc', {
      type: 'card-security-code',
      name: 'card_cvc',
      errorColor: '#D4388A',
      placeholder: 'CVC',
      maxLength: 4,
      validations: ['required', 'validCardSecurityCode'],
      autoComplete: 'cc-csc',
      css,
    });

    const cardExpiry = _form.field('#card_expiry', {
      type: 'card-expiration-date',
      name: 'card_exp',
      errorColor: '#D4388A',
      placeholder: '月 / 年',
      validations: ['required', 'validCardExpirationDate'],
      autoComplete: 'cc-exp',
      yearLength: '2',
      css,
    });

    Promise.all([
      name.promise,
      cardNumber.promise,
      cardCVC.promise,
      cardExpiry.promise,
    ]).then(() => setIsLoading(false));
    setForm(_form);
  };

  useLayoutEffect(() => {
    const loadForm = async () => {
      Mixpanel.trackAction({
        action: 'Page View',
        itemName: 'VGS Form Initialized',
      });

      const vgsCollect: VGSCollect = await loadVGSCollect({
        vaultId: process.env.REACT_APP_VGS_VAULT_ID,
        version: '2.16.0',
        environment: process.env.REACT_APP_ENV === 'prod' ? 'live' : 'sandbox',
      });

      if (
        document.querySelectorAll(
          '#card_name, #card_number, #card_cvc, #card_expiry'
        )?.length === 4
      ) {
        initForm(vgsCollect);
      }
    };

    (async () => {
      await loadForm();

      Mixpanel.trackAction({
        action: 'Page View',
        itemName: 'VGS Form Loaded',
      });
    })();
  }, []);

  const _onSubmit = createEventHandler(
    async (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsSubmitting(true);
      onSubmitStart?.();

      Mixpanel.trackAction({
        action: 'Click',
        itemName: 'Add a New Card',
      });

      form?.submit('/', {}, async (status, data) => {
        if (status === 200 && formState.card_number) {
          const { cardType } = formState.card_number;
          const cardBrand = cardType === 'dinersclub' ? 'diners' : cardType;

          const addPaymentMethodResultAction = await dispatch(
            addPaymentMethod({
              brand: cardBrand,
              securityCodeToken: data.card_cvc,
              expiry: data.card_exp,
              holderName: data.card_holder,
              numberToken: data.card_number,
              kind: 'card',
              isDefault: true,
            })
          );

          if (addPaymentMethod.fulfilled.match(addPaymentMethodResultAction)) {
            dispatch(getPaymentMethods());

            await onSubmitEnd?.(addPaymentMethodResultAction.payload.id);

            return;
          }

          setErrorMessage(
            ERROR_MESSAGES.PAYMENT[
              (addPaymentMethodResultAction.payload as APIPayload)?.errorCode
            ] ||
              ERROR_MESSAGES.PAYMENT[
                'consumers.payment-methods.invalid-payment-method-rejected'
              ]
          );
        } else {
          setErrorMessage(ERROR_MESSAGES.SHARED.unknown);
        }

        onAddCardError?.();
        setIsSubmitting(false);
      });
    }
  );

  const isSubmitButtonDisabled =
    formStateValues.length === 0 ||
    !(
      formStateValues.every((s) => s.isDirty) &&
      formStateValues.every((s) => s.isValid)
    ) ||
    !!_errorMessage;

  return (
    <form id="form_add_card" className={styles.form} onSubmit={_onSubmit}>
      <div className={styles.fields}>
        <h4 className={screenStyles['card-form-title']}>
          <div className={styles['with-hint']}>
            カード情報
            <Popup
              trigger={
                <img
                  src={IconInfoGray}
                  width={20}
                  height={20}
                  loading="lazy"
                  alt="Tip"
                />
              }
              content={
                <div>
                  CVCはカード裏面にある３桁の
                  <br />
                  セキュリティコードです。
                </div>
              }
              hasMarginLeft
              position="bottom-right"
              shouldDisplayOnInit={isDemoSite}
            />
          </div>
        </h4>
        <fieldset>
          {isLoading && (
            <div className={styles['loading-wrapper']}>
              <div className={styles['card-name']}>
                <div className={styles.loading} />
              </div>
              <div className={styles['card-content-wrapper']}>
                <img
                  src={ccPlaceholder}
                  alt="placeholder"
                  width={33.33}
                  height={20}
                />
                <div className={styles.number}>
                  <div className={styles.loading} />
                </div>
                <div className={styles.expiry}>
                  <div className={styles.loading} />
                  /
                  <div className={styles.loading} />
                </div>
              </div>
            </div>
          )}
          <div
            className={
              isLoading
                ? styles['add-card-form-hide']
                : styles['add-card-form-show']
            }
          >
            <div id="card_name" className={styles['card-name']} />
            <div className={styles['card-content-wrapper']}>
              <div id="card_number" className={styles.number} />
              <div id="card_expiry" className={styles.expiry} />
              <div
                id="card_cvc"
                className={cx(
                  styles.cvc,
                  formState.card_number?.isValid
                    ? styles['add-card-form-show']
                    : styles['add-card-form-hide']
                )}
              />
            </div>
          </div>
        </fieldset>
        {(errorMessage || _errorMessage) && (
          <div className={styles['error-wrapper']}>
            <p>{errorMessage || _errorMessage}</p>
          </div>
        )}

        <p className={selectorStyles.note}>
          ※
          2024年5月8日以降、デビットカードおよびプリペイドカードでのお支払いはご利用いただけなくなります。
        </p>
        <p className={selectorStyles.note}>
          ※ 即時口座引き落としのご利用をご希望の方は
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href="https://smartpay.co/faq/consumer/entry/what-is-smartpay-bank-direct/"
            target="_blank"
          >
            こちら
          </a>
          をご確認ください。
        </p>
      </div>

      <Button
        id="btn_submit_add_card"
        type="submit"
        label={submitButtonLabel || 'カード追加する'}
        className={
          isSubmitting || isSubmitButtonDisabled
            ? styles['btn-add-card-disabled']
            : styles['btn-add-card-enabled']
        }
        disabled={isSubmitting || isSubmitButtonDisabled}
      />
    </form>
  );
};

export default AddCardForm;
