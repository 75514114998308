import { Link } from '@reach/router';
import cx from 'classnames';
import { ReactNode } from 'react';
import Logotype from '../assets/logotype.svg';
import useAppSelector from '../hooks/use-app-selector';
import useResponsive from '../hooks/use-responsive';
import { BtnBack } from './Header/Header';
import styles from './Layout.module.scss';

const Layout = ({
  greetings,
  desc1,
  desc2,
  className,
  isContentCentered = false,
  logo,
  showRibbon = true,
  showLogo = true,
  showFooter = true,
  hasBack = true,
  children,
  logoHeight,
  contentClassName,
}: {
  greetings?: string;
  desc1?: string;
  desc2?: string;
  className?: string;
  contentClassName?: string;
  isContentCentered?: boolean;
  logo?: ReactNode;
  showRibbon?: boolean;
  showLogo?: boolean;
  showFooter?: boolean;
  hasBack?: boolean;
  logoHeight?: number;
  children: ReactNode;
}) => {
  const { isMobile } = useResponsive();
  const isTestSession = useAppSelector((state) => state.misc.isTestSession);

  return (
    <main
      className={cx(
        styles.main,
        isContentCentered ? styles['content-centered'] : '',
        className
      )}
    >
      {hasBack && !isMobile && <BtnBack className={styles['btn-back']} />}
      {showRibbon && isTestSession && (
        <div className={cx(styles.ribbon, styles.test)} />
      )}
      {showLogo &&
        (logo || (
          <img
            className={styles.logo}
            src={Logotype}
            alt="Smartpay"
            height={logoHeight || 27}
          />
        ))}
      <div className={styles['inner-wrapper']}>
        <div className={cx(styles.content, contentClassName)}>
          {greetings && <h2 className="main-greetings">{greetings}</h2>}
          {desc1 && (
            <p
              className="main-desc"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: desc1 }}
            />
          )}
          {desc2 && <p className="main-desc">{desc2}</p>}
          {children}
        </div>
        {showFooter && (
          <div className={styles.footer}>
            <Link to="/faq" className={styles.faq}>
              よくあるご質問へ
            </Link>
            <div>
              <Link to="/terms" className={styles.tnc}>
                利用規約
              </Link>
              ・
              <Link to="/privacy" className={styles.tnc}>
                プライバシーポリシー
              </Link>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default Layout;
