import { AxiosResponse } from 'axios';
import browserId from '../utils/browser-id';
import publicAxiosInstance from './public-axios-instance';

export type CheckEmailValidityParams = {
  email: string;
};

export type GetMerchantParams = {
  anonymousMerchantId: string;
};

export type CheckEmailValidityReturn = Promise<
  AxiosResponse<{
    result: 'valid' | 'invalid' | 'unknown';
    reason: 'PASS' | 'UPSTREAM_ERROR';
  }>
>;

export type GetMerchantReturn = Promise<
  AxiosResponse<{
    id: string;
    title: string;
    titleKana: string;
    logo: {
      url: string;
    };
  }>
>;

const SmartpayCoAPI = {
  checkEmailValidity: ({
    email,
  }: CheckEmailValidityParams): CheckEmailValidityReturn =>
    publicAxiosInstance.post(
      `https://${process.env.REACT_APP_BASE_DOMAIN}/api/consumer/pre-signup/check`,
      {
        email,
      },
      {
        headers: {
          ETag: browserId,
          'Smartpay-Api-Specific-Version': '1.0',
        },
      }
    ),
  getMerchant: ({
    anonymousMerchantId,
  }: GetMerchantParams): GetMerchantReturn =>
    publicAxiosInstance.get(
      `https://${process.env.REACT_APP_BASE_DOMAIN}/data/merchants/${anonymousMerchantId}.json`
    ),
};

export default SmartpayCoAPI;
