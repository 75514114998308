import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { RouteComponentProps } from '@reach/router';
import format from 'date-fns/format';
import { FC } from 'react';
import Logotype from '../../assets/logotype.svg';
import Header from '../../components/Header/Header';
import privacyPolicy from '../../contentful/privacy-policy.json';
import styles from './LegalScreen.module.scss';

const PrivacyScreen: FC<RouteComponentProps> = () => (
  <div className="rwd-wrapper no-rwd">
    <Header
      logo={
        <img
          className={styles.logo}
          src={Logotype}
          alt="Smartpay"
          height={27}
        />
      }
      hasClose={false}
      mode="full-width"
    />
    <div className={styles.wrapper}>
      <div className={styles.article}>
        <time className={styles['last-updated']}>
          最終更新日: {format(new Date(privacyPolicy.lastUpdated), 'yyyy/M/dd')}
        </time>
        <h1 className={styles.title}>{privacyPolicy.title}</h1>
        {documentToReactComponents(privacyPolicy.content as Document)}
      </div>
    </div>
  </div>
);

export default PrivacyScreen;
