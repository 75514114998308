import cx from 'classnames';
import styles from './Message.module.scss';

type Props = {
  type?: 'info' | 'warning' | 'danger';
  message: string;
  className?: string;
};

const Message = ({ type = 'info', message, className }: Props) => {
  return (
    <div className={cx(styles.message, styles[type], className)}>{message}</div>
  );
};

export default Message;
