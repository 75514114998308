import { AxiosResponse } from 'axios';
import publicAxiosInstance from './public-axios-instance';

export type GetPromotionCodeParams = {
  promotionCode: string;
  sessionIdWithSignature: string;
};

export type GetPromotionCodeReturn = Promise<
  AxiosResponse<{
    data: Array<{
      active: boolean;
      code: string;
      coupon: {
        id: string;
        currency: 'jpy';
        discountAmount: number;
        discountPercentage: number;
        discountType: 'amount' | 'percentage';
        expiresAt: number;
        maxRedemptionCount: number;
        name: string;
      };
      currency: 'jpy';
      expiresAt: number;
      firstTimeTransaction: boolean;
      maxRedemptionCount: number;
      minimumAmount: number;
    }>;
  }>
>;

const MerchantAPI = {
  getPromotionCode: ({
    promotionCode,
    sessionIdWithSignature,
  }: GetPromotionCodeParams): GetPromotionCodeReturn =>
    publicAxiosInstance.get(
      // eslint-disable-next-line max-len
      `${
        process.env.REACT_APP_PAYMENTS_API_URL
      }/promotion-codes?code=${encodeURIComponent(promotionCode)}`,
      {
        headers: {
          'Smartpay-Checkout-Token': `${sessionIdWithSignature}`,
        },
      }
    ),
};

export default MerchantAPI;
