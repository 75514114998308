import Mixpanel from '@smartpay/mixpanel';
import { useEffect } from 'react';
import useAppSelector from './use-app-selector';

const BEFORE_UNLOAD_MSG =
  'ご注文をオンラインショップに送信しています\n数秒お待ちいただいた後、「OK」をタップしてください。';

const beforeUnloadHandler = function beforeUnloadHandler(
  event: BeforeUnloadEvent
) {
  event.preventDefault();

  Mixpanel.trackAction({
    action: 'Click',
    itemName: `Close browser window before being redirected back to the merchant.`,
  });

  return BEFORE_UNLOAD_MSG;
};

const disableStopper = () => {
  window.removeEventListener('beforeunload', beforeUnloadHandler, false);
};

const useUnloadStopper = (enabled = true) => {
  const { isDemoSite } = useAppSelector((state) => state.misc);

  useEffect(() => {
    if (enabled && !isDemoSite) {
      window.addEventListener('beforeunload', beforeUnloadHandler, false);
    }

    return () => {
      if (enabled && !isDemoSite) {
        window.removeEventListener('beforeunload', beforeUnloadHandler, false);
      }
    };
  }, [enabled, isDemoSite]);

  return disableStopper;
};

export default useUnloadStopper;
