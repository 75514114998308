import IconInfoGray from '../../assets/icon-info-gray.svg';
import IconSecurityCheck from '../../assets/icon-security-check.svg';
import useAppSelector from '../../hooks/use-app-selector';
import Popup from '../Popup/Popup';
import styles from './PasswordDescription.module.scss';

const PasswordDescription = ({ desc }: { desc: string }) => {
  const { isDemoSite } = useAppSelector((state) => state.misc);

  return (
    <div className={styles['desc-container']}>
      <div className={styles.desc}>{desc}</div>
      <Popup
        trigger={
          <img
            src={IconInfoGray}
            width={20}
            height={20}
            loading="lazy"
            alt="Password must"
          />
        }
        content={
          <div className={styles['popup-content']}>
            <h6>パスワードルール</h6>
            <ul>
              <li>8字以上</li>
              <li>
                連続した数字や簡単に推測できる単語など、安全性の低い文字列は使用できません（
                <em>password123</em>など）
              </li>
            </ul>
          </div>
        }
        position="bottom-left"
        shouldDisplayOnInit={isDemoSite}
      />
      <div className={styles.space} />
      <Popup
        trigger={
          <img
            src={IconSecurityCheck}
            width={20}
            height={20}
            loading="lazy"
            alt="Security check"
          />
        }
        content={
          <div className={styles['popup-content']}>
            <h6>セキュリティーの注意喚起</h6>
            <ul>
              <li>暗証番号を他者に教えないでください。</li>
              <li>利用者自らが口座登録を実施してください。</li>
              <li>
                パスワードを入力するときに誰かに見られないようにしてください。
              </li>
              <li>他人にパスワードの入力を代行させないでください。</li>
              <li>安全なインターネット環境で利用してください。</li>
            </ul>
          </div>
        }
        position="bottom-left"
      />
    </div>
  );
};

export default PasswordDescription;
