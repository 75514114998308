import cx from 'classnames';
import ccAMEX from '../../assets/cc-vendors/amex.svg';
import ccDiners from '../../assets/cc-vendors/diners.svg';
import ccDiscover from '../../assets/cc-vendors/discover.svg';
import ccHipercard from '../../assets/cc-vendors/hipercard.svg';
import ccJCB from '../../assets/cc-vendors/jcb.svg';
import ccMastercard from '../../assets/cc-vendors/mastercard.svg';
import ccUnionPay from '../../assets/cc-vendors/unionpay.svg';
import ccVisa from '../../assets/cc-vendors/visa.svg';
import srcIconAdd from '../../assets/icon-circle-add-solid.svg';
import bankDirectLogo from '../../assets/logo-smartpay-bank-direct.svg';
import { Bankpay, Card, PaymentMethod } from '../../redux/payment-method';
import styles from './MethodSelector.module.scss';

export const ccImgs: {
  [key: string]: string;
} = {
  amex: ccAMEX,
  diners: ccDiners,
  discover: ccDiscover,
  hipercard: ccHipercard,
  jcb: ccJCB,
  mastercard: ccMastercard,
  unionpay: ccUnionPay,
  visa: ccVisa,
};

export const testCardPurposes: { [key: string]: string } = {
  pm_test_visaApproved: 'success-visa',
  pm_test_mastercardApproved: 'success-mastercard',
  pm_test_declinedAcquiringOther: 'soft-decline',
  pm_test_declinedAcquiringInvalidCard: 'soft-decline-invalid-card',
  pm_test_declinedAcquiringCredit: 'soft-decline-credit',
  pm_test_declinedAcquiringCvc: 'soft-decline-cvc',
  pm_test_declinedAcquiringExpiry: 'soft-decline-expiry',
  pm_test_declinedAcquiringPrepaid: 'soft-decline-prepaid',
  pm_test_declinedAcquiringFraud: 'soft-decline-fraud',
  pm_test_declinedUnderwritingRisk: 'hard-decline',
  pm_test_declinedUnderwritingDebit: 'soft-decline-debit',
  pm_test_declinedUnderwritingPastDue: 'hard-decline-past-due',
  pm_test_declinedUnderwritingInsufficientBalance:
    'hard-decline-insufficient-balance',
  pm_test_declinedUnderwritingDisabled: 'hard-decline-disabled',
  pm_test_declinedUnderwritingUnderaged: 'hard-decline-underage',
};

const CardOption = ({
  paymentOption,
  onClick,
  showArrow,
  isTestAccount,
}: {
  paymentOption: Card;
  onClick?: Function;
  showArrow?: boolean;
  isTestAccount: boolean;
}) => {
  const { id, cardType, last4 } = paymentOption;

  return (
    <div
      id={id}
      data-method-id={id}
      className={cx(styles['saved-method'])}
      onClick={onClick as (event: React.SyntheticEvent<HTMLDivElement>) => void}
    >
      {showArrow && <i className={styles.arrow} />}
      <h3>
        {cardType && ccImgs[cardType] && (
          <img
            src={ccImgs[cardType]}
            alt={cardType}
            width={33.33}
            height={20}
          />
        )}
        <span className={styles.masked}>••••</span>
        {last4}
        {isTestAccount && testCardPurposes[id] && (
          <span className={cx(styles['test-label'], testCardPurposes[id])}>
            {testCardPurposes[id].split('-').join(' ')}
          </span>
        )}
      </h3>
    </div>
  );
};

const BankpayOption = ({
  paymentOption,
  onClick,
  showArrow,
}: {
  paymentOption: Bankpay;
  onClick?: Function;
  showArrow?: boolean;
}) => {
  const { id } = paymentOption;

  return (
    <div
      id={id}
      data-method-id={id}
      className={cx(styles['saved-method'])}
      onClick={onClick as (event: React.SyntheticEvent<HTMLDivElement>) => void}
    >
      {showArrow && <i className={styles.arrow} />}
      <h3>
        <img src={bankDirectLogo} alt="Bank Direct" height="20" />
      </h3>
    </div>
  );
};

const MethodOption = ({
  paymentOption,
  onClick,
  showArrow,
  isTestAccount,
}: {
  paymentOption?: PaymentMethod;
  onClick?: Function;
  showArrow?: boolean;
  isTestAccount: boolean;
}) => {
  if (!paymentOption) {
    return (
      <div
        className={cx(styles['saved-method'])}
        onClick={
          onClick as (event: React.SyntheticEvent<HTMLDivElement>) => void
        }
      >
        {showArrow && <i className={styles.arrow} />}
        <h3>
          <img
            src={srcIconAdd}
            className={styles['add-icon']}
            width={24}
            height={24}
            alt=""
          />
          <span className={styles.masked}>追加する</span>
        </h3>
      </div>
    );
  }

  switch (paymentOption.kind) {
    case 'card':
      return (
        <CardOption
          paymentOption={paymentOption as Card}
          onClick={onClick}
          showArrow={showArrow}
          isTestAccount={isTestAccount}
        />
      );
    case 'bank_account':
      return (
        <BankpayOption
          paymentOption={paymentOption as Bankpay}
          onClick={onClick}
          showArrow={showArrow}
        />
      );
    default:
      return null;
  }
};

export default MethodOption;
