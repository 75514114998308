// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import japanPostalCode from 'japan-postal-code-oasis';
import debounce from 'lodash.debounce';
import React from 'react';
// eslint-disable-next-line max-len
// https://github.com/medipass/react-payment-inputs/tree/1fa8391a0c2f68baa8bf6382905c6ae10b4080e8/src/utils
import * as cardTypes from './card-types';

export const formatCardNumber = (cardNumber: string) => {
  const cardType = cardTypes.getCardTypeByValue(cardNumber);

  if (!cardType) return (cardNumber.match(/\d+/g) || []).join('');

  const { format } = cardType;

  if (format && format.global) {
    return (cardNumber.match(format) || []).join(' ');
  }

  if (format) {
    const execResult = format.exec(cardNumber.split(' ').join(''));

    if (execResult) {
      return execResult
        .splice(1, 3)
        .filter((x) => x)
        .join(' ');
    }
  }

  return cardNumber;
};

export const formatExpiry = (
  event: React.BaseSyntheticEvent<{ data: string }>
) => {
  const eventData = event.nativeEvent && event.nativeEvent.data;
  const prevExpiry = event.target.value.split(' / ').join('/');

  if (!prevExpiry) {
    return '';
  }

  let expiry = prevExpiry;

  if (/^[2-9]$/.test(expiry)) {
    expiry = `0${expiry}`;
  }

  if (prevExpiry.length === 2 && +prevExpiry > 12) {
    const [head, ...tail] = prevExpiry.split('');

    expiry = `0${head}/${tail.join('')}`;
  }

  if (/^1[/-]$/.test(expiry)) {
    return `01 / `;
  }

  expiry = expiry.match(/(\d{1,2})/g) || [];

  if (expiry.length === 1) {
    if (!eventData && prevExpiry.includes('/')) {
      return expiry[0];
    }

    if (/\d{2}/.test(expiry)) {
      return `${expiry[0]} / `;
    }
  }

  if (expiry.length > 2) {
    const [, month = null, year = null] =
      expiry.join('').match(/^(\d{2}).*(\d{2})$/) || [];

    return [month, year].join(' / ');
  }

  return expiry.join(' / ');
};

export const sanitizePostalCode = (value?: string) =>
  value?.replace(/\D/g, '') || '';

export const sanitizeOTP = (value?: string) => value?.replace(/\D/g, '') || '';

const ZIP_REQ_DELAY = 250;
const ZIP_DATA_HOST = 'https://zipcode.smartpay.co/jp/';

type ZipReqResult = {
  state: string;
  city: string;
  address1: string;
  address2: string;
};
export const debouncedPostalCodReq = debounce(
  ({
    updateProfilePairs,
    successCallback,
    value,
  }: {
    updateProfilePairs: (result: ZipReqResult) => void;
    successCallback: (result?: ZipReqResult) => void;
    value: string;
  }) => {
    const sanitizedPostalCode = sanitizePostalCode(value);

    try {
      japanPostalCode(sanitizedPostalCode, ZIP_DATA_HOST).then(
        (address: { prefecture: string; city: string; area: string }) => {
          if (address) {
            const result = {
              state: address.prefecture,
              city: address.city,
              address1: address.area,
              address2: '',
            };

            updateProfilePairs(result);
            successCallback(result);
          } else {
            successCallback(undefined);
          }
        }
      );
    } catch (error) {
      // sentry erorr tracking when available
    }
  },
  ZIP_REQ_DELAY
);

export const formatPhoneNumber = (phone: string): string => {
  // Filter only numbers from the input
  const cleaned = `${phone}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);

  if (match) {
    return `${match[1]} ${match[2]} ${match[3]}`;
  }

  return phone;
};

type CurrencyFormatOptions = {
  dollarSign?: boolean;
};

const jpyCurrencyformatter = new Intl.NumberFormat('ja-JP');

export const currencyFormatter = (
  n: number,
  options: CurrencyFormatOptions = {}
) =>
  `${n < 0 ? '-' : ''}${
    options.dollarSign !== false ? '¥' : ''
  }${jpyCurrencyformatter.format(Math.abs(n))}`;

export const onRestartDemo = () => {
  window?.top?.postMessage('restart', '*');

  return false;
};
