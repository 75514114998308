import { AnyAction, combineReducers, Reducer } from 'redux';
import auth, { initialState as initAuthState } from './auth';
import demo from './demo';
import featureFlag from './feature-flag';
import good from './good';
import misc from './misc';
import paymentMethod from './payment-method';
import profile from './profile';
import token from './token';

const appReducer = combineReducers({
  auth,
  paymentMethod,
  profile,
  misc,
  good,
  token,
  demo,
  featureFlag,
});

export type RootBaseState = ReturnType<typeof appReducer>;

const rootReducer: Reducer<RootBaseState, AnyAction> = (state, action) => {
  if (action.type === 'CHECKOUT_RESET') {
    return appReducer(
      state &&
        ({
          auth: {
            ...initAuthState,
            email: state?.auth.email,
          },
          misc: state?.misc,
        } as RootBaseState),
      action
    );
  }

  return appReducer(state, action);
};

export const resetStore = () => ({
  type: 'CHECKOUT_RESET',
});

export default rootReducer;
