import {
  navigate,
  Redirect,
  RouteComponentProps,
  Router,
  useLocation,
} from '@reach/router';
import * as Sentry from '@sentry/react';
import Mixpanel from '@smartpay/mixpanel';
import kebabCase from 'kebab-case';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { parse, stringifyUrl } from 'query-string';
import { FC, useEffect } from 'react';
import './App.scss';
import useAppSelector from './hooks/use-app-selector';
import useSessionStatus from './hooks/use-order-status';
import { useAppDispatch } from './index';
import {
  initialState as allowedDemoParams,
  updateDemoPairs,
} from './redux/demo';
import {
  AllowedQueryStringFeatureFlag,
  NO_PREFIX,
  queryStringFeatureFlagMapping,
  updateFeaturePairs,
} from './redux/feature-flag';
import { getCheckoutSession } from './redux/good';
import { getPromotionCode, updateMiscPairs } from './redux/misc';
import {
  ProfilePairs,
  setIsPrefectureFieldReadOnly,
  updateProfilePairs,
} from './redux/profile';
import AssociateOTPScreen from './screens/AssociateScreen/AssociateOTPScreen';
import AssociateScreen from './screens/AssociateScreen/AssociateScreen';
import BankDirectScreen from './screens/BankDirectScreen/BankDirectScreen';
import ErrorScreen from './screens/ErrorScreen/ErrorScreen';
import FAQScreen from './screens/FAQScreen/FAQScreen';
import PrivacyScreen from './screens/LegalScreen/PrivacyScreen';
import TNCScreen from './screens/LegalScreen/TNCScreen';
import LimitDeclineScreen from './screens/LimitDeclineScreen/LimitDeclineScreen';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import PasswordScreen from './screens/LoginScreen/PasswordScreen';
import PaymentApprovalScreen from './screens/PaymentApprovalScreen/PaymentApprovalScreen';
// eslint-disable-next-line max-len
import PaymentApprovalSuccessScreen from './screens/PaymentApprovalSuccessScreen/PaymentApprovalSuccessScreen';
import PaymentScreen from './screens/PaymentScreen/PaymentScreen';
import PaymentSuccessScreen from './screens/PaymentSuccessScreen/PaymentSuccessScreen';
import PhoneScreen from './screens/PhoneScreen/PhoneScreen';
import PinCodeScreen from './screens/PinCodeScreen/PinCodeScreen';
import ProfileScreen from './screens/ProfileScreen/ProfileScreen';
import QRCodeLoginScreen from './screens/QRCodeLoginScreen/QRCodeLoginScreen';
import RecoverScreen from './screens/RecoverScreen/RecoverScreen';
import HostedFailureScreen from './screens/ResultHostedScreen/Failure';
import HostedSuccessScreen from './screens/ResultHostedScreen/Success';
import TwoFAScreen from './screens/TwoFAScreen/TwoFAScreen';
import { debouncedPostalCodReq } from './utils';
import ldStore from './utils/launch-darkly';
import { rules } from './utils/validator';

const ENV_MAP: {
  [key: string]: string;
} = {
  prod: 'production',
  dev: 'development',
};

Sentry.init({
  dsn:
    !!process.env.REACT_APP_SKIP_SENTRY_INIT ||
    !window.location.host.startsWith('checkout.smartpay.co')
      ? ''
      : 'https://578f7b479a6a4a4baba4c20325de2229@o982838.ingest.sentry.io/5938319',

  release: `checkout@${process.env.REACT_APP_VERSION}`,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: ENV_MAP[process.env.REACT_APP_ENV as string] || 'development',
});

Mixpanel.initialize({
  token: process.env.REACT_APP_MIXPANEL_TOKEN || '',
  disabled:
    !!process.env.REACT_APP_DISABLE_MIXPANEL ||
    !window.location.host.startsWith('checkout.smartpay.co'),
  superProps: {
    Product: 'Checkout',
    Platform: 'Web',
  },
  urlToScreen: {
    '/2fa': '2FA',
    '/phone': 'Phone',
    '/pin': 'PinCode',
    '/recover': 'Recover',
    '/password': 'Password',
    '/associate/google': 'SSO Google',
    '/associate/google/pin': 'SSO Google PinCode',
    '/profile': 'Profile',
    '/error/session-invalid': 'Error (Invalid Session)',
    '/error/payment-overdue-installments': 'Error (Overdue Installment)',
    '/error/payment-over-limit': 'Error (Over Limit)',
    '/error/payment-rejected': 'Error (Payment Rejected)',
    '/error/payment-link-not-found': 'Error (Payment Link Not Found)',
    '/payment': 'Payment',
    '/payment-success': 'Payment Success',
    '/faq': 'FAQ',
    '/failure': 'Failure',
    '/terms': 'Terms',
    '/privacy': 'Privacy',
    '/bank-direct': 'Bank Direct',
    '/challenge': '3DS Challenge',
    '/payment-approval': 'Payment Approval (Token Flow)',
    '/payment-approval-success': 'Payment Approval Success',
  },
});

const queryFlagValue = (value: string | (string | null)[] | null) =>
  value === null ? true : !!value;

const SemiPrivateRoute = ({
  as: Comp,
  ...props
}: {
  as: FC<RouteComponentProps>;
} & RouteComponentProps) => {
  const email = useAppSelector((state) => state.auth.email);
  const sessionIdWithSignature = useAppSelector(
    (state) => state.misc.sessionIdWithSignature
  );

  return email && sessionIdWithSignature ? (
    <Comp {...props} />
  ) : (
    <Redirect to="/error/session-invalid" />
  );
};

const SemiPrivateOIDCRoute = ({
  as: Comp,
  ...props
}: {
  as: FC<RouteComponentProps>;
} & RouteComponentProps) => {
  const ssoToken = useAppSelector((state) => state.auth.ssoToken);
  const sessionIdWithSignature = useAppSelector(
    (state) => state.misc.sessionIdWithSignature
  );

  return ssoToken && sessionIdWithSignature ? (
    <Comp {...props} />
  ) : (
    <Redirect to="/error/session-invalid" />
  );
};

const PrivateRoute = ({
  as: Comp,
  ...props
}: {
  as: FC<RouteComponentProps>;
} & RouteComponentProps) => {
  const accessToken = useAppSelector((state) => state.auth.accessToken);

  return accessToken ? (
    <Comp {...props} />
  ) : (
    <Redirect to="/error/session-invalid" />
  );
};

const LoginContainer = ({
  as: Comp,
  ...props
}: {
  as: FC<RouteComponentProps>;
} & RouteComponentProps<{ sessionIdWithSignature: string }>) => {
  const dispatch = useAppDispatch();
  const promotionCode = useAppSelector((state) => state.misc.promotionCode);
  const sessionIdWithSignature = props.sessionIdWithSignature || '';
  const location = useLocation();
  const params = parse(location.search);

  const isInvalidSessionId = (id: string) => {
    const sessionId = id.split('.')[0];

    return (
      !/checkout_(test|live)_/.test(sessionId) ||
      (sessionId.length !== 35 && sessionId.length !== 36)
    );
  };

  useEffect(() => {
    (async () => {
      const isValidSessionId = !isInvalidSessionId(sessionIdWithSignature);

      if (isValidSessionId) {
        dispatch(updateMiscPairs({ sessionIdWithSignature }));

        const sessionResultAction = await dispatch(
          getCheckoutSession({ sessionIdWithSignature })
        );

        if (getCheckoutSession.rejected.match(sessionResultAction)) {
          navigate('/error/session-invalid');
        } else {
          const session = sessionResultAction.payload.checkoutSession;
          const status =
            session.order?.status || session.token?.status || undefined;

          if (status === 'rejected') {
            navigate('/error/payment-rejected');

            return;
          }

          const newPostalCode =
            // eslint-disable-next-line max-len
            (sessionResultAction.payload.checkoutSession.customerInfo?.address
              ?.postalCode ||
              sessionResultAction.payload.checkoutSession.order?.shippingInfo
                ?.address?.postalCode) ??
            '';

          if (rules.mustBePostalCode(newPostalCode) === true) {
            debouncedPostalCodReq({
              updateProfilePairs: (pairs: ProfilePairs) =>
                dispatch(updateProfilePairs(pairs)),
              successCallback: (result) => {
                if (result) {
                  dispatch(setIsPrefectureFieldReadOnly(true));
                } else {
                  dispatch(setIsPrefectureFieldReadOnly(false));
                }
              },
              value: newPostalCode,
            });
          }

          if (promotionCode) {
            dispatch(
              getPromotionCode({ sessionIdWithSignature, promotionCode })
            );
          }

          Mixpanel.registerSuperProperties({
            'Session ID': sessionIdWithSignature.split('.')[0],
            'Is Test Session': sessionIdWithSignature.includes('_test_'),
          });
        }
      }
    })();
  }, [dispatch, sessionIdWithSignature, promotionCode]);

  // If PaymentRequest is not exist, we use this 'no-refresh' to
  // force the page reload once and check again.
  if (!window.PaymentRequest && !queryFlagValue(params['no-refresh'])) {
    navigate(
      stringifyUrl({
        url: location.href,
        query: { 'no-refresh': 1 },
      })
    );

    return null;
  }

  if (isInvalidSessionId(sessionIdWithSignature)) {
    return <Redirect to="/error/session-invalid" />;
  }

  return <Comp {...props} />;
};

const App = () => {
  const dispatch = useAppDispatch();
  const verifyOrderStatus = useSessionStatus();
  const ldClient = useLDClient();
  const ldFlags = useFlags();

  useEffect(() => {
    ldStore.set(ldClient);
  }, [ldClient]);

  useEffect(() => {
    const onchange = async () => {
      if (document.visibilityState === 'visible') {
        const status = await verifyOrderStatus();

        if (status === 'succeeded' || status === 'requires_capture') {
          navigate('/payment-success');
        } else if (status === 'rejected') {
          navigate('/error/payment-rejected');
        }
      }
    };

    document.addEventListener('visibilitychange', onchange, false);

    return () => {
      document.removeEventListener('visibilitychange', onchange, false);
    };
  }, [verifyOrderStatus]);

  useEffect(() => {
    const params = parse(window.location.search);
    const referrer = params.referrer?.toString?.() || document?.referrer || '';
    const promotionCode = params['promotion-code']?.toString?.() || '';
    const isDemoSite = params.demo?.toString?.() || '';
    const allowedDemoParamsList = Object.keys(allowedDemoParams);
    const filteredDemoParams = Object.keys(params).reduce((accu, k) => {
      if (allowedDemoParamsList.includes(k)) {
        return { ...accu, [k]: params[k] };
      }

      return accu;
    }, {});

    // Prepare the feature flags from LD and QS params
    // Use LD flag values
    const allowedFeatureParamsList = Object.keys(queryStringFeatureFlagMapping);
    const ldFeatureFlags = Object.keys(ldFlags || {}).reduce(
      (accu, rawFeature) => {
        const feature = kebabCase(rawFeature);

        if (
          allowedFeatureParamsList.includes(feature) &&
          feature in queryStringFeatureFlagMapping
        ) {
          return {
            ...accu,
            [queryStringFeatureFlagMapping[
              feature as AllowedQueryStringFeatureFlag
            ]]: queryFlagValue(ldFlags[rawFeature]),
          };
        }

        return accu;
      },
      {}
    );

    // Override the value if there are QS params
    const normalizedFeatureFlags = Object.keys(params).reduce(
      (accu, feature) => {
        if (
          allowedFeatureParamsList.includes(feature) &&
          feature in queryStringFeatureFlagMapping
        ) {
          return {
            ...accu,
            [queryStringFeatureFlagMapping[
              feature as AllowedQueryStringFeatureFlag
            ]]: queryFlagValue(params[feature]),
          };
        }

        if (feature.startsWith(NO_PREFIX)) {
          const disableFeature = feature.slice(NO_PREFIX.length);

          if (
            allowedFeatureParamsList.includes(disableFeature) &&
            disableFeature in queryStringFeatureFlagMapping
          ) {
            return {
              ...accu,
              [queryStringFeatureFlagMapping[
                disableFeature as AllowedQueryStringFeatureFlag
              ]]: !queryFlagValue(params[feature]),
            };
          }
        }

        return accu;
      },
      ldFeatureFlags
    );

    dispatch(updateDemoPairs(filteredDemoParams));
    dispatch(updateFeaturePairs(normalizedFeatureFlags));
    dispatch(updateMiscPairs({ referrer }));
    dispatch(updateMiscPairs({ promotionCode }));
    dispatch(updateMiscPairs({ isDemoSite: isDemoSite === 'true' }));
    dispatch(updateMiscPairs({ loginURL: window.location.href }));
  }, [dispatch, ldFlags]);

  return (
    <>
      <Router primary={false}>
        <QRCodeLoginScreen path="qr-code" />
        <TNCScreen path="terms" />
        <PrivacyScreen path="privacy" />
        <FAQScreen path="faq" />
        <ErrorScreen path="error/:errorCode" />
        <HostedSuccessScreen path="success" />
        <HostedFailureScreen path="failure" />
        <PaymentSuccessScreen path="payment-success" />
        <PaymentApprovalSuccessScreen path="payment-approval-success" />
        <ProfileScreen path="ppp" />
        <Redirect from="/" to="/error/session-invalid" />

        <SemiPrivateRoute as={PasswordScreen} path="password" />
        <SemiPrivateRoute as={TwoFAScreen} path="2fa" />
        <SemiPrivateRoute as={RecoverScreen} path="recover" />
        <SemiPrivateRoute as={PhoneScreen} path="phone" />
        <SemiPrivateRoute as={PinCodeScreen} path="pin" />

        <SemiPrivateOIDCRoute as={AssociateScreen} path="associate/:service" />
        <SemiPrivateOIDCRoute
          as={AssociateOTPScreen}
          path="associate/:service/pin"
        />

        <PrivateRoute
          as={LimitDeclineScreen as FC<RouteComponentProps>}
          path="limit-decline"
        />
        <PrivateRoute as={PaymentScreen} path="payment" />
        <PrivateRoute as={PaymentApprovalScreen} path="payment-approval" />
        <PrivateRoute as={ProfileScreen} path="profile" />
        <PrivateRoute as={BankDirectScreen} path="bank-direct" />

        <LoginContainer as={LoginScreen} path=":sessionIdWithSignature" />
      </Router>
    </>
  );
};

export default App;
