import debounce from 'lodash.debounce';
import { useMemo } from 'react';
import { useAppDispatch } from '..';
import { getCheckoutSession } from '../redux/good';
import useAppSelector from './use-app-selector';

const useSessionStatus = () => {
  const dispatch = useAppDispatch();
  const sessionIdWithSignature = useAppSelector(
    (state) => state.misc.sessionIdWithSignature
  );

  const verifyOrderStatus = useMemo(
    () =>
      debounce(
        async () => {
          if (sessionIdWithSignature) {
            const sessionResultAction = await dispatch(
              getCheckoutSession({ sessionIdWithSignature })
            );

            if (
              getCheckoutSession.fulfilled.match(sessionResultAction) &&
              sessionResultAction.payload.checkoutSession.order
            ) {
              const session = sessionResultAction.payload.checkoutSession;
              const status =
                session.order?.status || session.token?.status || 'unknown';

              return status;
            }
          }

          return 'unknown';
        },
        1000,
        { leading: true }
      ),
    [dispatch, sessionIdWithSignature]
  );

  return verifyOrderStatus;
};

export default useSessionStatus;
