import { RouteComponentProps } from '@reach/router';
import { FC, ReactNode } from 'react';
import Lottie from 'react-lottie';
import transactionLoading from '../../assets/transaction-loading.json';
import MainLayout from '../../components/Layout';
import styles from './LoadingScreen.module.scss';

const LoadingScreen: FC<RouteComponentProps> = ({
  children,
}: {
  children?: ReactNode;
}) => (
  <div className="rwd-wrapper">
    <MainLayout
      hasBack={false}
      showFooter={false}
      className={styles.layout}
      logoHeight={35.95}
    >
      <div className={styles.content}>
        <div className={styles['lottie-wrapper']}>
          <Lottie
            options={{
              autoplay: true,
              animationData: transactionLoading,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={125}
            width={125}
            isClickToPauseDisabled
          />
        </div>
        {children}
      </div>
    </MainLayout>
  </div>
);

export default LoadingScreen;
