import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import { useState } from 'react';
import IconArrowRight from '../../assets/icon-arrow-right.svg';
import useAppSelector from '../../hooks/use-app-selector';
import { currencyFormatter } from '../../utils';
import getTotalAmount from '../../utils/get-total-amount';
import styles from './MerchantHeader.module.scss';

const MerchantHeader = ({ className }: { className?: string }) => {
  const { promotion, isTokenFlow } = useAppSelector((state) => state.misc);
  const hasAppliedPromotionCode = useAppSelector(
    (state) => state.good.hasAppliedPromotionCode
  );
  const goods = useAppSelector((state) => state.good.data);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const discountAmount = useAppSelector(
    (state) => state.paymentMethod.discountAmount
  );

  const onToggle = () => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Check Cart Items',
    });

    setIsDetailVisible(!isDetailVisible);
  };

  const { totalAmount, isValidPromotion } = getTotalAmount(
    goods,
    hasAppliedPromotionCode,
    discountAmount,
    promotion
  );

  if (isTokenFlow) {
    return null;
  }

  return (
    <main className={cx(styles.main, className)}>
      <div className={styles['merchant-header']}>
        <div className={styles.merchant}>
          {/* <h2>{goods.store}</h2> */}
          <div className={styles['order-confirmation']}>
            注文内容を確認{' '}
            <span className={styles.number}>
              （{goods.lineItems?.length ?? 0}商品）
            </span>
          </div>
        </div>
        <div className={styles.control}>
          <div
            onClick={onToggle}
            className={cx(
              styles.button,
              isDetailVisible ? styles.collapse : ''
            )}
          >
            {!isDetailVisible && (
              <span className={cx('price-amount', styles['header-total'])}>
                {currencyFormatter(totalAmount)}
              </span>
            )}
            <img src={IconArrowRight} width={24} height={24} alt="Toggle" />
          </div>
        </div>
      </div>
      <div
        className={cx(
          styles['order-details'],
          isDetailVisible ? '' : styles.collapsed
        )}
      >
        <ul className={styles.items}>
          {goods.lineItems?.map((item, i) => (
            <li key={i}>
              {item.price.product.images.length > 0 && (
                <img
                  src={item.price.product.images[0] ?? ''}
                  width={64}
                  height={64}
                  alt={item.price.product.name}
                />
              )}
              <div className={styles.info}>
                <p className={styles.title}>{item.price.product.name}</p>
                <p className={styles.desc}>
                  {item.price.product.description ?? ''}
                </p>
              </div>
              <div className={styles.amount}>
                <div className={cx('price-amount', styles.price)}>
                  {currencyFormatter(item.price.amount)}
                </div>
                <div className={styles.quantity}>×{item.quantity}</div>
              </div>
            </li>
          ))}
        </ul>
        <ul className={styles.subtotal}>
          <li className={styles.subtotal}>
            {hasAppliedPromotionCode && isValidPromotion && promotion && (
              <div className={styles.row}>
                <div className={styles.info}>
                  <p className={styles.title}>{promotion.code}</p>
                  <p className={styles.subtitle}>プロモーションコード</p>
                </div>
                <div className={cx('price-amount', styles.amount)}>
                  {currencyFormatter(-discountAmount)}
                </div>
              </div>
            )}
            {goods.shippingInfo && goods.shippingInfo.feeAmount > 0 && (
              <div className={styles.row}>
                <div className={styles.info}>
                  <p className={styles.title}>配送手数料</p>
                </div>
                <div className={cx('price-amount', styles.amount)}>
                  {currencyFormatter(goods.shippingInfo?.feeAmount ?? 0)}
                </div>
              </div>
            )}
            {!!goods.lineItemsTotalDiscountAmount &&
              goods.lineItemsTotalDiscountAmount > 0 && (
                <div className={styles.row}>
                  <div className={styles.info}>
                    <p className={styles.title}>割引</p>
                  </div>
                  <div className={cx('price-amount', styles.amount)}>
                    {currencyFormatter(
                      -goods.lineItemsTotalDiscountAmount ?? 0
                    )}
                  </div>
                </div>
              )}
            {!!goods.lineItemsTotalTaxAmount &&
              goods.lineItemsTotalTaxAmount > 0 && (
                <div className={styles.row}>
                  <div className={styles.info}>
                    <p className={styles.title}>消費税</p>
                  </div>
                  <div className={cx('price-amount', styles.amount)}>
                    {currencyFormatter(goods.lineItemsTotalTaxAmount ?? 0)}
                  </div>
                </div>
              )}
          </li>
          <li className={styles.total}>
            <div className={styles.info}>
              <p className={styles.title}>
                合計<span>（税込）</span>
              </p>
            </div>
            <div className={cx('price-amount', styles.amount)}>
              {currencyFormatter(totalAmount)}
            </div>
          </li>
        </ul>
      </div>
    </main>
  );
};

export default MerchantHeader;
