import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import { APIPayload } from '../../api/types';
import OTPLayout, {
  getErrorMessage,
} from '../../components/OTPLayout/OTPLayout';
import useAppSelector from '../../hooks/use-app-selector';
import useOnLogin from '../../hooks/use-on-login';
import {
  completePasswordReset,
  createPasswordResetSession,
  sendPasswordResetOTP,
} from '../../redux/auth';

const RecoverScreen = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();
  const { email, passwordResetId } = useAppSelector((state) => state.auth);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { onLogin } = useOnLogin({
    onFailure: (errorCode) => setErrorMessage(getErrorMessage(errorCode)),
  });

  const onResendOtp = useCallback(
    (method) =>
      passwordResetId &&
      dispatch(sendPasswordResetOTP({ passwordResetId, method })),
    [dispatch, passwordResetId]
  );

  const onSubmit = useCallback(
    async ({ otpSecret, password }) => {
      Mixpanel.trackAction({
        action: 'Click',
        itemName: 'Submit',
      });

      const completePasswordResetResultAction = await dispatch(
        completePasswordReset({
          password,
          passwordResetId,
          otpSecret,
        })
      );

      if (
        completePasswordReset.fulfilled.match(completePasswordResetResultAction)
      ) {
        await onLogin(completePasswordResetResultAction.payload);
      } else {
        const payload = completePasswordResetResultAction.payload as APIPayload;

        throw new Error(getErrorMessage(payload?.errorCode), {
          cause: payload?.details,
        });
      }
    },
    [dispatch, onLogin, passwordResetId]
  );

  useEffect(() => {
    (async () => {
      if (email) {
        const resultAction = await dispatch(createPasswordResetSession(email));

        if (createPasswordResetSession.rejected.match(resultAction)) {
          setErrorMessage(ERROR_MESSAGES.SHARED.unknown);
        }
      }
    })();
  }, [dispatch, email]);

  // The API didn't pass the OTP via the POST consumers/password-resets endpoint
  // Get the OTP via retries endpoint for test account
  useEffect(() => {
    if (/\+test/.test(email) && passwordResetId) {
      dispatch(sendPasswordResetOTP({ passwordResetId, method: 'text' }));
    }
  }, [email, dispatch, passwordResetId]);

  return (
    <OTPLayout
      title="パスワードをお忘れですか？"
      requestPassword
      passwordDescription="新しいパスワードを作成してください。"
      onSubmit={onSubmit}
      onResendOtp={onResendOtp}
      errorMessage={errorMessage}
    />
  );
};

export default RecoverScreen;
