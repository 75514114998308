import { WindowLocation } from '@reach/router';
import cx from 'classnames';
import { useCallback, useMemo } from 'react';
import { PreCheckOrderResponse } from '../../api/payment';
import illEkycRequired from '../../assets/ill-ekyc-required.svg';
import illInsufficientBalance from '../../assets/ill-insufficient-balance.svg';
import Button from '../../components/Form/Button';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import useAppSelector from '../../hooks/use-app-selector';
import useResponsive from '../../hooks/use-responsive';
import createOneLink from '../../utils/create-one-link';
import styles from './LimitDeclineScreen.module.scss';

type LimitDeclineScreenProps = {
  location: WindowLocation<PreCheckOrderResponse>;
};

const LimitDeclineScreen = ({ location }: LimitDeclineScreenProps) => {
  const {
    state: { ekycVerified, purchaseMade, rejectionReason },
  } = location;

  const { isMobile } = useResponsive();
  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const rememberMe = useAppSelector((state) => state.misc.rememberMe);

  const onPayNow = useCallback(() => {
    document.location.href = createOneLink('payments');
  }, []);
  const onVerify = useCallback(() => {
    document.location.href = createOneLink('kyc-application');
  }, []);
  const onCheckLimits = useCallback(() => {
    document.location.href = createOneLink('kyc-status');
  }, []);

  const { title, reason, note, illSrc, buttonText, onPress } = useMemo(() => {
    const _note = '後からこの画面に戻り、購入を継続できます。';
    const _illSrc = illInsufficientBalance;

    if (rejectionReason === 'past_due_charges') {
      return {
        note: _note,
        illSrc: _illSrc,
        title: '未払いのお支払いがあります',
        reason: '未払いのお支払いを完了してからSmartpayをご利用ください。',
        buttonText: '今すぐ支払う',
        onPress: onPayNow,
      };
    }

    if (rejectionReason === 'ekyc_required') {
      return {
        note: '後からこの画面に戻り、購入を継続できます。',
        illSrc: illEkycRequired,
        title: '本人確認が必要です',
        reason:
          'この店舗でSmartpayによるチェックアウトを続けるには、身分証明書を用いて本人確認を行う必要があります。',
        buttonText: '利用可能額を確認する',
        onPress: onVerify,
      };
    }

    if (!ekycVerified) {
      if (purchaseMade) {
        return {
          note: _note,
          illSrc: _illSrc,
          title: 'ご利用限度額に達しました',
          reason:
            '利用可能額の上限に達しています。アプリで本人確認を行うと限度額を上げることができます。また、早めに残額の支払いを行うと購入できる場合もあります。',
          buttonText: '今すぐ支払う',
          onPress: onPayNow,
        };
      }

      return {
        note: _note,
        illSrc: _illSrc,
        title: 'ご利用限度額に達しました',
        reason:
          '利用可能額の上限に達しています。アプリで本人確認を行うと限度額を上げることができます。',
        buttonText: '本人確認する',
        onPress: onVerify,
      };
    }

    if (purchaseMade) {
      return {
        note: _note,
        illSrc: _illSrc,
        title: 'ご利用限度額に達しました',
        reason:
          '利用可能額の上限に達しています。早めに残額の支払いを行うと購入できる場合もあります。',
        buttonText: '今すぐ支払う',
        onPress: onPayNow,
      };
    }

    return {
      note: _note,
      illSrc: _illSrc,
      title: 'ご利用限度額に達しました',
      reason:
        '利用可能額の上限に達しています。\nアプリで利用可能額をご確認いただけます。',
      buttonText: '利用可能額を確認する',
      onPress: onCheckLimits,
    };
  }, [
    ekycVerified,
    purchaseMade,
    rejectionReason,
    onPayNow,
    onVerify,
    onCheckLimits,
  ]);

  return (
    <div className={cx('rwd-wrapper', isTokenFlow ? 'token-flow' : '')}>
      <aside>
        <Header hasBack={!rememberMe} />
        {!isMobile && <MerchantHeader />}
      </aside>
      <MainLayout
        hasBack={!rememberMe}
        showFooter={false}
        showLogo={!isMobile}
        className={styles.layout}
      >
        <div className={styles.content}>
          <img src={illSrc} alt="InsufficientBalance" />
          <div>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.reason}>{reason}</div>
            <div className={styles.note}>{note}</div>
          </div>

          <div className={styles.bottom}>
            {!ekycVerified && ekycVerified && (
              <Button
                className={cx(styles['left-button'], styles.button)}
                type="button"
                label="今すぐ支払う"
                variant="outline"
                onClick={onPayNow}
              />
            )}
            <Button
              className={styles.button}
              type="button"
              label={buttonText}
              onClick={onPress}
            />
          </div>
          <div className={styles['app-store-message']}>
            アプリをお持ちでない場合は、App Storeが開きます
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default LimitDeclineScreen;
