import cx from 'classnames';
import { useState } from 'react';
import srcIconBack from '../../assets/icon-back.svg';
import srcIconClose from '../../assets/icon-close.svg';
import Backdrop from '../Modal/Backdrop';
import AddCard from './AddCard';
import MethodSelector from './MethodSelector';
import styles from './PaymentMethodModal.module.scss';

type PaymentMethodModalScreen = 'selection' | 'addcard';

const PaymentMethodModal = ({
  onSelect,
  onCancel,
}: {
  onSelect: (method: string) => void;
  onCancel: () => void;
}) => {
  const [screen, setScreen] = useState<PaymentMethodModalScreen>('selection');

  const onClickBack = () => {
    switch (screen) {
      case 'addcard':
        setScreen('selection');
        break;
      default:
        onCancel();
    }
  };

  return (
    <>
      <Backdrop />
      <div className={styles.modal}>
        <div className={styles.header}>
          <button
            type="button"
            onClick={onClickBack}
            className={cx(
              styles['header-back'],
              screen === 'addcard' && styles['force-active']
            )}
          >
            <img src={srcIconBack} width={24} height={24} alt="Back" />
          </button>
          <button
            type="button"
            onClick={onCancel}
            className={styles['header-close']}
          >
            <img src={srcIconClose} width={24} height={24} alt="Close" />
          </button>
        </div>
        {screen === 'selection' && (
          <MethodSelector
            onSelect={onSelect}
            onClickAddCard={() => {
              setScreen('addcard');
            }}
          />
        )}
        {screen === 'addcard' && (
          <AddCard
            onAdded={() => {
              setScreen('selection');
            }}
          />
        )}
      </div>
    </>
  );
};

export default PaymentMethodModal;
