import { useCallback, useRef, useState } from 'react';

const useAsyncMessage = () => {
  const [shouldDisplayMessage, setShouldDisplayMessage] = useState(false);
  const promiseResolveRef = useRef<(value: unknown) => void>();

  const onMessage = useCallback(() => {
    setShouldDisplayMessage(true);

    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
    });
  }, []);

  const onDismiss = useCallback((value) => {
    setShouldDisplayMessage(false);
    promiseResolveRef.current?.(value);
  }, []);

  return { onMessage, onDismiss, shouldDisplayMessage };
};

export default useAsyncMessage;
