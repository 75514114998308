type Dict = { [key: string]: string };

const data: Dict = {
  '113101': 'CREDIT',
  '113102': 'CREDIT',
  '113103': 'CREDIT',
  '113104': 'CREDIT',
  '113105': 'CREDIT',
  '113106': 'CREDIT',
  '113107': 'CREDIT',
  '113108': 'CREDIT',
  '113109': 'CREDIT',
  '113110': 'CREDIT',
  '113111': 'CREDIT',
  '113112': 'CREDIT',
  '113113': 'CREDIT',
  '113114': 'CREDIT',
  '113115': 'CREDIT',
  '113116': 'CREDIT',
  '113117': 'CREDIT',
  '113118': 'CREDIT',
  '113119': 'CREDIT',
  '113120': 'CREDIT',
  '113121': 'CREDIT',
  '113122': 'CREDIT',
  '113123': 'CREDIT',
  '113124': 'CREDIT',
  '113125': 'CREDIT',
  '113126': 'CREDIT',
  '113127': 'CREDIT',
  '113128': 'CREDIT',
  '113129': 'CREDIT',
  '113130': 'CREDIT',
  '113131': 'CREDIT',
  '113132': 'CREDIT',
  '113133': 'CREDIT',
  '113134': 'CREDIT',
  '113135': 'CREDIT',
  '113136': 'CREDIT',
  '113137': 'CREDIT',
  '113138': 'CREDIT',
  '113139': 'CREDIT',
  '113140': 'CREDIT',
  '113141': 'CREDIT',
  '113142': 'CREDIT',
  '113143': 'CREDIT',
  '113144': 'CREDIT',
  '113145': 'CREDIT',
  '113146': 'CREDIT',
  '113147': 'CREDIT',
  '113148': 'CREDIT',
  '113149': 'CREDIT',
  '113150': 'CREDIT',
  '113151': 'CREDIT',
  '113152': 'CREDIT',
  '113153': 'CREDIT',
  '113154': 'CREDIT',
  '113155': 'CREDIT',
  '113156': 'CREDIT',
  '113157': 'CREDIT',
  '113158': 'CREDIT',
  '113159': 'CREDIT',
  '113160': 'CREDIT',
  '113161': 'CREDIT',
  '113162': 'CREDIT',
  '113163': 'CREDIT',
  '113164': 'CREDIT',
  '113165': 'CREDIT',
  '113166': 'CREDIT',
  '113167': 'CREDIT',
  '113168': 'CREDIT',
  '113169': 'CREDIT',
  '113170': 'CREDIT',
  '113171': 'CREDIT',
  '113172': 'CREDIT',
  '113173': 'CREDIT',
  '113174': 'CREDIT',
  '113175': 'CREDIT',
  '113176': 'CREDIT',
  '113177': 'CREDIT',
  '113178': 'CREDIT',
  '113179': 'CREDIT',
  '113180': 'CREDIT',
  '113181': 'CREDIT',
  '113182': 'CREDIT',
  '113183': 'CREDIT',
  '113184': 'CREDIT',
  '113185': 'CREDIT',
  '113186': 'CREDIT',
  '113187': 'CREDIT',
  '113188': 'CREDIT',
  '113189': 'CREDIT',
  '113190': 'CREDIT',
  '113191': 'CREDIT',
  '113192': 'CREDIT',
  '113193': 'CREDIT',
  '113194': 'CREDIT',
  '113195': 'CREDIT',
  '113196': 'CREDIT',
  '113197': 'CREDIT',
  '113198': 'CREDIT',
  '113199': 'CREDIT',
  '120611': 'CREDIT',
  '127698': 'DEBIT',
  '180000': 'CREDIT',
  '180001': 'CREDIT',
  '180002': 'CREDIT',
  '180003': 'CREDIT',
  '180004': 'CREDIT',
  '180005': 'CREDIT',
  '180006': 'CREDIT',
  '180007': 'CREDIT',
  '180008': 'CREDIT',
  '180009': 'CREDIT',
  '180010': 'CREDIT',
  '180011': 'CREDIT',
  '180012': 'CREDIT',
  '180013': 'CREDIT',
  '180014': 'CREDIT',
  '180015': 'CREDIT',
  '180016': 'CREDIT',
  '180017': 'CREDIT',
  '180018': 'CREDIT',
  '180019': 'CREDIT',
  '180020': 'CREDIT',
  '180021': 'CREDIT',
  '180022': 'CREDIT',
  '180023': 'CREDIT',
  '180024': 'CREDIT',
  '180025': 'CREDIT',
  '180026': 'CREDIT',
  '180027': 'CREDIT',
  '180028': 'CREDIT',
  '180029': 'CREDIT',
  '180030': 'CREDIT',
  '180031': 'CREDIT',
  '180032': 'CREDIT',
  '180033': 'CREDIT',
  '180034': 'CREDIT',
  '180035': 'CREDIT',
  '180036': 'CREDIT',
  '180037': 'CREDIT',
  '180038': 'CREDIT',
  '180039': 'CREDIT',
  '180040': 'CREDIT',
  '180041': 'CREDIT',
  '180042': 'CREDIT',
  '180043': 'CREDIT',
  '180044': 'CREDIT',
  '180045': 'CREDIT',
  '180046': 'CREDIT',
  '180047': 'CREDIT',
  '180048': 'CREDIT',
  '180049': 'CREDIT',
  '180050': 'CREDIT',
  '180051': 'CREDIT',
  '180052': 'CREDIT',
  '180053': 'CREDIT',
  '180054': 'CREDIT',
  '180055': 'CREDIT',
  '180056': 'CREDIT',
  '180057': 'CREDIT',
  '180058': 'CREDIT',
  '180059': 'CREDIT',
  '180060': 'CREDIT',
  '180061': 'CREDIT',
  '180062': 'CREDIT',
  '180063': 'CREDIT',
  '180064': 'CREDIT',
  '180065': 'CREDIT',
  '180066': 'CREDIT',
  '180067': 'CREDIT',
  '180068': 'CREDIT',
  '180069': 'CREDIT',
  '180070': 'CREDIT',
  '180071': 'CREDIT',
  '180072': 'CREDIT',
  '180073': 'CREDIT',
  '180074': 'CREDIT',
  '180075': 'CREDIT',
  '180076': 'CREDIT',
  '180077': 'CREDIT',
  '180078': 'CREDIT',
  '180079': 'CREDIT',
  '180080': 'CREDIT',
  '180081': 'CREDIT',
  '180082': 'CREDIT',
  '180083': 'CREDIT',
  '180084': 'CREDIT',
  '180085': 'CREDIT',
  '180086': 'CREDIT',
  '180087': 'CREDIT',
  '180088': 'CREDIT',
  '180089': 'CREDIT',
  '180090': 'CREDIT',
  '180091': 'CREDIT',
  '180092': 'CREDIT',
  '180093': 'CREDIT',
  '180094': 'CREDIT',
  '180095': 'CREDIT',
  '180096': 'CREDIT',
  '180097': 'CREDIT',
  '180098': 'CREDIT',
  '180099': 'CREDIT',
  '200000': 'DEBIT',
  '200100': 'DEBIT',
  '210191': 'DEBIT',
  '222300': 'DEBIT',
  '222880': 'CREDIT',
  '222897': 'CREDIT',
  '222924': 'CREDIT',
  '268258': 'CREDIT',
  '270280': 'CREDIT',
  '300100': 'CREDIT',
  '300101': 'CREDIT',
  '300140': 'DEBIT',
  '300150': 'CREDIT',
  '300300': 'CREDIT',
  '301120': 'CREDIT',
  '307000': 'DEBIT',
  '308800': 'DEBIT',
  '308801': 'DEBIT',
  '308802': 'DEBIT',
  '308803': 'DEBIT',
  '308804': 'DEBIT',
  '308805': 'DEBIT',
  '308806': 'DEBIT',
  '308807': 'DEBIT',
  '308808': 'DEBIT',
  '308809': 'DEBIT',
  '308810': 'DEBIT',
  '308811': 'CREDIT',
  '308812': 'CREDIT',
  '308813': 'DEBIT',
  '308814': 'DEBIT',
  '308815': 'DEBIT',
  '308816': 'DEBIT',
  '308817': 'DEBIT',
  '308818': 'DEBIT',
  '308819': 'DEBIT',
  '308820': 'DEBIT',
  '308821': 'DEBIT',
  '308822': 'DEBIT',
  '308823': 'DEBIT',
  '308824': 'DEBIT',
  '308825': 'DEBIT',
  '308826': 'DEBIT',
  '308827': 'DEBIT',
  '308828': 'DEBIT',
  '308829': 'DEBIT',
  '308830': 'DEBIT',
  '308831': 'DEBIT',
  '308832': 'DEBIT',
  '308833': 'DEBIT',
  '308834': 'DEBIT',
  '308835': 'DEBIT',
  '308836': 'DEBIT',
  '308837': 'DEBIT',
  '308838': 'DEBIT',
  '308839': 'DEBIT',
  '308840': 'DEBIT',
  '308841': 'DEBIT',
  '308842': 'DEBIT',
  '308843': 'DEBIT',
  '308844': 'DEBIT',
  '308845': 'DEBIT',
  '308846': 'DEBIT',
  '308847': 'DEBIT',
  '308848': 'DEBIT',
  '308849': 'DEBIT',
  '308850': 'DEBIT',
  '308851': 'CREDIT',
  '308852': 'DEBIT',
  '308853': 'DEBIT',
  '308854': 'DEBIT',
  '308855': 'DEBIT',
  '308856': 'DEBIT',
  '308857': 'CREDIT',
  '308858': 'DEBIT',
  '308859': 'DEBIT',
  '308860': 'DEBIT',
  '308861': 'DEBIT',
  '308862': 'DEBIT',
  '308863': 'DEBIT',
  '308864': 'DEBIT',
  '308865': 'DEBIT',
  '308866': 'DEBIT',
  '308867': 'DEBIT',
  '308868': 'DEBIT',
  '308869': 'DEBIT',
  '308870': 'DEBIT',
  '308871': 'DEBIT',
  '308872': 'DEBIT',
  '308873': 'DEBIT',
  '308874': 'DEBIT',
  '308875': 'DEBIT',
  '308876': 'DEBIT',
  '308877': 'DEBIT',
  '308878': 'DEBIT',
  '308879': 'DEBIT',
  '308880': 'DEBIT',
  '308881': 'CREDIT',
  '308882': 'DEBIT',
  '308883': 'DEBIT',
  '308884': 'DEBIT',
  '308885': 'DEBIT',
  '308886': 'DEBIT',
  '308887': 'DEBIT',
  '308888': 'DEBIT',
  '308889': 'DEBIT',
  '308890': 'DEBIT',
  '308891': 'DEBIT',
  '308892': 'DEBIT',
  '308893': 'DEBIT',
  '308894': 'DEBIT',
  '308895': 'DEBIT',
  '308896': 'DEBIT',
  '308897': 'DEBIT',
  '308898': 'DEBIT',
  '308899': 'DEBIT',
  '308900': 'DEBIT',
  '308901': 'DEBIT',
  '308902': 'DEBIT',
  '308903': 'DEBIT',
  '308904': 'DEBIT',
  '308905': 'DEBIT',
  '308906': 'DEBIT',
  '308907': 'DEBIT',
  '308908': 'DEBIT',
  '308909': 'DEBIT',
  '308910': 'DEBIT',
  '308911': 'DEBIT',
  '308912': 'DEBIT',
  '308913': 'DEBIT',
  '308914': 'DEBIT',
  '308915': 'DEBIT',
  '308916': 'DEBIT',
  '308917': 'DEBIT',
  '308918': 'DEBIT',
  '308919': 'DEBIT',
  '308920': 'DEBIT',
  '308921': 'DEBIT',
  '308922': 'DEBIT',
  '308923': 'DEBIT',
  '308924': 'DEBIT',
  '308925': 'DEBIT',
  '308926': 'DEBIT',
  '308927': 'DEBIT',
  '308928': 'DEBIT',
  '308929': 'DEBIT',
  '308930': 'DEBIT',
  '308931': 'DEBIT',
  '308932': 'DEBIT',
  '308933': 'DEBIT',
  '308934': 'DEBIT',
  '308935': 'DEBIT',
  '308936': 'DEBIT',
  '308937': 'DEBIT',
  '308938': 'DEBIT',
  '308939': 'DEBIT',
  '308940': 'DEBIT',
  '308941': 'DEBIT',
  '308942': 'DEBIT',
  '308943': 'DEBIT',
  '308944': 'DEBIT',
  '308945': 'DEBIT',
  '308946': 'DEBIT',
  '308947': 'DEBIT',
  '308948': 'DEBIT',
  '308949': 'DEBIT',
  '308951': 'DEBIT',
  '308952': 'CREDIT',
  '308953': 'DEBIT',
  '308954': 'DEBIT',
  '308955': 'DEBIT',
  '308956': 'DEBIT',
  '308957': 'DEBIT',
  '308958': 'DEBIT',
  '308959': 'DEBIT',
  '308960': 'DEBIT',
  '308961': 'DEBIT',
  '308962': 'DEBIT',
  '308963': 'DEBIT',
  '308964': 'DEBIT',
  '308965': 'DEBIT',
  '308966': 'DEBIT',
  '308967': 'DEBIT',
  '308968': 'DEBIT',
  '308969': 'DEBIT',
  '308970': 'DEBIT',
  '308971': 'DEBIT',
  '308972': 'DEBIT',
  '308973': 'DEBIT',
  '308974': 'DEBIT',
  '308975': 'DEBIT',
  '308976': 'DEBIT',
  '308977': 'DEBIT',
  '308978': 'DEBIT',
  '308979': 'DEBIT',
  '308980': 'DEBIT',
  '308981': 'DEBIT',
  '308982': 'DEBIT',
  '308983': 'DEBIT',
  '308984': 'DEBIT',
  '308985': 'DEBIT',
  '308986': 'DEBIT',
  '308987': 'DEBIT',
  '308988': 'DEBIT',
  '308989': 'DEBIT',
  '308990': 'DEBIT',
  '308991': 'DEBIT',
  '308992': 'DEBIT',
  '308993': 'DEBIT',
  '308994': 'DEBIT',
  '308995': 'DEBIT',
  '308996': 'DEBIT',
  '308997': 'DEBIT',
  '308998': 'DEBIT',
  '308999': 'DEBIT',
  '309000': 'DEBIT',
  '309001': 'DEBIT',
  '309002': 'CREDIT',
  '309003': 'DEBIT',
  '309004': 'DEBIT',
  '309005': 'DEBIT',
  '309006': 'DEBIT',
  '309007': 'DEBIT',
  '309008': 'DEBIT',
  '309009': 'DEBIT',
  '309010': 'DEBIT',
  '309011': 'DEBIT',
  '309012': 'DEBIT',
  '309013': 'DEBIT',
  '309014': 'DEBIT',
  '309015': 'DEBIT',
  '309016': 'DEBIT',
  '309017': 'DEBIT',
  '309018': 'DEBIT',
  '309019': 'DEBIT',
  '309020': 'DEBIT',
  '309021': 'DEBIT',
  '309022': 'DEBIT',
  '309023': 'DEBIT',
  '309024': 'DEBIT',
  '309025': 'DEBIT',
  '309026': 'DEBIT',
  '309027': 'DEBIT',
  '309028': 'DEBIT',
  '309029': 'DEBIT',
  '309030': 'DEBIT',
  '309031': 'DEBIT',
  '309032': 'DEBIT',
  '309033': 'DEBIT',
  '309034': 'DEBIT',
  '309035': 'DEBIT',
  '309036': 'DEBIT',
  '309037': 'DEBIT',
  '309038': 'DEBIT',
  '309039': 'DEBIT',
  '309040': 'DEBIT',
  '309041': 'DEBIT',
  '309042': 'DEBIT',
  '309043': 'DEBIT',
  '309044': 'DEBIT',
  '309045': 'DEBIT',
  '309046': 'DEBIT',
  '309047': 'DEBIT',
  '309048': 'DEBIT',
  '309049': 'DEBIT',
  '309050': 'DEBIT',
  '309051': 'DEBIT',
  '309052': 'DEBIT',
  '309053': 'DEBIT',
  '309054': 'DEBIT',
  '309055': 'DEBIT',
  '309056': 'DEBIT',
  '309057': 'DEBIT',
  '309058': 'DEBIT',
  '309059': 'DEBIT',
  '309060': 'DEBIT',
  '309061': 'DEBIT',
  '309062': 'DEBIT',
  '309063': 'DEBIT',
  '309064': 'DEBIT',
  '309065': 'DEBIT',
  '309066': 'DEBIT',
  '309067': 'DEBIT',
  '309068': 'DEBIT',
  '309069': 'DEBIT',
  '309070': 'DEBIT',
  '309071': 'DEBIT',
  '309072': 'DEBIT',
  '309073': 'DEBIT',
  '309074': 'DEBIT',
  '309075': 'DEBIT',
  '309076': 'DEBIT',
  '309077': 'DEBIT',
  '309078': 'DEBIT',
  '309079': 'DEBIT',
  '309080': 'DEBIT',
  '309081': 'DEBIT',
  '309082': 'DEBIT',
  '309083': 'DEBIT',
  '309084': 'DEBIT',
  '309085': 'DEBIT',
  '309086': 'DEBIT',
  '309087': 'DEBIT',
  '309088': 'DEBIT',
  '309089': 'DEBIT',
  '309090': 'DEBIT',
  '309091': 'DEBIT',
  '309092': 'DEBIT',
  '309093': 'DEBIT',
  '309094': 'DEBIT',
  '309095': 'DEBIT',
  '309096': 'DEBIT',
  '309097': 'DEBIT',
  '309098': 'DEBIT',
  '309099': 'DEBIT',
  '309100': 'DEBIT',
  '309101': 'DEBIT',
  '309102': 'DEBIT',
  '309103': 'DEBIT',
  '309104': 'DEBIT',
  '309105': 'DEBIT',
  '309106': 'DEBIT',
  '309107': 'DEBIT',
  '309108': 'DEBIT',
  '309109': 'DEBIT',
  '309110': 'DEBIT',
  '309111': 'DEBIT',
  '309112': 'DEBIT',
  '309113': 'DEBIT',
  '309114': 'DEBIT',
  '309115': 'DEBIT',
  '309116': 'DEBIT',
  '309117': 'DEBIT',
  '309118': 'DEBIT',
  '309119': 'DEBIT',
  '309120': 'DEBIT',
  '309121': 'DEBIT',
  '309122': 'DEBIT',
  '309123': 'DEBIT',
  '309124': 'DEBIT',
  '309125': 'DEBIT',
  '309126': 'DEBIT',
  '309127': 'DEBIT',
  '309128': 'DEBIT',
  '309129': 'DEBIT',
  '309130': 'DEBIT',
  '309131': 'DEBIT',
  '309132': 'DEBIT',
  '309133': 'DEBIT',
  '309134': 'DEBIT',
  '309135': 'DEBIT',
  '309136': 'DEBIT',
  '309137': 'DEBIT',
  '309138': 'DEBIT',
  '309139': 'DEBIT',
  '309140': 'DEBIT',
  '309141': 'DEBIT',
  '309142': 'DEBIT',
  '309143': 'CREDIT',
  '309144': 'DEBIT',
  '309145': 'DEBIT',
  '309146': 'DEBIT',
  '309147': 'DEBIT',
  '309148': 'DEBIT',
  '309149': 'DEBIT',
  '309150': 'DEBIT',
  '309151': 'DEBIT',
  '309152': 'DEBIT',
  '309153': 'DEBIT',
  '309154': 'DEBIT',
  '309155': 'DEBIT',
  '309156': 'DEBIT',
  '309157': 'DEBIT',
  '309158': 'DEBIT',
  '309159': 'DEBIT',
  '309160': 'CREDIT',
  '309161': 'DEBIT',
  '309162': 'DEBIT',
  '309163': 'DEBIT',
  '309164': 'DEBIT',
  '309165': 'DEBIT',
  '309166': 'DEBIT',
  '309167': 'DEBIT',
  '309168': 'DEBIT',
  '309169': 'DEBIT',
  '309170': 'DEBIT',
  '309171': 'DEBIT',
  '309172': 'DEBIT',
  '309173': 'DEBIT',
  '309174': 'DEBIT',
  '309175': 'DEBIT',
  '309176': 'DEBIT',
  '309177': 'DEBIT',
  '309178': 'DEBIT',
  '309179': 'DEBIT',
  '309180': 'DEBIT',
  '309181': 'DEBIT',
  '309182': 'DEBIT',
  '309183': 'DEBIT',
  '309184': 'DEBIT',
  '309185': 'DEBIT',
  '309186': 'DEBIT',
  '309187': 'DEBIT',
  '309188': 'DEBIT',
  '309189': 'DEBIT',
  '309190': 'DEBIT',
  '309191': 'DEBIT',
  '309192': 'DEBIT',
  '309193': 'DEBIT',
  '309194': 'DEBIT',
  '309195': 'DEBIT',
  '309196': 'DEBIT',
  '309197': 'DEBIT',
  '309198': 'DEBIT',
  '309199': 'DEBIT',
  '309200': 'DEBIT',
  '309201': 'DEBIT',
  '309202': 'DEBIT',
  '309203': 'DEBIT',
  '309204': 'DEBIT',
  '309205': 'DEBIT',
  '309206': 'DEBIT',
  '309207': 'DEBIT',
  '309208': 'DEBIT',
  '309209': 'DEBIT',
  '309210': 'DEBIT',
  '309211': 'DEBIT',
  '309212': 'DEBIT',
  '309213': 'DEBIT',
  '309214': 'DEBIT',
  '309215': 'DEBIT',
  '309216': 'DEBIT',
  '309217': 'DEBIT',
  '309218': 'DEBIT',
  '309219': 'DEBIT',
  '309220': 'DEBIT',
  '309221': 'DEBIT',
  '309222': 'DEBIT',
  '309223': 'DEBIT',
  '309224': 'DEBIT',
  '309225': 'DEBIT',
  '309226': 'DEBIT',
  '309227': 'DEBIT',
  '309228': 'DEBIT',
  '309229': 'DEBIT',
  '309230': 'DEBIT',
  '309231': 'DEBIT',
  '309232': 'DEBIT',
  '309233': 'DEBIT',
  '309234': 'DEBIT',
  '309235': 'DEBIT',
  '309236': 'DEBIT',
  '309237': 'DEBIT',
  '309238': 'DEBIT',
  '309239': 'DEBIT',
  '309240': 'DEBIT',
  '309241': 'DEBIT',
  '309242': 'DEBIT',
  '309243': 'DEBIT',
  '309244': 'DEBIT',
  '309245': 'DEBIT',
  '309246': 'DEBIT',
  '309247': 'DEBIT',
  '309248': 'DEBIT',
  '309249': 'DEBIT',
  '309250': 'DEBIT',
  '309251': 'DEBIT',
  '309252': 'DEBIT',
  '309253': 'DEBIT',
  '309254': 'DEBIT',
  '309255': 'DEBIT',
  '309256': 'DEBIT',
  '309257': 'DEBIT',
  '309258': 'DEBIT',
  '309259': 'DEBIT',
  '309260': 'DEBIT',
  '309261': 'DEBIT',
  '309262': 'DEBIT',
  '309263': 'DEBIT',
  '309264': 'DEBIT',
  '309265': 'DEBIT',
  '309266': 'DEBIT',
  '309267': 'DEBIT',
  '309268': 'DEBIT',
  '309269': 'DEBIT',
  '309270': 'DEBIT',
  '309271': 'DEBIT',
  '309272': 'DEBIT',
  '309273': 'DEBIT',
  '309274': 'DEBIT',
  '309275': 'DEBIT',
  '309276': 'DEBIT',
  '309277': 'DEBIT',
  '309278': 'DEBIT',
  '309279': 'DEBIT',
  '309280': 'DEBIT',
  '309281': 'DEBIT',
  '309282': 'DEBIT',
  '309283': 'DEBIT',
  '309284': 'DEBIT',
  '309285': 'DEBIT',
  '309286': 'DEBIT',
  '309287': 'DEBIT',
  '309288': 'DEBIT',
  '309289': 'DEBIT',
  '309290': 'DEBIT',
  '309291': 'DEBIT',
  '309292': 'DEBIT',
  '309293': 'DEBIT',
  '309294': 'DEBIT',
  '309295': 'DEBIT',
  '309296': 'DEBIT',
  '309297': 'DEBIT',
  '309298': 'DEBIT',
  '309299': 'DEBIT',
  '309300': 'DEBIT',
  '309301': 'DEBIT',
  '309302': 'DEBIT',
  '309303': 'DEBIT',
  '309304': 'DEBIT',
  '309305': 'DEBIT',
  '309306': 'DEBIT',
  '309307': 'DEBIT',
  '309308': 'DEBIT',
  '309309': 'DEBIT',
  '309310': 'DEBIT',
  '309311': 'DEBIT',
  '309312': 'DEBIT',
  '309313': 'DEBIT',
  '309314': 'DEBIT',
  '309315': 'DEBIT',
  '309316': 'DEBIT',
  '309317': 'DEBIT',
  '309318': 'DEBIT',
  '309319': 'DEBIT',
  '309320': 'DEBIT',
  '309321': 'DEBIT',
  '309322': 'DEBIT',
  '309323': 'DEBIT',
  '309324': 'DEBIT',
  '309325': 'DEBIT',
  '309326': 'DEBIT',
  '309327': 'DEBIT',
  '309328': 'DEBIT',
  '309329': 'DEBIT',
  '309330': 'DEBIT',
  '309331': 'DEBIT',
  '309332': 'DEBIT',
  '309333': 'DEBIT',
  '309334': 'DEBIT',
  '309335': 'DEBIT',
  '309336': 'DEBIT',
  '309337': 'DEBIT',
  '309338': 'DEBIT',
  '309339': 'DEBIT',
  '309340': 'DEBIT',
  '309341': 'DEBIT',
  '309342': 'DEBIT',
  '309343': 'DEBIT',
  '309344': 'DEBIT',
  '309345': 'DEBIT',
  '309346': 'DEBIT',
  '309347': 'DEBIT',
  '309348': 'CREDIT',
  '309349': 'DEBIT',
  '309350': 'DEBIT',
  '309351': 'DEBIT',
  '309352': 'DEBIT',
  '309353': 'DEBIT',
  '309354': 'DEBIT',
  '309355': 'DEBIT',
  '309356': 'DEBIT',
  '309357': 'DEBIT',
  '309358': 'DEBIT',
  '309359': 'DEBIT',
  '309360': 'DEBIT',
  '309361': 'DEBIT',
  '309362': 'DEBIT',
  '309363': 'DEBIT',
  '309364': 'DEBIT',
  '309365': 'DEBIT',
  '309366': 'DEBIT',
  '309367': 'DEBIT',
  '309368': 'DEBIT',
  '309369': 'DEBIT',
  '309370': 'DEBIT',
  '309371': 'DEBIT',
  '309372': 'DEBIT',
  '309373': 'DEBIT',
  '309374': 'DEBIT',
  '309375': 'DEBIT',
  '309376': 'DEBIT',
  '309377': 'DEBIT',
  '309378': 'DEBIT',
  '309379': 'DEBIT',
  '309380': 'DEBIT',
  '309381': 'DEBIT',
  '309382': 'DEBIT',
  '309383': 'DEBIT',
  '309384': 'DEBIT',
  '309385': 'DEBIT',
  '309386': 'DEBIT',
  '309387': 'DEBIT',
  '309388': 'DEBIT',
  '309389': 'DEBIT',
  '309390': 'DEBIT',
  '309391': 'DEBIT',
  '309392': 'DEBIT',
  '309393': 'DEBIT',
  '309394': 'DEBIT',
  '309395': 'DEBIT',
  '309396': 'DEBIT',
  '309397': 'DEBIT',
  '309398': 'DEBIT',
  '309399': 'DEBIT',
  '309400': 'DEBIT',
  '309401': 'DEBIT',
  '309402': 'DEBIT',
  '309403': 'DEBIT',
  '309404': 'DEBIT',
  '309405': 'DEBIT',
  '309406': 'DEBIT',
  '309407': 'DEBIT',
  '309408': 'DEBIT',
  '309409': 'DEBIT',
  '309410': 'DEBIT',
  '309411': 'DEBIT',
  '309412': 'DEBIT',
  '309413': 'DEBIT',
  '309414': 'DEBIT',
  '309415': 'DEBIT',
  '309416': 'DEBIT',
  '309417': 'DEBIT',
  '309418': 'DEBIT',
  '309419': 'DEBIT',
  '309420': 'DEBIT',
  '309421': 'DEBIT',
  '309422': 'DEBIT',
  '309423': 'DEBIT',
  '309424': 'DEBIT',
  '309425': 'DEBIT',
  '309426': 'DEBIT',
  '309427': 'DEBIT',
  '309428': 'DEBIT',
  '309429': 'DEBIT',
  '309430': 'DEBIT',
  '309431': 'DEBIT',
  '309432': 'DEBIT',
  '309433': 'DEBIT',
  '309434': 'DEBIT',
  '309435': 'DEBIT',
  '309436': 'DEBIT',
  '309437': 'DEBIT',
  '309438': 'DEBIT',
  '309439': 'DEBIT',
  '309440': 'DEBIT',
  '309441': 'DEBIT',
  '309442': 'DEBIT',
  '309443': 'DEBIT',
  '309444': 'DEBIT',
  '309445': 'DEBIT',
  '309446': 'DEBIT',
  '309447': 'DEBIT',
  '309448': 'DEBIT',
  '309449': 'DEBIT',
  '309450': 'DEBIT',
  '309451': 'DEBIT',
  '309452': 'DEBIT',
  '309453': 'DEBIT',
  '309454': 'DEBIT',
  '309455': 'DEBIT',
  '309456': 'DEBIT',
  '309457': 'DEBIT',
  '309458': 'DEBIT',
  '309459': 'DEBIT',
  '309460': 'DEBIT',
  '309461': 'DEBIT',
  '309462': 'DEBIT',
  '309463': 'DEBIT',
  '309464': 'DEBIT',
  '309465': 'DEBIT',
  '309466': 'DEBIT',
  '309467': 'DEBIT',
  '309468': 'DEBIT',
  '309469': 'DEBIT',
  '309470': 'DEBIT',
  '309471': 'DEBIT',
  '309472': 'DEBIT',
  '309473': 'DEBIT',
  '309474': 'DEBIT',
  '309475': 'DEBIT',
  '309476': 'DEBIT',
  '309477': 'DEBIT',
  '309478': 'DEBIT',
  '309479': 'DEBIT',
  '309480': 'DEBIT',
  '309481': 'DEBIT',
  '309482': 'DEBIT',
  '309483': 'DEBIT',
  '309484': 'DEBIT',
  '309485': 'DEBIT',
  '309486': 'DEBIT',
  '309487': 'DEBIT',
  '309488': 'DEBIT',
  '309489': 'DEBIT',
  '309490': 'DEBIT',
  '309491': 'DEBIT',
  '309492': 'DEBIT',
  '309493': 'DEBIT',
  '309494': 'DEBIT',
  '309495': 'DEBIT',
  '309496': 'DEBIT',
  '309497': 'DEBIT',
  '309498': 'DEBIT',
  '309499': 'DEBIT',
  '309600': 'DEBIT',
  '309601': 'DEBIT',
  '309602': 'DEBIT',
  '309603': 'DEBIT',
  '309604': 'DEBIT',
  '309605': 'DEBIT',
  '309606': 'DEBIT',
  '309607': 'DEBIT',
  '309608': 'DEBIT',
  '309609': 'CREDIT',
  '309610': 'DEBIT',
  '309611': 'DEBIT',
  '309612': 'DEBIT',
  '309613': 'DEBIT',
  '309614': 'DEBIT',
  '309615': 'DEBIT',
  '309616': 'DEBIT',
  '309617': 'DEBIT',
  '309618': 'DEBIT',
  '309619': 'DEBIT',
  '309620': 'DEBIT',
  '309621': 'DEBIT',
  '309622': 'DEBIT',
  '309623': 'DEBIT',
  '309624': 'DEBIT',
  '309625': 'DEBIT',
  '309626': 'DEBIT',
  '309627': 'DEBIT',
  '309628': 'DEBIT',
  '309629': 'DEBIT',
  '309630': 'DEBIT',
  '309631': 'DEBIT',
  '309632': 'DEBIT',
  '309633': 'DEBIT',
  '309634': 'DEBIT',
  '309635': 'DEBIT',
  '309636': 'DEBIT',
  '309637': 'DEBIT',
  '309638': 'DEBIT',
  '309639': 'CREDIT',
  '309640': 'DEBIT',
  '309641': 'DEBIT',
  '309642': 'DEBIT',
  '309643': 'DEBIT',
  '309644': 'DEBIT',
  '309645': 'DEBIT',
  '309646': 'DEBIT',
  '309647': 'DEBIT',
  '309648': 'DEBIT',
  '309649': 'DEBIT',
  '309650': 'DEBIT',
  '309651': 'DEBIT',
  '309652': 'DEBIT',
  '309653': 'DEBIT',
  '309654': 'DEBIT',
  '309655': 'DEBIT',
  '309656': 'DEBIT',
  '309657': 'DEBIT',
  '309658': 'DEBIT',
  '309659': 'DEBIT',
  '309660': 'DEBIT',
  '309661': 'DEBIT',
  '309662': 'DEBIT',
  '309663': 'DEBIT',
  '309664': 'DEBIT',
  '309665': 'DEBIT',
  '309666': 'DEBIT',
  '309667': 'DEBIT',
  '309668': 'DEBIT',
  '309669': 'DEBIT',
  '309670': 'DEBIT',
  '309671': 'DEBIT',
  '309672': 'DEBIT',
  '309673': 'DEBIT',
  '309674': 'DEBIT',
  '309675': 'DEBIT',
  '309676': 'DEBIT',
  '309677': 'DEBIT',
  '309678': 'DEBIT',
  '309679': 'DEBIT',
  '309680': 'CREDIT',
  '309681': 'DEBIT',
  '309682': 'DEBIT',
  '309683': 'DEBIT',
  '309684': 'DEBIT',
  '309685': 'DEBIT',
  '309686': 'DEBIT',
  '309687': 'DEBIT',
  '309688': 'DEBIT',
  '309689': 'DEBIT',
  '309690': 'DEBIT',
  '309691': 'DEBIT',
  '309692': 'DEBIT',
  '309693': 'DEBIT',
  '309694': 'DEBIT',
  '309695': 'DEBIT',
  '309696': 'DEBIT',
  '309697': 'DEBIT',
  '309698': 'DEBIT',
  '309699': 'DEBIT',
  '309700': 'DEBIT',
  '309701': 'DEBIT',
  '309702': 'DEBIT',
  '309703': 'DEBIT',
  '309704': 'DEBIT',
  '309705': 'DEBIT',
  '309706': 'DEBIT',
  '309707': 'DEBIT',
  '309708': 'DEBIT',
  '309709': 'DEBIT',
  '309710': 'DEBIT',
  '309711': 'DEBIT',
  '309712': 'DEBIT',
  '309713': 'DEBIT',
  '309714': 'DEBIT',
  '309715': 'DEBIT',
  '309716': 'DEBIT',
  '309717': 'DEBIT',
  '309718': 'DEBIT',
  '309719': 'DEBIT',
  '309720': 'DEBIT',
  '309721': 'DEBIT',
  '309722': 'DEBIT',
  '309723': 'DEBIT',
  '309724': 'DEBIT',
  '309725': 'DEBIT',
  '309726': 'DEBIT',
  '309727': 'DEBIT',
  '309728': 'DEBIT',
  '309729': 'DEBIT',
  '309730': 'DEBIT',
  '309731': 'DEBIT',
  '309732': 'DEBIT',
  '309733': 'DEBIT',
  '309734': 'DEBIT',
  '309735': 'DEBIT',
  '309736': 'DEBIT',
  '309737': 'DEBIT',
  '309738': 'DEBIT',
  '309739': 'DEBIT',
  '309740': 'DEBIT',
  '309741': 'DEBIT',
  '309742': 'DEBIT',
  '309743': 'DEBIT',
  '309744': 'DEBIT',
  '309745': 'DEBIT',
  '309746': 'DEBIT',
  '309747': 'DEBIT',
  '309748': 'DEBIT',
  '309749': 'DEBIT',
  '309750': 'DEBIT',
  '309751': 'DEBIT',
  '309752': 'DEBIT',
  '309753': 'DEBIT',
  '309754': 'DEBIT',
  '309755': 'DEBIT',
  '309756': 'DEBIT',
  '309757': 'DEBIT',
  '309758': 'DEBIT',
  '309759': 'DEBIT',
  '309760': 'DEBIT',
  '309761': 'DEBIT',
  '309762': 'DEBIT',
  '309763': 'DEBIT',
  '309764': 'DEBIT',
  '309765': 'DEBIT',
  '309766': 'DEBIT',
  '309767': 'DEBIT',
  '309768': 'DEBIT',
  '309769': 'DEBIT',
  '309770': 'DEBIT',
  '309771': 'DEBIT',
  '309772': 'DEBIT',
  '309773': 'DEBIT',
  '309774': 'DEBIT',
  '309775': 'DEBIT',
  '309776': 'DEBIT',
  '309777': 'DEBIT',
  '309778': 'DEBIT',
  '309779': 'DEBIT',
  '309780': 'DEBIT',
  '309781': 'DEBIT',
  '309782': 'DEBIT',
  '309783': 'DEBIT',
  '309784': 'DEBIT',
  '309785': 'DEBIT',
  '309786': 'DEBIT',
  '309787': 'DEBIT',
  '309788': 'DEBIT',
  '309789': 'DEBIT',
  '309790': 'DEBIT',
  '309791': 'DEBIT',
  '309792': 'DEBIT',
  '309793': 'DEBIT',
  '309794': 'DEBIT',
  '309795': 'DEBIT',
  '309796': 'DEBIT',
  '309797': 'DEBIT',
  '309798': 'DEBIT',
  '309799': 'DEBIT',
  '309800': 'DEBIT',
  '309801': 'DEBIT',
  '309802': 'DEBIT',
  '309803': 'DEBIT',
  '309804': 'DEBIT',
  '309805': 'DEBIT',
  '309806': 'DEBIT',
  '309807': 'DEBIT',
  '309808': 'DEBIT',
  '309809': 'DEBIT',
  '309810': 'DEBIT',
  '309811': 'DEBIT',
  '309812': 'DEBIT',
  '309813': 'DEBIT',
  '309814': 'DEBIT',
  '309815': 'DEBIT',
  '309816': 'DEBIT',
  '309817': 'DEBIT',
  '309818': 'DEBIT',
  '309819': 'DEBIT',
  '309820': 'DEBIT',
  '309821': 'DEBIT',
  '309822': 'DEBIT',
  '309823': 'DEBIT',
  '309824': 'DEBIT',
  '309825': 'DEBIT',
  '309826': 'DEBIT',
  '309827': 'DEBIT',
  '309828': 'DEBIT',
  '309829': 'DEBIT',
  '309830': 'DEBIT',
  '309831': 'DEBIT',
  '309832': 'DEBIT',
  '309833': 'DEBIT',
  '309834': 'DEBIT',
  '309835': 'DEBIT',
  '309836': 'DEBIT',
  '309837': 'DEBIT',
  '309838': 'DEBIT',
  '309839': 'DEBIT',
  '309840': 'DEBIT',
  '309841': 'DEBIT',
  '309842': 'DEBIT',
  '309843': 'DEBIT',
  '309844': 'DEBIT',
  '309845': 'DEBIT',
  '309846': 'DEBIT',
  '309847': 'DEBIT',
  '309848': 'DEBIT',
  '309849': 'DEBIT',
  '309850': 'DEBIT',
  '309851': 'DEBIT',
  '309852': 'DEBIT',
  '309853': 'DEBIT',
  '309854': 'DEBIT',
  '309855': 'DEBIT',
  '309856': 'DEBIT',
  '309857': 'DEBIT',
  '309858': 'DEBIT',
  '309859': 'DEBIT',
  '309860': 'DEBIT',
  '309861': 'DEBIT',
  '309862': 'DEBIT',
  '309863': 'DEBIT',
  '309864': 'DEBIT',
  '309865': 'DEBIT',
  '309866': 'DEBIT',
  '309867': 'DEBIT',
  '309868': 'DEBIT',
  '309869': 'DEBIT',
  '309870': 'DEBIT',
  '309871': 'DEBIT',
  '309872': 'DEBIT',
  '309873': 'DEBIT',
  '309874': 'DEBIT',
  '309875': 'DEBIT',
  '309876': 'DEBIT',
  '309877': 'DEBIT',
  '309878': 'DEBIT',
  '309879': 'DEBIT',
  '309880': 'DEBIT',
  '309881': 'DEBIT',
  '309882': 'DEBIT',
  '309883': 'DEBIT',
  '309884': 'DEBIT',
  '309885': 'DEBIT',
  '309886': 'DEBIT',
  '309887': 'DEBIT',
  '309888': 'DEBIT',
  '309889': 'DEBIT',
  '309890': 'DEBIT',
  '309891': 'DEBIT',
  '309892': 'DEBIT',
  '309893': 'DEBIT',
  '309894': 'DEBIT',
  '309895': 'DEBIT',
  '309896': 'DEBIT',
  '309897': 'DEBIT',
  '309898': 'DEBIT',
  '309899': 'DEBIT',
  '309900': 'CREDIT',
  '309901': 'DEBIT',
  '309902': 'DEBIT',
  '309903': 'DEBIT',
  '309904': 'DEBIT',
  '309905': 'CREDIT',
  '309906': 'DEBIT',
  '309907': 'DEBIT',
  '309908': 'DEBIT',
  '309909': 'DEBIT',
  '309910': 'DEBIT',
  '309911': 'DEBIT',
  '309912': 'DEBIT',
  '309913': 'DEBIT',
  '309914': 'DEBIT',
  '309915': 'DEBIT',
  '309916': 'DEBIT',
  '309917': 'DEBIT',
  '309918': 'DEBIT',
  '309919': 'DEBIT',
  '309920': 'DEBIT',
  '309921': 'DEBIT',
  '309922': 'DEBIT',
  '309923': 'DEBIT',
  '309924': 'DEBIT',
  '309925': 'DEBIT',
  '309926': 'DEBIT',
  '309927': 'DEBIT',
  '309928': 'DEBIT',
  '309929': 'DEBIT',
  '309930': 'DEBIT',
  '309931': 'DEBIT',
  '309932': 'DEBIT',
  '309933': 'DEBIT',
  '309934': 'DEBIT',
  '309935': 'DEBIT',
  '309936': 'DEBIT',
  '309937': 'DEBIT',
  '309938': 'DEBIT',
  '309939': 'DEBIT',
  '309940': 'DEBIT',
  '309941': 'DEBIT',
  '309942': 'DEBIT',
  '309943': 'DEBIT',
  '309944': 'DEBIT',
  '309945': 'DEBIT',
  '309946': 'DEBIT',
  '309947': 'DEBIT',
  '309948': 'DEBIT',
  '309949': 'DEBIT',
  '309950': 'DEBIT',
  '309951': 'DEBIT',
  '309952': 'DEBIT',
  '309953': 'DEBIT',
  '309954': 'DEBIT',
  '309955': 'DEBIT',
  '309956': 'DEBIT',
  '309957': 'DEBIT',
  '309958': 'DEBIT',
  '309959': 'DEBIT',
  '309960': 'CREDIT',
  '309961': 'DEBIT',
  '309962': 'DEBIT',
  '309963': 'DEBIT',
  '309964': 'DEBIT',
  '309965': 'DEBIT',
  '309966': 'DEBIT',
  '309967': 'DEBIT',
  '309968': 'DEBIT',
  '309969': 'DEBIT',
  '309970': 'DEBIT',
  '309971': 'DEBIT',
  '309972': 'DEBIT',
  '309973': 'DEBIT',
  '309974': 'DEBIT',
  '309975': 'DEBIT',
  '309976': 'DEBIT',
  '309977': 'DEBIT',
  '309978': 'DEBIT',
  '309979': 'DEBIT',
  '309980': 'DEBIT',
  '309981': 'DEBIT',
  '309982': 'DEBIT',
  '309983': 'DEBIT',
  '309984': 'DEBIT',
  '309985': 'DEBIT',
  '309986': 'DEBIT',
  '309987': 'DEBIT',
  '309988': 'DEBIT',
  '309989': 'DEBIT',
  '309990': 'DEBIT',
  '309991': 'DEBIT',
  '309992': 'DEBIT',
  '309993': 'DEBIT',
  '309994': 'DEBIT',
  '309995': 'DEBIT',
  '309996': 'DEBIT',
  '309997': 'DEBIT',
  '309998': 'DEBIT',
  '309999': 'DEBIT',
  '310000': 'CREDIT',
  '310001': 'DEBIT',
  '310002': 'DEBIT',
  '310003': 'DEBIT',
  '310004': 'DEBIT',
  '310005': 'DEBIT',
  '310006': 'DEBIT',
  '310007': 'DEBIT',
  '310008': 'DEBIT',
  '310009': 'DEBIT',
  '310011': 'DEBIT',
  '310012': 'DEBIT',
  '310013': 'DEBIT',
  '310014': 'DEBIT',
  '310015': 'DEBIT',
  '310016': 'DEBIT',
  '310017': 'DEBIT',
  '310018': 'DEBIT',
  '310019': 'DEBIT',
  '310020': 'DEBIT',
  '310021': 'DEBIT',
  '310022': 'DEBIT',
  '310023': 'DEBIT',
  '310024': 'DEBIT',
  '310025': 'DEBIT',
  '310026': 'DEBIT',
  '310027': 'DEBIT',
  '310028': 'DEBIT',
  '310029': 'CREDIT',
  '310030': 'DEBIT',
  '310031': 'DEBIT',
  '310032': 'DEBIT',
  '310033': 'DEBIT',
  '310034': 'DEBIT',
  '310035': 'DEBIT',
  '310036': 'DEBIT',
  '310037': 'DEBIT',
  '310038': 'DEBIT',
  '310039': 'DEBIT',
  '310040': 'DEBIT',
  '310041': 'DEBIT',
  '310042': 'DEBIT',
  '310043': 'DEBIT',
  '310044': 'DEBIT',
  '310045': 'DEBIT',
  '310046': 'DEBIT',
  '310047': 'DEBIT',
  '310048': 'DEBIT',
  '310049': 'DEBIT',
  '310050': 'DEBIT',
  '310051': 'DEBIT',
  '310052': 'DEBIT',
  '310053': 'DEBIT',
  '310054': 'DEBIT',
  '310055': 'DEBIT',
  '310056': 'DEBIT',
  '310057': 'DEBIT',
  '310058': 'DEBIT',
  '310059': 'DEBIT',
  '310060': 'DEBIT',
  '310061': 'DEBIT',
  '310062': 'DEBIT',
  '310063': 'DEBIT',
  '310064': 'DEBIT',
  '310065': 'DEBIT',
  '310066': 'DEBIT',
  '310067': 'DEBIT',
  '310068': 'DEBIT',
  '310069': 'DEBIT',
  '310070': 'DEBIT',
  '310071': 'DEBIT',
  '310072': 'DEBIT',
  '310073': 'DEBIT',
  '310074': 'DEBIT',
  '310075': 'DEBIT',
  '310076': 'DEBIT',
  '310077': 'DEBIT',
  '310078': 'DEBIT',
  '310079': 'DEBIT',
  '310080': 'DEBIT',
  '310081': 'DEBIT',
  '310082': 'DEBIT',
  '310083': 'DEBIT',
  '310084': 'DEBIT',
  '310085': 'DEBIT',
  '310086': 'DEBIT',
  '310087': 'DEBIT',
  '310088': 'DEBIT',
  '310089': 'DEBIT',
  '310090': 'DEBIT',
  '310091': 'DEBIT',
  '310092': 'DEBIT',
  '310093': 'DEBIT',
  '310094': 'DEBIT',
  '310095': 'DEBIT',
  '310096': 'DEBIT',
  '310097': 'DEBIT',
  '310098': 'DEBIT',
  '310099': 'DEBIT',
  '310100': 'DEBIT',
  '310101': 'DEBIT',
  '310102': 'DEBIT',
  '310103': 'DEBIT',
  '310104': 'DEBIT',
  '310105': 'DEBIT',
  '310106': 'DEBIT',
  '310107': 'DEBIT',
  '310108': 'DEBIT',
  '310109': 'DEBIT',
  '310110': 'DEBIT',
  '310111': 'DEBIT',
  '310112': 'DEBIT',
  '310113': 'DEBIT',
  '310114': 'DEBIT',
  '310115': 'DEBIT',
  '310116': 'DEBIT',
  '310117': 'DEBIT',
  '310118': 'DEBIT',
  '310119': 'DEBIT',
  '310120': 'DEBIT',
  '310121': 'DEBIT',
  '310122': 'DEBIT',
  '310123': 'DEBIT',
  '310124': 'DEBIT',
  '310125': 'DEBIT',
  '310126': 'DEBIT',
  '310127': 'DEBIT',
  '310128': 'DEBIT',
  '310129': 'DEBIT',
  '310130': 'DEBIT',
  '310131': 'DEBIT',
  '310132': 'DEBIT',
  '310133': 'DEBIT',
  '310134': 'DEBIT',
  '310135': 'DEBIT',
  '310136': 'CREDIT',
  '310137': 'DEBIT',
  '310138': 'DEBIT',
  '310139': 'DEBIT',
  '310140': 'DEBIT',
  '310141': 'DEBIT',
  '310142': 'DEBIT',
  '310143': 'DEBIT',
  '310144': 'DEBIT',
  '310145': 'DEBIT',
  '310146': 'DEBIT',
  '310147': 'DEBIT',
  '310148': 'DEBIT',
  '310149': 'DEBIT',
  '310150': 'DEBIT',
  '310151': 'DEBIT',
  '310152': 'DEBIT',
  '310153': 'DEBIT',
  '310154': 'DEBIT',
  '310155': 'DEBIT',
  '310156': 'CREDIT',
  '310157': 'DEBIT',
  '310158': 'DEBIT',
  '310159': 'DEBIT',
  '310160': 'DEBIT',
  '310161': 'DEBIT',
  '310162': 'DEBIT',
  '310163': 'DEBIT',
  '310164': 'DEBIT',
  '310165': 'DEBIT',
  '310166': 'DEBIT',
  '310167': 'DEBIT',
  '310168': 'DEBIT',
  '310169': 'DEBIT',
  '310170': 'DEBIT',
  '310171': 'DEBIT',
  '310172': 'DEBIT',
  '310173': 'DEBIT',
  '310174': 'DEBIT',
  '310175': 'DEBIT',
  '310176': 'DEBIT',
  '310177': 'DEBIT',
  '310178': 'DEBIT',
  '310179': 'DEBIT',
  '310180': 'DEBIT',
  '310181': 'DEBIT',
  '310182': 'DEBIT',
  '310183': 'DEBIT',
  '310184': 'DEBIT',
  '310185': 'DEBIT',
  '310186': 'DEBIT',
  '310187': 'DEBIT',
  '310188': 'DEBIT',
  '310189': 'DEBIT',
  '310190': 'DEBIT',
  '310191': 'DEBIT',
  '310192': 'DEBIT',
  '310193': 'DEBIT',
  '310194': 'DEBIT',
  '310195': 'DEBIT',
  '310196': 'DEBIT',
  '310197': 'DEBIT',
  '310198': 'DEBIT',
  '310199': 'DEBIT',
  '310200': 'DEBIT',
  '310201': 'DEBIT',
  '310202': 'DEBIT',
  '310203': 'DEBIT',
  '310204': 'DEBIT',
  '310205': 'DEBIT',
  '310206': 'DEBIT',
  '310207': 'DEBIT',
  '310208': 'DEBIT',
  '310209': 'DEBIT',
  '310210': 'DEBIT',
  '310211': 'DEBIT',
  '310212': 'DEBIT',
  '310213': 'DEBIT',
  '310214': 'DEBIT',
  '310215': 'DEBIT',
  '310216': 'DEBIT',
  '310217': 'DEBIT',
  '310218': 'DEBIT',
  '310219': 'DEBIT',
  '310220': 'DEBIT',
  '310221': 'DEBIT',
  '310222': 'DEBIT',
  '310223': 'DEBIT',
  '310224': 'DEBIT',
  '310225': 'DEBIT',
  '310226': 'DEBIT',
  '310227': 'DEBIT',
  '310228': 'DEBIT',
  '310229': 'DEBIT',
  '310230': 'DEBIT',
  '310231': 'DEBIT',
  '310232': 'DEBIT',
  '310233': 'DEBIT',
  '310234': 'DEBIT',
  '310235': 'DEBIT',
  '310236': 'DEBIT',
  '310237': 'DEBIT',
  '310238': 'DEBIT',
  '310239': 'DEBIT',
  '310240': 'DEBIT',
  '310241': 'DEBIT',
  '310242': 'DEBIT',
  '310243': 'DEBIT',
  '310244': 'DEBIT',
  '310245': 'DEBIT',
  '310246': 'DEBIT',
  '310247': 'DEBIT',
  '310248': 'DEBIT',
  '310249': 'DEBIT',
  '310250': 'DEBIT',
  '310251': 'DEBIT',
  '310252': 'DEBIT',
  '310253': 'DEBIT',
  '310254': 'DEBIT',
  '310255': 'DEBIT',
  '310256': 'DEBIT',
  '310257': 'DEBIT',
  '310258': 'DEBIT',
  '310259': 'DEBIT',
  '310260': 'DEBIT',
  '310261': 'DEBIT',
  '310262': 'DEBIT',
  '310263': 'DEBIT',
  '310264': 'DEBIT',
  '310265': 'DEBIT',
  '310266': 'DEBIT',
  '310267': 'DEBIT',
  '310268': 'DEBIT',
  '310269': 'DEBIT',
  '310270': 'DEBIT',
  '310271': 'DEBIT',
  '310272': 'DEBIT',
  '310273': 'DEBIT',
  '310274': 'DEBIT',
  '310275': 'DEBIT',
  '310276': 'DEBIT',
  '310277': 'DEBIT',
  '310278': 'DEBIT',
  '310279': 'DEBIT',
  '310280': 'DEBIT',
  '310281': 'DEBIT',
  '310282': 'DEBIT',
  '310283': 'DEBIT',
  '310284': 'DEBIT',
  '310285': 'DEBIT',
  '310286': 'DEBIT',
  '310287': 'DEBIT',
  '310288': 'DEBIT',
  '310289': 'DEBIT',
  '310290': 'DEBIT',
  '310291': 'DEBIT',
  '310292': 'DEBIT',
  '310293': 'DEBIT',
  '310294': 'DEBIT',
  '310295': 'DEBIT',
  '310296': 'DEBIT',
  '310297': 'DEBIT',
  '310298': 'DEBIT',
  '310299': 'DEBIT',
  '311200': 'DEBIT',
  '311201': 'DEBIT',
  '311202': 'DEBIT',
  '311203': 'DEBIT',
  '311204': 'DEBIT',
  '311205': 'DEBIT',
  '311206': 'DEBIT',
  '311207': 'DEBIT',
  '311208': 'CREDIT',
  '311209': 'DEBIT',
  '311210': 'DEBIT',
  '311211': 'DEBIT',
  '311212': 'DEBIT',
  '311213': 'DEBIT',
  '311214': 'CREDIT',
  '311215': 'DEBIT',
  '311216': 'DEBIT',
  '311217': 'DEBIT',
  '311218': 'DEBIT',
  '311219': 'DEBIT',
  '311220': 'DEBIT',
  '311221': 'DEBIT',
  '311222': 'DEBIT',
  '311223': 'DEBIT',
  '311224': 'DEBIT',
  '311225': 'DEBIT',
  '311226': 'DEBIT',
  '311227': 'DEBIT',
  '311228': 'DEBIT',
  '311229': 'DEBIT',
  '311230': 'DEBIT',
  '311231': 'DEBIT',
  '311232': 'DEBIT',
  '311233': 'DEBIT',
  '311234': 'DEBIT',
  '311235': 'DEBIT',
  '311236': 'DEBIT',
  '311237': 'DEBIT',
  '311238': 'DEBIT',
  '311239': 'DEBIT',
  '311240': 'DEBIT',
  '311241': 'DEBIT',
  '311242': 'DEBIT',
  '311243': 'DEBIT',
  '311244': 'DEBIT',
  '311245': 'DEBIT',
  '311246': 'DEBIT',
  '311247': 'DEBIT',
  '311248': 'DEBIT',
  '311249': 'DEBIT',
  '311250': 'DEBIT',
  '311251': 'DEBIT',
  '311252': 'DEBIT',
  '311253': 'DEBIT',
  '311254': 'DEBIT',
  '311255': 'DEBIT',
  '311256': 'DEBIT',
  '311257': 'DEBIT',
  '311258': 'DEBIT',
  '311259': 'DEBIT',
  '311260': 'DEBIT',
  '311261': 'DEBIT',
  '311262': 'DEBIT',
  '311263': 'DEBIT',
  '311264': 'DEBIT',
  '311265': 'DEBIT',
  '311266': 'DEBIT',
  '311267': 'DEBIT',
  '311268': 'DEBIT',
  '311269': 'DEBIT',
  '311270': 'DEBIT',
  '311271': 'DEBIT',
  '311272': 'DEBIT',
  '311273': 'DEBIT',
  '311274': 'DEBIT',
  '311275': 'DEBIT',
  '311276': 'DEBIT',
  '311277': 'DEBIT',
  '311278': 'DEBIT',
  '311279': 'DEBIT',
  '311280': 'DEBIT',
  '311281': 'DEBIT',
  '311282': 'DEBIT',
  '311283': 'DEBIT',
  '311284': 'CREDIT',
  '311285': 'DEBIT',
  '311286': 'DEBIT',
  '311287': 'DEBIT',
  '311288': 'DEBIT',
  '311289': 'DEBIT',
  '311290': 'DEBIT',
  '311291': 'DEBIT',
  '311292': 'DEBIT',
  '311293': 'DEBIT',
  '311294': 'DEBIT',
  '311295': 'DEBIT',
  '311296': 'DEBIT',
  '311297': 'DEBIT',
  '311298': 'DEBIT',
  '311299': 'DEBIT',
  '311300': 'DEBIT',
  '311301': 'DEBIT',
  '311302': 'DEBIT',
  '311303': 'DEBIT',
  '311304': 'DEBIT',
  '311305': 'DEBIT',
  '311306': 'DEBIT',
  '311307': 'DEBIT',
  '311308': 'DEBIT',
  '311309': 'DEBIT',
  '311310': 'DEBIT',
  '311311': 'DEBIT',
  '311312': 'DEBIT',
  '311313': 'DEBIT',
  '311314': 'DEBIT',
  '311315': 'DEBIT',
  '311316': 'DEBIT',
  '311317': 'DEBIT',
  '311318': 'DEBIT',
  '311319': 'DEBIT',
  '311320': 'DEBIT',
  '311321': 'DEBIT',
  '311322': 'DEBIT',
  '311323': 'DEBIT',
  '311324': 'DEBIT',
  '311325': 'DEBIT',
  '311326': 'DEBIT',
  '311327': 'DEBIT',
  '311328': 'DEBIT',
  '311329': 'DEBIT',
  '311330': 'DEBIT',
  '311331': 'DEBIT',
  '311332': 'DEBIT',
  '311333': 'DEBIT',
  '311334': 'DEBIT',
  '311335': 'DEBIT',
  '311336': 'DEBIT',
  '311337': 'DEBIT',
  '311338': 'DEBIT',
  '311339': 'DEBIT',
  '311340': 'DEBIT',
  '311341': 'DEBIT',
  '311342': 'DEBIT',
  '311343': 'DEBIT',
  '311344': 'DEBIT',
  '311345': 'DEBIT',
  '311346': 'DEBIT',
  '311347': 'DEBIT',
  '311348': 'DEBIT',
  '311349': 'DEBIT',
  '311350': 'DEBIT',
  '311351': 'DEBIT',
  '311352': 'DEBIT',
  '311353': 'DEBIT',
  '311354': 'DEBIT',
  '311355': 'DEBIT',
  '311356': 'DEBIT',
  '311357': 'DEBIT',
  '311358': 'DEBIT',
  '311359': 'DEBIT',
  '311360': 'DEBIT',
  '311361': 'DEBIT',
  '311362': 'DEBIT',
  '311363': 'DEBIT',
  '311364': 'DEBIT',
  '311365': 'DEBIT',
  '311366': 'DEBIT',
  '311367': 'DEBIT',
  '311368': 'DEBIT',
  '311369': 'DEBIT',
  '311370': 'DEBIT',
  '311371': 'DEBIT',
  '311372': 'DEBIT',
  '311373': 'DEBIT',
  '311374': 'DEBIT',
  '311375': 'DEBIT',
  '311376': 'DEBIT',
  '311377': 'DEBIT',
  '311378': 'DEBIT',
  '311379': 'DEBIT',
  '311380': 'DEBIT',
  '311381': 'DEBIT',
  '311382': 'DEBIT',
  '311383': 'DEBIT',
  '311384': 'DEBIT',
  '311385': 'DEBIT',
  '311386': 'DEBIT',
  '311387': 'DEBIT',
  '311388': 'DEBIT',
  '311389': 'DEBIT',
  '311390': 'DEBIT',
  '311391': 'DEBIT',
  '311392': 'DEBIT',
  '311393': 'DEBIT',
  '311394': 'DEBIT',
  '311395': 'DEBIT',
  '311396': 'DEBIT',
  '311397': 'DEBIT',
  '311398': 'CREDIT',
  '311399': 'DEBIT',
  '311400': 'DEBIT',
  '311401': 'DEBIT',
  '311402': 'DEBIT',
  '311403': 'DEBIT',
  '311404': 'DEBIT',
  '311405': 'DEBIT',
  '311406': 'DEBIT',
  '311407': 'DEBIT',
  '311408': 'DEBIT',
  '311409': 'DEBIT',
  '311410': 'DEBIT',
  '311411': 'DEBIT',
  '311412': 'DEBIT',
  '311413': 'DEBIT',
  '311414': 'DEBIT',
  '311415': 'DEBIT',
  '311416': 'DEBIT',
  '311417': 'DEBIT',
  '311418': 'DEBIT',
  '311419': 'DEBIT',
  '311420': 'DEBIT',
  '311421': 'DEBIT',
  '311422': 'DEBIT',
  '311423': 'DEBIT',
  '311424': 'DEBIT',
  '311425': 'DEBIT',
  '311426': 'DEBIT',
  '311427': 'DEBIT',
  '311428': 'DEBIT',
  '311429': 'DEBIT',
  '311430': 'DEBIT',
  '311431': 'DEBIT',
  '311432': 'DEBIT',
  '311433': 'DEBIT',
  '311434': 'DEBIT',
  '311435': 'DEBIT',
  '311436': 'DEBIT',
  '311437': 'DEBIT',
  '311438': 'DEBIT',
  '311439': 'DEBIT',
  '311440': 'DEBIT',
  '311441': 'DEBIT',
  '311442': 'DEBIT',
  '311443': 'DEBIT',
  '311444': 'DEBIT',
  '311445': 'DEBIT',
  '311446': 'DEBIT',
  '311447': 'DEBIT',
  '311448': 'DEBIT',
  '311449': 'DEBIT',
  '311450': 'DEBIT',
  '311451': 'DEBIT',
  '311452': 'DEBIT',
  '311453': 'DEBIT',
  '311454': 'DEBIT',
  '311455': 'DEBIT',
  '311456': 'DEBIT',
  '311457': 'DEBIT',
  '311458': 'DEBIT',
  '311459': 'DEBIT',
  '311460': 'DEBIT',
  '311461': 'DEBIT',
  '311462': 'DEBIT',
  '311463': 'DEBIT',
  '311464': 'DEBIT',
  '311465': 'DEBIT',
  '311466': 'DEBIT',
  '311467': 'DEBIT',
  '311468': 'DEBIT',
  '311469': 'DEBIT',
  '311470': 'DEBIT',
  '311471': 'DEBIT',
  '311472': 'DEBIT',
  '311473': 'DEBIT',
  '311474': 'DEBIT',
  '311475': 'DEBIT',
  '311476': 'DEBIT',
  '311477': 'DEBIT',
  '311478': 'DEBIT',
  '311479': 'DEBIT',
  '311480': 'DEBIT',
  '311481': 'DEBIT',
  '311482': 'DEBIT',
  '311483': 'DEBIT',
  '311484': 'DEBIT',
  '311485': 'DEBIT',
  '311486': 'DEBIT',
  '311487': 'DEBIT',
  '311488': 'DEBIT',
  '311489': 'DEBIT',
  '311490': 'DEBIT',
  '311491': 'DEBIT',
  '311492': 'DEBIT',
  '311493': 'DEBIT',
  '311494': 'DEBIT',
  '311495': 'DEBIT',
  '311496': 'DEBIT',
  '311497': 'DEBIT',
  '311498': 'DEBIT',
  '311499': 'DEBIT',
  '311500': 'DEBIT',
  '311501': 'DEBIT',
  '311502': 'DEBIT',
  '311503': 'DEBIT',
  '311504': 'DEBIT',
  '311505': 'DEBIT',
  '311506': 'DEBIT',
  '311507': 'DEBIT',
  '311508': 'DEBIT',
  '311509': 'DEBIT',
  '311510': 'DEBIT',
  '311511': 'DEBIT',
  '311512': 'DEBIT',
  '311513': 'DEBIT',
  '311514': 'DEBIT',
  '311515': 'DEBIT',
  '311516': 'DEBIT',
  '311517': 'DEBIT',
  '311518': 'DEBIT',
  '311519': 'DEBIT',
  '311520': 'DEBIT',
  '311521': 'DEBIT',
  '311522': 'DEBIT',
  '311523': 'DEBIT',
  '311524': 'DEBIT',
  '311525': 'DEBIT',
  '311526': 'DEBIT',
  '311527': 'DEBIT',
  '311528': 'DEBIT',
  '311529': 'DEBIT',
  '311530': 'DEBIT',
  '311531': 'DEBIT',
  '311532': 'DEBIT',
  '311533': 'DEBIT',
  '311534': 'DEBIT',
  '311535': 'DEBIT',
  '311536': 'DEBIT',
  '311537': 'DEBIT',
  '311538': 'DEBIT',
  '311539': 'DEBIT',
  '311540': 'DEBIT',
  '311541': 'DEBIT',
  '311542': 'DEBIT',
  '311543': 'DEBIT',
  '311544': 'DEBIT',
  '311545': 'DEBIT',
  '311546': 'DEBIT',
  '311547': 'DEBIT',
  '311548': 'DEBIT',
  '311549': 'DEBIT',
  '311550': 'DEBIT',
  '311551': 'DEBIT',
  '311552': 'DEBIT',
  '311553': 'DEBIT',
  '311554': 'DEBIT',
  '311555': 'DEBIT',
  '311556': 'DEBIT',
  '311557': 'DEBIT',
  '311558': 'DEBIT',
  '311559': 'DEBIT',
  '311560': 'DEBIT',
  '311561': 'DEBIT',
  '311562': 'DEBIT',
  '311563': 'DEBIT',
  '311564': 'DEBIT',
  '311565': 'DEBIT',
  '311566': 'DEBIT',
  '311567': 'DEBIT',
  '311568': 'DEBIT',
  '311569': 'DEBIT',
  '311570': 'DEBIT',
  '311571': 'DEBIT',
  '311572': 'DEBIT',
  '311573': 'DEBIT',
  '311574': 'DEBIT',
  '311575': 'DEBIT',
  '311576': 'DEBIT',
  '311577': 'DEBIT',
  '311578': 'DEBIT',
  '311579': 'DEBIT',
  '311580': 'DEBIT',
  '311581': 'DEBIT',
  '311582': 'DEBIT',
  '311583': 'DEBIT',
  '311584': 'DEBIT',
  '311585': 'DEBIT',
  '311586': 'DEBIT',
  '311587': 'DEBIT',
  '311588': 'DEBIT',
  '311589': 'DEBIT',
  '311590': 'DEBIT',
  '311591': 'DEBIT',
  '311592': 'DEBIT',
  '311593': 'DEBIT',
  '311594': 'DEBIT',
  '311595': 'DEBIT',
  '311596': 'DEBIT',
  '311597': 'DEBIT',
  '311598': 'DEBIT',
  '311599': 'DEBIT',
  '311600': 'DEBIT',
  '311601': 'DEBIT',
  '311602': 'DEBIT',
  '311603': 'DEBIT',
  '311604': 'DEBIT',
  '311605': 'DEBIT',
  '311606': 'DEBIT',
  '311607': 'DEBIT',
  '311608': 'DEBIT',
  '311609': 'DEBIT',
  '311610': 'DEBIT',
  '311611': 'DEBIT',
  '311612': 'DEBIT',
  '311613': 'DEBIT',
  '311614': 'DEBIT',
  '311615': 'DEBIT',
  '311616': 'DEBIT',
  '311617': 'DEBIT',
  '311618': 'DEBIT',
  '311619': 'DEBIT',
  '311620': 'DEBIT',
  '311621': 'DEBIT',
  '311622': 'DEBIT',
  '311623': 'DEBIT',
  '311624': 'DEBIT',
  '311625': 'DEBIT',
  '311626': 'DEBIT',
  '311627': 'DEBIT',
  '311628': 'DEBIT',
  '311629': 'DEBIT',
  '311630': 'DEBIT',
  '311631': 'DEBIT',
  '311632': 'DEBIT',
  '311633': 'DEBIT',
  '311634': 'DEBIT',
  '311635': 'DEBIT',
  '311636': 'DEBIT',
  '311637': 'DEBIT',
  '311638': 'DEBIT',
  '311639': 'DEBIT',
  '311640': 'DEBIT',
  '311641': 'DEBIT',
  '311642': 'DEBIT',
  '311643': 'DEBIT',
  '311644': 'DEBIT',
  '311645': 'DEBIT',
  '311646': 'DEBIT',
  '311647': 'DEBIT',
  '311648': 'DEBIT',
  '311649': 'DEBIT',
  '311650': 'DEBIT',
  '311651': 'DEBIT',
  '311652': 'DEBIT',
  '311653': 'DEBIT',
  '311654': 'DEBIT',
  '311655': 'DEBIT',
  '311656': 'DEBIT',
  '311657': 'DEBIT',
  '311658': 'DEBIT',
  '311659': 'DEBIT',
  '311660': 'DEBIT',
  '311661': 'DEBIT',
  '311662': 'DEBIT',
  '311663': 'DEBIT',
  '311664': 'DEBIT',
  '311665': 'DEBIT',
  '311666': 'DEBIT',
  '311667': 'DEBIT',
  '311668': 'DEBIT',
  '311669': 'DEBIT',
  '311670': 'DEBIT',
  '311671': 'DEBIT',
  '311672': 'DEBIT',
  '311673': 'DEBIT',
  '311674': 'DEBIT',
  '311675': 'DEBIT',
  '311676': 'DEBIT',
  '311677': 'DEBIT',
  '311678': 'DEBIT',
  '311679': 'DEBIT',
  '311680': 'DEBIT',
  '311681': 'DEBIT',
  '311682': 'DEBIT',
  '311683': 'DEBIT',
  '311684': 'DEBIT',
  '311685': 'DEBIT',
  '311686': 'DEBIT',
  '311687': 'DEBIT',
  '311688': 'DEBIT',
  '311689': 'DEBIT',
  '311690': 'DEBIT',
  '311691': 'DEBIT',
  '311692': 'DEBIT',
  '311693': 'DEBIT',
  '311694': 'DEBIT',
  '311695': 'DEBIT',
  '311696': 'DEBIT',
  '311697': 'DEBIT',
  '311698': 'DEBIT',
  '311699': 'DEBIT',
  '311700': 'DEBIT',
  '311701': 'DEBIT',
  '311702': 'DEBIT',
  '311703': 'DEBIT',
  '311704': 'DEBIT',
  '311705': 'DEBIT',
  '311706': 'DEBIT',
  '311707': 'DEBIT',
  '311708': 'DEBIT',
  '311709': 'DEBIT',
  '311710': 'DEBIT',
  '311711': 'DEBIT',
  '311712': 'DEBIT',
  '311713': 'DEBIT',
  '311714': 'DEBIT',
  '311715': 'DEBIT',
  '311716': 'DEBIT',
  '311717': 'DEBIT',
  '311718': 'DEBIT',
  '311719': 'DEBIT',
  '311720': 'DEBIT',
  '311721': 'DEBIT',
  '311722': 'DEBIT',
  '311723': 'DEBIT',
  '311724': 'DEBIT',
  '311725': 'DEBIT',
  '311726': 'DEBIT',
  '311727': 'DEBIT',
  '311728': 'DEBIT',
  '311729': 'DEBIT',
  '311730': 'DEBIT',
  '311731': 'DEBIT',
  '311732': 'DEBIT',
  '311733': 'DEBIT',
  '311734': 'DEBIT',
  '311735': 'DEBIT',
  '311736': 'DEBIT',
  '311737': 'DEBIT',
  '311738': 'DEBIT',
  '311739': 'DEBIT',
  '311740': 'DEBIT',
  '311741': 'DEBIT',
  '311742': 'DEBIT',
  '311743': 'DEBIT',
  '311744': 'DEBIT',
  '311745': 'DEBIT',
  '311746': 'DEBIT',
  '311747': 'DEBIT',
  '311748': 'DEBIT',
  '311749': 'DEBIT',
  '311750': 'DEBIT',
  '311751': 'DEBIT',
  '311752': 'DEBIT',
  '311753': 'DEBIT',
  '311754': 'DEBIT',
  '311755': 'DEBIT',
  '311756': 'DEBIT',
  '311757': 'DEBIT',
  '311758': 'DEBIT',
  '311759': 'DEBIT',
  '311760': 'DEBIT',
  '311761': 'DEBIT',
  '311762': 'DEBIT',
  '311763': 'DEBIT',
  '311764': 'DEBIT',
  '311765': 'DEBIT',
  '311766': 'DEBIT',
  '311767': 'DEBIT',
  '311768': 'DEBIT',
  '311769': 'DEBIT',
  '311770': 'DEBIT',
  '311771': 'DEBIT',
  '311772': 'DEBIT',
  '311773': 'DEBIT',
  '311774': 'DEBIT',
  '311775': 'DEBIT',
  '311776': 'DEBIT',
  '311777': 'DEBIT',
  '311778': 'DEBIT',
  '311779': 'DEBIT',
  '311780': 'DEBIT',
  '311781': 'DEBIT',
  '311782': 'DEBIT',
  '311783': 'DEBIT',
  '311784': 'DEBIT',
  '311785': 'DEBIT',
  '311786': 'DEBIT',
  '311787': 'DEBIT',
  '311788': 'DEBIT',
  '311789': 'DEBIT',
  '311790': 'DEBIT',
  '311791': 'DEBIT',
  '311792': 'DEBIT',
  '311793': 'DEBIT',
  '311794': 'DEBIT',
  '311795': 'DEBIT',
  '311796': 'DEBIT',
  '311797': 'DEBIT',
  '311798': 'DEBIT',
  '311799': 'DEBIT',
  '311800': 'CREDIT',
  '311801': 'DEBIT',
  '311802': 'DEBIT',
  '311803': 'DEBIT',
  '311804': 'DEBIT',
  '311805': 'DEBIT',
  '311806': 'DEBIT',
  '311807': 'DEBIT',
  '311808': 'DEBIT',
  '311809': 'DEBIT',
  '311810': 'DEBIT',
  '311811': 'DEBIT',
  '311812': 'DEBIT',
  '311813': 'DEBIT',
  '311814': 'DEBIT',
  '311815': 'DEBIT',
  '311816': 'DEBIT',
  '311817': 'DEBIT',
  '311818': 'DEBIT',
  '311819': 'DEBIT',
  '311820': 'DEBIT',
  '311821': 'DEBIT',
  '311822': 'DEBIT',
  '311823': 'DEBIT',
  '311824': 'DEBIT',
  '311825': 'DEBIT',
  '311826': 'DEBIT',
  '311827': 'DEBIT',
  '311828': 'DEBIT',
  '311829': 'DEBIT',
  '311830': 'DEBIT',
  '311831': 'DEBIT',
  '311832': 'DEBIT',
  '311833': 'DEBIT',
  '311834': 'DEBIT',
  '311835': 'DEBIT',
  '311836': 'DEBIT',
  '311837': 'DEBIT',
  '311838': 'DEBIT',
  '311839': 'DEBIT',
  '311840': 'DEBIT',
  '311841': 'DEBIT',
  '311842': 'DEBIT',
  '311843': 'DEBIT',
  '311844': 'DEBIT',
  '311845': 'DEBIT',
  '311846': 'DEBIT',
  '311847': 'DEBIT',
  '311848': 'DEBIT',
  '311849': 'DEBIT',
  '311850': 'DEBIT',
  '311851': 'DEBIT',
  '311852': 'DEBIT',
  '311853': 'DEBIT',
  '311854': 'DEBIT',
  '311855': 'DEBIT',
  '311856': 'DEBIT',
  '311857': 'DEBIT',
  '311858': 'DEBIT',
  '311859': 'DEBIT',
  '311860': 'DEBIT',
  '311861': 'DEBIT',
  '311862': 'DEBIT',
  '311863': 'DEBIT',
  '311864': 'DEBIT',
  '311865': 'DEBIT',
  '311866': 'DEBIT',
  '311867': 'DEBIT',
  '311868': 'DEBIT',
  '311869': 'DEBIT',
  '311870': 'DEBIT',
  '311871': 'DEBIT',
  '311872': 'DEBIT',
  '311873': 'DEBIT',
  '311874': 'DEBIT',
  '311875': 'DEBIT',
  '311876': 'DEBIT',
  '311877': 'DEBIT',
  '311878': 'DEBIT',
  '311879': 'DEBIT',
  '311880': 'DEBIT',
  '311881': 'DEBIT',
  '311882': 'DEBIT',
  '311883': 'DEBIT',
  '311884': 'DEBIT',
  '311885': 'DEBIT',
  '311886': 'DEBIT',
  '311887': 'DEBIT',
  '311888': 'DEBIT',
  '311889': 'DEBIT',
  '311890': 'DEBIT',
  '311891': 'DEBIT',
  '311892': 'DEBIT',
  '311893': 'DEBIT',
  '311894': 'DEBIT',
  '311895': 'DEBIT',
  '311896': 'DEBIT',
  '311897': 'DEBIT',
  '311898': 'DEBIT',
  '311899': 'DEBIT',
  '311900': 'DEBIT',
  '311901': 'DEBIT',
  '311902': 'DEBIT',
  '311903': 'DEBIT',
  '311904': 'DEBIT',
  '311905': 'DEBIT',
  '311906': 'DEBIT',
  '311907': 'DEBIT',
  '311908': 'DEBIT',
  '311909': 'DEBIT',
  '311910': 'DEBIT',
  '311911': 'DEBIT',
  '311912': 'DEBIT',
  '311913': 'DEBIT',
  '311914': 'DEBIT',
  '311915': 'DEBIT',
  '311916': 'DEBIT',
  '311917': 'DEBIT',
  '311918': 'DEBIT',
  '311919': 'DEBIT',
  '311920': 'DEBIT',
  '311921': 'DEBIT',
  '311922': 'DEBIT',
  '311923': 'DEBIT',
  '311924': 'DEBIT',
  '311925': 'DEBIT',
  '311926': 'DEBIT',
  '311927': 'DEBIT',
  '311928': 'DEBIT',
  '311929': 'DEBIT',
  '311930': 'DEBIT',
  '311931': 'DEBIT',
  '311932': 'DEBIT',
  '311933': 'DEBIT',
  '311934': 'DEBIT',
  '311935': 'DEBIT',
  '311936': 'DEBIT',
  '311937': 'DEBIT',
  '311938': 'DEBIT',
  '311939': 'DEBIT',
  '311940': 'DEBIT',
  '311941': 'DEBIT',
  '311942': 'DEBIT',
  '311943': 'DEBIT',
  '311944': 'DEBIT',
  '311945': 'DEBIT',
  '311946': 'DEBIT',
  '311947': 'DEBIT',
  '311948': 'DEBIT',
  '311949': 'DEBIT',
  '311950': 'DEBIT',
  '311951': 'DEBIT',
  '311952': 'DEBIT',
  '311953': 'DEBIT',
  '311954': 'DEBIT',
  '311955': 'DEBIT',
  '311956': 'DEBIT',
  '311957': 'DEBIT',
  '311958': 'DEBIT',
  '311959': 'DEBIT',
  '311960': 'DEBIT',
  '311961': 'DEBIT',
  '311962': 'DEBIT',
  '311963': 'DEBIT',
  '311964': 'DEBIT',
  '311965': 'DEBIT',
  '311966': 'CREDIT',
  '311967': 'CREDIT',
  '311968': 'DEBIT',
  '311969': 'DEBIT',
  '311970': 'DEBIT',
  '311971': 'DEBIT',
  '311972': 'DEBIT',
  '311973': 'DEBIT',
  '311974': 'DEBIT',
  '311975': 'DEBIT',
  '311976': 'DEBIT',
  '311977': 'DEBIT',
  '311978': 'DEBIT',
  '311979': 'DEBIT',
  '311980': 'DEBIT',
  '311981': 'DEBIT',
  '311982': 'DEBIT',
  '311983': 'DEBIT',
  '311984': 'DEBIT',
  '311985': 'DEBIT',
  '311986': 'DEBIT',
  '311987': 'DEBIT',
  '311988': 'DEBIT',
  '311989': 'DEBIT',
  '311990': 'DEBIT',
  '311991': 'DEBIT',
  '311992': 'DEBIT',
  '311993': 'DEBIT',
  '311994': 'DEBIT',
  '311995': 'DEBIT',
  '311996': 'DEBIT',
  '311997': 'DEBIT',
  '311998': 'DEBIT',
  '311999': 'DEBIT',
  '312000': 'DEBIT',
  '312001': 'DEBIT',
  '312002': 'DEBIT',
  '312003': 'DEBIT',
  '312004': 'DEBIT',
  '312005': 'DEBIT',
  '312006': 'DEBIT',
  '312007': 'DEBIT',
  '312008': 'DEBIT',
  '312009': 'DEBIT',
  '312010': 'DEBIT',
  '312011': 'DEBIT',
  '312012': 'DEBIT',
  '312013': 'DEBIT',
  '312014': 'DEBIT',
  '312015': 'DEBIT',
  '312016': 'DEBIT',
  '312017': 'DEBIT',
  '312018': 'DEBIT',
  '312019': 'DEBIT',
  '312020': 'DEBIT',
  '312021': 'DEBIT',
  '312022': 'DEBIT',
  '312023': 'DEBIT',
  '312024': 'DEBIT',
  '312025': 'DEBIT',
  '312026': 'DEBIT',
  '312027': 'DEBIT',
  '312028': 'DEBIT',
  '312029': 'DEBIT',
  '312030': 'DEBIT',
  '312031': 'DEBIT',
  '312032': 'DEBIT',
  '312033': 'DEBIT',
  '312034': 'DEBIT',
  '312035': 'DEBIT',
  '312036': 'DEBIT',
  '312037': 'DEBIT',
  '312038': 'DEBIT',
  '312039': 'DEBIT',
  '312040': 'DEBIT',
  '312041': 'DEBIT',
  '312042': 'DEBIT',
  '312043': 'DEBIT',
  '312044': 'DEBIT',
  '312045': 'DEBIT',
  '312046': 'DEBIT',
  '312047': 'DEBIT',
  '312048': 'DEBIT',
  '312049': 'DEBIT',
  '312050': 'DEBIT',
  '312051': 'DEBIT',
  '312052': 'DEBIT',
  '312053': 'DEBIT',
  '312054': 'DEBIT',
  '312055': 'DEBIT',
  '312056': 'DEBIT',
  '312057': 'DEBIT',
  '312058': 'DEBIT',
  '312059': 'DEBIT',
  '312060': 'DEBIT',
  '312061': 'DEBIT',
  '312062': 'DEBIT',
  '312063': 'DEBIT',
  '312064': 'DEBIT',
  '312065': 'DEBIT',
  '312066': 'DEBIT',
  '312067': 'DEBIT',
  '312068': 'DEBIT',
  '312069': 'DEBIT',
  '312070': 'DEBIT',
  '312071': 'DEBIT',
  '312072': 'DEBIT',
  '312073': 'DEBIT',
  '312074': 'DEBIT',
  '312075': 'DEBIT',
  '312076': 'DEBIT',
  '312077': 'DEBIT',
  '312078': 'DEBIT',
  '312079': 'DEBIT',
  '312080': 'DEBIT',
  '312081': 'DEBIT',
  '312082': 'DEBIT',
  '312083': 'DEBIT',
  '312084': 'DEBIT',
  '312085': 'DEBIT',
  '312086': 'DEBIT',
  '312087': 'DEBIT',
  '312088': 'DEBIT',
  '312089': 'DEBIT',
  '312090': 'DEBIT',
  '312091': 'DEBIT',
  '312092': 'DEBIT',
  '312093': 'DEBIT',
  '312094': 'DEBIT',
  '312095': 'DEBIT',
  '312096': 'DEBIT',
  '312097': 'DEBIT',
  '312098': 'DEBIT',
  '312099': 'DEBIT',
  '315800': 'DEBIT',
  '315801': 'DEBIT',
  '315802': 'DEBIT',
  '315803': 'DEBIT',
  '315804': 'DEBIT',
  '315805': 'DEBIT',
  '315806': 'DEBIT',
  '315807': 'DEBIT',
  '315808': 'DEBIT',
  '315809': 'DEBIT',
  '315810': 'DEBIT',
  '315811': 'DEBIT',
  '315812': 'DEBIT',
  '315813': 'DEBIT',
  '315814': 'DEBIT',
  '315815': 'DEBIT',
  '315816': 'DEBIT',
  '315817': 'DEBIT',
  '315818': 'DEBIT',
  '315819': 'DEBIT',
  '315820': 'DEBIT',
  '315821': 'DEBIT',
  '315822': 'DEBIT',
  '315823': 'DEBIT',
  '315824': 'DEBIT',
  '315825': 'DEBIT',
  '315826': 'DEBIT',
  '315827': 'DEBIT',
  '315828': 'DEBIT',
  '315829': 'DEBIT',
  '315830': 'DEBIT',
  '315831': 'DEBIT',
  '315832': 'DEBIT',
  '315833': 'DEBIT',
  '315834': 'DEBIT',
  '315835': 'DEBIT',
  '315836': 'DEBIT',
  '315837': 'DEBIT',
  '315838': 'DEBIT',
  '315839': 'DEBIT',
  '315840': 'DEBIT',
  '315841': 'DEBIT',
  '315842': 'DEBIT',
  '315843': 'DEBIT',
  '315844': 'DEBIT',
  '315845': 'DEBIT',
  '315846': 'DEBIT',
  '315847': 'DEBIT',
  '315848': 'DEBIT',
  '315849': 'DEBIT',
  '315850': 'DEBIT',
  '315851': 'DEBIT',
  '315852': 'DEBIT',
  '315853': 'DEBIT',
  '315854': 'DEBIT',
  '315855': 'DEBIT',
  '315856': 'DEBIT',
  '315857': 'DEBIT',
  '315858': 'DEBIT',
  '315859': 'DEBIT',
  '315860': 'DEBIT',
  '315861': 'DEBIT',
  '315862': 'DEBIT',
  '315863': 'DEBIT',
  '315864': 'DEBIT',
  '315865': 'DEBIT',
  '315866': 'DEBIT',
  '315867': 'DEBIT',
  '315868': 'DEBIT',
  '315869': 'DEBIT',
  '315870': 'DEBIT',
  '315871': 'DEBIT',
  '315872': 'DEBIT',
  '315873': 'DEBIT',
  '315874': 'DEBIT',
  '315875': 'DEBIT',
  '315876': 'DEBIT',
  '315877': 'DEBIT',
  '315878': 'DEBIT',
  '315879': 'DEBIT',
  '315880': 'DEBIT',
  '315881': 'DEBIT',
  '315882': 'DEBIT',
  '315883': 'DEBIT',
  '315884': 'DEBIT',
  '315885': 'DEBIT',
  '315886': 'DEBIT',
  '315887': 'DEBIT',
  '315888': 'DEBIT',
  '315889': 'DEBIT',
  '315890': 'DEBIT',
  '315891': 'DEBIT',
  '315892': 'DEBIT',
  '315893': 'DEBIT',
  '315894': 'DEBIT',
  '315895': 'DEBIT',
  '315896': 'DEBIT',
  '315897': 'DEBIT',
  '315898': 'DEBIT',
  '315899': 'DEBIT',
  '315900': 'DEBIT',
  '315901': 'DEBIT',
  '315902': 'DEBIT',
  '315903': 'DEBIT',
  '315904': 'DEBIT',
  '315905': 'DEBIT',
  '315906': 'DEBIT',
  '315907': 'DEBIT',
  '315908': 'DEBIT',
  '315909': 'DEBIT',
  '315910': 'DEBIT',
  '315911': 'DEBIT',
  '315912': 'DEBIT',
  '315913': 'DEBIT',
  '315914': 'DEBIT',
  '315915': 'DEBIT',
  '315916': 'DEBIT',
  '315917': 'DEBIT',
  '315918': 'DEBIT',
  '315919': 'DEBIT',
  '315920': 'DEBIT',
  '315921': 'DEBIT',
  '315922': 'DEBIT',
  '315923': 'DEBIT',
  '315924': 'DEBIT',
  '315925': 'DEBIT',
  '315926': 'DEBIT',
  '315927': 'DEBIT',
  '315928': 'DEBIT',
  '315929': 'DEBIT',
  '315930': 'DEBIT',
  '315931': 'DEBIT',
  '315932': 'DEBIT',
  '315933': 'DEBIT',
  '315934': 'DEBIT',
  '315935': 'DEBIT',
  '315936': 'DEBIT',
  '315937': 'DEBIT',
  '315938': 'DEBIT',
  '315939': 'DEBIT',
  '315940': 'DEBIT',
  '315941': 'DEBIT',
  '315942': 'DEBIT',
  '315943': 'DEBIT',
  '315944': 'DEBIT',
  '315945': 'DEBIT',
  '315946': 'DEBIT',
  '315947': 'DEBIT',
  '315948': 'DEBIT',
  '315949': 'DEBIT',
  '315950': 'DEBIT',
  '315951': 'DEBIT',
  '315952': 'DEBIT',
  '315953': 'DEBIT',
  '315954': 'DEBIT',
  '315955': 'DEBIT',
  '315956': 'DEBIT',
  '315957': 'DEBIT',
  '315958': 'DEBIT',
  '315959': 'DEBIT',
  '315960': 'DEBIT',
  '315961': 'DEBIT',
  '315962': 'DEBIT',
  '315963': 'DEBIT',
  '315964': 'DEBIT',
  '315965': 'DEBIT',
  '315966': 'DEBIT',
  '315967': 'DEBIT',
  '315968': 'DEBIT',
  '315969': 'DEBIT',
  '315970': 'DEBIT',
  '315971': 'DEBIT',
  '315972': 'DEBIT',
  '315973': 'DEBIT',
  '315974': 'DEBIT',
  '315975': 'DEBIT',
  '315976': 'DEBIT',
  '315977': 'DEBIT',
  '315978': 'DEBIT',
  '315979': 'DEBIT',
  '315980': 'DEBIT',
  '315981': 'DEBIT',
  '315982': 'DEBIT',
  '315983': 'DEBIT',
  '315984': 'DEBIT',
  '315985': 'DEBIT',
  '315986': 'DEBIT',
  '315987': 'DEBIT',
  '315988': 'DEBIT',
  '315989': 'DEBIT',
  '315990': 'DEBIT',
  '315991': 'DEBIT',
  '315992': 'DEBIT',
  '315993': 'DEBIT',
  '315994': 'DEBIT',
  '315995': 'DEBIT',
  '315996': 'DEBIT',
  '315997': 'DEBIT',
  '315998': 'DEBIT',
  '315999': 'DEBIT',
  '333700': 'DEBIT',
  '333701': 'DEBIT',
  '333702': 'DEBIT',
  '333703': 'DEBIT',
  '333704': 'DEBIT',
  '333705': 'DEBIT',
  '333706': 'DEBIT',
  '333707': 'DEBIT',
  '333708': 'DEBIT',
  '333709': 'DEBIT',
  '333710': 'DEBIT',
  '333711': 'DEBIT',
  '333712': 'DEBIT',
  '333713': 'DEBIT',
  '333714': 'DEBIT',
  '333715': 'DEBIT',
  '333716': 'DEBIT',
  '333717': 'DEBIT',
  '333718': 'DEBIT',
  '333719': 'DEBIT',
  '333720': 'DEBIT',
  '333721': 'DEBIT',
  '333722': 'DEBIT',
  '333723': 'DEBIT',
  '333724': 'DEBIT',
  '333725': 'DEBIT',
  '333726': 'DEBIT',
  '333727': 'DEBIT',
  '333728': 'DEBIT',
  '333729': 'DEBIT',
  '333730': 'DEBIT',
  '333731': 'DEBIT',
  '333732': 'DEBIT',
  '333733': 'CREDIT',
  '333734': 'DEBIT',
  '333735': 'DEBIT',
  '333736': 'DEBIT',
  '333737': 'DEBIT',
  '333738': 'DEBIT',
  '333739': 'DEBIT',
  '333740': 'DEBIT',
  '333741': 'DEBIT',
  '333742': 'DEBIT',
  '333743': 'DEBIT',
  '333744': 'DEBIT',
  '333745': 'DEBIT',
  '333746': 'DEBIT',
  '333747': 'DEBIT',
  '333748': 'DEBIT',
  '333749': 'DEBIT',
  '333750': 'DEBIT',
  '333751': 'DEBIT',
  '333752': 'DEBIT',
  '333753': 'DEBIT',
  '333754': 'DEBIT',
  '333755': 'DEBIT',
  '333756': 'DEBIT',
  '333757': 'DEBIT',
  '333758': 'DEBIT',
  '333759': 'DEBIT',
  '333760': 'DEBIT',
  '333761': 'DEBIT',
  '333762': 'DEBIT',
  '333763': 'DEBIT',
  '333764': 'DEBIT',
  '333765': 'DEBIT',
  '333766': 'DEBIT',
  '333767': 'DEBIT',
  '333768': 'DEBIT',
  '333769': 'DEBIT',
  '333770': 'DEBIT',
  '333771': 'DEBIT',
  '333772': 'DEBIT',
  '333773': 'DEBIT',
  '333774': 'DEBIT',
  '333775': 'DEBIT',
  '333776': 'DEBIT',
  '333777': 'DEBIT',
  '333778': 'DEBIT',
  '333779': 'DEBIT',
  '333780': 'DEBIT',
  '333781': 'DEBIT',
  '333782': 'DEBIT',
  '333783': 'DEBIT',
  '333784': 'DEBIT',
  '333785': 'DEBIT',
  '333786': 'DEBIT',
  '333787': 'DEBIT',
  '333788': 'DEBIT',
  '333789': 'DEBIT',
  '333790': 'DEBIT',
  '333791': 'DEBIT',
  '333792': 'DEBIT',
  '333793': 'DEBIT',
  '333794': 'DEBIT',
  '333795': 'DEBIT',
  '333796': 'DEBIT',
  '333797': 'DEBIT',
  '333798': 'DEBIT',
  '333799': 'DEBIT',
  '333800': 'DEBIT',
  '333801': 'DEBIT',
  '333802': 'DEBIT',
  '333803': 'DEBIT',
  '333804': 'DEBIT',
  '333805': 'DEBIT',
  '333806': 'DEBIT',
  '333807': 'DEBIT',
  '333808': 'DEBIT',
  '333809': 'DEBIT',
  '333810': 'DEBIT',
  '333811': 'DEBIT',
  '333812': 'DEBIT',
  '333813': 'DEBIT',
  '333814': 'DEBIT',
  '333815': 'DEBIT',
  '333816': 'DEBIT',
  '333817': 'DEBIT',
  '333818': 'DEBIT',
  '333819': 'DEBIT',
  '333820': 'DEBIT',
  '333821': 'DEBIT',
  '333822': 'DEBIT',
  '333823': 'DEBIT',
  '333824': 'DEBIT',
  '333825': 'DEBIT',
  '333826': 'DEBIT',
  '333827': 'DEBIT',
  '333828': 'DEBIT',
  '333829': 'DEBIT',
  '333830': 'DEBIT',
  '333831': 'DEBIT',
  '333832': 'DEBIT',
  '333833': 'DEBIT',
  '333834': 'DEBIT',
  '333835': 'DEBIT',
  '333836': 'DEBIT',
  '333837': 'DEBIT',
  '333838': 'DEBIT',
  '333839': 'DEBIT',
  '333840': 'DEBIT',
  '333841': 'DEBIT',
  '333842': 'DEBIT',
  '333843': 'DEBIT',
  '333844': 'DEBIT',
  '333845': 'DEBIT',
  '333846': 'DEBIT',
  '333847': 'DEBIT',
  '333848': 'DEBIT',
  '333849': 'DEBIT',
  '333850': 'DEBIT',
  '333851': 'DEBIT',
  '333852': 'DEBIT',
  '333853': 'DEBIT',
  '333854': 'DEBIT',
  '333855': 'DEBIT',
  '333856': 'DEBIT',
  '333857': 'DEBIT',
  '333858': 'DEBIT',
  '333859': 'DEBIT',
  '333860': 'DEBIT',
  '333861': 'DEBIT',
  '333862': 'DEBIT',
  '333863': 'DEBIT',
  '333864': 'DEBIT',
  '333865': 'DEBIT',
  '333866': 'DEBIT',
  '333867': 'DEBIT',
  '333868': 'DEBIT',
  '333869': 'DEBIT',
  '333870': 'DEBIT',
  '333871': 'DEBIT',
  '333872': 'DEBIT',
  '333873': 'DEBIT',
  '333874': 'DEBIT',
  '333875': 'DEBIT',
  '333876': 'DEBIT',
  '333877': 'DEBIT',
  '333878': 'DEBIT',
  '333879': 'DEBIT',
  '333880': 'DEBIT',
  '333881': 'DEBIT',
  '333882': 'DEBIT',
  '333883': 'DEBIT',
  '333884': 'DEBIT',
  '333885': 'DEBIT',
  '333886': 'DEBIT',
  '333887': 'DEBIT',
  '333888': 'DEBIT',
  '333889': 'DEBIT',
  '333890': 'DEBIT',
  '333891': 'DEBIT',
  '333892': 'DEBIT',
  '333893': 'DEBIT',
  '333894': 'DEBIT',
  '333895': 'DEBIT',
  '333896': 'DEBIT',
  '333897': 'DEBIT',
  '333898': 'DEBIT',
  '333899': 'DEBIT',
  '333900': 'DEBIT',
  '333901': 'DEBIT',
  '333902': 'DEBIT',
  '333903': 'DEBIT',
  '333904': 'DEBIT',
  '333905': 'DEBIT',
  '333906': 'DEBIT',
  '333907': 'DEBIT',
  '333908': 'DEBIT',
  '333909': 'DEBIT',
  '333910': 'DEBIT',
  '333911': 'CREDIT',
  '333912': 'DEBIT',
  '333913': 'DEBIT',
  '333914': 'DEBIT',
  '333915': 'DEBIT',
  '333916': 'DEBIT',
  '333917': 'DEBIT',
  '333918': 'DEBIT',
  '333919': 'DEBIT',
  '333920': 'DEBIT',
  '333921': 'DEBIT',
  '333922': 'DEBIT',
  '333923': 'DEBIT',
  '333924': 'DEBIT',
  '333925': 'DEBIT',
  '333926': 'DEBIT',
  '333927': 'DEBIT',
  '333928': 'DEBIT',
  '333929': 'DEBIT',
  '333930': 'DEBIT',
  '333931': 'DEBIT',
  '333932': 'DEBIT',
  '333933': 'DEBIT',
  '333934': 'DEBIT',
  '333935': 'DEBIT',
  '333936': 'DEBIT',
  '333937': 'DEBIT',
  '333938': 'DEBIT',
  '333939': 'DEBIT',
  '333940': 'DEBIT',
  '333941': 'DEBIT',
  '333942': 'DEBIT',
  '333943': 'DEBIT',
  '333944': 'DEBIT',
  '333945': 'DEBIT',
  '333946': 'DEBIT',
  '333947': 'DEBIT',
  '333948': 'DEBIT',
  '333949': 'DEBIT',
  '333950': 'DEBIT',
  '333951': 'DEBIT',
  '333952': 'DEBIT',
  '333953': 'DEBIT',
  '333954': 'DEBIT',
  '333955': 'DEBIT',
  '333956': 'DEBIT',
  '333957': 'DEBIT',
  '333958': 'DEBIT',
  '333959': 'DEBIT',
  '333960': 'DEBIT',
  '333961': 'DEBIT',
  '333962': 'DEBIT',
  '333963': 'DEBIT',
  '333964': 'DEBIT',
  '333965': 'DEBIT',
  '333966': 'DEBIT',
  '333967': 'DEBIT',
  '333968': 'DEBIT',
  '333969': 'DEBIT',
  '333970': 'DEBIT',
  '333971': 'DEBIT',
  '333972': 'DEBIT',
  '333973': 'DEBIT',
  '333974': 'DEBIT',
  '333975': 'DEBIT',
  '333976': 'DEBIT',
  '333977': 'DEBIT',
  '333978': 'DEBIT',
  '333979': 'DEBIT',
  '333980': 'DEBIT',
  '333981': 'DEBIT',
  '333982': 'DEBIT',
  '333983': 'DEBIT',
  '333984': 'DEBIT',
  '333985': 'DEBIT',
  '333986': 'DEBIT',
  '333987': 'DEBIT',
  '333988': 'DEBIT',
  '333989': 'DEBIT',
  '333990': 'DEBIT',
  '333991': 'DEBIT',
  '333992': 'DEBIT',
  '333993': 'DEBIT',
  '333994': 'DEBIT',
  '333995': 'DEBIT',
  '333996': 'DEBIT',
  '333997': 'DEBIT',
  '333998': 'DEBIT',
  '333999': 'DEBIT',
  '334000': 'DEBIT',
  '334001': 'DEBIT',
  '334002': 'DEBIT',
  '334003': 'DEBIT',
  '334004': 'DEBIT',
  '334005': 'DEBIT',
  '334006': 'DEBIT',
  '334007': 'DEBIT',
  '334008': 'DEBIT',
  '334009': 'DEBIT',
  '334010': 'DEBIT',
  '334011': 'DEBIT',
  '334012': 'DEBIT',
  '334013': 'DEBIT',
  '334014': 'DEBIT',
  '334015': 'DEBIT',
  '334016': 'DEBIT',
  '334017': 'DEBIT',
  '334018': 'DEBIT',
  '334019': 'DEBIT',
  '334020': 'DEBIT',
  '334021': 'CREDIT',
  '334022': 'DEBIT',
  '334023': 'DEBIT',
  '334024': 'DEBIT',
  '334025': 'DEBIT',
  '334026': 'DEBIT',
  '334027': 'DEBIT',
  '334028': 'DEBIT',
  '334029': 'DEBIT',
  '334030': 'DEBIT',
  '334031': 'DEBIT',
  '334032': 'DEBIT',
  '334033': 'DEBIT',
  '334034': 'DEBIT',
  '334035': 'DEBIT',
  '334036': 'DEBIT',
  '334037': 'DEBIT',
  '334038': 'DEBIT',
  '334039': 'DEBIT',
  '334040': 'DEBIT',
  '334041': 'DEBIT',
  '334042': 'DEBIT',
  '334043': 'DEBIT',
  '334044': 'DEBIT',
  '334045': 'DEBIT',
  '334046': 'DEBIT',
  '334047': 'DEBIT',
  '334048': 'DEBIT',
  '334049': 'DEBIT',
  '334050': 'DEBIT',
  '334051': 'DEBIT',
  '334052': 'DEBIT',
  '334053': 'DEBIT',
  '334054': 'DEBIT',
  '334055': 'DEBIT',
  '334056': 'DEBIT',
  '334057': 'DEBIT',
  '334058': 'DEBIT',
  '334059': 'DEBIT',
  '334060': 'DEBIT',
  '334061': 'DEBIT',
  '334062': 'DEBIT',
  '334063': 'DEBIT',
  '334064': 'DEBIT',
  '334065': 'DEBIT',
  '334066': 'DEBIT',
  '334067': 'DEBIT',
  '334068': 'DEBIT',
  '334069': 'DEBIT',
  '334070': 'DEBIT',
  '334071': 'DEBIT',
  '334072': 'DEBIT',
  '334073': 'DEBIT',
  '334074': 'DEBIT',
  '334075': 'DEBIT',
  '334076': 'DEBIT',
  '334077': 'DEBIT',
  '334078': 'DEBIT',
  '334079': 'DEBIT',
  '334080': 'DEBIT',
  '334081': 'DEBIT',
  '334082': 'DEBIT',
  '334083': 'DEBIT',
  '334084': 'DEBIT',
  '334085': 'DEBIT',
  '334086': 'DEBIT',
  '334087': 'DEBIT',
  '334088': 'DEBIT',
  '334089': 'DEBIT',
  '334090': 'DEBIT',
  '334091': 'DEBIT',
  '334092': 'DEBIT',
  '334093': 'DEBIT',
  '334094': 'DEBIT',
  '334095': 'DEBIT',
  '334096': 'DEBIT',
  '334097': 'DEBIT',
  '334098': 'DEBIT',
  '334099': 'DEBIT',
  '334100': 'DEBIT',
  '334101': 'DEBIT',
  '334102': 'DEBIT',
  '334103': 'DEBIT',
  '334104': 'DEBIT',
  '334105': 'DEBIT',
  '334106': 'DEBIT',
  '334107': 'DEBIT',
  '334108': 'DEBIT',
  '334109': 'DEBIT',
  '334110': 'DEBIT',
  '334111': 'DEBIT',
  '334112': 'DEBIT',
  '334113': 'DEBIT',
  '334114': 'DEBIT',
  '334115': 'DEBIT',
  '334116': 'DEBIT',
  '334117': 'DEBIT',
  '334118': 'DEBIT',
  '334119': 'DEBIT',
  '334120': 'DEBIT',
  '334121': 'DEBIT',
  '334122': 'DEBIT',
  '334123': 'DEBIT',
  '334124': 'DEBIT',
  '334125': 'DEBIT',
  '334126': 'DEBIT',
  '334127': 'DEBIT',
  '334128': 'DEBIT',
  '334129': 'DEBIT',
  '334130': 'DEBIT',
  '334131': 'DEBIT',
  '334132': 'DEBIT',
  '334133': 'DEBIT',
  '334134': 'DEBIT',
  '334135': 'DEBIT',
  '334136': 'DEBIT',
  '334137': 'DEBIT',
  '334138': 'DEBIT',
  '334139': 'DEBIT',
  '334140': 'DEBIT',
  '334141': 'DEBIT',
  '334142': 'DEBIT',
  '334143': 'DEBIT',
  '334144': 'DEBIT',
  '334145': 'DEBIT',
  '334146': 'DEBIT',
  '334147': 'DEBIT',
  '334148': 'DEBIT',
  '334149': 'DEBIT',
  '334150': 'DEBIT',
  '334151': 'DEBIT',
  '334152': 'DEBIT',
  '334153': 'DEBIT',
  '334154': 'DEBIT',
  '334155': 'CREDIT',
  '334156': 'DEBIT',
  '334157': 'DEBIT',
  '334158': 'DEBIT',
  '334159': 'DEBIT',
  '334160': 'DEBIT',
  '334161': 'DEBIT',
  '334162': 'DEBIT',
  '334163': 'DEBIT',
  '334164': 'DEBIT',
  '334165': 'DEBIT',
  '334166': 'DEBIT',
  '334167': 'DEBIT',
  '334168': 'DEBIT',
  '334169': 'DEBIT',
  '334170': 'DEBIT',
  '334171': 'DEBIT',
  '334172': 'DEBIT',
  '334173': 'DEBIT',
  '334174': 'DEBIT',
  '334175': 'DEBIT',
  '334176': 'DEBIT',
  '334177': 'DEBIT',
  '334178': 'DEBIT',
  '334179': 'DEBIT',
  '334180': 'DEBIT',
  '334181': 'DEBIT',
  '334182': 'DEBIT',
  '334183': 'DEBIT',
  '334184': 'DEBIT',
  '334185': 'DEBIT',
  '334186': 'DEBIT',
  '334187': 'DEBIT',
  '334188': 'DEBIT',
  '334189': 'DEBIT',
  '334190': 'DEBIT',
  '334191': 'DEBIT',
  '334192': 'DEBIT',
  '334193': 'DEBIT',
  '334194': 'DEBIT',
  '334195': 'DEBIT',
  '334196': 'DEBIT',
  '334197': 'DEBIT',
  '334198': 'DEBIT',
  '334199': 'DEBIT',
  '334200': 'DEBIT',
  '334201': 'DEBIT',
  '334202': 'DEBIT',
  '334203': 'DEBIT',
  '334204': 'DEBIT',
  '334205': 'DEBIT',
  '334206': 'DEBIT',
  '334207': 'DEBIT',
  '334208': 'DEBIT',
  '334209': 'DEBIT',
  '334210': 'DEBIT',
  '334211': 'DEBIT',
  '334212': 'DEBIT',
  '334213': 'DEBIT',
  '334214': 'DEBIT',
  '334215': 'DEBIT',
  '334216': 'DEBIT',
  '334217': 'DEBIT',
  '334218': 'DEBIT',
  '334219': 'DEBIT',
  '334220': 'DEBIT',
  '334221': 'DEBIT',
  '334222': 'DEBIT',
  '334223': 'DEBIT',
  '334224': 'DEBIT',
  '334225': 'DEBIT',
  '334226': 'DEBIT',
  '334227': 'DEBIT',
  '334228': 'DEBIT',
  '334229': 'DEBIT',
  '334230': 'DEBIT',
  '334231': 'DEBIT',
  '334232': 'DEBIT',
  '334233': 'DEBIT',
  '334234': 'DEBIT',
  '334235': 'DEBIT',
  '334236': 'DEBIT',
  '334237': 'DEBIT',
  '334238': 'DEBIT',
  '334239': 'DEBIT',
  '334240': 'DEBIT',
  '334241': 'DEBIT',
  '334242': 'DEBIT',
  '334243': 'DEBIT',
  '334244': 'DEBIT',
  '334245': 'DEBIT',
  '334246': 'DEBIT',
  '334247': 'DEBIT',
  '334248': 'DEBIT',
  '334249': 'DEBIT',
  '334250': 'DEBIT',
  '334251': 'DEBIT',
  '334252': 'DEBIT',
  '334253': 'DEBIT',
  '334254': 'DEBIT',
  '334255': 'DEBIT',
  '334256': 'DEBIT',
  '334257': 'DEBIT',
  '334258': 'DEBIT',
  '334259': 'DEBIT',
  '334260': 'DEBIT',
  '334261': 'DEBIT',
  '334262': 'DEBIT',
  '334263': 'DEBIT',
  '334264': 'DEBIT',
  '334265': 'DEBIT',
  '334266': 'DEBIT',
  '334267': 'DEBIT',
  '334268': 'DEBIT',
  '334269': 'DEBIT',
  '334270': 'DEBIT',
  '334271': 'DEBIT',
  '334272': 'DEBIT',
  '334273': 'DEBIT',
  '334274': 'DEBIT',
  '334275': 'DEBIT',
  '334276': 'DEBIT',
  '334277': 'DEBIT',
  '334278': 'DEBIT',
  '334279': 'DEBIT',
  '334280': 'DEBIT',
  '334281': 'DEBIT',
  '334282': 'DEBIT',
  '334283': 'DEBIT',
  '334284': 'DEBIT',
  '334285': 'DEBIT',
  '334286': 'DEBIT',
  '334287': 'DEBIT',
  '334288': 'DEBIT',
  '334289': 'DEBIT',
  '334290': 'DEBIT',
  '334291': 'DEBIT',
  '334292': 'DEBIT',
  '334293': 'DEBIT',
  '334294': 'DEBIT',
  '334295': 'DEBIT',
  '334296': 'DEBIT',
  '334297': 'DEBIT',
  '334298': 'DEBIT',
  '334299': 'DEBIT',
  '334300': 'DEBIT',
  '334301': 'DEBIT',
  '334302': 'DEBIT',
  '334303': 'DEBIT',
  '334304': 'DEBIT',
  '334305': 'DEBIT',
  '334306': 'DEBIT',
  '334307': 'DEBIT',
  '334308': 'DEBIT',
  '334309': 'DEBIT',
  '334310': 'DEBIT',
  '334311': 'DEBIT',
  '334312': 'DEBIT',
  '334313': 'DEBIT',
  '334314': 'DEBIT',
  '334315': 'DEBIT',
  '334316': 'DEBIT',
  '334317': 'DEBIT',
  '334318': 'DEBIT',
  '334319': 'DEBIT',
  '334320': 'DEBIT',
  '334321': 'DEBIT',
  '334322': 'DEBIT',
  '334323': 'DEBIT',
  '334324': 'DEBIT',
  '334325': 'DEBIT',
  '334326': 'DEBIT',
  '334327': 'DEBIT',
  '334328': 'DEBIT',
  '334329': 'DEBIT',
  '334330': 'DEBIT',
  '334331': 'DEBIT',
  '334332': 'DEBIT',
  '334333': 'DEBIT',
  '334334': 'DEBIT',
  '334335': 'DEBIT',
  '334336': 'DEBIT',
  '334337': 'DEBIT',
  '334338': 'DEBIT',
  '334339': 'DEBIT',
  '334340': 'DEBIT',
  '334341': 'DEBIT',
  '334342': 'DEBIT',
  '334343': 'DEBIT',
  '334344': 'DEBIT',
  '334345': 'DEBIT',
  '334346': 'DEBIT',
  '334347': 'DEBIT',
  '334348': 'DEBIT',
  '334349': 'DEBIT',
  '334350': 'DEBIT',
  '334351': 'DEBIT',
  '334352': 'DEBIT',
  '334353': 'DEBIT',
  '334354': 'DEBIT',
  '334355': 'DEBIT',
  '334356': 'DEBIT',
  '334357': 'DEBIT',
  '334358': 'DEBIT',
  '334359': 'DEBIT',
  '334360': 'DEBIT',
  '334361': 'DEBIT',
  '334362': 'DEBIT',
  '334363': 'DEBIT',
  '334364': 'DEBIT',
  '334365': 'DEBIT',
  '334366': 'DEBIT',
  '334367': 'DEBIT',
  '334368': 'DEBIT',
  '334369': 'DEBIT',
  '334370': 'DEBIT',
  '334371': 'DEBIT',
  '334372': 'CREDIT',
  '334373': 'DEBIT',
  '334374': 'DEBIT',
  '334375': 'DEBIT',
  '334376': 'DEBIT',
  '334377': 'DEBIT',
  '334378': 'DEBIT',
  '334379': 'DEBIT',
  '334380': 'DEBIT',
  '334381': 'DEBIT',
  '334382': 'DEBIT',
  '334383': 'DEBIT',
  '334384': 'DEBIT',
  '334385': 'DEBIT',
  '334386': 'DEBIT',
  '334387': 'DEBIT',
  '334388': 'DEBIT',
  '334389': 'DEBIT',
  '334390': 'DEBIT',
  '334391': 'DEBIT',
  '334392': 'DEBIT',
  '334393': 'DEBIT',
  '334394': 'DEBIT',
  '334395': 'DEBIT',
  '334396': 'DEBIT',
  '334397': 'DEBIT',
  '334398': 'DEBIT',
  '334399': 'DEBIT',
  '334400': 'DEBIT',
  '334401': 'DEBIT',
  '334402': 'DEBIT',
  '334403': 'DEBIT',
  '334404': 'DEBIT',
  '334405': 'DEBIT',
  '334406': 'DEBIT',
  '334407': 'DEBIT',
  '334408': 'DEBIT',
  '334409': 'DEBIT',
  '334410': 'DEBIT',
  '334411': 'DEBIT',
  '334412': 'DEBIT',
  '334413': 'DEBIT',
  '334414': 'DEBIT',
  '334415': 'DEBIT',
  '334416': 'DEBIT',
  '334417': 'DEBIT',
  '334418': 'DEBIT',
  '334419': 'DEBIT',
  '334420': 'DEBIT',
  '334421': 'DEBIT',
  '334422': 'DEBIT',
  '334423': 'DEBIT',
  '334424': 'DEBIT',
  '334425': 'DEBIT',
  '334426': 'DEBIT',
  '334427': 'DEBIT',
  '334428': 'DEBIT',
  '334429': 'DEBIT',
  '334430': 'DEBIT',
  '334431': 'DEBIT',
  '334432': 'DEBIT',
  '334433': 'DEBIT',
  '334434': 'DEBIT',
  '334435': 'DEBIT',
  '334436': 'DEBIT',
  '334437': 'DEBIT',
  '334438': 'DEBIT',
  '334439': 'DEBIT',
  '334440': 'DEBIT',
  '334441': 'DEBIT',
  '334442': 'DEBIT',
  '334443': 'DEBIT',
  '334444': 'DEBIT',
  '334445': 'DEBIT',
  '334446': 'DEBIT',
  '334447': 'DEBIT',
  '334448': 'DEBIT',
  '334449': 'DEBIT',
  '334450': 'DEBIT',
  '334451': 'DEBIT',
  '334452': 'DEBIT',
  '334453': 'DEBIT',
  '334454': 'DEBIT',
  '334455': 'DEBIT',
  '334456': 'DEBIT',
  '334457': 'DEBIT',
  '334458': 'DEBIT',
  '334459': 'DEBIT',
  '334460': 'DEBIT',
  '334461': 'DEBIT',
  '334462': 'DEBIT',
  '334463': 'DEBIT',
  '334464': 'DEBIT',
  '334465': 'DEBIT',
  '334466': 'DEBIT',
  '334467': 'DEBIT',
  '334468': 'DEBIT',
  '334469': 'DEBIT',
  '334470': 'DEBIT',
  '334471': 'DEBIT',
  '334472': 'DEBIT',
  '334473': 'DEBIT',
  '334474': 'DEBIT',
  '334475': 'DEBIT',
  '334476': 'DEBIT',
  '334477': 'DEBIT',
  '334478': 'DEBIT',
  '334479': 'DEBIT',
  '334480': 'DEBIT',
  '334481': 'DEBIT',
  '334482': 'DEBIT',
  '334483': 'DEBIT',
  '334484': 'DEBIT',
  '334485': 'DEBIT',
  '334486': 'DEBIT',
  '334487': 'DEBIT',
  '334488': 'DEBIT',
  '334489': 'DEBIT',
  '334490': 'DEBIT',
  '334491': 'DEBIT',
  '334492': 'DEBIT',
  '334493': 'DEBIT',
  '334494': 'DEBIT',
  '334495': 'DEBIT',
  '334496': 'DEBIT',
  '334497': 'DEBIT',
  '334498': 'DEBIT',
  '334499': 'DEBIT',
  '334500': 'DEBIT',
  '334501': 'DEBIT',
  '334502': 'DEBIT',
  '334503': 'DEBIT',
  '334504': 'DEBIT',
  '334505': 'CREDIT',
  '334506': 'DEBIT',
  '334507': 'DEBIT',
  '334508': 'DEBIT',
  '334509': 'DEBIT',
  '334510': 'DEBIT',
  '334511': 'DEBIT',
  '334512': 'DEBIT',
  '334513': 'DEBIT',
  '334514': 'DEBIT',
  '334515': 'DEBIT',
  '334516': 'DEBIT',
  '334517': 'DEBIT',
  '334518': 'DEBIT',
  '334519': 'DEBIT',
  '334520': 'DEBIT',
  '334521': 'CREDIT',
  '334522': 'DEBIT',
  '334523': 'DEBIT',
  '334524': 'DEBIT',
  '334525': 'DEBIT',
  '334526': 'DEBIT',
  '334527': 'DEBIT',
  '334528': 'DEBIT',
  '334529': 'DEBIT',
  '334530': 'DEBIT',
  '334531': 'DEBIT',
  '334532': 'DEBIT',
  '334533': 'CREDIT',
  '334534': 'DEBIT',
  '334535': 'DEBIT',
  '334536': 'DEBIT',
  '334537': 'DEBIT',
  '334538': 'DEBIT',
  '334539': 'DEBIT',
  '334540': 'DEBIT',
  '334541': 'DEBIT',
  '334542': 'DEBIT',
  '334543': 'DEBIT',
  '334544': 'DEBIT',
  '334545': 'DEBIT',
  '334546': 'DEBIT',
  '334547': 'DEBIT',
  '334548': 'DEBIT',
  '334549': 'DEBIT',
  '334550': 'DEBIT',
  '334551': 'DEBIT',
  '334552': 'DEBIT',
  '334553': 'DEBIT',
  '334554': 'DEBIT',
  '334555': 'DEBIT',
  '334556': 'DEBIT',
  '334557': 'DEBIT',
  '334558': 'DEBIT',
  '334559': 'DEBIT',
  '334560': 'DEBIT',
  '334561': 'DEBIT',
  '334562': 'DEBIT',
  '334563': 'DEBIT',
  '334564': 'CREDIT',
  '334565': 'DEBIT',
  '334566': 'DEBIT',
  '334567': 'DEBIT',
  '334568': 'DEBIT',
  '334569': 'DEBIT',
  '334570': 'DEBIT',
  '334571': 'DEBIT',
  '334572': 'DEBIT',
  '334573': 'DEBIT',
  '334574': 'DEBIT',
  '334575': 'DEBIT',
  '334576': 'DEBIT',
  '334577': 'DEBIT',
  '334578': 'DEBIT',
  '334579': 'DEBIT',
  '334580': 'DEBIT',
  '334581': 'DEBIT',
  '334582': 'DEBIT',
  '334583': 'DEBIT',
  '334584': 'DEBIT',
  '334585': 'DEBIT',
  '334586': 'DEBIT',
  '334587': 'DEBIT',
  '334588': 'DEBIT',
  '334589': 'CREDIT',
  '334590': 'DEBIT',
  '334591': 'DEBIT',
  '334592': 'DEBIT',
  '334593': 'DEBIT',
  '334594': 'DEBIT',
  '334595': 'DEBIT',
  '334596': 'DEBIT',
  '334597': 'DEBIT',
  '334598': 'DEBIT',
  '334599': 'DEBIT',
  '334600': 'DEBIT',
  '334601': 'DEBIT',
  '334602': 'DEBIT',
  '334603': 'DEBIT',
  '334604': 'DEBIT',
  '334605': 'DEBIT',
  '334606': 'DEBIT',
  '334607': 'DEBIT',
  '334608': 'DEBIT',
  '334609': 'DEBIT',
  '334610': 'DEBIT',
  '334611': 'DEBIT',
  '334612': 'DEBIT',
  '334613': 'DEBIT',
  '334614': 'DEBIT',
  '334615': 'DEBIT',
  '334616': 'DEBIT',
  '334617': 'DEBIT',
  '334618': 'DEBIT',
  '334619': 'DEBIT',
  '334620': 'DEBIT',
  '334621': 'DEBIT',
  '334622': 'DEBIT',
  '334623': 'DEBIT',
  '334624': 'DEBIT',
  '334625': 'DEBIT',
  '334626': 'DEBIT',
  '334627': 'DEBIT',
  '334628': 'DEBIT',
  '334629': 'DEBIT',
  '334630': 'DEBIT',
  '334631': 'DEBIT',
  '334632': 'DEBIT',
  '334633': 'DEBIT',
  '334634': 'DEBIT',
  '334635': 'DEBIT',
  '334636': 'DEBIT',
  '334637': 'DEBIT',
  '334638': 'DEBIT',
  '334639': 'DEBIT',
  '334640': 'DEBIT',
  '334641': 'DEBIT',
  '334642': 'DEBIT',
  '334643': 'DEBIT',
  '334644': 'DEBIT',
  '334645': 'DEBIT',
  '334646': 'DEBIT',
  '334647': 'DEBIT',
  '334648': 'DEBIT',
  '334649': 'DEBIT',
  '334650': 'DEBIT',
  '334651': 'DEBIT',
  '334652': 'DEBIT',
  '334653': 'DEBIT',
  '334654': 'DEBIT',
  '334655': 'DEBIT',
  '334656': 'DEBIT',
  '334657': 'DEBIT',
  '334658': 'DEBIT',
  '334659': 'DEBIT',
  '334660': 'DEBIT',
  '334661': 'DEBIT',
  '334662': 'DEBIT',
  '334663': 'DEBIT',
  '334664': 'DEBIT',
  '334665': 'DEBIT',
  '334666': 'DEBIT',
  '334667': 'DEBIT',
  '334668': 'DEBIT',
  '334669': 'DEBIT',
  '334670': 'DEBIT',
  '334671': 'DEBIT',
  '334672': 'DEBIT',
  '334673': 'DEBIT',
  '334674': 'DEBIT',
  '334675': 'DEBIT',
  '334676': 'DEBIT',
  '334677': 'DEBIT',
  '334678': 'DEBIT',
  '334679': 'DEBIT',
  '334680': 'DEBIT',
  '334681': 'DEBIT',
  '334682': 'DEBIT',
  '334683': 'DEBIT',
  '334684': 'DEBIT',
  '334685': 'DEBIT',
  '334686': 'DEBIT',
  '334687': 'DEBIT',
  '334688': 'DEBIT',
  '334689': 'DEBIT',
  '334690': 'DEBIT',
  '334691': 'DEBIT',
  '334692': 'DEBIT',
  '334693': 'DEBIT',
  '334694': 'DEBIT',
  '334695': 'DEBIT',
  '334696': 'DEBIT',
  '334697': 'DEBIT',
  '334698': 'DEBIT',
  '334699': 'DEBIT',
  '334700': 'DEBIT',
  '334701': 'DEBIT',
  '334702': 'DEBIT',
  '334703': 'DEBIT',
  '334704': 'DEBIT',
  '334705': 'DEBIT',
  '334706': 'DEBIT',
  '334707': 'DEBIT',
  '334708': 'DEBIT',
  '334709': 'DEBIT',
  '334710': 'DEBIT',
  '334711': 'DEBIT',
  '334712': 'DEBIT',
  '334713': 'DEBIT',
  '334714': 'DEBIT',
  '334715': 'DEBIT',
  '334716': 'DEBIT',
  '334717': 'DEBIT',
  '334718': 'DEBIT',
  '334719': 'DEBIT',
  '334720': 'DEBIT',
  '334721': 'DEBIT',
  '334722': 'DEBIT',
  '334723': 'DEBIT',
  '334724': 'DEBIT',
  '334725': 'DEBIT',
  '334726': 'DEBIT',
  '334727': 'DEBIT',
  '334728': 'DEBIT',
  '334729': 'DEBIT',
  '334730': 'DEBIT',
  '334731': 'DEBIT',
  '334732': 'DEBIT',
  '334733': 'DEBIT',
  '334734': 'DEBIT',
  '334735': 'DEBIT',
  '334736': 'DEBIT',
  '334737': 'DEBIT',
  '334738': 'DEBIT',
  '334739': 'DEBIT',
  '334740': 'DEBIT',
  '334741': 'DEBIT',
  '334742': 'DEBIT',
  '334743': 'DEBIT',
  '334744': 'DEBIT',
  '334745': 'DEBIT',
  '334746': 'DEBIT',
  '334747': 'DEBIT',
  '334748': 'DEBIT',
  '334749': 'DEBIT',
  '334750': 'DEBIT',
  '334751': 'DEBIT',
  '334752': 'DEBIT',
  '334753': 'DEBIT',
  '334754': 'DEBIT',
  '334755': 'DEBIT',
  '334756': 'DEBIT',
  '334757': 'DEBIT',
  '334758': 'DEBIT',
  '334759': 'DEBIT',
  '334760': 'DEBIT',
  '334761': 'DEBIT',
  '334762': 'DEBIT',
  '334763': 'DEBIT',
  '334764': 'DEBIT',
  '334765': 'DEBIT',
  '334766': 'DEBIT',
  '334767': 'DEBIT',
  '334768': 'DEBIT',
  '334769': 'DEBIT',
  '334770': 'DEBIT',
  '334771': 'DEBIT',
  '334772': 'DEBIT',
  '334773': 'DEBIT',
  '334774': 'DEBIT',
  '334775': 'DEBIT',
  '334776': 'DEBIT',
  '334777': 'DEBIT',
  '334778': 'DEBIT',
  '334779': 'DEBIT',
  '334780': 'DEBIT',
  '334781': 'DEBIT',
  '334782': 'DEBIT',
  '334783': 'DEBIT',
  '334784': 'DEBIT',
  '334785': 'DEBIT',
  '334786': 'DEBIT',
  '334787': 'DEBIT',
  '334788': 'DEBIT',
  '334789': 'DEBIT',
  '334790': 'DEBIT',
  '334791': 'DEBIT',
  '334792': 'DEBIT',
  '334793': 'DEBIT',
  '334794': 'DEBIT',
  '334795': 'DEBIT',
  '334796': 'DEBIT',
  '334797': 'DEBIT',
  '334798': 'DEBIT',
  '334799': 'DEBIT',
  '334800': 'DEBIT',
  '334801': 'DEBIT',
  '334802': 'DEBIT',
  '334803': 'DEBIT',
  '334804': 'DEBIT',
  '334805': 'DEBIT',
  '334806': 'DEBIT',
  '334807': 'DEBIT',
  '334808': 'DEBIT',
  '334809': 'DEBIT',
  '334810': 'DEBIT',
  '334811': 'DEBIT',
  '334812': 'DEBIT',
  '334813': 'DEBIT',
  '334814': 'DEBIT',
  '334815': 'DEBIT',
  '334816': 'DEBIT',
  '334817': 'DEBIT',
  '334818': 'DEBIT',
  '334819': 'DEBIT',
  '334820': 'DEBIT',
  '334821': 'DEBIT',
  '334822': 'DEBIT',
  '334823': 'DEBIT',
  '334824': 'DEBIT',
  '334825': 'DEBIT',
  '334826': 'DEBIT',
  '334827': 'DEBIT',
  '334828': 'DEBIT',
  '334829': 'DEBIT',
  '334830': 'DEBIT',
  '334831': 'DEBIT',
  '334832': 'DEBIT',
  '334833': 'DEBIT',
  '334834': 'DEBIT',
  '334835': 'DEBIT',
  '334836': 'DEBIT',
  '334837': 'DEBIT',
  '334838': 'DEBIT',
  '334839': 'DEBIT',
  '334840': 'DEBIT',
  '334841': 'DEBIT',
  '334842': 'DEBIT',
  '334843': 'DEBIT',
  '334844': 'DEBIT',
  '334845': 'DEBIT',
  '334846': 'DEBIT',
  '334847': 'DEBIT',
  '334848': 'DEBIT',
  '334849': 'DEBIT',
  '334850': 'DEBIT',
  '334851': 'DEBIT',
  '334852': 'DEBIT',
  '334853': 'DEBIT',
  '334854': 'DEBIT',
  '334855': 'DEBIT',
  '334856': 'CREDIT',
  '334857': 'DEBIT',
  '334858': 'DEBIT',
  '334859': 'DEBIT',
  '334860': 'DEBIT',
  '334861': 'DEBIT',
  '334862': 'DEBIT',
  '334863': 'DEBIT',
  '334864': 'DEBIT',
  '334865': 'DEBIT',
  '334866': 'DEBIT',
  '334867': 'DEBIT',
  '334868': 'DEBIT',
  '334869': 'DEBIT',
  '334870': 'DEBIT',
  '334871': 'DEBIT',
  '334872': 'DEBIT',
  '334873': 'DEBIT',
  '334874': 'DEBIT',
  '334875': 'DEBIT',
  '334876': 'DEBIT',
  '334877': 'DEBIT',
  '334878': 'DEBIT',
  '334879': 'DEBIT',
  '334880': 'DEBIT',
  '334881': 'DEBIT',
  '334882': 'DEBIT',
  '334883': 'DEBIT',
  '334884': 'DEBIT',
  '334885': 'DEBIT',
  '334886': 'DEBIT',
  '334887': 'DEBIT',
  '334888': 'DEBIT',
  '334889': 'DEBIT',
  '334890': 'DEBIT',
  '334891': 'DEBIT',
  '334892': 'DEBIT',
  '334893': 'DEBIT',
  '334894': 'DEBIT',
  '334895': 'DEBIT',
  '334896': 'DEBIT',
  '334897': 'DEBIT',
  '334898': 'DEBIT',
  '334899': 'DEBIT',
  '334900': 'DEBIT',
  '334901': 'DEBIT',
  '334902': 'DEBIT',
  '334903': 'DEBIT',
  '334904': 'DEBIT',
  '334905': 'DEBIT',
  '334906': 'DEBIT',
  '334907': 'DEBIT',
  '334908': 'DEBIT',
  '334909': 'DEBIT',
  '334910': 'DEBIT',
  '334911': 'DEBIT',
  '334912': 'DEBIT',
  '334913': 'DEBIT',
  '334914': 'DEBIT',
  '334915': 'DEBIT',
  '334916': 'DEBIT',
  '334917': 'DEBIT',
  '334918': 'DEBIT',
  '334919': 'DEBIT',
  '334920': 'DEBIT',
  '334921': 'DEBIT',
  '334922': 'DEBIT',
  '334923': 'DEBIT',
  '334924': 'DEBIT',
  '334925': 'DEBIT',
  '334926': 'DEBIT',
  '334927': 'DEBIT',
  '334928': 'DEBIT',
  '334929': 'DEBIT',
  '334930': 'DEBIT',
  '334931': 'DEBIT',
  '334932': 'DEBIT',
  '334933': 'DEBIT',
  '334934': 'DEBIT',
  '334935': 'DEBIT',
  '334936': 'DEBIT',
  '334937': 'DEBIT',
  '334938': 'DEBIT',
  '334939': 'DEBIT',
  '334940': 'DEBIT',
  '334941': 'DEBIT',
  '334942': 'DEBIT',
  '334943': 'DEBIT',
  '334944': 'DEBIT',
  '334945': 'DEBIT',
  '334946': 'DEBIT',
  '334947': 'DEBIT',
  '334948': 'DEBIT',
  '334949': 'DEBIT',
  '334950': 'DEBIT',
  '334951': 'DEBIT',
  '334952': 'DEBIT',
  '334953': 'DEBIT',
  '334954': 'DEBIT',
  '334955': 'DEBIT',
  '334956': 'DEBIT',
  '334957': 'DEBIT',
  '334958': 'DEBIT',
  '334959': 'DEBIT',
  '334960': 'CREDIT',
  '334961': 'DEBIT',
  '334962': 'DEBIT',
  '334963': 'DEBIT',
  '334964': 'DEBIT',
  '334965': 'DEBIT',
  '334966': 'DEBIT',
  '334967': 'DEBIT',
  '334968': 'DEBIT',
  '334969': 'DEBIT',
  '334970': 'DEBIT',
  '334971': 'DEBIT',
  '334972': 'DEBIT',
  '334973': 'DEBIT',
  '334974': 'DEBIT',
  '334975': 'DEBIT',
  '334976': 'DEBIT',
  '334977': 'CREDIT',
  '334978': 'DEBIT',
  '334979': 'DEBIT',
  '334980': 'DEBIT',
  '334981': 'DEBIT',
  '334982': 'DEBIT',
  '334983': 'CREDIT',
  '334984': 'DEBIT',
  '334985': 'DEBIT',
  '334986': 'DEBIT',
  '334987': 'DEBIT',
  '334988': 'DEBIT',
  '334989': 'DEBIT',
  '334990': 'DEBIT',
  '334991': 'DEBIT',
  '334992': 'DEBIT',
  '334993': 'DEBIT',
  '334994': 'DEBIT',
  '334995': 'DEBIT',
  '334996': 'DEBIT',
  '334997': 'DEBIT',
  '334998': 'DEBIT',
  '334999': 'DEBIT',
  '340054': 'CREDIT',
  '340179': 'CREDIT',
  '340180': 'CREDIT',
  '340181': 'CREDIT',
  '340182': 'CREDIT',
  '340183': 'CREDIT',
  '340184': 'CREDIT',
  '340185': 'CREDIT',
  '340186': 'CREDIT',
  '340187': 'CREDIT',
  '340188': 'CREDIT',
  '340189': 'CREDIT',
  '340190': 'CREDIT',
  '340191': 'CREDIT',
  '340192': 'CREDIT',
  '340193': 'CREDIT',
  '340194': 'CREDIT',
  '340195': 'CREDIT',
  '340196': 'CREDIT',
  '340197': 'CREDIT',
  '340198': 'CREDIT',
  '340199': 'CREDIT',
  '340200': 'CREDIT',
  '340201': 'CREDIT',
  '340202': 'CREDIT',
  '340203': 'CREDIT',
  '341280': 'CREDIT',
  '344865': 'CREDIT',
  '345101': 'CREDIT',
  '345203': 'CREDIT',
  '350688': 'CREDIT',
  '351990': 'CREDIT',
  '352800': 'CREDIT',
  '352801': 'CREDIT',
  '352802': 'CREDIT',
  '352803': 'CREDIT',
  '352804': 'CREDIT',
  '352805': 'CREDIT',
  '352806': 'CREDIT',
  '352807': 'CREDIT',
  '352808': 'CREDIT',
  '352809': 'CREDIT',
  '352810': 'CREDIT',
  '352811': 'CREDIT',
  '352812': 'CREDIT',
  '352813': 'CREDIT',
  '352814': 'CREDIT',
  '352815': 'CREDIT',
  '352816': 'CREDIT',
  '352817': 'CREDIT',
  '352818': 'CREDIT',
  '352819': 'CREDIT',
  '352820': 'CREDIT',
  '352821': 'CREDIT',
  '352822': 'CREDIT',
  '352823': 'CREDIT',
  '352824': 'CREDIT',
  '352825': 'CREDIT',
  '352826': 'CREDIT',
  '352827': 'CREDIT',
  '352828': 'CREDIT',
  '352829': 'CREDIT',
  '352830': 'CREDIT',
  '352831': 'CREDIT',
  '352832': 'CREDIT',
  '352833': 'CREDIT',
  '352834': 'CREDIT',
  '352835': 'CREDIT',
  '352836': 'CREDIT',
  '352837': 'CREDIT',
  '352838': 'CREDIT',
  '352839': 'CREDIT',
  '352840': 'CREDIT',
  '352841': 'CREDIT',
  '352842': 'CREDIT',
  '352843': 'CREDIT',
  '352844': 'CREDIT',
  '352845': 'CREDIT',
  '352846': 'CREDIT',
  '352847': 'CREDIT',
  '352848': 'CREDIT',
  '352849': 'CREDIT',
  '352850': 'CREDIT',
  '352851': 'CREDIT',
  '352852': 'CREDIT',
  '352853': 'CREDIT',
  '352854': 'CREDIT',
  '352855': 'CREDIT',
  '352856': 'CREDIT',
  '352857': 'CREDIT',
  '352858': 'CREDIT',
  '352859': 'CREDIT',
  '352860': 'CREDIT',
  '352861': 'CREDIT',
  '352862': 'CREDIT',
  '352863': 'CREDIT',
  '352864': 'CREDIT',
  '352865': 'CREDIT',
  '352866': 'CREDIT',
  '352867': 'CREDIT',
  '352868': 'CREDIT',
  '352869': 'CREDIT',
  '352870': 'CREDIT',
  '352871': 'CREDIT',
  '352872': 'CREDIT',
  '352873': 'CREDIT',
  '352874': 'CREDIT',
  '352875': 'CREDIT',
  '352876': 'CREDIT',
  '352877': 'CREDIT',
  '352878': 'CREDIT',
  '352879': 'CREDIT',
  '352880': 'CREDIT',
  '352881': 'CREDIT',
  '352882': 'CREDIT',
  '352883': 'CREDIT',
  '352884': 'CREDIT',
  '352885': 'CREDIT',
  '352886': 'CREDIT',
  '352887': 'CREDIT',
  '352888': 'CREDIT',
  '352889': 'CREDIT',
  '352890': 'CREDIT',
  '352891': 'CREDIT',
  '352892': 'CREDIT',
  '352893': 'CREDIT',
  '352894': 'CREDIT',
  '352895': 'CREDIT',
  '352896': 'CREDIT',
  '352897': 'CREDIT',
  '352898': 'CREDIT',
  '352899': 'CREDIT',
  '352900': 'CREDIT',
  '352901': 'CREDIT',
  '352902': 'CREDIT',
  '352903': 'CREDIT',
  '352904': 'CREDIT',
  '352905': 'CREDIT',
  '352906': 'CREDIT',
  '352907': 'CREDIT',
  '352908': 'CREDIT',
  '352909': 'CREDIT',
  '352910': 'CREDIT',
  '352911': 'CREDIT',
  '352912': 'CREDIT',
  '352913': 'CREDIT',
  '352914': 'CREDIT',
  '352915': 'CREDIT',
  '352916': 'CREDIT',
  '352917': 'CREDIT',
  '352918': 'CREDIT',
  '352919': 'CREDIT',
  '352920': 'CREDIT',
  '352921': 'CREDIT',
  '352922': 'CREDIT',
  '352923': 'CREDIT',
  '352924': 'CREDIT',
  '352925': 'CREDIT',
  '352926': 'CREDIT',
  '352927': 'CREDIT',
  '352928': 'CREDIT',
  '352929': 'CREDIT',
  '352930': 'CREDIT',
  '352931': 'CREDIT',
  '352932': 'CREDIT',
  '352933': 'CREDIT',
  '352934': 'CREDIT',
  '352935': 'CREDIT',
  '352936': 'CREDIT',
  '352937': 'CREDIT',
  '352938': 'CREDIT',
  '352939': 'CREDIT',
  '352940': 'CREDIT',
  '352941': 'CREDIT',
  '352942': 'CREDIT',
  '352943': 'CREDIT',
  '352944': 'CREDIT',
  '352945': 'CREDIT',
  '352946': 'CREDIT',
  '352947': 'CREDIT',
  '352948': 'CREDIT',
  '352949': 'CREDIT',
  '352950': 'CREDIT',
  '352951': 'CREDIT',
  '352952': 'CREDIT',
  '352953': 'CREDIT',
  '352954': 'CREDIT',
  '352955': 'CREDIT',
  '352956': 'CREDIT',
  '352957': 'CREDIT',
  '352958': 'CREDIT',
  '352959': 'CREDIT',
  '352960': 'CREDIT',
  '352961': 'CREDIT',
  '352962': 'CREDIT',
  '352963': 'CREDIT',
  '352964': 'CREDIT',
  '352965': 'CREDIT',
  '352966': 'CREDIT',
  '352967': 'CREDIT',
  '352968': 'CREDIT',
  '352969': 'CREDIT',
  '352970': 'CREDIT',
  '352971': 'CREDIT',
  '352972': 'CREDIT',
  '352973': 'CREDIT',
  '352974': 'CREDIT',
  '352975': 'CREDIT',
  '352976': 'CREDIT',
  '352977': 'CREDIT',
  '352978': 'CREDIT',
  '352979': 'CREDIT',
  '352980': 'CREDIT',
  '352981': 'CREDIT',
  '352982': 'CREDIT',
  '352983': 'CREDIT',
  '352984': 'CREDIT',
  '352985': 'CREDIT',
  '352986': 'CREDIT',
  '352987': 'CREDIT',
  '352988': 'CREDIT',
  '352989': 'CREDIT',
  '352990': 'CREDIT',
  '352991': 'CREDIT',
  '352992': 'CREDIT',
  '352993': 'CREDIT',
  '352994': 'CREDIT',
  '352995': 'CREDIT',
  '352996': 'CREDIT',
  '352997': 'CREDIT',
  '352998': 'CREDIT',
  '352999': 'CREDIT',
  '353000': 'CREDIT',
  '353001': 'CREDIT',
  '353002': 'CREDIT',
  '353003': 'CREDIT',
  '353004': 'CREDIT',
  '353005': 'CREDIT',
  '353006': 'CREDIT',
  '353007': 'CREDIT',
  '353008': 'CREDIT',
  '353009': 'CREDIT',
  '353010': 'CREDIT',
  '353011': 'CREDIT',
  '353012': 'CREDIT',
  '353013': 'CREDIT',
  '353014': 'CREDIT',
  '353015': 'CREDIT',
  '353016': 'CREDIT',
  '353017': 'CREDIT',
  '353018': 'CREDIT',
  '353019': 'CREDIT',
  '353020': 'CREDIT',
  '353021': 'CREDIT',
  '353022': 'CREDIT',
  '353023': 'CREDIT',
  '353024': 'CREDIT',
  '353025': 'CREDIT',
  '353026': 'CREDIT',
  '353027': 'CREDIT',
  '353028': 'CREDIT',
  '353029': 'CREDIT',
  '353030': 'CREDIT',
  '353031': 'CREDIT',
  '353032': 'CREDIT',
  '353033': 'CREDIT',
  '353034': 'CREDIT',
  '353035': 'CREDIT',
  '353036': 'CREDIT',
  '353037': 'CREDIT',
  '353038': 'CREDIT',
  '353039': 'CREDIT',
  '353040': 'CREDIT',
  '353041': 'CREDIT',
  '353042': 'CREDIT',
  '353043': 'CREDIT',
  '353044': 'CREDIT',
  '353045': 'CREDIT',
  '353046': 'CREDIT',
  '353047': 'CREDIT',
  '353048': 'CREDIT',
  '353049': 'CREDIT',
  '353050': 'CREDIT',
  '353051': 'CREDIT',
  '353052': 'CREDIT',
  '353053': 'CREDIT',
  '353054': 'CREDIT',
  '353055': 'CREDIT',
  '353056': 'CREDIT',
  '353057': 'CREDIT',
  '353058': 'CREDIT',
  '353059': 'CREDIT',
  '353060': 'CREDIT',
  '353061': 'CREDIT',
  '353062': 'CREDIT',
  '353063': 'CREDIT',
  '353064': 'CREDIT',
  '353065': 'CREDIT',
  '353066': 'CREDIT',
  '353067': 'CREDIT',
  '353068': 'CREDIT',
  '353069': 'CREDIT',
  '353070': 'CREDIT',
  '353071': 'CREDIT',
  '353072': 'CREDIT',
  '353073': 'CREDIT',
  '353074': 'CREDIT',
  '353075': 'CREDIT',
  '353076': 'CREDIT',
  '353077': 'CREDIT',
  '353078': 'CREDIT',
  '353079': 'CREDIT',
  '353080': 'CREDIT',
  '353081': 'CREDIT',
  '353082': 'CREDIT',
  '353083': 'CREDIT',
  '353084': 'CREDIT',
  '353085': 'CREDIT',
  '353086': 'CREDIT',
  '353087': 'CREDIT',
  '353088': 'CREDIT',
  '353089': 'CREDIT',
  '353090': 'CREDIT',
  '353091': 'CREDIT',
  '353092': 'CREDIT',
  '353093': 'CREDIT',
  '353094': 'CREDIT',
  '353095': 'CREDIT',
  '353096': 'CREDIT',
  '353097': 'CREDIT',
  '353098': 'CREDIT',
  '353099': 'CREDIT',
  '353100': 'CREDIT',
  '353101': 'CREDIT',
  '353102': 'CREDIT',
  '353103': 'CREDIT',
  '353104': 'CREDIT',
  '353105': 'CREDIT',
  '353106': 'CREDIT',
  '353107': 'CREDIT',
  '353108': 'CREDIT',
  '353109': 'CREDIT',
  '353110': 'CREDIT',
  '353111': 'CREDIT',
  '353112': 'CREDIT',
  '353113': 'CREDIT',
  '353114': 'CREDIT',
  '353115': 'CREDIT',
  '353116': 'CREDIT',
  '353117': 'CREDIT',
  '353118': 'CREDIT',
  '353119': 'CREDIT',
  '353120': 'CREDIT',
  '353121': 'CREDIT',
  '353122': 'CREDIT',
  '353123': 'CREDIT',
  '353124': 'CREDIT',
  '353125': 'CREDIT',
  '353126': 'CREDIT',
  '353127': 'CREDIT',
  '353128': 'CREDIT',
  '353129': 'CREDIT',
  '353130': 'CREDIT',
  '353131': 'CREDIT',
  '353132': 'CREDIT',
  '353133': 'CREDIT',
  '353134': 'CREDIT',
  '353135': 'CREDIT',
  '353136': 'CREDIT',
  '353137': 'CREDIT',
  '353138': 'CREDIT',
  '353139': 'CREDIT',
  '353140': 'CREDIT',
  '353141': 'CREDIT',
  '353142': 'CREDIT',
  '353143': 'CREDIT',
  '353144': 'CREDIT',
  '353145': 'CREDIT',
  '353146': 'CREDIT',
  '353147': 'CREDIT',
  '353148': 'CREDIT',
  '353149': 'CREDIT',
  '353150': 'CREDIT',
  '353151': 'CREDIT',
  '353152': 'CREDIT',
  '353153': 'CREDIT',
  '353154': 'CREDIT',
  '353155': 'CREDIT',
  '353156': 'CREDIT',
  '353157': 'CREDIT',
  '353158': 'CREDIT',
  '353159': 'CREDIT',
  '353160': 'CREDIT',
  '353161': 'CREDIT',
  '353162': 'CREDIT',
  '353163': 'CREDIT',
  '353164': 'CREDIT',
  '353165': 'CREDIT',
  '353166': 'CREDIT',
  '353167': 'CREDIT',
  '353168': 'CREDIT',
  '353169': 'CREDIT',
  '353170': 'CREDIT',
  '353171': 'CREDIT',
  '353172': 'CREDIT',
  '353173': 'CREDIT',
  '353174': 'CREDIT',
  '353175': 'CREDIT',
  '353176': 'CREDIT',
  '353177': 'CREDIT',
  '353178': 'CREDIT',
  '353179': 'CREDIT',
  '353180': 'CREDIT',
  '353181': 'CREDIT',
  '353182': 'CREDIT',
  '353183': 'CREDIT',
  '353184': 'CREDIT',
  '353185': 'CREDIT',
  '353186': 'CREDIT',
  '353187': 'CREDIT',
  '353188': 'CREDIT',
  '353189': 'CREDIT',
  '353190': 'CREDIT',
  '353191': 'CREDIT',
  '353192': 'CREDIT',
  '353193': 'CREDIT',
  '353194': 'CREDIT',
  '353195': 'CREDIT',
  '353196': 'CREDIT',
  '353197': 'CREDIT',
  '353198': 'CREDIT',
  '353199': 'CREDIT',
  '353200': 'CREDIT',
  '353201': 'CREDIT',
  '353202': 'CREDIT',
  '353203': 'CREDIT',
  '353204': 'CREDIT',
  '353205': 'CREDIT',
  '353206': 'CREDIT',
  '353207': 'CREDIT',
  '353208': 'CREDIT',
  '353209': 'CREDIT',
  '353210': 'CREDIT',
  '353211': 'CREDIT',
  '353212': 'CREDIT',
  '353213': 'CREDIT',
  '353214': 'CREDIT',
  '353215': 'CREDIT',
  '353216': 'CREDIT',
  '353217': 'CREDIT',
  '353218': 'CREDIT',
  '353219': 'CREDIT',
  '353220': 'CREDIT',
  '353221': 'CREDIT',
  '353222': 'CREDIT',
  '353223': 'CREDIT',
  '353224': 'CREDIT',
  '353225': 'CREDIT',
  '353226': 'CREDIT',
  '353227': 'CREDIT',
  '353228': 'CREDIT',
  '353229': 'CREDIT',
  '353230': 'CREDIT',
  '353231': 'CREDIT',
  '353232': 'CREDIT',
  '353233': 'CREDIT',
  '353234': 'CREDIT',
  '353235': 'CREDIT',
  '353236': 'CREDIT',
  '353237': 'CREDIT',
  '353238': 'CREDIT',
  '353239': 'CREDIT',
  '353240': 'CREDIT',
  '353241': 'CREDIT',
  '353242': 'CREDIT',
  '353243': 'CREDIT',
  '353244': 'CREDIT',
  '353245': 'CREDIT',
  '353246': 'CREDIT',
  '353247': 'CREDIT',
  '353248': 'CREDIT',
  '353249': 'CREDIT',
  '353250': 'CREDIT',
  '353251': 'CREDIT',
  '353252': 'CREDIT',
  '353253': 'CREDIT',
  '353254': 'CREDIT',
  '353255': 'CREDIT',
  '353256': 'CREDIT',
  '353257': 'CREDIT',
  '353258': 'CREDIT',
  '353259': 'CREDIT',
  '353260': 'CREDIT',
  '353261': 'CREDIT',
  '353262': 'CREDIT',
  '353263': 'CREDIT',
  '353264': 'CREDIT',
  '353265': 'CREDIT',
  '353266': 'CREDIT',
  '353267': 'CREDIT',
  '353268': 'CREDIT',
  '353269': 'CREDIT',
  '353270': 'CREDIT',
  '353271': 'CREDIT',
  '353272': 'CREDIT',
  '353273': 'CREDIT',
  '353274': 'CREDIT',
  '353275': 'CREDIT',
  '353276': 'CREDIT',
  '353277': 'CREDIT',
  '353278': 'CREDIT',
  '353279': 'CREDIT',
  '353280': 'CREDIT',
  '353281': 'CREDIT',
  '353282': 'CREDIT',
  '353283': 'CREDIT',
  '353284': 'CREDIT',
  '353285': 'CREDIT',
  '353286': 'CREDIT',
  '353287': 'CREDIT',
  '353288': 'CREDIT',
  '353289': 'CREDIT',
  '353290': 'CREDIT',
  '353291': 'CREDIT',
  '353292': 'CREDIT',
  '353293': 'CREDIT',
  '353294': 'CREDIT',
  '353295': 'CREDIT',
  '353296': 'CREDIT',
  '353297': 'CREDIT',
  '353298': 'CREDIT',
  '353299': 'CREDIT',
  '353303': 'DEBIT',
  '353305': 'DEBIT',
  '353306': 'DEBIT',
  '353307': 'DEBIT',
  '353310': 'DEBIT',
  '353311': 'DEBIT',
  '353312': 'DEBIT',
  '353313': 'DEBIT',
  '353314': 'DEBIT',
  '353315': 'DEBIT',
  '353316': 'DEBIT',
  '353317': 'DEBIT',
  '353318': 'DEBIT',
  '353319': 'DEBIT',
  '353320': 'DEBIT',
  '353321': 'DEBIT',
  '353322': 'DEBIT',
  '353323': 'DEBIT',
  '353324': 'DEBIT',
  '353325': 'DEBIT',
  '353326': 'DEBIT',
  '353327': 'DEBIT',
  '353328': 'DEBIT',
  '353329': 'DEBIT',
  '353330': 'DEBIT',
  '353331': 'DEBIT',
  '353332': 'DEBIT',
  '353333': 'DEBIT',
  '353334': 'DEBIT',
  '353335': 'DEBIT',
  '353336': 'DEBIT',
  '353337': 'DEBIT',
  '353338': 'DEBIT',
  '353339': 'DEBIT',
  '353340': 'DEBIT',
  '353341': 'DEBIT',
  '353342': 'DEBIT',
  '353343': 'DEBIT',
  '353344': 'DEBIT',
  '353345': 'DEBIT',
  '353346': 'DEBIT',
  '353347': 'DEBIT',
  '353348': 'DEBIT',
  '353349': 'DEBIT',
  '353350': 'DEBIT',
  '353351': 'DEBIT',
  '353352': 'DEBIT',
  '353353': 'DEBIT',
  '353354': 'DEBIT',
  '353355': 'DEBIT',
  '353356': 'DEBIT',
  '353357': 'DEBIT',
  '353358': 'DEBIT',
  '353359': 'DEBIT',
  '353360': 'DEBIT',
  '353361': 'DEBIT',
  '353362': 'DEBIT',
  '353363': 'DEBIT',
  '353364': 'DEBIT',
  '353365': 'DEBIT',
  '353366': 'DEBIT',
  '353367': 'DEBIT',
  '353368': 'DEBIT',
  '353369': 'DEBIT',
  '353370': 'DEBIT',
  '353371': 'DEBIT',
  '353372': 'DEBIT',
  '353373': 'DEBIT',
  '353374': 'DEBIT',
  '353375': 'DEBIT',
  '353376': 'DEBIT',
  '353377': 'DEBIT',
  '353378': 'DEBIT',
  '353379': 'DEBIT',
  '353380': 'DEBIT',
  '353381': 'DEBIT',
  '353382': 'DEBIT',
  '353383': 'DEBIT',
  '353384': 'DEBIT',
  '353385': 'DEBIT',
  '353386': 'DEBIT',
  '353387': 'DEBIT',
  '353388': 'DEBIT',
  '353389': 'DEBIT',
  '353390': 'DEBIT',
  '353391': 'DEBIT',
  '353392': 'DEBIT',
  '353393': 'DEBIT',
  '353394': 'DEBIT',
  '353395': 'DEBIT',
  '353396': 'DEBIT',
  '353397': 'DEBIT',
  '353398': 'DEBIT',
  '353399': 'DEBIT',
  '353400': 'DEBIT',
  '353401': 'DEBIT',
  '353402': 'DEBIT',
  '353403': 'DEBIT',
  '353404': 'DEBIT',
  '353405': 'DEBIT',
  '353406': 'DEBIT',
  '353407': 'DEBIT',
  '353408': 'DEBIT',
  '353409': 'DEBIT',
  '353410': 'DEBIT',
  '353411': 'DEBIT',
  '353412': 'DEBIT',
  '353413': 'DEBIT',
  '353414': 'DEBIT',
  '353415': 'DEBIT',
  '353416': 'DEBIT',
  '353417': 'DEBIT',
  '353418': 'DEBIT',
  '353419': 'DEBIT',
  '353420': 'DEBIT',
  '353421': 'DEBIT',
  '353422': 'DEBIT',
  '353423': 'DEBIT',
  '353424': 'DEBIT',
  '353425': 'DEBIT',
  '353426': 'DEBIT',
  '353427': 'DEBIT',
  '353428': 'DEBIT',
  '353429': 'DEBIT',
  '353430': 'DEBIT',
  '353431': 'DEBIT',
  '353432': 'DEBIT',
  '353433': 'DEBIT',
  '353434': 'DEBIT',
  '353435': 'DEBIT',
  '353436': 'DEBIT',
  '353437': 'DEBIT',
  '353438': 'DEBIT',
  '353439': 'DEBIT',
  '353440': 'DEBIT',
  '353441': 'DEBIT',
  '353442': 'DEBIT',
  '353443': 'DEBIT',
  '353444': 'DEBIT',
  '353445': 'DEBIT',
  '353446': 'DEBIT',
  '353447': 'DEBIT',
  '353448': 'DEBIT',
  '353449': 'DEBIT',
  '353450': 'DEBIT',
  '353451': 'DEBIT',
  '353452': 'DEBIT',
  '353453': 'DEBIT',
  '353454': 'DEBIT',
  '353455': 'DEBIT',
  '353456': 'DEBIT',
  '353457': 'DEBIT',
  '353458': 'DEBIT',
  '353459': 'DEBIT',
  '353460': 'DEBIT',
  '353461': 'DEBIT',
  '353462': 'DEBIT',
  '353463': 'DEBIT',
  '353464': 'DEBIT',
  '353465': 'DEBIT',
  '353466': 'DEBIT',
  '353467': 'DEBIT',
  '353468': 'DEBIT',
  '353469': 'DEBIT',
  '353470': 'DEBIT',
  '353471': 'DEBIT',
  '353472': 'DEBIT',
  '353473': 'DEBIT',
  '353474': 'DEBIT',
  '353475': 'DEBIT',
  '353476': 'DEBIT',
  '353477': 'DEBIT',
  '353478': 'DEBIT',
  '353479': 'DEBIT',
  '353480': 'DEBIT',
  '353481': 'DEBIT',
  '353482': 'DEBIT',
  '353483': 'DEBIT',
  '353484': 'DEBIT',
  '353485': 'DEBIT',
  '353486': 'DEBIT',
  '353487': 'DEBIT',
  '353488': 'DEBIT',
  '353489': 'DEBIT',
  '353490': 'DEBIT',
  '353491': 'DEBIT',
  '353492': 'DEBIT',
  '353493': 'DEBIT',
  '353494': 'DEBIT',
  '353495': 'DEBIT',
  '353496': 'DEBIT',
  '353497': 'DEBIT',
  '353498': 'DEBIT',
  '353499': 'DEBIT',
  '353500': 'DEBIT',
  '353501': 'DEBIT',
  '353502': 'DEBIT',
  '353503': 'DEBIT',
  '353504': 'DEBIT',
  '353505': 'DEBIT',
  '353506': 'DEBIT',
  '353507': 'DEBIT',
  '353508': 'DEBIT',
  '353509': 'DEBIT',
  '353510': 'DEBIT',
  '353511': 'DEBIT',
  '353512': 'DEBIT',
  '353513': 'DEBIT',
  '353514': 'DEBIT',
  '353515': 'DEBIT',
  '353516': 'DEBIT',
  '353517': 'DEBIT',
  '353518': 'DEBIT',
  '353519': 'DEBIT',
  '353520': 'DEBIT',
  '353521': 'DEBIT',
  '353522': 'DEBIT',
  '353523': 'DEBIT',
  '353524': 'DEBIT',
  '353525': 'DEBIT',
  '353526': 'DEBIT',
  '353527': 'DEBIT',
  '353528': 'DEBIT',
  '353529': 'DEBIT',
  '353530': 'DEBIT',
  '353531': 'DEBIT',
  '353532': 'DEBIT',
  '353533': 'DEBIT',
  '353534': 'DEBIT',
  '353535': 'DEBIT',
  '353536': 'DEBIT',
  '353537': 'DEBIT',
  '353538': 'DEBIT',
  '353539': 'DEBIT',
  '353540': 'DEBIT',
  '353541': 'DEBIT',
  '353542': 'DEBIT',
  '353543': 'DEBIT',
  '353544': 'DEBIT',
  '353545': 'DEBIT',
  '353546': 'DEBIT',
  '353547': 'DEBIT',
  '353548': 'DEBIT',
  '353549': 'DEBIT',
  '353550': 'DEBIT',
  '353551': 'DEBIT',
  '353552': 'DEBIT',
  '353553': 'DEBIT',
  '353554': 'DEBIT',
  '353555': 'DEBIT',
  '353556': 'DEBIT',
  '353557': 'DEBIT',
  '353558': 'DEBIT',
  '353559': 'DEBIT',
  '353560': 'DEBIT',
  '353561': 'DEBIT',
  '353562': 'DEBIT',
  '353563': 'DEBIT',
  '353564': 'DEBIT',
  '353565': 'DEBIT',
  '353566': 'DEBIT',
  '353567': 'DEBIT',
  '353568': 'DEBIT',
  '353569': 'DEBIT',
  '353570': 'DEBIT',
  '353571': 'DEBIT',
  '353572': 'DEBIT',
  '353573': 'DEBIT',
  '353574': 'DEBIT',
  '353575': 'DEBIT',
  '353576': 'DEBIT',
  '353577': 'DEBIT',
  '353578': 'DEBIT',
  '353579': 'DEBIT',
  '353580': 'DEBIT',
  '353581': 'DEBIT',
  '353582': 'DEBIT',
  '353583': 'DEBIT',
  '353584': 'DEBIT',
  '353585': 'DEBIT',
  '353586': 'DEBIT',
  '353587': 'DEBIT',
  '353588': 'DEBIT',
  '353589': 'DEBIT',
  '353590': 'DEBIT',
  '353591': 'DEBIT',
  '353592': 'DEBIT',
  '353593': 'DEBIT',
  '353594': 'DEBIT',
  '353595': 'DEBIT',
  '353596': 'DEBIT',
  '353597': 'DEBIT',
  '353598': 'DEBIT',
  '353599': 'DEBIT',
  '354000': 'CREDIT',
  '354001': 'CREDIT',
  '354002': 'CREDIT',
  '354003': 'CREDIT',
  '354004': 'CREDIT',
  '354005': 'CREDIT',
  '354006': 'CREDIT',
  '354007': 'CREDIT',
  '354008': 'CREDIT',
  '354009': 'CREDIT',
  '354010': 'CREDIT',
  '354011': 'CREDIT',
  '354012': 'CREDIT',
  '354013': 'CREDIT',
  '354014': 'CREDIT',
  '354015': 'CREDIT',
  '354016': 'CREDIT',
  '354017': 'CREDIT',
  '354018': 'CREDIT',
  '354019': 'CREDIT',
  '354020': 'CREDIT',
  '354021': 'CREDIT',
  '354022': 'CREDIT',
  '354023': 'CREDIT',
  '354024': 'CREDIT',
  '354025': 'CREDIT',
  '354026': 'CREDIT',
  '354027': 'CREDIT',
  '354028': 'CREDIT',
  '354029': 'CREDIT',
  '354030': 'CREDIT',
  '354031': 'CREDIT',
  '354032': 'CREDIT',
  '354033': 'CREDIT',
  '354034': 'CREDIT',
  '354035': 'CREDIT',
  '354036': 'CREDIT',
  '354037': 'CREDIT',
  '354038': 'CREDIT',
  '354039': 'CREDIT',
  '354040': 'CREDIT',
  '354041': 'CREDIT',
  '354042': 'CREDIT',
  '354043': 'CREDIT',
  '354044': 'CREDIT',
  '354045': 'CREDIT',
  '354046': 'CREDIT',
  '354047': 'CREDIT',
  '354048': 'CREDIT',
  '354049': 'CREDIT',
  '354050': 'CREDIT',
  '354051': 'CREDIT',
  '354052': 'CREDIT',
  '354053': 'CREDIT',
  '354054': 'CREDIT',
  '354055': 'CREDIT',
  '354056': 'CREDIT',
  '354057': 'CREDIT',
  '354058': 'CREDIT',
  '354059': 'CREDIT',
  '354060': 'CREDIT',
  '354061': 'CREDIT',
  '354062': 'CREDIT',
  '354063': 'CREDIT',
  '354064': 'CREDIT',
  '354065': 'CREDIT',
  '354066': 'CREDIT',
  '354067': 'CREDIT',
  '354068': 'CREDIT',
  '354069': 'CREDIT',
  '354070': 'CREDIT',
  '354071': 'CREDIT',
  '354072': 'CREDIT',
  '354073': 'CREDIT',
  '354074': 'CREDIT',
  '354075': 'CREDIT',
  '354076': 'CREDIT',
  '354077': 'CREDIT',
  '354078': 'CREDIT',
  '354079': 'CREDIT',
  '354080': 'CREDIT',
  '354081': 'CREDIT',
  '354082': 'CREDIT',
  '354083': 'CREDIT',
  '354084': 'CREDIT',
  '354085': 'CREDIT',
  '354086': 'CREDIT',
  '354087': 'CREDIT',
  '354088': 'CREDIT',
  '354089': 'CREDIT',
  '354090': 'CREDIT',
  '354091': 'CREDIT',
  '354092': 'CREDIT',
  '354093': 'CREDIT',
  '354094': 'CREDIT',
  '354095': 'CREDIT',
  '354096': 'CREDIT',
  '354097': 'CREDIT',
  '354098': 'CREDIT',
  '354099': 'CREDIT',
  '354100': 'CREDIT',
  '354101': 'CREDIT',
  '354102': 'CREDIT',
  '354103': 'CREDIT',
  '354104': 'CREDIT',
  '354105': 'CREDIT',
  '354106': 'CREDIT',
  '354107': 'CREDIT',
  '354108': 'CREDIT',
  '354109': 'CREDIT',
  '354110': 'CREDIT',
  '354111': 'CREDIT',
  '354112': 'CREDIT',
  '354113': 'CREDIT',
  '354114': 'CREDIT',
  '354115': 'CREDIT',
  '354116': 'CREDIT',
  '354117': 'CREDIT',
  '354118': 'CREDIT',
  '354119': 'CREDIT',
  '354120': 'CREDIT',
  '354121': 'CREDIT',
  '354122': 'CREDIT',
  '354123': 'CREDIT',
  '354124': 'CREDIT',
  '354125': 'CREDIT',
  '354126': 'CREDIT',
  '354127': 'CREDIT',
  '354128': 'CREDIT',
  '354129': 'CREDIT',
  '354130': 'CREDIT',
  '354131': 'CREDIT',
  '354132': 'CREDIT',
  '354133': 'CREDIT',
  '354134': 'CREDIT',
  '354135': 'CREDIT',
  '354136': 'CREDIT',
  '354137': 'CREDIT',
  '354138': 'CREDIT',
  '354139': 'CREDIT',
  '354140': 'CREDIT',
  '354141': 'CREDIT',
  '354142': 'CREDIT',
  '354143': 'CREDIT',
  '354144': 'CREDIT',
  '354145': 'CREDIT',
  '354146': 'CREDIT',
  '354147': 'CREDIT',
  '354148': 'CREDIT',
  '354149': 'CREDIT',
  '354150': 'CREDIT',
  '354151': 'CREDIT',
  '354152': 'CREDIT',
  '354153': 'CREDIT',
  '354154': 'CREDIT',
  '354155': 'CREDIT',
  '354156': 'CREDIT',
  '354157': 'CREDIT',
  '354158': 'CREDIT',
  '354159': 'CREDIT',
  '354160': 'CREDIT',
  '354161': 'CREDIT',
  '354162': 'CREDIT',
  '354163': 'CREDIT',
  '354164': 'CREDIT',
  '354165': 'CREDIT',
  '354166': 'CREDIT',
  '354167': 'CREDIT',
  '354168': 'CREDIT',
  '354169': 'CREDIT',
  '354170': 'CREDIT',
  '354171': 'CREDIT',
  '354172': 'CREDIT',
  '354173': 'CREDIT',
  '354174': 'CREDIT',
  '354175': 'CREDIT',
  '354176': 'CREDIT',
  '354177': 'CREDIT',
  '354178': 'CREDIT',
  '354179': 'CREDIT',
  '354180': 'CREDIT',
  '354181': 'CREDIT',
  '354182': 'CREDIT',
  '354183': 'CREDIT',
  '354184': 'CREDIT',
  '354185': 'CREDIT',
  '354186': 'CREDIT',
  '354187': 'CREDIT',
  '354188': 'CREDIT',
  '354189': 'CREDIT',
  '354190': 'CREDIT',
  '354191': 'CREDIT',
  '354192': 'CREDIT',
  '354193': 'CREDIT',
  '354194': 'CREDIT',
  '354195': 'CREDIT',
  '354196': 'CREDIT',
  '354197': 'CREDIT',
  '354198': 'CREDIT',
  '354199': 'CREDIT',
  '354200': 'CREDIT',
  '354201': 'CREDIT',
  '354202': 'CREDIT',
  '354203': 'CREDIT',
  '354204': 'CREDIT',
  '354205': 'CREDIT',
  '354206': 'CREDIT',
  '354207': 'CREDIT',
  '354208': 'CREDIT',
  '354209': 'CREDIT',
  '354210': 'CREDIT',
  '354211': 'CREDIT',
  '354212': 'CREDIT',
  '354213': 'CREDIT',
  '354214': 'CREDIT',
  '354215': 'CREDIT',
  '354216': 'CREDIT',
  '354217': 'CREDIT',
  '354218': 'CREDIT',
  '354219': 'CREDIT',
  '354220': 'CREDIT',
  '354221': 'CREDIT',
  '354222': 'CREDIT',
  '354223': 'CREDIT',
  '354224': 'CREDIT',
  '354225': 'CREDIT',
  '354226': 'CREDIT',
  '354227': 'CREDIT',
  '354228': 'CREDIT',
  '354229': 'CREDIT',
  '354230': 'CREDIT',
  '354231': 'CREDIT',
  '354232': 'CREDIT',
  '354233': 'CREDIT',
  '354234': 'CREDIT',
  '354235': 'CREDIT',
  '354236': 'CREDIT',
  '354237': 'CREDIT',
  '354238': 'CREDIT',
  '354239': 'CREDIT',
  '354240': 'CREDIT',
  '354241': 'CREDIT',
  '354242': 'CREDIT',
  '354243': 'CREDIT',
  '354244': 'CREDIT',
  '354245': 'CREDIT',
  '354246': 'CREDIT',
  '354247': 'CREDIT',
  '354248': 'CREDIT',
  '354249': 'CREDIT',
  '354250': 'CREDIT',
  '354251': 'CREDIT',
  '354252': 'CREDIT',
  '354253': 'CREDIT',
  '354254': 'CREDIT',
  '354255': 'CREDIT',
  '354256': 'CREDIT',
  '354257': 'CREDIT',
  '354258': 'CREDIT',
  '354259': 'CREDIT',
  '354260': 'CREDIT',
  '354261': 'CREDIT',
  '354262': 'CREDIT',
  '354263': 'CREDIT',
  '354264': 'CREDIT',
  '354265': 'CREDIT',
  '354266': 'CREDIT',
  '354267': 'CREDIT',
  '354268': 'CREDIT',
  '354269': 'CREDIT',
  '354270': 'CREDIT',
  '354271': 'CREDIT',
  '354272': 'CREDIT',
  '354273': 'CREDIT',
  '354274': 'CREDIT',
  '354275': 'CREDIT',
  '354276': 'CREDIT',
  '354277': 'CREDIT',
  '354278': 'CREDIT',
  '354279': 'CREDIT',
  '354280': 'CREDIT',
  '354281': 'CREDIT',
  '354282': 'CREDIT',
  '354283': 'CREDIT',
  '354284': 'CREDIT',
  '354285': 'CREDIT',
  '354286': 'CREDIT',
  '354287': 'CREDIT',
  '354288': 'CREDIT',
  '354289': 'CREDIT',
  '354290': 'CREDIT',
  '354291': 'CREDIT',
  '354292': 'CREDIT',
  '354293': 'CREDIT',
  '354294': 'CREDIT',
  '354295': 'CREDIT',
  '354296': 'CREDIT',
  '354297': 'CREDIT',
  '354298': 'CREDIT',
  '354299': 'CREDIT',
  '354300': 'CREDIT',
  '354301': 'CREDIT',
  '354302': 'CREDIT',
  '354303': 'CREDIT',
  '354304': 'CREDIT',
  '354305': 'CREDIT',
  '354306': 'CREDIT',
  '354307': 'CREDIT',
  '354308': 'CREDIT',
  '354309': 'CREDIT',
  '354310': 'CREDIT',
  '354311': 'CREDIT',
  '354312': 'CREDIT',
  '354313': 'CREDIT',
  '354314': 'CREDIT',
  '354315': 'CREDIT',
  '354316': 'CREDIT',
  '354317': 'CREDIT',
  '354318': 'CREDIT',
  '354319': 'CREDIT',
  '354320': 'CREDIT',
  '354321': 'CREDIT',
  '354322': 'CREDIT',
  '354323': 'CREDIT',
  '354324': 'CREDIT',
  '354325': 'CREDIT',
  '354326': 'CREDIT',
  '354327': 'CREDIT',
  '354328': 'CREDIT',
  '354329': 'CREDIT',
  '354330': 'CREDIT',
  '354331': 'CREDIT',
  '354332': 'CREDIT',
  '354333': 'CREDIT',
  '354334': 'CREDIT',
  '354335': 'CREDIT',
  '354336': 'CREDIT',
  '354337': 'CREDIT',
  '354338': 'CREDIT',
  '354339': 'CREDIT',
  '354340': 'CREDIT',
  '354341': 'CREDIT',
  '354342': 'CREDIT',
  '354343': 'CREDIT',
  '354344': 'CREDIT',
  '354345': 'CREDIT',
  '354346': 'CREDIT',
  '354347': 'CREDIT',
  '354348': 'CREDIT',
  '354349': 'CREDIT',
  '354350': 'CREDIT',
  '354351': 'CREDIT',
  '354352': 'CREDIT',
  '354353': 'CREDIT',
  '354354': 'CREDIT',
  '354355': 'CREDIT',
  '354356': 'CREDIT',
  '354357': 'CREDIT',
  '354358': 'CREDIT',
  '354359': 'CREDIT',
  '354360': 'CREDIT',
  '354361': 'CREDIT',
  '354362': 'CREDIT',
  '354363': 'CREDIT',
  '354364': 'CREDIT',
  '354365': 'CREDIT',
  '354366': 'CREDIT',
  '354367': 'CREDIT',
  '354368': 'CREDIT',
  '354369': 'CREDIT',
  '354370': 'CREDIT',
  '354371': 'CREDIT',
  '354372': 'CREDIT',
  '354373': 'CREDIT',
  '354374': 'CREDIT',
  '354375': 'CREDIT',
  '354376': 'CREDIT',
  '354377': 'CREDIT',
  '354378': 'CREDIT',
  '354379': 'CREDIT',
  '354380': 'CREDIT',
  '354381': 'CREDIT',
  '354382': 'CREDIT',
  '354383': 'CREDIT',
  '354384': 'CREDIT',
  '354385': 'CREDIT',
  '354386': 'CREDIT',
  '354387': 'CREDIT',
  '354388': 'CREDIT',
  '354389': 'CREDIT',
  '354390': 'CREDIT',
  '354391': 'CREDIT',
  '354392': 'CREDIT',
  '354393': 'CREDIT',
  '354394': 'CREDIT',
  '354395': 'CREDIT',
  '354396': 'CREDIT',
  '354397': 'CREDIT',
  '354398': 'CREDIT',
  '354399': 'CREDIT',
  '354400': 'CREDIT',
  '354401': 'CREDIT',
  '354402': 'CREDIT',
  '354403': 'CREDIT',
  '354404': 'CREDIT',
  '354405': 'CREDIT',
  '354406': 'CREDIT',
  '354407': 'CREDIT',
  '354408': 'CREDIT',
  '354409': 'CREDIT',
  '354410': 'CREDIT',
  '354411': 'CREDIT',
  '354412': 'CREDIT',
  '354413': 'CREDIT',
  '354414': 'CREDIT',
  '354415': 'CREDIT',
  '354416': 'CREDIT',
  '354417': 'CREDIT',
  '354418': 'CREDIT',
  '354419': 'CREDIT',
  '354420': 'CREDIT',
  '354421': 'CREDIT',
  '354422': 'CREDIT',
  '354423': 'CREDIT',
  '354424': 'CREDIT',
  '354425': 'CREDIT',
  '354426': 'CREDIT',
  '354427': 'CREDIT',
  '354428': 'CREDIT',
  '354429': 'CREDIT',
  '354430': 'CREDIT',
  '354431': 'CREDIT',
  '354432': 'CREDIT',
  '354433': 'CREDIT',
  '354434': 'CREDIT',
  '354435': 'CREDIT',
  '354436': 'CREDIT',
  '354437': 'CREDIT',
  '354438': 'CREDIT',
  '354439': 'CREDIT',
  '354440': 'CREDIT',
  '354441': 'CREDIT',
  '354442': 'CREDIT',
  '354443': 'CREDIT',
  '354444': 'CREDIT',
  '354445': 'CREDIT',
  '354446': 'CREDIT',
  '354447': 'CREDIT',
  '354448': 'CREDIT',
  '354449': 'CREDIT',
  '354450': 'CREDIT',
  '354451': 'CREDIT',
  '354452': 'CREDIT',
  '354453': 'CREDIT',
  '354454': 'CREDIT',
  '354455': 'CREDIT',
  '354456': 'CREDIT',
  '354457': 'CREDIT',
  '354458': 'CREDIT',
  '354459': 'CREDIT',
  '354460': 'CREDIT',
  '354461': 'CREDIT',
  '354462': 'CREDIT',
  '354463': 'CREDIT',
  '354464': 'CREDIT',
  '354465': 'CREDIT',
  '354466': 'CREDIT',
  '354467': 'CREDIT',
  '354468': 'CREDIT',
  '354469': 'CREDIT',
  '354470': 'CREDIT',
  '354471': 'CREDIT',
  '354472': 'CREDIT',
  '354473': 'CREDIT',
  '354474': 'CREDIT',
  '354475': 'CREDIT',
  '354476': 'CREDIT',
  '354477': 'CREDIT',
  '354478': 'CREDIT',
  '354479': 'CREDIT',
  '354480': 'CREDIT',
  '354481': 'CREDIT',
  '354482': 'CREDIT',
  '354483': 'CREDIT',
  '354484': 'CREDIT',
  '354485': 'CREDIT',
  '354486': 'CREDIT',
  '354487': 'CREDIT',
  '354488': 'CREDIT',
  '354489': 'CREDIT',
  '354490': 'CREDIT',
  '354491': 'CREDIT',
  '354492': 'CREDIT',
  '354493': 'CREDIT',
  '354494': 'CREDIT',
  '354495': 'CREDIT',
  '354496': 'CREDIT',
  '354497': 'CREDIT',
  '354498': 'CREDIT',
  '354499': 'CREDIT',
  '354500': 'CREDIT',
  '354501': 'CREDIT',
  '354502': 'CREDIT',
  '354503': 'CREDIT',
  '354504': 'CREDIT',
  '354505': 'CREDIT',
  '354506': 'CREDIT',
  '354507': 'CREDIT',
  '354508': 'CREDIT',
  '354509': 'CREDIT',
  '354510': 'CREDIT',
  '354511': 'CREDIT',
  '354512': 'CREDIT',
  '354513': 'CREDIT',
  '354514': 'CREDIT',
  '354515': 'CREDIT',
  '354516': 'CREDIT',
  '354517': 'CREDIT',
  '354518': 'CREDIT',
  '354519': 'CREDIT',
  '354520': 'CREDIT',
  '354521': 'CREDIT',
  '354522': 'CREDIT',
  '354523': 'CREDIT',
  '354524': 'CREDIT',
  '354525': 'CREDIT',
  '354526': 'CREDIT',
  '354527': 'CREDIT',
  '354528': 'CREDIT',
  '354529': 'CREDIT',
  '354530': 'CREDIT',
  '354531': 'CREDIT',
  '354532': 'CREDIT',
  '354533': 'CREDIT',
  '354534': 'CREDIT',
  '354535': 'CREDIT',
  '354536': 'CREDIT',
  '354537': 'CREDIT',
  '354538': 'CREDIT',
  '354539': 'CREDIT',
  '354540': 'CREDIT',
  '354541': 'CREDIT',
  '354542': 'CREDIT',
  '354543': 'CREDIT',
  '354544': 'CREDIT',
  '354545': 'CREDIT',
  '354546': 'CREDIT',
  '354547': 'CREDIT',
  '354548': 'CREDIT',
  '354549': 'CREDIT',
  '354550': 'CREDIT',
  '354551': 'CREDIT',
  '354552': 'CREDIT',
  '354553': 'CREDIT',
  '354554': 'CREDIT',
  '354555': 'CREDIT',
  '354556': 'CREDIT',
  '354557': 'CREDIT',
  '354558': 'CREDIT',
  '354559': 'CREDIT',
  '354560': 'CREDIT',
  '354561': 'CREDIT',
  '354562': 'CREDIT',
  '354563': 'CREDIT',
  '354564': 'CREDIT',
  '354565': 'CREDIT',
  '354566': 'CREDIT',
  '354567': 'CREDIT',
  '354568': 'CREDIT',
  '354569': 'CREDIT',
  '354570': 'CREDIT',
  '354571': 'CREDIT',
  '354572': 'CREDIT',
  '354573': 'CREDIT',
  '354574': 'CREDIT',
  '354575': 'CREDIT',
  '354576': 'CREDIT',
  '354577': 'CREDIT',
  '354578': 'CREDIT',
  '354579': 'CREDIT',
  '354580': 'CREDIT',
  '354581': 'CREDIT',
  '354582': 'CREDIT',
  '354583': 'CREDIT',
  '354584': 'CREDIT',
  '354585': 'CREDIT',
  '354586': 'CREDIT',
  '354587': 'CREDIT',
  '354588': 'CREDIT',
  '354589': 'CREDIT',
  '354590': 'CREDIT',
  '354591': 'CREDIT',
  '354592': 'CREDIT',
  '354593': 'CREDIT',
  '354594': 'CREDIT',
  '354595': 'CREDIT',
  '354596': 'CREDIT',
  '354597': 'CREDIT',
  '354598': 'CREDIT',
  '354599': 'CREDIT',
  '354600': 'CREDIT',
  '354601': 'CREDIT',
  '354602': 'CREDIT',
  '354603': 'CREDIT',
  '354604': 'CREDIT',
  '354605': 'CREDIT',
  '354606': 'CREDIT',
  '354607': 'CREDIT',
  '354608': 'CREDIT',
  '354609': 'CREDIT',
  '354610': 'CREDIT',
  '354611': 'CREDIT',
  '354612': 'CREDIT',
  '354613': 'CREDIT',
  '354614': 'CREDIT',
  '354615': 'CREDIT',
  '354616': 'CREDIT',
  '354617': 'CREDIT',
  '354618': 'CREDIT',
  '354619': 'CREDIT',
  '354620': 'CREDIT',
  '354621': 'CREDIT',
  '354622': 'CREDIT',
  '354623': 'CREDIT',
  '354624': 'CREDIT',
  '354625': 'CREDIT',
  '354626': 'CREDIT',
  '354627': 'CREDIT',
  '354628': 'CREDIT',
  '354629': 'CREDIT',
  '354630': 'CREDIT',
  '354631': 'CREDIT',
  '354632': 'CREDIT',
  '354633': 'CREDIT',
  '354634': 'CREDIT',
  '354635': 'CREDIT',
  '354636': 'CREDIT',
  '354637': 'CREDIT',
  '354638': 'CREDIT',
  '354639': 'CREDIT',
  '354640': 'CREDIT',
  '354641': 'CREDIT',
  '354642': 'CREDIT',
  '354643': 'CREDIT',
  '354644': 'CREDIT',
  '354645': 'CREDIT',
  '354646': 'CREDIT',
  '354647': 'CREDIT',
  '354648': 'CREDIT',
  '354649': 'CREDIT',
  '354650': 'CREDIT',
  '354651': 'CREDIT',
  '354652': 'CREDIT',
  '354653': 'CREDIT',
  '354654': 'CREDIT',
  '354655': 'CREDIT',
  '354656': 'CREDIT',
  '354657': 'CREDIT',
  '354658': 'CREDIT',
  '354659': 'CREDIT',
  '354660': 'CREDIT',
  '354661': 'CREDIT',
  '354662': 'CREDIT',
  '354663': 'CREDIT',
  '354664': 'CREDIT',
  '354665': 'CREDIT',
  '354666': 'CREDIT',
  '354667': 'CREDIT',
  '354668': 'CREDIT',
  '354669': 'CREDIT',
  '354670': 'CREDIT',
  '354671': 'CREDIT',
  '354672': 'CREDIT',
  '354673': 'CREDIT',
  '354674': 'CREDIT',
  '354675': 'CREDIT',
  '354676': 'CREDIT',
  '354677': 'CREDIT',
  '354678': 'CREDIT',
  '354679': 'CREDIT',
  '354680': 'CREDIT',
  '354681': 'CREDIT',
  '354682': 'CREDIT',
  '354683': 'CREDIT',
  '354684': 'CREDIT',
  '354685': 'CREDIT',
  '354686': 'CREDIT',
  '354687': 'CREDIT',
  '354688': 'CREDIT',
  '354689': 'CREDIT',
  '354690': 'CREDIT',
  '354691': 'CREDIT',
  '354692': 'CREDIT',
  '354693': 'CREDIT',
  '354694': 'CREDIT',
  '354695': 'CREDIT',
  '354696': 'CREDIT',
  '354697': 'CREDIT',
  '354698': 'CREDIT',
  '354699': 'CREDIT',
  '354700': 'CREDIT',
  '354701': 'CREDIT',
  '354702': 'CREDIT',
  '354703': 'CREDIT',
  '354704': 'CREDIT',
  '354705': 'CREDIT',
  '354706': 'CREDIT',
  '354707': 'CREDIT',
  '354708': 'CREDIT',
  '354709': 'CREDIT',
  '354710': 'CREDIT',
  '354711': 'CREDIT',
  '354712': 'CREDIT',
  '354713': 'CREDIT',
  '354714': 'CREDIT',
  '354715': 'CREDIT',
  '354716': 'CREDIT',
  '354717': 'CREDIT',
  '354718': 'CREDIT',
  '354719': 'CREDIT',
  '354720': 'CREDIT',
  '354721': 'CREDIT',
  '354722': 'CREDIT',
  '354723': 'CREDIT',
  '354724': 'CREDIT',
  '354725': 'CREDIT',
  '354726': 'CREDIT',
  '354727': 'CREDIT',
  '354728': 'CREDIT',
  '354729': 'CREDIT',
  '354730': 'CREDIT',
  '354731': 'CREDIT',
  '354732': 'CREDIT',
  '354733': 'CREDIT',
  '354734': 'CREDIT',
  '354735': 'CREDIT',
  '354736': 'CREDIT',
  '354737': 'CREDIT',
  '354738': 'CREDIT',
  '354739': 'CREDIT',
  '354740': 'CREDIT',
  '354741': 'CREDIT',
  '354742': 'CREDIT',
  '354743': 'CREDIT',
  '354744': 'CREDIT',
  '354745': 'CREDIT',
  '354746': 'CREDIT',
  '354747': 'CREDIT',
  '354748': 'CREDIT',
  '354749': 'CREDIT',
  '354750': 'CREDIT',
  '354751': 'CREDIT',
  '354752': 'CREDIT',
  '354753': 'CREDIT',
  '354754': 'CREDIT',
  '354755': 'CREDIT',
  '354756': 'CREDIT',
  '354757': 'CREDIT',
  '354758': 'CREDIT',
  '354759': 'CREDIT',
  '354760': 'CREDIT',
  '354761': 'CREDIT',
  '354762': 'CREDIT',
  '354763': 'CREDIT',
  '354764': 'CREDIT',
  '354765': 'CREDIT',
  '354766': 'CREDIT',
  '354767': 'CREDIT',
  '354768': 'CREDIT',
  '354769': 'CREDIT',
  '354770': 'CREDIT',
  '354771': 'CREDIT',
  '354772': 'CREDIT',
  '354773': 'CREDIT',
  '354774': 'CREDIT',
  '354775': 'CREDIT',
  '354776': 'CREDIT',
  '354777': 'CREDIT',
  '354778': 'CREDIT',
  '354779': 'CREDIT',
  '354780': 'CREDIT',
  '354781': 'CREDIT',
  '354782': 'CREDIT',
  '354783': 'CREDIT',
  '354784': 'CREDIT',
  '354785': 'CREDIT',
  '354786': 'CREDIT',
  '354787': 'CREDIT',
  '354788': 'CREDIT',
  '354789': 'CREDIT',
  '354790': 'CREDIT',
  '354791': 'CREDIT',
  '354792': 'CREDIT',
  '354793': 'CREDIT',
  '354794': 'CREDIT',
  '354795': 'CREDIT',
  '354796': 'CREDIT',
  '354797': 'CREDIT',
  '354798': 'CREDIT',
  '354799': 'CREDIT',
  '354800': 'CREDIT',
  '354801': 'CREDIT',
  '354802': 'CREDIT',
  '354803': 'CREDIT',
  '354804': 'CREDIT',
  '354805': 'CREDIT',
  '354806': 'CREDIT',
  '354807': 'CREDIT',
  '354808': 'CREDIT',
  '354809': 'CREDIT',
  '354810': 'CREDIT',
  '354811': 'CREDIT',
  '354812': 'CREDIT',
  '354813': 'CREDIT',
  '354814': 'CREDIT',
  '354815': 'CREDIT',
  '354816': 'CREDIT',
  '354817': 'CREDIT',
  '354818': 'CREDIT',
  '354819': 'CREDIT',
  '354820': 'CREDIT',
  '354821': 'CREDIT',
  '354822': 'CREDIT',
  '354823': 'CREDIT',
  '354824': 'CREDIT',
  '354825': 'CREDIT',
  '354826': 'CREDIT',
  '354827': 'CREDIT',
  '354828': 'CREDIT',
  '354829': 'CREDIT',
  '354830': 'CREDIT',
  '354831': 'CREDIT',
  '354832': 'CREDIT',
  '354833': 'CREDIT',
  '354834': 'CREDIT',
  '354835': 'CREDIT',
  '354836': 'CREDIT',
  '354837': 'CREDIT',
  '354838': 'CREDIT',
  '354839': 'CREDIT',
  '354840': 'CREDIT',
  '354841': 'CREDIT',
  '354842': 'CREDIT',
  '354843': 'CREDIT',
  '354844': 'CREDIT',
  '354845': 'CREDIT',
  '354846': 'CREDIT',
  '354847': 'CREDIT',
  '354848': 'CREDIT',
  '354849': 'CREDIT',
  '354850': 'CREDIT',
  '354851': 'CREDIT',
  '354852': 'CREDIT',
  '354853': 'CREDIT',
  '354854': 'CREDIT',
  '354855': 'CREDIT',
  '354856': 'CREDIT',
  '354857': 'CREDIT',
  '354858': 'CREDIT',
  '354859': 'CREDIT',
  '354860': 'CREDIT',
  '354861': 'CREDIT',
  '354862': 'CREDIT',
  '354863': 'CREDIT',
  '354864': 'CREDIT',
  '354865': 'CREDIT',
  '354866': 'CREDIT',
  '354867': 'CREDIT',
  '354868': 'CREDIT',
  '354869': 'CREDIT',
  '354870': 'CREDIT',
  '354871': 'CREDIT',
  '354872': 'CREDIT',
  '354873': 'CREDIT',
  '354874': 'CREDIT',
  '354875': 'CREDIT',
  '354876': 'CREDIT',
  '354877': 'CREDIT',
  '354878': 'CREDIT',
  '354879': 'CREDIT',
  '354880': 'CREDIT',
  '354881': 'CREDIT',
  '354882': 'CREDIT',
  '354883': 'CREDIT',
  '354884': 'CREDIT',
  '354885': 'CREDIT',
  '354886': 'CREDIT',
  '354887': 'CREDIT',
  '354888': 'CREDIT',
  '354889': 'CREDIT',
  '354890': 'CREDIT',
  '354891': 'CREDIT',
  '354892': 'CREDIT',
  '354893': 'CREDIT',
  '354894': 'CREDIT',
  '354895': 'CREDIT',
  '354896': 'CREDIT',
  '354897': 'CREDIT',
  '354898': 'CREDIT',
  '354899': 'CREDIT',
  '354900': 'CREDIT',
  '354901': 'CREDIT',
  '354902': 'CREDIT',
  '354903': 'CREDIT',
  '354904': 'CREDIT',
  '354905': 'CREDIT',
  '354906': 'CREDIT',
  '354907': 'CREDIT',
  '354908': 'CREDIT',
  '354909': 'CREDIT',
  '354910': 'CREDIT',
  '354911': 'CREDIT',
  '354912': 'CREDIT',
  '354913': 'CREDIT',
  '354914': 'CREDIT',
  '354915': 'CREDIT',
  '354916': 'CREDIT',
  '354917': 'CREDIT',
  '354918': 'CREDIT',
  '354919': 'CREDIT',
  '354920': 'CREDIT',
  '354921': 'CREDIT',
  '354922': 'CREDIT',
  '354923': 'CREDIT',
  '354924': 'CREDIT',
  '354925': 'CREDIT',
  '354926': 'CREDIT',
  '354927': 'CREDIT',
  '354928': 'CREDIT',
  '354929': 'CREDIT',
  '354930': 'CREDIT',
  '354931': 'CREDIT',
  '354932': 'CREDIT',
  '354933': 'CREDIT',
  '354934': 'CREDIT',
  '354935': 'CREDIT',
  '354936': 'CREDIT',
  '354937': 'CREDIT',
  '354938': 'CREDIT',
  '354939': 'CREDIT',
  '354940': 'CREDIT',
  '354941': 'CREDIT',
  '354942': 'CREDIT',
  '354943': 'CREDIT',
  '354944': 'CREDIT',
  '354945': 'CREDIT',
  '354946': 'CREDIT',
  '354947': 'CREDIT',
  '354948': 'CREDIT',
  '354949': 'CREDIT',
  '354950': 'CREDIT',
  '354951': 'CREDIT',
  '354952': 'CREDIT',
  '354953': 'CREDIT',
  '354954': 'CREDIT',
  '354955': 'CREDIT',
  '354956': 'CREDIT',
  '354957': 'CREDIT',
  '354958': 'CREDIT',
  '354959': 'CREDIT',
  '354960': 'CREDIT',
  '354961': 'CREDIT',
  '354962': 'CREDIT',
  '354963': 'CREDIT',
  '354964': 'CREDIT',
  '354965': 'CREDIT',
  '354966': 'CREDIT',
  '354967': 'CREDIT',
  '354968': 'CREDIT',
  '354969': 'CREDIT',
  '354970': 'CREDIT',
  '354971': 'CREDIT',
  '354972': 'CREDIT',
  '354973': 'CREDIT',
  '354974': 'CREDIT',
  '354975': 'CREDIT',
  '354976': 'CREDIT',
  '354977': 'CREDIT',
  '354978': 'CREDIT',
  '354979': 'CREDIT',
  '354980': 'CREDIT',
  '354981': 'CREDIT',
  '354982': 'CREDIT',
  '354983': 'CREDIT',
  '354984': 'CREDIT',
  '354985': 'CREDIT',
  '354986': 'CREDIT',
  '354987': 'CREDIT',
  '354988': 'CREDIT',
  '354989': 'CREDIT',
  '354990': 'CREDIT',
  '354991': 'CREDIT',
  '354992': 'CREDIT',
  '354993': 'CREDIT',
  '354994': 'CREDIT',
  '354995': 'CREDIT',
  '354996': 'CREDIT',
  '354997': 'CREDIT',
  '354998': 'CREDIT',
  '354999': 'CREDIT',
  '355000': 'CREDIT',
  '355001': 'CREDIT',
  '355002': 'CREDIT',
  '355003': 'CREDIT',
  '355004': 'CREDIT',
  '355005': 'CREDIT',
  '355006': 'CREDIT',
  '355007': 'CREDIT',
  '355008': 'CREDIT',
  '355009': 'CREDIT',
  '355010': 'CREDIT',
  '355011': 'CREDIT',
  '355012': 'CREDIT',
  '355013': 'CREDIT',
  '355014': 'CREDIT',
  '355015': 'CREDIT',
  '355016': 'CREDIT',
  '355017': 'CREDIT',
  '355018': 'CREDIT',
  '355019': 'CREDIT',
  '355020': 'CREDIT',
  '355021': 'CREDIT',
  '355022': 'CREDIT',
  '355023': 'CREDIT',
  '355024': 'CREDIT',
  '355025': 'CREDIT',
  '355026': 'CREDIT',
  '355027': 'CREDIT',
  '355028': 'CREDIT',
  '355029': 'CREDIT',
  '355030': 'CREDIT',
  '355031': 'CREDIT',
  '355032': 'CREDIT',
  '355033': 'CREDIT',
  '355034': 'CREDIT',
  '355035': 'CREDIT',
  '355036': 'CREDIT',
  '355037': 'CREDIT',
  '355038': 'CREDIT',
  '355039': 'CREDIT',
  '355040': 'CREDIT',
  '355041': 'CREDIT',
  '355042': 'CREDIT',
  '355043': 'CREDIT',
  '355044': 'CREDIT',
  '355045': 'CREDIT',
  '355046': 'CREDIT',
  '355047': 'CREDIT',
  '355048': 'CREDIT',
  '355049': 'CREDIT',
  '355050': 'CREDIT',
  '355051': 'CREDIT',
  '355052': 'CREDIT',
  '355053': 'CREDIT',
  '355054': 'CREDIT',
  '355055': 'CREDIT',
  '355056': 'CREDIT',
  '355057': 'CREDIT',
  '355058': 'CREDIT',
  '355059': 'CREDIT',
  '355060': 'CREDIT',
  '355061': 'CREDIT',
  '355062': 'CREDIT',
  '355063': 'CREDIT',
  '355064': 'CREDIT',
  '355065': 'CREDIT',
  '355066': 'CREDIT',
  '355067': 'CREDIT',
  '355068': 'CREDIT',
  '355069': 'CREDIT',
  '355070': 'CREDIT',
  '355071': 'CREDIT',
  '355072': 'CREDIT',
  '355073': 'CREDIT',
  '355074': 'CREDIT',
  '355075': 'CREDIT',
  '355076': 'CREDIT',
  '355077': 'CREDIT',
  '355078': 'CREDIT',
  '355079': 'CREDIT',
  '355080': 'CREDIT',
  '355081': 'CREDIT',
  '355082': 'CREDIT',
  '355083': 'CREDIT',
  '355084': 'CREDIT',
  '355085': 'CREDIT',
  '355086': 'CREDIT',
  '355087': 'CREDIT',
  '355088': 'CREDIT',
  '355089': 'CREDIT',
  '355090': 'CREDIT',
  '355091': 'CREDIT',
  '355092': 'CREDIT',
  '355093': 'CREDIT',
  '355094': 'CREDIT',
  '355095': 'CREDIT',
  '355096': 'CREDIT',
  '355097': 'CREDIT',
  '355098': 'CREDIT',
  '355099': 'CREDIT',
  '355100': 'CREDIT',
  '355101': 'CREDIT',
  '355102': 'CREDIT',
  '355103': 'CREDIT',
  '355104': 'CREDIT',
  '355105': 'CREDIT',
  '355106': 'CREDIT',
  '355107': 'CREDIT',
  '355108': 'CREDIT',
  '355109': 'CREDIT',
  '355110': 'CREDIT',
  '355111': 'CREDIT',
  '355112': 'CREDIT',
  '355113': 'CREDIT',
  '355114': 'CREDIT',
  '355115': 'CREDIT',
  '355116': 'CREDIT',
  '355117': 'CREDIT',
  '355118': 'CREDIT',
  '355119': 'CREDIT',
  '355120': 'CREDIT',
  '355121': 'CREDIT',
  '355122': 'CREDIT',
  '355123': 'CREDIT',
  '355124': 'CREDIT',
  '355125': 'CREDIT',
  '355126': 'CREDIT',
  '355127': 'CREDIT',
  '355128': 'CREDIT',
  '355129': 'CREDIT',
  '355130': 'CREDIT',
  '355131': 'CREDIT',
  '355132': 'CREDIT',
  '355133': 'CREDIT',
  '355134': 'CREDIT',
  '355135': 'CREDIT',
  '355136': 'CREDIT',
  '355137': 'CREDIT',
  '355138': 'CREDIT',
  '355139': 'CREDIT',
  '355140': 'CREDIT',
  '355141': 'CREDIT',
  '355142': 'CREDIT',
  '355143': 'CREDIT',
  '355144': 'CREDIT',
  '355145': 'CREDIT',
  '355146': 'CREDIT',
  '355147': 'CREDIT',
  '355148': 'CREDIT',
  '355149': 'CREDIT',
  '355150': 'CREDIT',
  '355151': 'CREDIT',
  '355152': 'CREDIT',
  '355153': 'CREDIT',
  '355154': 'CREDIT',
  '355155': 'CREDIT',
  '355156': 'CREDIT',
  '355157': 'CREDIT',
  '355158': 'CREDIT',
  '355159': 'CREDIT',
  '355160': 'CREDIT',
  '355161': 'CREDIT',
  '355162': 'CREDIT',
  '355163': 'CREDIT',
  '355164': 'CREDIT',
  '355165': 'CREDIT',
  '355166': 'CREDIT',
  '355167': 'CREDIT',
  '355168': 'CREDIT',
  '355169': 'CREDIT',
  '355170': 'CREDIT',
  '355171': 'CREDIT',
  '355172': 'CREDIT',
  '355173': 'CREDIT',
  '355174': 'CREDIT',
  '355175': 'CREDIT',
  '355176': 'CREDIT',
  '355177': 'CREDIT',
  '355178': 'CREDIT',
  '355179': 'CREDIT',
  '355180': 'CREDIT',
  '355181': 'CREDIT',
  '355182': 'CREDIT',
  '355183': 'CREDIT',
  '355184': 'CREDIT',
  '355185': 'CREDIT',
  '355186': 'CREDIT',
  '355187': 'CREDIT',
  '355188': 'CREDIT',
  '355189': 'CREDIT',
  '355190': 'CREDIT',
  '355191': 'CREDIT',
  '355192': 'CREDIT',
  '355193': 'CREDIT',
  '355194': 'CREDIT',
  '355195': 'CREDIT',
  '355196': 'CREDIT',
  '355197': 'CREDIT',
  '355198': 'CREDIT',
  '355199': 'CREDIT',
  '355200': 'CREDIT',
  '355201': 'CREDIT',
  '355202': 'CREDIT',
  '355203': 'CREDIT',
  '355204': 'CREDIT',
  '355205': 'CREDIT',
  '355206': 'CREDIT',
  '355207': 'CREDIT',
  '355208': 'CREDIT',
  '355209': 'CREDIT',
  '355210': 'CREDIT',
  '355211': 'CREDIT',
  '355212': 'CREDIT',
  '355213': 'CREDIT',
  '355214': 'CREDIT',
  '355215': 'CREDIT',
  '355216': 'CREDIT',
  '355217': 'CREDIT',
  '355218': 'CREDIT',
  '355219': 'CREDIT',
  '355220': 'CREDIT',
  '355221': 'CREDIT',
  '355222': 'CREDIT',
  '355223': 'CREDIT',
  '355224': 'CREDIT',
  '355225': 'CREDIT',
  '355226': 'CREDIT',
  '355227': 'CREDIT',
  '355228': 'CREDIT',
  '355229': 'CREDIT',
  '355230': 'CREDIT',
  '355231': 'CREDIT',
  '355232': 'CREDIT',
  '355233': 'CREDIT',
  '355234': 'CREDIT',
  '355235': 'CREDIT',
  '355236': 'CREDIT',
  '355237': 'CREDIT',
  '355238': 'CREDIT',
  '355239': 'CREDIT',
  '355240': 'CREDIT',
  '355241': 'CREDIT',
  '355242': 'CREDIT',
  '355243': 'CREDIT',
  '355244': 'CREDIT',
  '355245': 'CREDIT',
  '355246': 'CREDIT',
  '355247': 'CREDIT',
  '355248': 'CREDIT',
  '355249': 'CREDIT',
  '355250': 'CREDIT',
  '355251': 'CREDIT',
  '355252': 'CREDIT',
  '355253': 'CREDIT',
  '355254': 'CREDIT',
  '355255': 'CREDIT',
  '355256': 'CREDIT',
  '355257': 'CREDIT',
  '355258': 'CREDIT',
  '355259': 'CREDIT',
  '355260': 'CREDIT',
  '355261': 'CREDIT',
  '355262': 'CREDIT',
  '355263': 'CREDIT',
  '355264': 'CREDIT',
  '355265': 'CREDIT',
  '355266': 'CREDIT',
  '355267': 'CREDIT',
  '355268': 'CREDIT',
  '355269': 'CREDIT',
  '355270': 'CREDIT',
  '355271': 'CREDIT',
  '355272': 'CREDIT',
  '355273': 'CREDIT',
  '355274': 'CREDIT',
  '355275': 'CREDIT',
  '355276': 'CREDIT',
  '355277': 'CREDIT',
  '355278': 'CREDIT',
  '355279': 'CREDIT',
  '355280': 'CREDIT',
  '355281': 'CREDIT',
  '355282': 'CREDIT',
  '355283': 'CREDIT',
  '355284': 'CREDIT',
  '355285': 'CREDIT',
  '355286': 'CREDIT',
  '355287': 'CREDIT',
  '355288': 'CREDIT',
  '355289': 'CREDIT',
  '355290': 'CREDIT',
  '355291': 'CREDIT',
  '355292': 'CREDIT',
  '355293': 'CREDIT',
  '355294': 'CREDIT',
  '355295': 'CREDIT',
  '355296': 'CREDIT',
  '355297': 'CREDIT',
  '355298': 'CREDIT',
  '355299': 'CREDIT',
  '355300': 'CREDIT',
  '355301': 'CREDIT',
  '355302': 'CREDIT',
  '355303': 'CREDIT',
  '355304': 'CREDIT',
  '355305': 'CREDIT',
  '355306': 'CREDIT',
  '355307': 'CREDIT',
  '355308': 'CREDIT',
  '355309': 'CREDIT',
  '355310': 'CREDIT',
  '355311': 'CREDIT',
  '355312': 'CREDIT',
  '355313': 'CREDIT',
  '355314': 'CREDIT',
  '355315': 'CREDIT',
  '355316': 'CREDIT',
  '355317': 'CREDIT',
  '355318': 'CREDIT',
  '355319': 'CREDIT',
  '355320': 'CREDIT',
  '355321': 'CREDIT',
  '355322': 'CREDIT',
  '355323': 'CREDIT',
  '355324': 'CREDIT',
  '355325': 'CREDIT',
  '355326': 'CREDIT',
  '355327': 'CREDIT',
  '355328': 'CREDIT',
  '355329': 'CREDIT',
  '355330': 'CREDIT',
  '355331': 'CREDIT',
  '355332': 'CREDIT',
  '355333': 'CREDIT',
  '355334': 'CREDIT',
  '355335': 'CREDIT',
  '355336': 'CREDIT',
  '355337': 'CREDIT',
  '355338': 'CREDIT',
  '355339': 'CREDIT',
  '355340': 'CREDIT',
  '355341': 'CREDIT',
  '355342': 'CREDIT',
  '355343': 'CREDIT',
  '355344': 'CREDIT',
  '355345': 'CREDIT',
  '355346': 'CREDIT',
  '355347': 'CREDIT',
  '355348': 'CREDIT',
  '355349': 'CREDIT',
  '355350': 'CREDIT',
  '355351': 'CREDIT',
  '355352': 'CREDIT',
  '355353': 'CREDIT',
  '355354': 'CREDIT',
  '355355': 'CREDIT',
  '355356': 'CREDIT',
  '355357': 'CREDIT',
  '355358': 'CREDIT',
  '355359': 'CREDIT',
  '355360': 'CREDIT',
  '355361': 'CREDIT',
  '355362': 'CREDIT',
  '355363': 'CREDIT',
  '355364': 'CREDIT',
  '355365': 'CREDIT',
  '355366': 'CREDIT',
  '355367': 'CREDIT',
  '355368': 'CREDIT',
  '355369': 'CREDIT',
  '355370': 'CREDIT',
  '355371': 'CREDIT',
  '355372': 'CREDIT',
  '355373': 'CREDIT',
  '355374': 'CREDIT',
  '355375': 'CREDIT',
  '355376': 'CREDIT',
  '355377': 'CREDIT',
  '355378': 'CREDIT',
  '355379': 'CREDIT',
  '355380': 'CREDIT',
  '355381': 'CREDIT',
  '355382': 'CREDIT',
  '355383': 'CREDIT',
  '355384': 'CREDIT',
  '355385': 'CREDIT',
  '355386': 'CREDIT',
  '355387': 'CREDIT',
  '355388': 'CREDIT',
  '355389': 'CREDIT',
  '355390': 'CREDIT',
  '355391': 'CREDIT',
  '355392': 'CREDIT',
  '355393': 'CREDIT',
  '355394': 'CREDIT',
  '355395': 'CREDIT',
  '355396': 'CREDIT',
  '355397': 'CREDIT',
  '355398': 'CREDIT',
  '355399': 'CREDIT',
  '355400': 'CREDIT',
  '355401': 'CREDIT',
  '355402': 'CREDIT',
  '355403': 'CREDIT',
  '355404': 'CREDIT',
  '355405': 'CREDIT',
  '355406': 'CREDIT',
  '355407': 'CREDIT',
  '355408': 'CREDIT',
  '355409': 'CREDIT',
  '355410': 'CREDIT',
  '355411': 'CREDIT',
  '355412': 'CREDIT',
  '355413': 'CREDIT',
  '355414': 'CREDIT',
  '355415': 'CREDIT',
  '355416': 'CREDIT',
  '355417': 'CREDIT',
  '355418': 'CREDIT',
  '355419': 'CREDIT',
  '355420': 'CREDIT',
  '355421': 'CREDIT',
  '355422': 'CREDIT',
  '355423': 'CREDIT',
  '355424': 'CREDIT',
  '355425': 'CREDIT',
  '355426': 'CREDIT',
  '355427': 'CREDIT',
  '355428': 'CREDIT',
  '355429': 'CREDIT',
  '355430': 'CREDIT',
  '355431': 'CREDIT',
  '355432': 'CREDIT',
  '355433': 'CREDIT',
  '355434': 'CREDIT',
  '355435': 'CREDIT',
  '355436': 'CREDIT',
  '355437': 'CREDIT',
  '355438': 'CREDIT',
  '355439': 'CREDIT',
  '355440': 'CREDIT',
  '355441': 'CREDIT',
  '355442': 'CREDIT',
  '355443': 'CREDIT',
  '355444': 'CREDIT',
  '355445': 'CREDIT',
  '355446': 'CREDIT',
  '355447': 'CREDIT',
  '355448': 'CREDIT',
  '355449': 'CREDIT',
  '355450': 'CREDIT',
  '355451': 'CREDIT',
  '355452': 'CREDIT',
  '355453': 'CREDIT',
  '355454': 'CREDIT',
  '355455': 'CREDIT',
  '355456': 'CREDIT',
  '355457': 'CREDIT',
  '355458': 'CREDIT',
  '355459': 'CREDIT',
  '355460': 'CREDIT',
  '355461': 'CREDIT',
  '355462': 'CREDIT',
  '355463': 'CREDIT',
  '355464': 'CREDIT',
  '355465': 'CREDIT',
  '355466': 'CREDIT',
  '355467': 'CREDIT',
  '355468': 'CREDIT',
  '355469': 'CREDIT',
  '355470': 'CREDIT',
  '355471': 'CREDIT',
  '355472': 'CREDIT',
  '355473': 'CREDIT',
  '355474': 'CREDIT',
  '355475': 'CREDIT',
  '355476': 'CREDIT',
  '355477': 'CREDIT',
  '355478': 'CREDIT',
  '355479': 'CREDIT',
  '355480': 'CREDIT',
  '355481': 'CREDIT',
  '355482': 'CREDIT',
  '355483': 'CREDIT',
  '355484': 'CREDIT',
  '355485': 'CREDIT',
  '355486': 'CREDIT',
  '355487': 'CREDIT',
  '355488': 'CREDIT',
  '355489': 'CREDIT',
  '355490': 'CREDIT',
  '355491': 'CREDIT',
  '355492': 'CREDIT',
  '355493': 'CREDIT',
  '355494': 'CREDIT',
  '355495': 'CREDIT',
  '355496': 'CREDIT',
  '355497': 'CREDIT',
  '355498': 'CREDIT',
  '355499': 'CREDIT',
  '355500': 'CREDIT',
  '355501': 'CREDIT',
  '355502': 'CREDIT',
  '355503': 'CREDIT',
  '355504': 'CREDIT',
  '355505': 'CREDIT',
  '355506': 'CREDIT',
  '355507': 'CREDIT',
  '355508': 'CREDIT',
  '355509': 'CREDIT',
  '355510': 'CREDIT',
  '355511': 'CREDIT',
  '355512': 'CREDIT',
  '355513': 'CREDIT',
  '355514': 'CREDIT',
  '355515': 'CREDIT',
  '355516': 'CREDIT',
  '355517': 'CREDIT',
  '355518': 'CREDIT',
  '355519': 'CREDIT',
  '355520': 'CREDIT',
  '355521': 'CREDIT',
  '355522': 'CREDIT',
  '355523': 'CREDIT',
  '355524': 'CREDIT',
  '355525': 'CREDIT',
  '355526': 'CREDIT',
  '355527': 'CREDIT',
  '355528': 'CREDIT',
  '355529': 'CREDIT',
  '355530': 'CREDIT',
  '355531': 'CREDIT',
  '355532': 'CREDIT',
  '355533': 'CREDIT',
  '355534': 'CREDIT',
  '355535': 'CREDIT',
  '355536': 'CREDIT',
  '355537': 'CREDIT',
  '355538': 'CREDIT',
  '355539': 'CREDIT',
  '355540': 'CREDIT',
  '355541': 'CREDIT',
  '355542': 'CREDIT',
  '355543': 'CREDIT',
  '355544': 'CREDIT',
  '355545': 'CREDIT',
  '355546': 'CREDIT',
  '355547': 'CREDIT',
  '355548': 'CREDIT',
  '355549': 'CREDIT',
  '355550': 'CREDIT',
  '355551': 'CREDIT',
  '355552': 'CREDIT',
  '355553': 'CREDIT',
  '355554': 'CREDIT',
  '355555': 'CREDIT',
  '355556': 'CREDIT',
  '355557': 'CREDIT',
  '355558': 'CREDIT',
  '355559': 'CREDIT',
  '355560': 'CREDIT',
  '355561': 'CREDIT',
  '355562': 'CREDIT',
  '355563': 'CREDIT',
  '355564': 'CREDIT',
  '355565': 'CREDIT',
  '355566': 'CREDIT',
  '355567': 'CREDIT',
  '355568': 'CREDIT',
  '355569': 'CREDIT',
  '355570': 'CREDIT',
  '355571': 'CREDIT',
  '355572': 'CREDIT',
  '355573': 'CREDIT',
  '355574': 'CREDIT',
  '355575': 'CREDIT',
  '355576': 'CREDIT',
  '355577': 'CREDIT',
  '355578': 'CREDIT',
  '355579': 'CREDIT',
  '355580': 'CREDIT',
  '355581': 'CREDIT',
  '355582': 'CREDIT',
  '355583': 'CREDIT',
  '355584': 'CREDIT',
  '355585': 'CREDIT',
  '355586': 'CREDIT',
  '355587': 'CREDIT',
  '355588': 'CREDIT',
  '355589': 'CREDIT',
  '355590': 'CREDIT',
  '355591': 'CREDIT',
  '355592': 'CREDIT',
  '355593': 'CREDIT',
  '355594': 'CREDIT',
  '355595': 'CREDIT',
  '355596': 'CREDIT',
  '355597': 'CREDIT',
  '355598': 'CREDIT',
  '355599': 'CREDIT',
  '355600': 'CREDIT',
  '355601': 'CREDIT',
  '355602': 'CREDIT',
  '355603': 'CREDIT',
  '355604': 'CREDIT',
  '355605': 'CREDIT',
  '355606': 'CREDIT',
  '355607': 'CREDIT',
  '355608': 'CREDIT',
  '355609': 'CREDIT',
  '355610': 'CREDIT',
  '355611': 'CREDIT',
  '355612': 'CREDIT',
  '355613': 'CREDIT',
  '355614': 'CREDIT',
  '355615': 'CREDIT',
  '355616': 'CREDIT',
  '355617': 'CREDIT',
  '355618': 'CREDIT',
  '355619': 'CREDIT',
  '355620': 'CREDIT',
  '355621': 'CREDIT',
  '355622': 'CREDIT',
  '355623': 'CREDIT',
  '355624': 'CREDIT',
  '355625': 'CREDIT',
  '355626': 'CREDIT',
  '355627': 'CREDIT',
  '355628': 'CREDIT',
  '355629': 'CREDIT',
  '355630': 'CREDIT',
  '355631': 'CREDIT',
  '355632': 'CREDIT',
  '355633': 'CREDIT',
  '355634': 'CREDIT',
  '355635': 'CREDIT',
  '355636': 'CREDIT',
  '355637': 'CREDIT',
  '355638': 'CREDIT',
  '355639': 'CREDIT',
  '355640': 'CREDIT',
  '355641': 'CREDIT',
  '355642': 'CREDIT',
  '355643': 'CREDIT',
  '355644': 'CREDIT',
  '355645': 'CREDIT',
  '355646': 'CREDIT',
  '355647': 'CREDIT',
  '355648': 'CREDIT',
  '355649': 'CREDIT',
  '355650': 'CREDIT',
  '355651': 'CREDIT',
  '355652': 'CREDIT',
  '355653': 'CREDIT',
  '355654': 'CREDIT',
  '355655': 'CREDIT',
  '355656': 'CREDIT',
  '355657': 'CREDIT',
  '355658': 'CREDIT',
  '355659': 'CREDIT',
  '355660': 'CREDIT',
  '355661': 'CREDIT',
  '355662': 'CREDIT',
  '355663': 'CREDIT',
  '355664': 'CREDIT',
  '355665': 'CREDIT',
  '355666': 'CREDIT',
  '355667': 'CREDIT',
  '355668': 'CREDIT',
  '355669': 'CREDIT',
  '355670': 'CREDIT',
  '355671': 'CREDIT',
  '355672': 'CREDIT',
  '355673': 'CREDIT',
  '355674': 'CREDIT',
  '355675': 'CREDIT',
  '355676': 'CREDIT',
  '355677': 'CREDIT',
  '355678': 'CREDIT',
  '355679': 'CREDIT',
  '355680': 'CREDIT',
  '355681': 'CREDIT',
  '355682': 'CREDIT',
  '355683': 'CREDIT',
  '355684': 'CREDIT',
  '355685': 'CREDIT',
  '355686': 'CREDIT',
  '355687': 'CREDIT',
  '355688': 'CREDIT',
  '355689': 'CREDIT',
  '355690': 'CREDIT',
  '355691': 'CREDIT',
  '355692': 'CREDIT',
  '355693': 'CREDIT',
  '355694': 'CREDIT',
  '355695': 'CREDIT',
  '355696': 'CREDIT',
  '355697': 'CREDIT',
  '355698': 'CREDIT',
  '355699': 'CREDIT',
  '355700': 'CREDIT',
  '355701': 'CREDIT',
  '355702': 'CREDIT',
  '355703': 'CREDIT',
  '355704': 'CREDIT',
  '355705': 'CREDIT',
  '355706': 'CREDIT',
  '355707': 'CREDIT',
  '355708': 'CREDIT',
  '355709': 'CREDIT',
  '355710': 'CREDIT',
  '355711': 'CREDIT',
  '355712': 'CREDIT',
  '355713': 'CREDIT',
  '355714': 'CREDIT',
  '355715': 'CREDIT',
  '355716': 'CREDIT',
  '355717': 'CREDIT',
  '355718': 'CREDIT',
  '355719': 'CREDIT',
  '355720': 'CREDIT',
  '355721': 'CREDIT',
  '355722': 'CREDIT',
  '355723': 'CREDIT',
  '355724': 'CREDIT',
  '355725': 'CREDIT',
  '355726': 'CREDIT',
  '355727': 'CREDIT',
  '355728': 'CREDIT',
  '355729': 'CREDIT',
  '355730': 'CREDIT',
  '355731': 'CREDIT',
  '355732': 'CREDIT',
  '355733': 'CREDIT',
  '355734': 'CREDIT',
  '355735': 'CREDIT',
  '355736': 'CREDIT',
  '355737': 'CREDIT',
  '355738': 'CREDIT',
  '355739': 'CREDIT',
  '355740': 'CREDIT',
  '355741': 'CREDIT',
  '355742': 'CREDIT',
  '355743': 'CREDIT',
  '355744': 'CREDIT',
  '355745': 'CREDIT',
  '355746': 'CREDIT',
  '355747': 'CREDIT',
  '355748': 'CREDIT',
  '355749': 'CREDIT',
  '355750': 'CREDIT',
  '355751': 'CREDIT',
  '355752': 'CREDIT',
  '355753': 'CREDIT',
  '355754': 'CREDIT',
  '355755': 'CREDIT',
  '355756': 'CREDIT',
  '355757': 'CREDIT',
  '355758': 'CREDIT',
  '355759': 'CREDIT',
  '355760': 'CREDIT',
  '355761': 'CREDIT',
  '355762': 'CREDIT',
  '355763': 'CREDIT',
  '355764': 'CREDIT',
  '355765': 'CREDIT',
  '355766': 'CREDIT',
  '355767': 'CREDIT',
  '355768': 'CREDIT',
  '355769': 'CREDIT',
  '355770': 'CREDIT',
  '355771': 'CREDIT',
  '355772': 'CREDIT',
  '355773': 'CREDIT',
  '355774': 'CREDIT',
  '355775': 'CREDIT',
  '355776': 'CREDIT',
  '355777': 'CREDIT',
  '355778': 'CREDIT',
  '355779': 'CREDIT',
  '355780': 'CREDIT',
  '355781': 'CREDIT',
  '355782': 'CREDIT',
  '355783': 'CREDIT',
  '355784': 'CREDIT',
  '355785': 'CREDIT',
  '355786': 'CREDIT',
  '355787': 'CREDIT',
  '355788': 'CREDIT',
  '355789': 'CREDIT',
  '355790': 'CREDIT',
  '355791': 'CREDIT',
  '355792': 'CREDIT',
  '355793': 'CREDIT',
  '355794': 'CREDIT',
  '355795': 'CREDIT',
  '355796': 'CREDIT',
  '355797': 'CREDIT',
  '355798': 'CREDIT',
  '355799': 'CREDIT',
  '355800': 'CREDIT',
  '355801': 'CREDIT',
  '355802': 'CREDIT',
  '355803': 'CREDIT',
  '355804': 'CREDIT',
  '355805': 'CREDIT',
  '355806': 'CREDIT',
  '355807': 'CREDIT',
  '355808': 'CREDIT',
  '355809': 'CREDIT',
  '355810': 'CREDIT',
  '355811': 'CREDIT',
  '355812': 'CREDIT',
  '355813': 'CREDIT',
  '355814': 'CREDIT',
  '355815': 'CREDIT',
  '355816': 'CREDIT',
  '355817': 'CREDIT',
  '355818': 'CREDIT',
  '355819': 'CREDIT',
  '355820': 'CREDIT',
  '355821': 'CREDIT',
  '355822': 'CREDIT',
  '355823': 'CREDIT',
  '355824': 'CREDIT',
  '355825': 'CREDIT',
  '355826': 'CREDIT',
  '355827': 'CREDIT',
  '355828': 'CREDIT',
  '355829': 'CREDIT',
  '355830': 'CREDIT',
  '355831': 'CREDIT',
  '355832': 'CREDIT',
  '355833': 'CREDIT',
  '355834': 'CREDIT',
  '355835': 'CREDIT',
  '355836': 'CREDIT',
  '355837': 'CREDIT',
  '355838': 'CREDIT',
  '355839': 'CREDIT',
  '355840': 'CREDIT',
  '355841': 'CREDIT',
  '355842': 'CREDIT',
  '355843': 'CREDIT',
  '355844': 'CREDIT',
  '355845': 'CREDIT',
  '355846': 'CREDIT',
  '355847': 'CREDIT',
  '355848': 'CREDIT',
  '355849': 'CREDIT',
  '355850': 'CREDIT',
  '355851': 'CREDIT',
  '355852': 'CREDIT',
  '355853': 'CREDIT',
  '355854': 'CREDIT',
  '355855': 'CREDIT',
  '355856': 'CREDIT',
  '355857': 'CREDIT',
  '355858': 'CREDIT',
  '355859': 'CREDIT',
  '355860': 'CREDIT',
  '355861': 'CREDIT',
  '355862': 'CREDIT',
  '355863': 'CREDIT',
  '355864': 'CREDIT',
  '355865': 'CREDIT',
  '355866': 'CREDIT',
  '355867': 'CREDIT',
  '355868': 'CREDIT',
  '355869': 'CREDIT',
  '355870': 'CREDIT',
  '355871': 'CREDIT',
  '355872': 'CREDIT',
  '355873': 'CREDIT',
  '355874': 'CREDIT',
  '355875': 'CREDIT',
  '355876': 'CREDIT',
  '355877': 'CREDIT',
  '355878': 'CREDIT',
  '355879': 'CREDIT',
  '355880': 'CREDIT',
  '355881': 'CREDIT',
  '355882': 'CREDIT',
  '355883': 'CREDIT',
  '355884': 'CREDIT',
  '355885': 'CREDIT',
  '355886': 'CREDIT',
  '355887': 'CREDIT',
  '355888': 'CREDIT',
  '355889': 'CREDIT',
  '355890': 'CREDIT',
  '355891': 'CREDIT',
  '355892': 'CREDIT',
  '355893': 'CREDIT',
  '355894': 'CREDIT',
  '355895': 'CREDIT',
  '355896': 'CREDIT',
  '355897': 'CREDIT',
  '355898': 'CREDIT',
  '355899': 'CREDIT',
  '355900': 'CREDIT',
  '355901': 'CREDIT',
  '355902': 'CREDIT',
  '355903': 'CREDIT',
  '355904': 'CREDIT',
  '355905': 'CREDIT',
  '355906': 'CREDIT',
  '355907': 'CREDIT',
  '355908': 'CREDIT',
  '355909': 'CREDIT',
  '355910': 'CREDIT',
  '355911': 'CREDIT',
  '355912': 'CREDIT',
  '355913': 'CREDIT',
  '355914': 'CREDIT',
  '355915': 'CREDIT',
  '355916': 'CREDIT',
  '355917': 'CREDIT',
  '355918': 'CREDIT',
  '355919': 'CREDIT',
  '355920': 'CREDIT',
  '355921': 'CREDIT',
  '355922': 'CREDIT',
  '355923': 'CREDIT',
  '355924': 'CREDIT',
  '355925': 'CREDIT',
  '355926': 'CREDIT',
  '355927': 'CREDIT',
  '355928': 'CREDIT',
  '355929': 'CREDIT',
  '355930': 'CREDIT',
  '355931': 'CREDIT',
  '355932': 'CREDIT',
  '355933': 'CREDIT',
  '355934': 'CREDIT',
  '355935': 'CREDIT',
  '355936': 'CREDIT',
  '355937': 'CREDIT',
  '355938': 'CREDIT',
  '355939': 'CREDIT',
  '355940': 'CREDIT',
  '355941': 'CREDIT',
  '355942': 'CREDIT',
  '355943': 'CREDIT',
  '355944': 'CREDIT',
  '355945': 'CREDIT',
  '355946': 'CREDIT',
  '355947': 'CREDIT',
  '355948': 'CREDIT',
  '355949': 'CREDIT',
  '355950': 'CREDIT',
  '355951': 'CREDIT',
  '355952': 'CREDIT',
  '355953': 'CREDIT',
  '355954': 'CREDIT',
  '355955': 'CREDIT',
  '355956': 'CREDIT',
  '355957': 'CREDIT',
  '355958': 'CREDIT',
  '355959': 'CREDIT',
  '355960': 'CREDIT',
  '355961': 'CREDIT',
  '355962': 'CREDIT',
  '355963': 'CREDIT',
  '355964': 'CREDIT',
  '355965': 'CREDIT',
  '355966': 'CREDIT',
  '355967': 'CREDIT',
  '355968': 'CREDIT',
  '355969': 'CREDIT',
  '355970': 'CREDIT',
  '355971': 'CREDIT',
  '355972': 'CREDIT',
  '355973': 'CREDIT',
  '355974': 'CREDIT',
  '355975': 'CREDIT',
  '355976': 'CREDIT',
  '355977': 'CREDIT',
  '355978': 'CREDIT',
  '355979': 'CREDIT',
  '355980': 'CREDIT',
  '355981': 'CREDIT',
  '355982': 'CREDIT',
  '355983': 'CREDIT',
  '355984': 'CREDIT',
  '355985': 'CREDIT',
  '355986': 'CREDIT',
  '355987': 'CREDIT',
  '355988': 'CREDIT',
  '355989': 'CREDIT',
  '355990': 'CREDIT',
  '355991': 'CREDIT',
  '355992': 'CREDIT',
  '355993': 'CREDIT',
  '355994': 'CREDIT',
  '355995': 'CREDIT',
  '355996': 'CREDIT',
  '355997': 'CREDIT',
  '355998': 'CREDIT',
  '355999': 'CREDIT',
  '356000': 'CREDIT',
  '356001': 'CREDIT',
  '356002': 'CREDIT',
  '356004': 'CREDIT',
  '356005': 'CREDIT',
  '356007': 'CREDIT',
  '356008': 'CREDIT',
  '356009': 'CREDIT',
  '356010': 'CREDIT',
  '356013': 'CREDIT',
  '356014': 'CREDIT',
  '356015': 'CREDIT',
  '356016': 'CREDIT',
  '356017': 'CREDIT',
  '356018': 'CREDIT',
  '356019': 'CREDIT',
  '356026': 'CREDIT',
  '356028': 'CREDIT',
  '356029': 'CREDIT',
  '356030': 'CREDIT',
  '356034': 'CREDIT',
  '356035': 'CREDIT',
  '356037': 'CREDIT',
  '356038': 'CREDIT',
  '356040': 'CREDIT',
  '356041': 'CREDIT',
  '356042': 'CREDIT',
  '356043': 'CREDIT',
  '356044': 'CREDIT',
  '356046': 'CREDIT',
  '356047': 'CREDIT',
  '356048': 'CREDIT',
  '356049': 'CREDIT',
  '356050': 'CREDIT',
  '356054': 'CREDIT',
  '356057': 'CREDIT',
  '356060': 'CREDIT',
  '356065': 'CREDIT',
  '356066': 'CREDIT',
  '356069': 'CREDIT',
  '356072': 'CREDIT',
  '356073': 'CREDIT',
  '356075': 'CREDIT',
  '356077': 'CREDIT',
  '356081': 'CREDIT',
  '356082': 'CREDIT',
  '356086': 'CREDIT',
  '356092': 'CREDIT',
  '356093': 'CREDIT',
  '356094': 'CREDIT',
  '356095': 'CREDIT',
  '356098': 'CREDIT',
  '356200': 'CREDIT',
  '356203': 'CREDIT',
  '356204': 'CREDIT',
  '356205': 'CREDIT',
  '356206': 'CREDIT',
  '356207': 'CREDIT',
  '356212': 'CREDIT',
  '356213': 'CREDIT',
  '356214': 'CREDIT',
  '356215': 'CREDIT',
  '356216': 'CREDIT',
  '356217': 'CREDIT',
  '356218': 'CREDIT',
  '356219': 'CREDIT',
  '356220': 'CREDIT',
  '356221': 'CREDIT',
  '356222': 'CREDIT',
  '356223': 'CREDIT',
  '356224': 'CREDIT',
  '356225': 'CREDIT',
  '356226': 'CREDIT',
  '356227': 'CREDIT',
  '356228': 'CREDIT',
  '356229': 'CREDIT',
  '356230': 'CREDIT',
  '356231': 'CREDIT',
  '356235': 'CREDIT',
  '356236': 'CREDIT',
  '356237': 'CREDIT',
  '356238': 'CREDIT',
  '356244': 'CREDIT',
  '356245': 'CREDIT',
  '356246': 'CREDIT',
  '356247': 'CREDIT',
  '356248': 'CREDIT',
  '356249': 'CREDIT',
  '356250': 'CREDIT',
  '356251': 'CREDIT',
  '356252': 'CREDIT',
  '356253': 'CREDIT',
  '356254': 'CREDIT',
  '356255': 'CREDIT',
  '356256': 'CREDIT',
  '356257': 'CREDIT',
  '356258': 'CREDIT',
  '356259': 'CREDIT',
  '356260': 'CREDIT',
  '356261': 'CREDIT',
  '356262': 'CREDIT',
  '356263': 'CREDIT',
  '356264': 'CREDIT',
  '356265': 'CREDIT',
  '356266': 'CREDIT',
  '356267': 'CREDIT',
  '356268': 'CREDIT',
  '356269': 'CREDIT',
  '356271': 'CREDIT',
  '356273': 'CREDIT',
  '356274': 'CREDIT',
  '356275': 'CREDIT',
  '356276': 'CREDIT',
  '356282': 'CREDIT',
  '356287': 'CREDIT',
  '356289': 'CREDIT',
  '356300': 'CREDIT',
  '356301': 'CREDIT',
  '356302': 'CREDIT',
  '356304': 'CREDIT',
  '356305': 'CREDIT',
  '356307': 'CREDIT',
  '356308': 'CREDIT',
  '356309': 'CREDIT',
  '356310': 'CREDIT',
  '356313': 'CREDIT',
  '356314': 'CREDIT',
  '356315': 'CREDIT',
  '356318': 'CREDIT',
  '356319': 'CREDIT',
  '356326': 'CREDIT',
  '356328': 'CREDIT',
  '356329': 'CREDIT',
  '356330': 'CREDIT',
  '356334': 'CREDIT',
  '356335': 'CREDIT',
  '356337': 'CREDIT',
  '356338': 'CREDIT',
  '356340': 'CREDIT',
  '356341': 'CREDIT',
  '356344': 'CREDIT',
  '356345': 'CREDIT',
  '356346': 'CREDIT',
  '356347': 'CREDIT',
  '356348': 'CREDIT',
  '356349': 'CREDIT',
  '356354': 'CREDIT',
  '356357': 'CREDIT',
  '356358': 'CREDIT',
  '356362': 'CREDIT',
  '356364': 'CREDIT',
  '356366': 'CREDIT',
  '356369': 'CREDIT',
  '356372': 'CREDIT',
  '356373': 'CREDIT',
  '356375': 'CREDIT',
  '356377': 'CREDIT',
  '356381': 'CREDIT',
  '356386': 'CREDIT',
  '356387': 'CREDIT',
  '356396': 'CREDIT',
  '356399': 'CREDIT',
  '356400': 'CREDIT',
  '356401': 'CREDIT',
  '356405': 'CREDIT',
  '356408': 'CREDIT',
  '356409': 'CREDIT',
  '356410': 'CREDIT',
  '356411': 'CREDIT',
  '356412': 'CREDIT',
  '356420': 'CREDIT',
  '356421': 'CREDIT',
  '356422': 'CREDIT',
  '356423': 'CREDIT',
  '356424': 'CREDIT',
  '356425': 'CREDIT',
  '356426': 'CREDIT',
  '356427': 'CREDIT',
  '356428': 'CREDIT',
  '356429': 'CREDIT',
  '356430': 'CREDIT',
  '356431': 'CREDIT',
  '356432': 'CREDIT',
  '356434': 'CREDIT',
  '356436': 'CREDIT',
  '356437': 'CREDIT',
  '356439': 'CREDIT',
  '356440': 'CREDIT',
  '356441': 'CREDIT',
  '356442': 'CREDIT',
  '356443': 'CREDIT',
  '356444': 'CREDIT',
  '356445': 'CREDIT',
  '356446': 'CREDIT',
  '356447': 'CREDIT',
  '356448': 'CREDIT',
  '356449': 'CREDIT',
  '356453': 'CREDIT',
  '356459': 'CREDIT',
  '356460': 'CREDIT',
  '356469': 'CREDIT',
  '356470': 'CREDIT',
  '356471': 'CREDIT',
  '356472': 'CREDIT',
  '356473': 'CREDIT',
  '356475': 'CREDIT',
  '356476': 'CREDIT',
  '356477': 'CREDIT',
  '356482': 'CREDIT',
  '356483': 'CREDIT',
  '356486': 'CREDIT',
  '356487': 'CREDIT',
  '356488': 'CREDIT',
  '356489': 'CREDIT',
  '356490': 'CREDIT',
  '356491': 'CREDIT',
  '356492': 'CREDIT',
  '356493': 'CREDIT',
  '356494': 'CREDIT',
  '356495': 'CREDIT',
  '356499': 'CREDIT',
  '356500': 'CREDIT',
  '356501': 'CREDIT',
  '356502': 'CREDIT',
  '356528': 'CREDIT',
  '356529': 'CREDIT',
  '356530': 'CREDIT',
  '356538': 'CREDIT',
  '356541': 'CREDIT',
  '356542': 'CREDIT',
  '356547': 'CREDIT',
  '356555': 'CREDIT',
  '356565': 'CREDIT',
  '356566': 'CREDIT',
  '356572': 'CREDIT',
  '356573': 'CREDIT',
  '356576': 'CREDIT',
  '356577': 'CREDIT',
  '356578': 'CREDIT',
  '356579': 'CREDIT',
  '356582': 'CREDIT',
  '356591': 'CREDIT',
  '356595': 'CREDIT',
  '356603': 'CREDIT',
  '356604': 'CREDIT',
  '356605': 'CREDIT',
  '356606': 'CREDIT',
  '356607': 'CREDIT',
  '356608': 'CREDIT',
  '356609': 'CREDIT',
  '356612': 'CREDIT',
  '356613': 'CREDIT',
  '356615': 'CREDIT',
  '356616': 'CREDIT',
  '356617': 'CREDIT',
  '356619': 'CREDIT',
  '356620': 'CREDIT',
  '356621': 'CREDIT',
  '356622': 'CREDIT',
  '356623': 'CREDIT',
  '356624': 'CREDIT',
  '356625': 'CREDIT',
  '356626': 'CREDIT',
  '356627': 'CREDIT',
  '356628': 'CREDIT',
  '356629': 'CREDIT',
  '356631': 'CREDIT',
  '356632': 'CREDIT',
  '356633': 'CREDIT',
  '356634': 'CREDIT',
  '356635': 'CREDIT',
  '356636': 'CREDIT',
  '356637': 'CREDIT',
  '356638': 'CREDIT',
  '356639': 'CREDIT',
  '356640': 'CREDIT',
  '356641': 'CREDIT',
  '356642': 'CREDIT',
  '356643': 'CREDIT',
  '356644': 'CREDIT',
  '356645': 'CREDIT',
  '356646': 'CREDIT',
  '356647': 'CREDIT',
  '356648': 'CREDIT',
  '356649': 'CREDIT',
  '356650': 'CREDIT',
  '356654': 'CREDIT',
  '356655': 'CREDIT',
  '356658': 'CREDIT',
  '356659': 'CREDIT',
  '356660': 'CREDIT',
  '356662': 'CREDIT',
  '356664': 'CREDIT',
  '356665': 'CREDIT',
  '356666': 'CREDIT',
  '356667': 'CREDIT',
  '356668': 'CREDIT',
  '356669': 'CREDIT',
  '356671': 'CREDIT',
  '356672': 'CREDIT',
  '356673': 'CREDIT',
  '356674': 'CREDIT',
  '356675': 'CREDIT',
  '356676': 'CREDIT',
  '356677': 'CREDIT',
  '356678': 'CREDIT',
  '356679': 'CREDIT',
  '356682': 'CREDIT',
  '356683': 'CREDIT',
  '356693': 'CREDIT',
  '356694': 'CREDIT',
  '356695': 'CREDIT',
  '356696': 'CREDIT',
  '356697': 'CREDIT',
  '356698': 'CREDIT',
  '356699': 'CREDIT',
  '356705': 'CREDIT',
  '356710': 'CREDIT',
  '356711': 'CREDIT',
  '356719': 'CREDIT',
  '356720': 'CREDIT',
  '356723': 'CREDIT',
  '356726': 'CREDIT',
  '356739': 'CREDIT',
  '356740': 'CREDIT',
  '356749': 'CREDIT',
  '356750': 'CREDIT',
  '356755': 'CREDIT',
  '356760': 'CREDIT',
  '356761': 'CREDIT',
  '356762': 'CREDIT',
  '356765': 'CREDIT',
  '356767': 'CREDIT',
  '356768': 'CREDIT',
  '356773': 'CREDIT',
  '356774': 'CREDIT',
  '356775': 'CREDIT',
  '356778': 'CREDIT',
  '356779': 'CREDIT',
  '356781': 'CREDIT',
  '356782': 'CREDIT',
  '356783': 'CREDIT',
  '356784': 'CREDIT',
  '356789': 'CREDIT',
  '356797': 'CREDIT',
  '356798': 'CREDIT',
  '356799': 'CREDIT',
  '356804': 'CREDIT',
  '356805': 'CREDIT',
  '356806': 'CREDIT',
  '356807': 'CREDIT',
  '356808': 'CREDIT',
  '356809': 'CREDIT',
  '356810': 'CREDIT',
  '356811': 'CREDIT',
  '356814': 'CREDIT',
  '356815': 'CREDIT',
  '356816': 'CREDIT',
  '356818': 'CREDIT',
  '356821': 'CREDIT',
  '356831': 'CREDIT',
  '356836': 'CREDIT',
  '356842': 'CREDIT',
  '356843': 'CREDIT',
  '356848': 'CREDIT',
  '356849': 'CREDIT',
  '356854': 'CREDIT',
  '356855': 'CREDIT',
  '356860': 'CREDIT',
  '356861': 'CREDIT',
  '356863': 'CREDIT',
  '356864': 'CREDIT',
  '356865': 'CREDIT',
  '356866': 'CREDIT',
  '356871': 'CREDIT',
  '356873': 'CREDIT',
  '356874': 'CREDIT',
  '356875': 'CREDIT',
  '356877': 'CREDIT',
  '356884': 'CREDIT',
  '356893': 'CREDIT',
  '356894': 'CREDIT',
  '356903': 'CREDIT',
  '356909': 'CREDIT',
  '356913': 'CREDIT',
  '356917': 'CREDIT',
  '356918': 'CREDIT',
  '356919': 'CREDIT',
  '356921': 'CREDIT',
  '356922': 'CREDIT',
  '356923': 'CREDIT',
  '356924': 'CREDIT',
  '356925': 'CREDIT',
  '356926': 'CREDIT',
  '356927': 'CREDIT',
  '356928': 'CREDIT',
  '356929': 'CREDIT',
  '356930': 'CREDIT',
  '356931': 'CREDIT',
  '356933': 'CREDIT',
  '356934': 'CREDIT',
  '356935': 'CREDIT',
  '356936': 'CREDIT',
  '356937': 'CREDIT',
  '356938': 'CREDIT',
  '356939': 'CREDIT',
  '356940': 'CREDIT',
  '356941': 'CREDIT',
  '356942': 'CREDIT',
  '356943': 'CREDIT',
  '356944': 'CREDIT',
  '356945': 'CREDIT',
  '356946': 'CREDIT',
  '356947': 'CREDIT',
  '356948': 'CREDIT',
  '356949': 'CREDIT',
  '356950': 'CREDIT',
  '356951': 'CREDIT',
  '356952': 'CREDIT',
  '356953': 'CREDIT',
  '356954': 'CREDIT',
  '356955': 'CREDIT',
  '356956': 'CREDIT',
  '356957': 'CREDIT',
  '356958': 'CREDIT',
  '356959': 'CREDIT',
  '356960': 'CREDIT',
  '356961': 'CREDIT',
  '356962': 'CREDIT',
  '356965': 'CREDIT',
  '356967': 'CREDIT',
  '356968': 'CREDIT',
  '356972': 'CREDIT',
  '356973': 'CREDIT',
  '356974': 'CREDIT',
  '356975': 'CREDIT',
  '356976': 'CREDIT',
  '356977': 'CREDIT',
  '356980': 'CREDIT',
  '356981': 'CREDIT',
  '356982': 'CREDIT',
  '356983': 'CREDIT',
  '356984': 'CREDIT',
  '356985': 'CREDIT',
  '356986': 'CREDIT',
  '356987': 'CREDIT',
  '356988': 'CREDIT',
  '356990': 'CREDIT',
  '356991': 'CREDIT',
  '356992': 'CREDIT',
  '356993': 'CREDIT',
  '356994': 'CREDIT',
  '356995': 'CREDIT',
  '356996': 'CREDIT',
  '356997': 'CREDIT',
  '356998': 'CREDIT',
  '356999': 'CREDIT',
  '357000': 'DEBIT',
  '357001': 'DEBIT',
  '357002': 'DEBIT',
  '357003': 'DEBIT',
  '357004': 'DEBIT',
  '357005': 'DEBIT',
  '357006': 'DEBIT',
  '357007': 'DEBIT',
  '357008': 'DEBIT',
  '357009': 'DEBIT',
  '357010': 'DEBIT',
  '357011': 'DEBIT',
  '357012': 'DEBIT',
  '357013': 'DEBIT',
  '357014': 'DEBIT',
  '357015': 'DEBIT',
  '357016': 'DEBIT',
  '357017': 'DEBIT',
  '357018': 'DEBIT',
  '357019': 'DEBIT',
  '357020': 'DEBIT',
  '357021': 'DEBIT',
  '357022': 'DEBIT',
  '357023': 'DEBIT',
  '357024': 'DEBIT',
  '357025': 'DEBIT',
  '357026': 'DEBIT',
  '357027': 'DEBIT',
  '357028': 'DEBIT',
  '357029': 'DEBIT',
  '357030': 'DEBIT',
  '357031': 'DEBIT',
  '357032': 'DEBIT',
  '357033': 'DEBIT',
  '357034': 'DEBIT',
  '357035': 'DEBIT',
  '357036': 'DEBIT',
  '357037': 'DEBIT',
  '357038': 'DEBIT',
  '357039': 'DEBIT',
  '357040': 'DEBIT',
  '357041': 'DEBIT',
  '357042': 'DEBIT',
  '357043': 'DEBIT',
  '357044': 'DEBIT',
  '357045': 'DEBIT',
  '357046': 'DEBIT',
  '357047': 'DEBIT',
  '357048': 'DEBIT',
  '357049': 'DEBIT',
  '357050': 'DEBIT',
  '357051': 'DEBIT',
  '357052': 'DEBIT',
  '357053': 'DEBIT',
  '357054': 'DEBIT',
  '357055': 'DEBIT',
  '357056': 'DEBIT',
  '357057': 'DEBIT',
  '357058': 'DEBIT',
  '357059': 'DEBIT',
  '357060': 'DEBIT',
  '357061': 'DEBIT',
  '357062': 'DEBIT',
  '357063': 'DEBIT',
  '357064': 'DEBIT',
  '357065': 'DEBIT',
  '357066': 'DEBIT',
  '357067': 'DEBIT',
  '357068': 'DEBIT',
  '357069': 'DEBIT',
  '357070': 'DEBIT',
  '357071': 'DEBIT',
  '357072': 'DEBIT',
  '357073': 'DEBIT',
  '357074': 'DEBIT',
  '357075': 'DEBIT',
  '357076': 'DEBIT',
  '357077': 'DEBIT',
  '357078': 'DEBIT',
  '357079': 'DEBIT',
  '357080': 'DEBIT',
  '357081': 'DEBIT',
  '357082': 'DEBIT',
  '357083': 'DEBIT',
  '357084': 'DEBIT',
  '357085': 'DEBIT',
  '357086': 'DEBIT',
  '357087': 'DEBIT',
  '357088': 'DEBIT',
  '357089': 'DEBIT',
  '357090': 'DEBIT',
  '357091': 'DEBIT',
  '357092': 'DEBIT',
  '357093': 'DEBIT',
  '357094': 'DEBIT',
  '357095': 'DEBIT',
  '357096': 'DEBIT',
  '357097': 'DEBIT',
  '357098': 'DEBIT',
  '357099': 'DEBIT',
  '357100': 'DEBIT',
  '357101': 'DEBIT',
  '357102': 'DEBIT',
  '357103': 'DEBIT',
  '357106': 'DEBIT',
  '357107': 'DEBIT',
  '357121': 'DEBIT',
  '357122': 'DEBIT',
  '357125': 'DEBIT',
  '357127': 'DEBIT',
  '357130': 'DEBIT',
  '357131': 'DEBIT',
  '357132': 'DEBIT',
  '357133': 'DEBIT',
  '357135': 'DEBIT',
  '357136': 'DEBIT',
  '357137': 'DEBIT',
  '357138': 'DEBIT',
  '357139': 'DEBIT',
  '357140': 'DEBIT',
  '357141': 'DEBIT',
  '357142': 'DEBIT',
  '357143': 'DEBIT',
  '357144': 'DEBIT',
  '357145': 'DEBIT',
  '357146': 'DEBIT',
  '357147': 'DEBIT',
  '357148': 'DEBIT',
  '357149': 'DEBIT',
  '357150': 'DEBIT',
  '357151': 'DEBIT',
  '357152': 'DEBIT',
  '357153': 'DEBIT',
  '357154': 'DEBIT',
  '357155': 'DEBIT',
  '357156': 'DEBIT',
  '357157': 'DEBIT',
  '357158': 'DEBIT',
  '357159': 'DEBIT',
  '357160': 'DEBIT',
  '357161': 'DEBIT',
  '357162': 'DEBIT',
  '357163': 'DEBIT',
  '357164': 'DEBIT',
  '357165': 'DEBIT',
  '357166': 'DEBIT',
  '357167': 'DEBIT',
  '357168': 'DEBIT',
  '357169': 'DEBIT',
  '357170': 'DEBIT',
  '357171': 'DEBIT',
  '357172': 'DEBIT',
  '357173': 'DEBIT',
  '357174': 'DEBIT',
  '357175': 'DEBIT',
  '357176': 'DEBIT',
  '357177': 'DEBIT',
  '357178': 'DEBIT',
  '357179': 'DEBIT',
  '357180': 'DEBIT',
  '357181': 'DEBIT',
  '357182': 'DEBIT',
  '357183': 'DEBIT',
  '357184': 'DEBIT',
  '357185': 'DEBIT',
  '357186': 'DEBIT',
  '357187': 'DEBIT',
  '357188': 'DEBIT',
  '357189': 'DEBIT',
  '357190': 'DEBIT',
  '357191': 'DEBIT',
  '357192': 'DEBIT',
  '357193': 'DEBIT',
  '357194': 'DEBIT',
  '357195': 'DEBIT',
  '357196': 'DEBIT',
  '357197': 'DEBIT',
  '357198': 'DEBIT',
  '357199': 'DEBIT',
  '357200': 'DEBIT',
  '357201': 'DEBIT',
  '357202': 'DEBIT',
  '357203': 'DEBIT',
  '357204': 'DEBIT',
  '357205': 'DEBIT',
  '357206': 'DEBIT',
  '357207': 'DEBIT',
  '357208': 'DEBIT',
  '357209': 'DEBIT',
  '357210': 'DEBIT',
  '357211': 'DEBIT',
  '357212': 'DEBIT',
  '357213': 'DEBIT',
  '357214': 'DEBIT',
  '357215': 'DEBIT',
  '357216': 'DEBIT',
  '357217': 'DEBIT',
  '357218': 'DEBIT',
  '357219': 'DEBIT',
  '357220': 'DEBIT',
  '357221': 'DEBIT',
  '357222': 'DEBIT',
  '357223': 'DEBIT',
  '357224': 'DEBIT',
  '357225': 'DEBIT',
  '357226': 'DEBIT',
  '357227': 'DEBIT',
  '357228': 'DEBIT',
  '357229': 'DEBIT',
  '357230': 'DEBIT',
  '357231': 'DEBIT',
  '357232': 'DEBIT',
  '357233': 'DEBIT',
  '357234': 'DEBIT',
  '357235': 'DEBIT',
  '357236': 'DEBIT',
  '357237': 'DEBIT',
  '357238': 'DEBIT',
  '357240': 'DEBIT',
  '357241': 'DEBIT',
  '357242': 'DEBIT',
  '357243': 'DEBIT',
  '357244': 'DEBIT',
  '357245': 'DEBIT',
  '357246': 'DEBIT',
  '357247': 'DEBIT',
  '357248': 'DEBIT',
  '357249': 'DEBIT',
  '357250': 'DEBIT',
  '357251': 'DEBIT',
  '357252': 'DEBIT',
  '357253': 'DEBIT',
  '357254': 'DEBIT',
  '357255': 'DEBIT',
  '357256': 'DEBIT',
  '357257': 'DEBIT',
  '357258': 'DEBIT',
  '357259': 'DEBIT',
  '357260': 'DEBIT',
  '357261': 'DEBIT',
  '357262': 'DEBIT',
  '357263': 'DEBIT',
  '357264': 'DEBIT',
  '357265': 'DEBIT',
  '357267': 'DEBIT',
  '357268': 'DEBIT',
  '357269': 'DEBIT',
  '357270': 'DEBIT',
  '357271': 'DEBIT',
  '357272': 'DEBIT',
  '357273': 'DEBIT',
  '357274': 'DEBIT',
  '357275': 'DEBIT',
  '357276': 'DEBIT',
  '357277': 'DEBIT',
  '357278': 'DEBIT',
  '357279': 'DEBIT',
  '357280': 'DEBIT',
  '357281': 'DEBIT',
  '357282': 'DEBIT',
  '357283': 'DEBIT',
  '357284': 'DEBIT',
  '357285': 'DEBIT',
  '357286': 'DEBIT',
  '357287': 'DEBIT',
  '357288': 'DEBIT',
  '357289': 'DEBIT',
  '357290': 'DEBIT',
  '357291': 'DEBIT',
  '357292': 'DEBIT',
  '357293': 'DEBIT',
  '357294': 'DEBIT',
  '357295': 'DEBIT',
  '357296': 'DEBIT',
  '357297': 'DEBIT',
  '357298': 'DEBIT',
  '357299': 'DEBIT',
  '357300': 'DEBIT',
  '357301': 'DEBIT',
  '357302': 'DEBIT',
  '357303': 'DEBIT',
  '357304': 'DEBIT',
  '357305': 'DEBIT',
  '357306': 'DEBIT',
  '357307': 'DEBIT',
  '357308': 'DEBIT',
  '357309': 'DEBIT',
  '357310': 'DEBIT',
  '357311': 'DEBIT',
  '357312': 'DEBIT',
  '357313': 'DEBIT',
  '357314': 'DEBIT',
  '357315': 'DEBIT',
  '357316': 'DEBIT',
  '357317': 'DEBIT',
  '357318': 'DEBIT',
  '357319': 'DEBIT',
  '357320': 'DEBIT',
  '357321': 'DEBIT',
  '357322': 'DEBIT',
  '357323': 'DEBIT',
  '357324': 'DEBIT',
  '357325': 'DEBIT',
  '357326': 'DEBIT',
  '357327': 'DEBIT',
  '357328': 'DEBIT',
  '357329': 'DEBIT',
  '357330': 'DEBIT',
  '357331': 'DEBIT',
  '357332': 'DEBIT',
  '357333': 'DEBIT',
  '357334': 'DEBIT',
  '357335': 'DEBIT',
  '357336': 'DEBIT',
  '357337': 'DEBIT',
  '357338': 'DEBIT',
  '357339': 'DEBIT',
  '357340': 'DEBIT',
  '357341': 'DEBIT',
  '357342': 'DEBIT',
  '357343': 'DEBIT',
  '357344': 'DEBIT',
  '357345': 'DEBIT',
  '357346': 'DEBIT',
  '357347': 'DEBIT',
  '357348': 'DEBIT',
  '357349': 'DEBIT',
  '357350': 'DEBIT',
  '357351': 'DEBIT',
  '357352': 'DEBIT',
  '357353': 'DEBIT',
  '357354': 'DEBIT',
  '357355': 'DEBIT',
  '357356': 'DEBIT',
  '357357': 'DEBIT',
  '357358': 'DEBIT',
  '357359': 'DEBIT',
  '357360': 'DEBIT',
  '357361': 'DEBIT',
  '357362': 'DEBIT',
  '357363': 'DEBIT',
  '357364': 'DEBIT',
  '357365': 'DEBIT',
  '357366': 'DEBIT',
  '357367': 'DEBIT',
  '357368': 'DEBIT',
  '357369': 'DEBIT',
  '357370': 'DEBIT',
  '357371': 'DEBIT',
  '357372': 'DEBIT',
  '357373': 'DEBIT',
  '357374': 'DEBIT',
  '357375': 'DEBIT',
  '357376': 'DEBIT',
  '357377': 'DEBIT',
  '357378': 'DEBIT',
  '357379': 'DEBIT',
  '357380': 'DEBIT',
  '357381': 'DEBIT',
  '357382': 'DEBIT',
  '357383': 'DEBIT',
  '357384': 'DEBIT',
  '357385': 'DEBIT',
  '357386': 'DEBIT',
  '357387': 'DEBIT',
  '357388': 'DEBIT',
  '357389': 'DEBIT',
  '357390': 'DEBIT',
  '357391': 'DEBIT',
  '357392': 'DEBIT',
  '357393': 'DEBIT',
  '357394': 'DEBIT',
  '357395': 'DEBIT',
  '357396': 'DEBIT',
  '357397': 'DEBIT',
  '357398': 'DEBIT',
  '357399': 'DEBIT',
  '357400': 'DEBIT',
  '357401': 'DEBIT',
  '357402': 'DEBIT',
  '357403': 'DEBIT',
  '357404': 'DEBIT',
  '357405': 'DEBIT',
  '357406': 'DEBIT',
  '357407': 'DEBIT',
  '357408': 'DEBIT',
  '357409': 'DEBIT',
  '357410': 'DEBIT',
  '357411': 'DEBIT',
  '357412': 'DEBIT',
  '357413': 'DEBIT',
  '357414': 'DEBIT',
  '357415': 'DEBIT',
  '357416': 'DEBIT',
  '357417': 'DEBIT',
  '357418': 'DEBIT',
  '357419': 'DEBIT',
  '357420': 'DEBIT',
  '357421': 'DEBIT',
  '357422': 'DEBIT',
  '357423': 'DEBIT',
  '357424': 'DEBIT',
  '357425': 'DEBIT',
  '357426': 'DEBIT',
  '357427': 'DEBIT',
  '357428': 'DEBIT',
  '357429': 'DEBIT',
  '357430': 'DEBIT',
  '357431': 'DEBIT',
  '357432': 'DEBIT',
  '357433': 'DEBIT',
  '357434': 'DEBIT',
  '357435': 'DEBIT',
  '357436': 'DEBIT',
  '357437': 'DEBIT',
  '357438': 'DEBIT',
  '357439': 'DEBIT',
  '357440': 'DEBIT',
  '357441': 'DEBIT',
  '357442': 'DEBIT',
  '357443': 'DEBIT',
  '357444': 'DEBIT',
  '357445': 'DEBIT',
  '357446': 'DEBIT',
  '357447': 'DEBIT',
  '357448': 'DEBIT',
  '357449': 'DEBIT',
  '357450': 'DEBIT',
  '357451': 'DEBIT',
  '357452': 'DEBIT',
  '357453': 'DEBIT',
  '357454': 'DEBIT',
  '357455': 'DEBIT',
  '357456': 'DEBIT',
  '357457': 'DEBIT',
  '357458': 'DEBIT',
  '357459': 'DEBIT',
  '357460': 'DEBIT',
  '357461': 'DEBIT',
  '357462': 'DEBIT',
  '357463': 'DEBIT',
  '357464': 'DEBIT',
  '357465': 'DEBIT',
  '357466': 'DEBIT',
  '357467': 'DEBIT',
  '357468': 'DEBIT',
  '357469': 'DEBIT',
  '357470': 'DEBIT',
  '357471': 'DEBIT',
  '357472': 'DEBIT',
  '357473': 'DEBIT',
  '357474': 'DEBIT',
  '357475': 'DEBIT',
  '357476': 'DEBIT',
  '357477': 'DEBIT',
  '357478': 'DEBIT',
  '357479': 'DEBIT',
  '357480': 'DEBIT',
  '357481': 'DEBIT',
  '357482': 'DEBIT',
  '357483': 'DEBIT',
  '357484': 'DEBIT',
  '357485': 'DEBIT',
  '357486': 'DEBIT',
  '357487': 'DEBIT',
  '357488': 'DEBIT',
  '357489': 'DEBIT',
  '357490': 'DEBIT',
  '357491': 'DEBIT',
  '357492': 'DEBIT',
  '357493': 'DEBIT',
  '357494': 'DEBIT',
  '357495': 'DEBIT',
  '357496': 'DEBIT',
  '357497': 'DEBIT',
  '357498': 'DEBIT',
  '357499': 'DEBIT',
  '357500': 'DEBIT',
  '357501': 'DEBIT',
  '357502': 'DEBIT',
  '357503': 'DEBIT',
  '357504': 'DEBIT',
  '357505': 'DEBIT',
  '357506': 'DEBIT',
  '357507': 'DEBIT',
  '357508': 'DEBIT',
  '357509': 'DEBIT',
  '357510': 'DEBIT',
  '357511': 'DEBIT',
  '357512': 'DEBIT',
  '357513': 'DEBIT',
  '357514': 'DEBIT',
  '357515': 'DEBIT',
  '357516': 'DEBIT',
  '357517': 'DEBIT',
  '357518': 'DEBIT',
  '357519': 'DEBIT',
  '357520': 'DEBIT',
  '357521': 'DEBIT',
  '357522': 'DEBIT',
  '357523': 'DEBIT',
  '357524': 'DEBIT',
  '357525': 'DEBIT',
  '357526': 'DEBIT',
  '357527': 'DEBIT',
  '357528': 'DEBIT',
  '357529': 'DEBIT',
  '357530': 'DEBIT',
  '357531': 'DEBIT',
  '357532': 'DEBIT',
  '357533': 'DEBIT',
  '357534': 'DEBIT',
  '357535': 'DEBIT',
  '357536': 'DEBIT',
  '357537': 'DEBIT',
  '357538': 'DEBIT',
  '357539': 'DEBIT',
  '357540': 'DEBIT',
  '357541': 'DEBIT',
  '357542': 'DEBIT',
  '357543': 'DEBIT',
  '357544': 'DEBIT',
  '357545': 'DEBIT',
  '357546': 'DEBIT',
  '357547': 'DEBIT',
  '357548': 'DEBIT',
  '357549': 'DEBIT',
  '357550': 'DEBIT',
  '357551': 'DEBIT',
  '357552': 'DEBIT',
  '357553': 'DEBIT',
  '357554': 'DEBIT',
  '357555': 'DEBIT',
  '357556': 'DEBIT',
  '357557': 'DEBIT',
  '357558': 'DEBIT',
  '357559': 'DEBIT',
  '357560': 'DEBIT',
  '357561': 'DEBIT',
  '357562': 'DEBIT',
  '357563': 'DEBIT',
  '357564': 'DEBIT',
  '357565': 'DEBIT',
  '357566': 'DEBIT',
  '357567': 'DEBIT',
  '357568': 'DEBIT',
  '357569': 'DEBIT',
  '357570': 'DEBIT',
  '357571': 'DEBIT',
  '357572': 'DEBIT',
  '357573': 'DEBIT',
  '357574': 'DEBIT',
  '357575': 'DEBIT',
  '357576': 'DEBIT',
  '357577': 'DEBIT',
  '357578': 'DEBIT',
  '357579': 'DEBIT',
  '357580': 'DEBIT',
  '357581': 'DEBIT',
  '357582': 'DEBIT',
  '357583': 'DEBIT',
  '357584': 'DEBIT',
  '357585': 'DEBIT',
  '357586': 'DEBIT',
  '357587': 'DEBIT',
  '357588': 'DEBIT',
  '357589': 'DEBIT',
  '357590': 'DEBIT',
  '357591': 'DEBIT',
  '357592': 'DEBIT',
  '357593': 'DEBIT',
  '357594': 'DEBIT',
  '357595': 'DEBIT',
  '357596': 'DEBIT',
  '357597': 'DEBIT',
  '357598': 'DEBIT',
  '357599': 'DEBIT',
  '357600': 'DEBIT',
  '357601': 'DEBIT',
  '357602': 'DEBIT',
  '357603': 'DEBIT',
  '357604': 'DEBIT',
  '357605': 'DEBIT',
  '357606': 'DEBIT',
  '357607': 'DEBIT',
  '357608': 'DEBIT',
  '357609': 'DEBIT',
  '357610': 'DEBIT',
  '357611': 'DEBIT',
  '357612': 'DEBIT',
  '357613': 'DEBIT',
  '357614': 'DEBIT',
  '357615': 'DEBIT',
  '357616': 'DEBIT',
  '357617': 'DEBIT',
  '357618': 'DEBIT',
  '357619': 'DEBIT',
  '357620': 'DEBIT',
  '357621': 'DEBIT',
  '357622': 'DEBIT',
  '357623': 'DEBIT',
  '357624': 'DEBIT',
  '357625': 'DEBIT',
  '357626': 'DEBIT',
  '357627': 'DEBIT',
  '357628': 'DEBIT',
  '357629': 'DEBIT',
  '357630': 'DEBIT',
  '357631': 'DEBIT',
  '357632': 'DEBIT',
  '357633': 'DEBIT',
  '357634': 'DEBIT',
  '357635': 'DEBIT',
  '357636': 'DEBIT',
  '357637': 'DEBIT',
  '357638': 'DEBIT',
  '357639': 'DEBIT',
  '357640': 'DEBIT',
  '357641': 'DEBIT',
  '357642': 'DEBIT',
  '357643': 'DEBIT',
  '357644': 'DEBIT',
  '357645': 'DEBIT',
  '357646': 'DEBIT',
  '357647': 'DEBIT',
  '357648': 'DEBIT',
  '357649': 'DEBIT',
  '357650': 'DEBIT',
  '357651': 'DEBIT',
  '357652': 'DEBIT',
  '357653': 'DEBIT',
  '357654': 'DEBIT',
  '357655': 'DEBIT',
  '357656': 'DEBIT',
  '357657': 'DEBIT',
  '357658': 'DEBIT',
  '357659': 'DEBIT',
  '357660': 'DEBIT',
  '357661': 'DEBIT',
  '357662': 'DEBIT',
  '357663': 'DEBIT',
  '357664': 'DEBIT',
  '357665': 'DEBIT',
  '357666': 'DEBIT',
  '357667': 'DEBIT',
  '357668': 'DEBIT',
  '357669': 'DEBIT',
  '357670': 'DEBIT',
  '357671': 'DEBIT',
  '357672': 'DEBIT',
  '357673': 'DEBIT',
  '357674': 'DEBIT',
  '357675': 'DEBIT',
  '357676': 'DEBIT',
  '357677': 'DEBIT',
  '357678': 'DEBIT',
  '357679': 'DEBIT',
  '357680': 'DEBIT',
  '357681': 'DEBIT',
  '357682': 'DEBIT',
  '357683': 'DEBIT',
  '357684': 'DEBIT',
  '357685': 'DEBIT',
  '357686': 'DEBIT',
  '357687': 'DEBIT',
  '357688': 'DEBIT',
  '357689': 'DEBIT',
  '357690': 'DEBIT',
  '357691': 'DEBIT',
  '357692': 'DEBIT',
  '357693': 'DEBIT',
  '357694': 'DEBIT',
  '357695': 'DEBIT',
  '357696': 'DEBIT',
  '357697': 'DEBIT',
  '357698': 'DEBIT',
  '357699': 'DEBIT',
  '357707': 'DEBIT',
  '357711': 'DEBIT',
  '357713': 'DEBIT',
  '357719': 'DEBIT',
  '357722': 'DEBIT',
  '357723': 'DEBIT',
  '357724': 'DEBIT',
  '357725': 'DEBIT',
  '357726': 'DEBIT',
  '357727': 'DEBIT',
  '357728': 'DEBIT',
  '357729': 'DEBIT',
  '357730': 'DEBIT',
  '357731': 'DEBIT',
  '357732': 'DEBIT',
  '357733': 'DEBIT',
  '357734': 'DEBIT',
  '357735': 'DEBIT',
  '357736': 'DEBIT',
  '357737': 'DEBIT',
  '357738': 'DEBIT',
  '357739': 'DEBIT',
  '357740': 'DEBIT',
  '357741': 'DEBIT',
  '357742': 'DEBIT',
  '357743': 'DEBIT',
  '357744': 'DEBIT',
  '357745': 'DEBIT',
  '357746': 'DEBIT',
  '357747': 'DEBIT',
  '357748': 'DEBIT',
  '357749': 'DEBIT',
  '357750': 'DEBIT',
  '357751': 'DEBIT',
  '357752': 'DEBIT',
  '357753': 'DEBIT',
  '357754': 'DEBIT',
  '357755': 'DEBIT',
  '357756': 'DEBIT',
  '357757': 'DEBIT',
  '357758': 'DEBIT',
  '357759': 'DEBIT',
  '357760': 'DEBIT',
  '357761': 'DEBIT',
  '357762': 'DEBIT',
  '357763': 'DEBIT',
  '357764': 'DEBIT',
  '357765': 'DEBIT',
  '357766': 'DEBIT',
  '357767': 'DEBIT',
  '357768': 'DEBIT',
  '357769': 'DEBIT',
  '357770': 'DEBIT',
  '357771': 'DEBIT',
  '357772': 'DEBIT',
  '357773': 'DEBIT',
  '357774': 'DEBIT',
  '357775': 'DEBIT',
  '357780': 'DEBIT',
  '357781': 'DEBIT',
  '357782': 'DEBIT',
  '357783': 'DEBIT',
  '357784': 'DEBIT',
  '357785': 'DEBIT',
  '357786': 'DEBIT',
  '357787': 'DEBIT',
  '357788': 'DEBIT',
  '357789': 'DEBIT',
  '357790': 'DEBIT',
  '357791': 'DEBIT',
  '357792': 'DEBIT',
  '357793': 'DEBIT',
  '357794': 'DEBIT',
  '357795': 'DEBIT',
  '357796': 'DEBIT',
  '357797': 'DEBIT',
  '357798': 'DEBIT',
  '357799': 'DEBIT',
  '357800': 'DEBIT',
  '357801': 'DEBIT',
  '357802': 'DEBIT',
  '357803': 'DEBIT',
  '357804': 'DEBIT',
  '357805': 'DEBIT',
  '357806': 'DEBIT',
  '357807': 'DEBIT',
  '357808': 'DEBIT',
  '357809': 'DEBIT',
  '357810': 'DEBIT',
  '357811': 'DEBIT',
  '357812': 'DEBIT',
  '357813': 'DEBIT',
  '357814': 'DEBIT',
  '357815': 'DEBIT',
  '357816': 'DEBIT',
  '357817': 'DEBIT',
  '357818': 'DEBIT',
  '357819': 'DEBIT',
  '357820': 'DEBIT',
  '357821': 'DEBIT',
  '357822': 'DEBIT',
  '357823': 'DEBIT',
  '357824': 'DEBIT',
  '357825': 'DEBIT',
  '357826': 'DEBIT',
  '357827': 'DEBIT',
  '357828': 'DEBIT',
  '357829': 'DEBIT',
  '357830': 'DEBIT',
  '357831': 'DEBIT',
  '357832': 'DEBIT',
  '357833': 'DEBIT',
  '357834': 'DEBIT',
  '357835': 'DEBIT',
  '357836': 'DEBIT',
  '357837': 'DEBIT',
  '357838': 'DEBIT',
  '357839': 'DEBIT',
  '357840': 'DEBIT',
  '357841': 'DEBIT',
  '357842': 'DEBIT',
  '357843': 'DEBIT',
  '357844': 'DEBIT',
  '357845': 'DEBIT',
  '357846': 'DEBIT',
  '357847': 'DEBIT',
  '357848': 'DEBIT',
  '357849': 'DEBIT',
  '357850': 'DEBIT',
  '357851': 'DEBIT',
  '357852': 'DEBIT',
  '357853': 'DEBIT',
  '357854': 'DEBIT',
  '357855': 'DEBIT',
  '357856': 'DEBIT',
  '357857': 'DEBIT',
  '357858': 'DEBIT',
  '357859': 'DEBIT',
  '357860': 'DEBIT',
  '357861': 'DEBIT',
  '357862': 'DEBIT',
  '357863': 'DEBIT',
  '357864': 'DEBIT',
  '357865': 'DEBIT',
  '357866': 'DEBIT',
  '357867': 'DEBIT',
  '357868': 'DEBIT',
  '357869': 'DEBIT',
  '357870': 'DEBIT',
  '357871': 'DEBIT',
  '357872': 'DEBIT',
  '357873': 'DEBIT',
  '357874': 'DEBIT',
  '357875': 'DEBIT',
  '357876': 'DEBIT',
  '357877': 'DEBIT',
  '357878': 'DEBIT',
  '357879': 'DEBIT',
  '357880': 'DEBIT',
  '357881': 'DEBIT',
  '357882': 'DEBIT',
  '357883': 'DEBIT',
  '357884': 'DEBIT',
  '357885': 'DEBIT',
  '357886': 'DEBIT',
  '357887': 'DEBIT',
  '357888': 'DEBIT',
  '357889': 'DEBIT',
  '357890': 'DEBIT',
  '357891': 'DEBIT',
  '357892': 'DEBIT',
  '357893': 'DEBIT',
  '357894': 'DEBIT',
  '357895': 'DEBIT',
  '357896': 'DEBIT',
  '357897': 'DEBIT',
  '357898': 'DEBIT',
  '357899': 'DEBIT',
  '357900': 'DEBIT',
  '357901': 'DEBIT',
  '357902': 'DEBIT',
  '357903': 'DEBIT',
  '357904': 'DEBIT',
  '357905': 'DEBIT',
  '357906': 'DEBIT',
  '357907': 'DEBIT',
  '357908': 'DEBIT',
  '357909': 'DEBIT',
  '357910': 'DEBIT',
  '357911': 'DEBIT',
  '357912': 'DEBIT',
  '357913': 'DEBIT',
  '357914': 'DEBIT',
  '357915': 'DEBIT',
  '357916': 'DEBIT',
  '357917': 'DEBIT',
  '357918': 'DEBIT',
  '357919': 'DEBIT',
  '357920': 'DEBIT',
  '357921': 'DEBIT',
  '357922': 'DEBIT',
  '357923': 'DEBIT',
  '357924': 'DEBIT',
  '357925': 'DEBIT',
  '357926': 'DEBIT',
  '357927': 'DEBIT',
  '357928': 'DEBIT',
  '357929': 'DEBIT',
  '357930': 'DEBIT',
  '357931': 'DEBIT',
  '357932': 'DEBIT',
  '357933': 'DEBIT',
  '357934': 'DEBIT',
  '357935': 'DEBIT',
  '357936': 'DEBIT',
  '357937': 'DEBIT',
  '357938': 'DEBIT',
  '357939': 'DEBIT',
  '357940': 'DEBIT',
  '357941': 'DEBIT',
  '357942': 'DEBIT',
  '357943': 'DEBIT',
  '357944': 'DEBIT',
  '357945': 'DEBIT',
  '357946': 'DEBIT',
  '357947': 'DEBIT',
  '357948': 'DEBIT',
  '357949': 'DEBIT',
  '357950': 'DEBIT',
  '357951': 'DEBIT',
  '357952': 'DEBIT',
  '357953': 'DEBIT',
  '357954': 'DEBIT',
  '357955': 'DEBIT',
  '357956': 'DEBIT',
  '357957': 'DEBIT',
  '357958': 'DEBIT',
  '357959': 'DEBIT',
  '357960': 'DEBIT',
  '357961': 'DEBIT',
  '357962': 'DEBIT',
  '357963': 'DEBIT',
  '357964': 'DEBIT',
  '357965': 'DEBIT',
  '357966': 'DEBIT',
  '357967': 'DEBIT',
  '357968': 'DEBIT',
  '357969': 'DEBIT',
  '357970': 'DEBIT',
  '357971': 'DEBIT',
  '357972': 'DEBIT',
  '357973': 'DEBIT',
  '357974': 'DEBIT',
  '357975': 'DEBIT',
  '357976': 'DEBIT',
  '357977': 'DEBIT',
  '357978': 'DEBIT',
  '357979': 'DEBIT',
  '357980': 'DEBIT',
  '357981': 'DEBIT',
  '357982': 'DEBIT',
  '357983': 'DEBIT',
  '357984': 'DEBIT',
  '357985': 'DEBIT',
  '357986': 'DEBIT',
  '357987': 'DEBIT',
  '357988': 'DEBIT',
  '357989': 'DEBIT',
  '357990': 'DEBIT',
  '357991': 'DEBIT',
  '357992': 'DEBIT',
  '357993': 'DEBIT',
  '357994': 'DEBIT',
  '357995': 'DEBIT',
  '357996': 'DEBIT',
  '357997': 'DEBIT',
  '357998': 'DEBIT',
  '357999': 'DEBIT',
  '358000': 'CREDIT',
  '358001': 'CREDIT',
  '358002': 'CREDIT',
  '358003': 'CREDIT',
  '358004': 'CREDIT',
  '358005': 'CREDIT',
  '358006': 'CREDIT',
  '358007': 'CREDIT',
  '358008': 'CREDIT',
  '358009': 'CREDIT',
  '358010': 'CREDIT',
  '358011': 'CREDIT',
  '358012': 'CREDIT',
  '358013': 'CREDIT',
  '358014': 'CREDIT',
  '358015': 'CREDIT',
  '358016': 'CREDIT',
  '358017': 'CREDIT',
  '358018': 'CREDIT',
  '358019': 'CREDIT',
  '358020': 'CREDIT',
  '358021': 'CREDIT',
  '358022': 'CREDIT',
  '358023': 'CREDIT',
  '358024': 'CREDIT',
  '358025': 'CREDIT',
  '358026': 'CREDIT',
  '358027': 'CREDIT',
  '358028': 'CREDIT',
  '358029': 'CREDIT',
  '358030': 'CREDIT',
  '358031': 'CREDIT',
  '358032': 'CREDIT',
  '358033': 'CREDIT',
  '358034': 'CREDIT',
  '358035': 'CREDIT',
  '358036': 'CREDIT',
  '358037': 'CREDIT',
  '358038': 'CREDIT',
  '358039': 'CREDIT',
  '358040': 'CREDIT',
  '358041': 'CREDIT',
  '358042': 'CREDIT',
  '358043': 'CREDIT',
  '358044': 'CREDIT',
  '358045': 'CREDIT',
  '358046': 'CREDIT',
  '358047': 'CREDIT',
  '358048': 'CREDIT',
  '358049': 'CREDIT',
  '358050': 'CREDIT',
  '358051': 'CREDIT',
  '358052': 'CREDIT',
  '358053': 'CREDIT',
  '358054': 'CREDIT',
  '358055': 'CREDIT',
  '358056': 'CREDIT',
  '358057': 'CREDIT',
  '358058': 'CREDIT',
  '358059': 'CREDIT',
  '358060': 'CREDIT',
  '358061': 'CREDIT',
  '358062': 'CREDIT',
  '358063': 'CREDIT',
  '358064': 'CREDIT',
  '358065': 'CREDIT',
  '358066': 'CREDIT',
  '358067': 'CREDIT',
  '358068': 'CREDIT',
  '358069': 'CREDIT',
  '358070': 'CREDIT',
  '358071': 'CREDIT',
  '358072': 'CREDIT',
  '358073': 'CREDIT',
  '358074': 'CREDIT',
  '358075': 'CREDIT',
  '358076': 'CREDIT',
  '358077': 'CREDIT',
  '358078': 'CREDIT',
  '358079': 'CREDIT',
  '358080': 'CREDIT',
  '358081': 'CREDIT',
  '358082': 'CREDIT',
  '358083': 'CREDIT',
  '358084': 'CREDIT',
  '358085': 'CREDIT',
  '358086': 'CREDIT',
  '358087': 'CREDIT',
  '358088': 'CREDIT',
  '358089': 'CREDIT',
  '358090': 'CREDIT',
  '358091': 'CREDIT',
  '358092': 'CREDIT',
  '358093': 'CREDIT',
  '358094': 'CREDIT',
  '358095': 'CREDIT',
  '358096': 'CREDIT',
  '358097': 'CREDIT',
  '358098': 'CREDIT',
  '358099': 'CREDIT',
  '358100': 'CREDIT',
  '358101': 'CREDIT',
  '358102': 'CREDIT',
  '358103': 'CREDIT',
  '358104': 'CREDIT',
  '358105': 'CREDIT',
  '358106': 'CREDIT',
  '358107': 'CREDIT',
  '358108': 'CREDIT',
  '358109': 'CREDIT',
  '358110': 'CREDIT',
  '358111': 'CREDIT',
  '358112': 'CREDIT',
  '358113': 'CREDIT',
  '358114': 'CREDIT',
  '358115': 'CREDIT',
  '358116': 'CREDIT',
  '358117': 'CREDIT',
  '358118': 'CREDIT',
  '358119': 'CREDIT',
  '358120': 'CREDIT',
  '358121': 'CREDIT',
  '358122': 'CREDIT',
  '358123': 'CREDIT',
  '358124': 'CREDIT',
  '358125': 'CREDIT',
  '358126': 'CREDIT',
  '358127': 'CREDIT',
  '358128': 'CREDIT',
  '358129': 'CREDIT',
  '358130': 'CREDIT',
  '358131': 'CREDIT',
  '358132': 'CREDIT',
  '358133': 'CREDIT',
  '358134': 'CREDIT',
  '358135': 'CREDIT',
  '358136': 'CREDIT',
  '358137': 'CREDIT',
  '358138': 'CREDIT',
  '358139': 'CREDIT',
  '358140': 'CREDIT',
  '358141': 'CREDIT',
  '358142': 'CREDIT',
  '358143': 'CREDIT',
  '358144': 'CREDIT',
  '358145': 'CREDIT',
  '358146': 'CREDIT',
  '358147': 'CREDIT',
  '358148': 'CREDIT',
  '358149': 'CREDIT',
  '358150': 'CREDIT',
  '358151': 'CREDIT',
  '358152': 'CREDIT',
  '358153': 'CREDIT',
  '358154': 'CREDIT',
  '358155': 'CREDIT',
  '358156': 'CREDIT',
  '358157': 'CREDIT',
  '358158': 'CREDIT',
  '358159': 'CREDIT',
  '358160': 'CREDIT',
  '358161': 'CREDIT',
  '358162': 'CREDIT',
  '358163': 'CREDIT',
  '358164': 'CREDIT',
  '358165': 'CREDIT',
  '358166': 'CREDIT',
  '358167': 'CREDIT',
  '358168': 'CREDIT',
  '358169': 'CREDIT',
  '358170': 'CREDIT',
  '358171': 'CREDIT',
  '358172': 'CREDIT',
  '358173': 'CREDIT',
  '358174': 'CREDIT',
  '358175': 'CREDIT',
  '358176': 'CREDIT',
  '358177': 'CREDIT',
  '358178': 'CREDIT',
  '358179': 'CREDIT',
  '358180': 'CREDIT',
  '358181': 'CREDIT',
  '358182': 'CREDIT',
  '358183': 'CREDIT',
  '358184': 'CREDIT',
  '358185': 'CREDIT',
  '358186': 'CREDIT',
  '358187': 'CREDIT',
  '358188': 'CREDIT',
  '358189': 'CREDIT',
  '358190': 'CREDIT',
  '358191': 'CREDIT',
  '358192': 'CREDIT',
  '358193': 'CREDIT',
  '358194': 'CREDIT',
  '358195': 'CREDIT',
  '358196': 'CREDIT',
  '358197': 'CREDIT',
  '358198': 'CREDIT',
  '358199': 'CREDIT',
  '358200': 'CREDIT',
  '358201': 'CREDIT',
  '358202': 'CREDIT',
  '358203': 'CREDIT',
  '358204': 'CREDIT',
  '358205': 'CREDIT',
  '358206': 'CREDIT',
  '358207': 'CREDIT',
  '358208': 'CREDIT',
  '358209': 'CREDIT',
  '358210': 'CREDIT',
  '358211': 'CREDIT',
  '358212': 'CREDIT',
  '358213': 'CREDIT',
  '358214': 'CREDIT',
  '358215': 'CREDIT',
  '358216': 'CREDIT',
  '358217': 'CREDIT',
  '358218': 'CREDIT',
  '358219': 'CREDIT',
  '358220': 'CREDIT',
  '358221': 'CREDIT',
  '358222': 'CREDIT',
  '358223': 'CREDIT',
  '358224': 'CREDIT',
  '358225': 'CREDIT',
  '358226': 'CREDIT',
  '358227': 'CREDIT',
  '358228': 'CREDIT',
  '358229': 'CREDIT',
  '358230': 'CREDIT',
  '358231': 'CREDIT',
  '358232': 'CREDIT',
  '358233': 'CREDIT',
  '358234': 'CREDIT',
  '358235': 'CREDIT',
  '358236': 'CREDIT',
  '358237': 'CREDIT',
  '358238': 'CREDIT',
  '358239': 'CREDIT',
  '358240': 'CREDIT',
  '358241': 'CREDIT',
  '358242': 'CREDIT',
  '358243': 'CREDIT',
  '358244': 'CREDIT',
  '358245': 'CREDIT',
  '358246': 'CREDIT',
  '358247': 'CREDIT',
  '358248': 'CREDIT',
  '358249': 'CREDIT',
  '358250': 'CREDIT',
  '358251': 'CREDIT',
  '358252': 'CREDIT',
  '358253': 'CREDIT',
  '358254': 'CREDIT',
  '358255': 'CREDIT',
  '358256': 'CREDIT',
  '358257': 'CREDIT',
  '358258': 'CREDIT',
  '358259': 'CREDIT',
  '358260': 'CREDIT',
  '358261': 'CREDIT',
  '358262': 'CREDIT',
  '358263': 'CREDIT',
  '358264': 'CREDIT',
  '358265': 'CREDIT',
  '358266': 'CREDIT',
  '358267': 'CREDIT',
  '358268': 'CREDIT',
  '358269': 'CREDIT',
  '358270': 'CREDIT',
  '358271': 'CREDIT',
  '358272': 'CREDIT',
  '358273': 'CREDIT',
  '358274': 'CREDIT',
  '358275': 'CREDIT',
  '358276': 'CREDIT',
  '358277': 'CREDIT',
  '358278': 'CREDIT',
  '358279': 'CREDIT',
  '358280': 'CREDIT',
  '358281': 'CREDIT',
  '358282': 'CREDIT',
  '358283': 'CREDIT',
  '358284': 'CREDIT',
  '358285': 'CREDIT',
  '358286': 'CREDIT',
  '358287': 'CREDIT',
  '358288': 'CREDIT',
  '358289': 'CREDIT',
  '358290': 'CREDIT',
  '358291': 'CREDIT',
  '358292': 'CREDIT',
  '358293': 'CREDIT',
  '358294': 'CREDIT',
  '358295': 'CREDIT',
  '358296': 'CREDIT',
  '358297': 'CREDIT',
  '358298': 'CREDIT',
  '358299': 'CREDIT',
  '358300': 'CREDIT',
  '358301': 'CREDIT',
  '358302': 'CREDIT',
  '358303': 'CREDIT',
  '358304': 'CREDIT',
  '358305': 'CREDIT',
  '358306': 'CREDIT',
  '358307': 'CREDIT',
  '358308': 'CREDIT',
  '358309': 'CREDIT',
  '358310': 'CREDIT',
  '358311': 'CREDIT',
  '358312': 'CREDIT',
  '358313': 'CREDIT',
  '358314': 'CREDIT',
  '358315': 'CREDIT',
  '358316': 'CREDIT',
  '358317': 'CREDIT',
  '358318': 'CREDIT',
  '358319': 'CREDIT',
  '358320': 'CREDIT',
  '358321': 'CREDIT',
  '358322': 'CREDIT',
  '358323': 'CREDIT',
  '358324': 'CREDIT',
  '358325': 'CREDIT',
  '358326': 'CREDIT',
  '358327': 'CREDIT',
  '358328': 'CREDIT',
  '358329': 'CREDIT',
  '358330': 'CREDIT',
  '358331': 'CREDIT',
  '358332': 'CREDIT',
  '358333': 'CREDIT',
  '358334': 'CREDIT',
  '358335': 'CREDIT',
  '358336': 'CREDIT',
  '358337': 'CREDIT',
  '358338': 'CREDIT',
  '358339': 'CREDIT',
  '358340': 'CREDIT',
  '358341': 'CREDIT',
  '358342': 'CREDIT',
  '358343': 'CREDIT',
  '358344': 'CREDIT',
  '358345': 'CREDIT',
  '358346': 'CREDIT',
  '358347': 'CREDIT',
  '358348': 'CREDIT',
  '358349': 'CREDIT',
  '358350': 'CREDIT',
  '358351': 'CREDIT',
  '358352': 'CREDIT',
  '358353': 'CREDIT',
  '358354': 'CREDIT',
  '358355': 'CREDIT',
  '358356': 'CREDIT',
  '358357': 'CREDIT',
  '358358': 'CREDIT',
  '358359': 'CREDIT',
  '358360': 'CREDIT',
  '358361': 'CREDIT',
  '358362': 'CREDIT',
  '358363': 'CREDIT',
  '358364': 'CREDIT',
  '358365': 'CREDIT',
  '358366': 'CREDIT',
  '358367': 'CREDIT',
  '358368': 'CREDIT',
  '358369': 'CREDIT',
  '358370': 'CREDIT',
  '358371': 'CREDIT',
  '358372': 'CREDIT',
  '358373': 'CREDIT',
  '358374': 'CREDIT',
  '358375': 'CREDIT',
  '358376': 'CREDIT',
  '358377': 'CREDIT',
  '358378': 'CREDIT',
  '358379': 'CREDIT',
  '358380': 'CREDIT',
  '358381': 'CREDIT',
  '358382': 'CREDIT',
  '358383': 'CREDIT',
  '358384': 'CREDIT',
  '358385': 'CREDIT',
  '358386': 'CREDIT',
  '358387': 'CREDIT',
  '358388': 'CREDIT',
  '358389': 'CREDIT',
  '358390': 'CREDIT',
  '358391': 'CREDIT',
  '358392': 'CREDIT',
  '358393': 'CREDIT',
  '358394': 'CREDIT',
  '358395': 'CREDIT',
  '358396': 'CREDIT',
  '358397': 'CREDIT',
  '358398': 'CREDIT',
  '358399': 'CREDIT',
  '358400': 'CREDIT',
  '358401': 'CREDIT',
  '358402': 'CREDIT',
  '358403': 'CREDIT',
  '358404': 'CREDIT',
  '358405': 'CREDIT',
  '358406': 'CREDIT',
  '358407': 'CREDIT',
  '358408': 'CREDIT',
  '358409': 'CREDIT',
  '358410': 'CREDIT',
  '358411': 'CREDIT',
  '358412': 'CREDIT',
  '358413': 'CREDIT',
  '358414': 'CREDIT',
  '358415': 'CREDIT',
  '358416': 'CREDIT',
  '358417': 'CREDIT',
  '358418': 'CREDIT',
  '358419': 'CREDIT',
  '358420': 'CREDIT',
  '358421': 'CREDIT',
  '358422': 'CREDIT',
  '358423': 'CREDIT',
  '358424': 'CREDIT',
  '358425': 'CREDIT',
  '358426': 'CREDIT',
  '358427': 'CREDIT',
  '358428': 'CREDIT',
  '358429': 'CREDIT',
  '358430': 'CREDIT',
  '358431': 'CREDIT',
  '358432': 'CREDIT',
  '358433': 'CREDIT',
  '358434': 'CREDIT',
  '358435': 'CREDIT',
  '358436': 'CREDIT',
  '358437': 'CREDIT',
  '358438': 'CREDIT',
  '358439': 'CREDIT',
  '358440': 'CREDIT',
  '358441': 'CREDIT',
  '358442': 'CREDIT',
  '358443': 'CREDIT',
  '358444': 'CREDIT',
  '358445': 'CREDIT',
  '358446': 'CREDIT',
  '358447': 'CREDIT',
  '358448': 'CREDIT',
  '358449': 'CREDIT',
  '358450': 'CREDIT',
  '358451': 'CREDIT',
  '358452': 'CREDIT',
  '358453': 'CREDIT',
  '358454': 'CREDIT',
  '358455': 'CREDIT',
  '358456': 'CREDIT',
  '358457': 'CREDIT',
  '358458': 'CREDIT',
  '358459': 'CREDIT',
  '358460': 'CREDIT',
  '358461': 'CREDIT',
  '358462': 'CREDIT',
  '358463': 'CREDIT',
  '358464': 'CREDIT',
  '358465': 'CREDIT',
  '358466': 'CREDIT',
  '358467': 'CREDIT',
  '358468': 'CREDIT',
  '358469': 'CREDIT',
  '358470': 'CREDIT',
  '358471': 'CREDIT',
  '358472': 'CREDIT',
  '358473': 'CREDIT',
  '358474': 'CREDIT',
  '358475': 'CREDIT',
  '358476': 'CREDIT',
  '358477': 'CREDIT',
  '358478': 'CREDIT',
  '358479': 'CREDIT',
  '358480': 'CREDIT',
  '358481': 'CREDIT',
  '358482': 'CREDIT',
  '358483': 'CREDIT',
  '358484': 'CREDIT',
  '358485': 'CREDIT',
  '358486': 'CREDIT',
  '358487': 'CREDIT',
  '358488': 'CREDIT',
  '358489': 'CREDIT',
  '358490': 'CREDIT',
  '358491': 'CREDIT',
  '358492': 'CREDIT',
  '358493': 'CREDIT',
  '358494': 'CREDIT',
  '358495': 'CREDIT',
  '358496': 'CREDIT',
  '358497': 'CREDIT',
  '358498': 'CREDIT',
  '358499': 'CREDIT',
  '358500': 'CREDIT',
  '358501': 'CREDIT',
  '358502': 'CREDIT',
  '358503': 'CREDIT',
  '358504': 'CREDIT',
  '358505': 'CREDIT',
  '358506': 'CREDIT',
  '358507': 'CREDIT',
  '358508': 'CREDIT',
  '358509': 'CREDIT',
  '358510': 'CREDIT',
  '358511': 'CREDIT',
  '358512': 'CREDIT',
  '358513': 'CREDIT',
  '358514': 'CREDIT',
  '358515': 'CREDIT',
  '358516': 'CREDIT',
  '358517': 'CREDIT',
  '358518': 'CREDIT',
  '358519': 'CREDIT',
  '358520': 'CREDIT',
  '358521': 'CREDIT',
  '358522': 'CREDIT',
  '358523': 'CREDIT',
  '358524': 'CREDIT',
  '358525': 'CREDIT',
  '358526': 'CREDIT',
  '358527': 'CREDIT',
  '358528': 'CREDIT',
  '358529': 'CREDIT',
  '358530': 'CREDIT',
  '358531': 'CREDIT',
  '358532': 'CREDIT',
  '358533': 'CREDIT',
  '358534': 'CREDIT',
  '358535': 'CREDIT',
  '358536': 'CREDIT',
  '358537': 'CREDIT',
  '358538': 'CREDIT',
  '358539': 'CREDIT',
  '358540': 'CREDIT',
  '358541': 'CREDIT',
  '358542': 'CREDIT',
  '358543': 'CREDIT',
  '358544': 'CREDIT',
  '358545': 'CREDIT',
  '358546': 'CREDIT',
  '358547': 'CREDIT',
  '358548': 'CREDIT',
  '358549': 'CREDIT',
  '358550': 'CREDIT',
  '358551': 'CREDIT',
  '358552': 'CREDIT',
  '358553': 'CREDIT',
  '358554': 'CREDIT',
  '358555': 'CREDIT',
  '358556': 'CREDIT',
  '358557': 'CREDIT',
  '358558': 'CREDIT',
  '358559': 'CREDIT',
  '358560': 'CREDIT',
  '358561': 'CREDIT',
  '358562': 'CREDIT',
  '358563': 'CREDIT',
  '358564': 'CREDIT',
  '358565': 'CREDIT',
  '358566': 'CREDIT',
  '358567': 'CREDIT',
  '358568': 'CREDIT',
  '358569': 'CREDIT',
  '358570': 'CREDIT',
  '358571': 'CREDIT',
  '358572': 'CREDIT',
  '358573': 'CREDIT',
  '358574': 'CREDIT',
  '358575': 'CREDIT',
  '358576': 'CREDIT',
  '358577': 'CREDIT',
  '358578': 'CREDIT',
  '358579': 'CREDIT',
  '358580': 'CREDIT',
  '358581': 'CREDIT',
  '358582': 'CREDIT',
  '358583': 'CREDIT',
  '358584': 'CREDIT',
  '358585': 'CREDIT',
  '358586': 'CREDIT',
  '358587': 'CREDIT',
  '358588': 'CREDIT',
  '358589': 'CREDIT',
  '358590': 'CREDIT',
  '358591': 'CREDIT',
  '358592': 'CREDIT',
  '358593': 'CREDIT',
  '358594': 'CREDIT',
  '358595': 'CREDIT',
  '358596': 'CREDIT',
  '358597': 'CREDIT',
  '358598': 'CREDIT',
  '358599': 'CREDIT',
  '358600': 'CREDIT',
  '358601': 'CREDIT',
  '358602': 'CREDIT',
  '358603': 'CREDIT',
  '358604': 'CREDIT',
  '358605': 'CREDIT',
  '358606': 'CREDIT',
  '358607': 'CREDIT',
  '358608': 'CREDIT',
  '358609': 'CREDIT',
  '358610': 'CREDIT',
  '358611': 'CREDIT',
  '358612': 'CREDIT',
  '358613': 'CREDIT',
  '358614': 'CREDIT',
  '358615': 'CREDIT',
  '358616': 'CREDIT',
  '358617': 'CREDIT',
  '358618': 'CREDIT',
  '358619': 'CREDIT',
  '358620': 'CREDIT',
  '358621': 'CREDIT',
  '358622': 'CREDIT',
  '358623': 'CREDIT',
  '358624': 'CREDIT',
  '358625': 'CREDIT',
  '358626': 'CREDIT',
  '358627': 'CREDIT',
  '358628': 'CREDIT',
  '358629': 'CREDIT',
  '358630': 'CREDIT',
  '358631': 'CREDIT',
  '358632': 'CREDIT',
  '358633': 'CREDIT',
  '358634': 'CREDIT',
  '358635': 'CREDIT',
  '358636': 'CREDIT',
  '358637': 'CREDIT',
  '358638': 'CREDIT',
  '358639': 'CREDIT',
  '358640': 'CREDIT',
  '358641': 'CREDIT',
  '358642': 'CREDIT',
  '358643': 'CREDIT',
  '358644': 'CREDIT',
  '358645': 'CREDIT',
  '358646': 'CREDIT',
  '358647': 'CREDIT',
  '358648': 'CREDIT',
  '358649': 'CREDIT',
  '358650': 'CREDIT',
  '358651': 'CREDIT',
  '358652': 'CREDIT',
  '358653': 'CREDIT',
  '358654': 'CREDIT',
  '358655': 'CREDIT',
  '358656': 'CREDIT',
  '358657': 'CREDIT',
  '358658': 'CREDIT',
  '358659': 'CREDIT',
  '358660': 'CREDIT',
  '358661': 'CREDIT',
  '358662': 'CREDIT',
  '358663': 'CREDIT',
  '358664': 'CREDIT',
  '358665': 'CREDIT',
  '358666': 'CREDIT',
  '358667': 'CREDIT',
  '358668': 'CREDIT',
  '358669': 'CREDIT',
  '358670': 'CREDIT',
  '358671': 'CREDIT',
  '358672': 'CREDIT',
  '358673': 'CREDIT',
  '358674': 'CREDIT',
  '358675': 'CREDIT',
  '358676': 'CREDIT',
  '358677': 'CREDIT',
  '358678': 'CREDIT',
  '358679': 'CREDIT',
  '358680': 'CREDIT',
  '358681': 'CREDIT',
  '358682': 'CREDIT',
  '358683': 'CREDIT',
  '358684': 'CREDIT',
  '358685': 'CREDIT',
  '358686': 'CREDIT',
  '358687': 'CREDIT',
  '358688': 'CREDIT',
  '358689': 'CREDIT',
  '358690': 'CREDIT',
  '358691': 'CREDIT',
  '358692': 'CREDIT',
  '358693': 'CREDIT',
  '358694': 'CREDIT',
  '358695': 'CREDIT',
  '358696': 'CREDIT',
  '358697': 'CREDIT',
  '358698': 'CREDIT',
  '358699': 'CREDIT',
  '358700': 'CREDIT',
  '358701': 'CREDIT',
  '358702': 'CREDIT',
  '358703': 'CREDIT',
  '358704': 'CREDIT',
  '358705': 'CREDIT',
  '358706': 'CREDIT',
  '358707': 'CREDIT',
  '358708': 'CREDIT',
  '358709': 'CREDIT',
  '358710': 'CREDIT',
  '358711': 'CREDIT',
  '358712': 'CREDIT',
  '358713': 'CREDIT',
  '358714': 'CREDIT',
  '358715': 'CREDIT',
  '358716': 'CREDIT',
  '358717': 'CREDIT',
  '358718': 'CREDIT',
  '358719': 'CREDIT',
  '358720': 'CREDIT',
  '358721': 'CREDIT',
  '358722': 'CREDIT',
  '358723': 'CREDIT',
  '358724': 'CREDIT',
  '358725': 'CREDIT',
  '358726': 'CREDIT',
  '358727': 'CREDIT',
  '358728': 'CREDIT',
  '358729': 'CREDIT',
  '358730': 'CREDIT',
  '358731': 'CREDIT',
  '358732': 'CREDIT',
  '358733': 'CREDIT',
  '358734': 'CREDIT',
  '358735': 'CREDIT',
  '358736': 'CREDIT',
  '358737': 'CREDIT',
  '358738': 'CREDIT',
  '358739': 'CREDIT',
  '358740': 'CREDIT',
  '358741': 'CREDIT',
  '358742': 'CREDIT',
  '358743': 'CREDIT',
  '358744': 'CREDIT',
  '358745': 'CREDIT',
  '358746': 'CREDIT',
  '358747': 'CREDIT',
  '358748': 'CREDIT',
  '358749': 'CREDIT',
  '358750': 'CREDIT',
  '358751': 'CREDIT',
  '358752': 'CREDIT',
  '358753': 'CREDIT',
  '358754': 'CREDIT',
  '358755': 'CREDIT',
  '358756': 'CREDIT',
  '358757': 'CREDIT',
  '358758': 'CREDIT',
  '358759': 'CREDIT',
  '358760': 'CREDIT',
  '358761': 'CREDIT',
  '358762': 'CREDIT',
  '358763': 'CREDIT',
  '358764': 'CREDIT',
  '358765': 'CREDIT',
  '358766': 'CREDIT',
  '358767': 'CREDIT',
  '358768': 'CREDIT',
  '358769': 'CREDIT',
  '358770': 'CREDIT',
  '358771': 'CREDIT',
  '358772': 'CREDIT',
  '358773': 'CREDIT',
  '358774': 'CREDIT',
  '358775': 'CREDIT',
  '358776': 'CREDIT',
  '358777': 'CREDIT',
  '358778': 'CREDIT',
  '358779': 'CREDIT',
  '358780': 'CREDIT',
  '358781': 'CREDIT',
  '358782': 'CREDIT',
  '358783': 'CREDIT',
  '358784': 'CREDIT',
  '358785': 'CREDIT',
  '358786': 'CREDIT',
  '358787': 'CREDIT',
  '358788': 'CREDIT',
  '358789': 'CREDIT',
  '358790': 'CREDIT',
  '358791': 'CREDIT',
  '358792': 'CREDIT',
  '358793': 'CREDIT',
  '358794': 'CREDIT',
  '358795': 'CREDIT',
  '358796': 'CREDIT',
  '358797': 'CREDIT',
  '358798': 'CREDIT',
  '358799': 'CREDIT',
  '358800': 'CREDIT',
  '358801': 'CREDIT',
  '358802': 'CREDIT',
  '358803': 'CREDIT',
  '358804': 'CREDIT',
  '358805': 'CREDIT',
  '358806': 'CREDIT',
  '358807': 'CREDIT',
  '358808': 'CREDIT',
  '358809': 'CREDIT',
  '358810': 'CREDIT',
  '358811': 'CREDIT',
  '358812': 'CREDIT',
  '358813': 'CREDIT',
  '358814': 'CREDIT',
  '358815': 'CREDIT',
  '358816': 'CREDIT',
  '358817': 'CREDIT',
  '358818': 'CREDIT',
  '358819': 'CREDIT',
  '358820': 'CREDIT',
  '358821': 'CREDIT',
  '358822': 'CREDIT',
  '358823': 'CREDIT',
  '358824': 'CREDIT',
  '358825': 'CREDIT',
  '358826': 'CREDIT',
  '358827': 'CREDIT',
  '358828': 'CREDIT',
  '358829': 'CREDIT',
  '358830': 'CREDIT',
  '358831': 'CREDIT',
  '358832': 'CREDIT',
  '358833': 'CREDIT',
  '358834': 'CREDIT',
  '358835': 'CREDIT',
  '358836': 'CREDIT',
  '358837': 'CREDIT',
  '358838': 'CREDIT',
  '358839': 'CREDIT',
  '358840': 'CREDIT',
  '358841': 'CREDIT',
  '358842': 'CREDIT',
  '358843': 'CREDIT',
  '358844': 'CREDIT',
  '358845': 'CREDIT',
  '358846': 'CREDIT',
  '358847': 'CREDIT',
  '358848': 'CREDIT',
  '358849': 'CREDIT',
  '358850': 'CREDIT',
  '358851': 'CREDIT',
  '358852': 'CREDIT',
  '358853': 'CREDIT',
  '358854': 'CREDIT',
  '358855': 'CREDIT',
  '358856': 'CREDIT',
  '358857': 'CREDIT',
  '358858': 'CREDIT',
  '358859': 'CREDIT',
  '358860': 'CREDIT',
  '358861': 'CREDIT',
  '358862': 'CREDIT',
  '358863': 'CREDIT',
  '358864': 'CREDIT',
  '358865': 'CREDIT',
  '358866': 'CREDIT',
  '358867': 'CREDIT',
  '358868': 'CREDIT',
  '358869': 'CREDIT',
  '358870': 'CREDIT',
  '358871': 'CREDIT',
  '358872': 'CREDIT',
  '358873': 'CREDIT',
  '358874': 'CREDIT',
  '358875': 'CREDIT',
  '358876': 'CREDIT',
  '358877': 'CREDIT',
  '358878': 'CREDIT',
  '358879': 'CREDIT',
  '358880': 'CREDIT',
  '358881': 'CREDIT',
  '358882': 'CREDIT',
  '358883': 'CREDIT',
  '358884': 'CREDIT',
  '358885': 'CREDIT',
  '358886': 'CREDIT',
  '358887': 'CREDIT',
  '358888': 'CREDIT',
  '358889': 'CREDIT',
  '358890': 'CREDIT',
  '358891': 'CREDIT',
  '358892': 'CREDIT',
  '358893': 'CREDIT',
  '358894': 'CREDIT',
  '358895': 'CREDIT',
  '358896': 'CREDIT',
  '358897': 'CREDIT',
  '358898': 'CREDIT',
  '358899': 'CREDIT',
  '358900': 'CREDIT',
  '358901': 'CREDIT',
  '358902': 'CREDIT',
  '358903': 'CREDIT',
  '358904': 'CREDIT',
  '358905': 'CREDIT',
  '358906': 'CREDIT',
  '358907': 'CREDIT',
  '358908': 'CREDIT',
  '358909': 'CREDIT',
  '358910': 'CREDIT',
  '358911': 'CREDIT',
  '358912': 'CREDIT',
  '358913': 'CREDIT',
  '358914': 'CREDIT',
  '358915': 'CREDIT',
  '358916': 'CREDIT',
  '358917': 'CREDIT',
  '358918': 'CREDIT',
  '358919': 'CREDIT',
  '358920': 'CREDIT',
  '358921': 'CREDIT',
  '358922': 'CREDIT',
  '358923': 'CREDIT',
  '358924': 'CREDIT',
  '358925': 'CREDIT',
  '358926': 'CREDIT',
  '358927': 'CREDIT',
  '358928': 'CREDIT',
  '358929': 'CREDIT',
  '358930': 'CREDIT',
  '358931': 'CREDIT',
  '358932': 'CREDIT',
  '358933': 'CREDIT',
  '358934': 'CREDIT',
  '358935': 'CREDIT',
  '358936': 'CREDIT',
  '358937': 'CREDIT',
  '358938': 'CREDIT',
  '358939': 'CREDIT',
  '358940': 'CREDIT',
  '358941': 'CREDIT',
  '358942': 'CREDIT',
  '358943': 'CREDIT',
  '358944': 'CREDIT',
  '358945': 'CREDIT',
  '358946': 'CREDIT',
  '358947': 'CREDIT',
  '358948': 'CREDIT',
  '358949': 'CREDIT',
  '358950': 'CREDIT',
  '358951': 'CREDIT',
  '358952': 'CREDIT',
  '358953': 'CREDIT',
  '358954': 'CREDIT',
  '358955': 'CREDIT',
  '358956': 'CREDIT',
  '358957': 'CREDIT',
  '358958': 'CREDIT',
  '358959': 'CREDIT',
  '358960': 'CREDIT',
  '358961': 'CREDIT',
  '358962': 'CREDIT',
  '358963': 'CREDIT',
  '358964': 'CREDIT',
  '358965': 'CREDIT',
  '358966': 'CREDIT',
  '358967': 'CREDIT',
  '358968': 'CREDIT',
  '358969': 'CREDIT',
  '358970': 'CREDIT',
  '358971': 'CREDIT',
  '358972': 'CREDIT',
  '358973': 'CREDIT',
  '358974': 'CREDIT',
  '358975': 'CREDIT',
  '358976': 'CREDIT',
  '358977': 'CREDIT',
  '358978': 'CREDIT',
  '358979': 'CREDIT',
  '358980': 'CREDIT',
  '358981': 'CREDIT',
  '358982': 'CREDIT',
  '358983': 'CREDIT',
  '358984': 'CREDIT',
  '358985': 'CREDIT',
  '358986': 'CREDIT',
  '358987': 'CREDIT',
  '358988': 'CREDIT',
  '358989': 'CREDIT',
  '358990': 'CREDIT',
  '358991': 'CREDIT',
  '358992': 'CREDIT',
  '358993': 'CREDIT',
  '358994': 'CREDIT',
  '358995': 'CREDIT',
  '358996': 'CREDIT',
  '358997': 'CREDIT',
  '358998': 'CREDIT',
  '358999': 'CREDIT',
  '359000': 'DEBIT',
  '359663': 'CREDIT',
  '362344': 'CREDIT',
  '362437': 'CREDIT',
  '362695': 'CREDIT',
  '362739': 'CREDIT',
  '366000': 'CREDIT',
  '366001': 'CREDIT',
  '366002': 'CREDIT',
  '366003': 'CREDIT',
  '366004': 'CREDIT',
  '366005': 'CREDIT',
  '366006': 'CREDIT',
  '366007': 'CREDIT',
  '366008': 'CREDIT',
  '366009': 'CREDIT',
  '366010': 'CREDIT',
  '366011': 'CREDIT',
  '366012': 'CREDIT',
  '366013': 'CREDIT',
  '366014': 'CREDIT',
  '366015': 'CREDIT',
  '366016': 'CREDIT',
  '366017': 'CREDIT',
  '366018': 'CREDIT',
  '366019': 'CREDIT',
  '366020': 'CREDIT',
  '366021': 'CREDIT',
  '366022': 'CREDIT',
  '366023': 'CREDIT',
  '366024': 'CREDIT',
  '366025': 'CREDIT',
  '366026': 'CREDIT',
  '366027': 'CREDIT',
  '366028': 'CREDIT',
  '366029': 'CREDIT',
  '366030': 'CREDIT',
  '366031': 'CREDIT',
  '366032': 'CREDIT',
  '366033': 'CREDIT',
  '366034': 'CREDIT',
  '366035': 'CREDIT',
  '366036': 'CREDIT',
  '366037': 'CREDIT',
  '366038': 'CREDIT',
  '366039': 'CREDIT',
  '366040': 'CREDIT',
  '366041': 'CREDIT',
  '366042': 'CREDIT',
  '366043': 'CREDIT',
  '366044': 'CREDIT',
  '366045': 'CREDIT',
  '366046': 'CREDIT',
  '366047': 'CREDIT',
  '366048': 'CREDIT',
  '366049': 'CREDIT',
  '366050': 'CREDIT',
  '366051': 'CREDIT',
  '366052': 'CREDIT',
  '366053': 'CREDIT',
  '366054': 'CREDIT',
  '366055': 'CREDIT',
  '366056': 'CREDIT',
  '366057': 'CREDIT',
  '366058': 'CREDIT',
  '366059': 'CREDIT',
  '366060': 'CREDIT',
  '366061': 'CREDIT',
  '366062': 'CREDIT',
  '366063': 'CREDIT',
  '366064': 'CREDIT',
  '366065': 'CREDIT',
  '366066': 'CREDIT',
  '366067': 'CREDIT',
  '366068': 'CREDIT',
  '366069': 'CREDIT',
  '366070': 'CREDIT',
  '366071': 'CREDIT',
  '366072': 'CREDIT',
  '366073': 'CREDIT',
  '366074': 'CREDIT',
  '366075': 'CREDIT',
  '366076': 'CREDIT',
  '366077': 'CREDIT',
  '366078': 'CREDIT',
  '366079': 'CREDIT',
  '366080': 'CREDIT',
  '366081': 'CREDIT',
  '366082': 'CREDIT',
  '366083': 'CREDIT',
  '366084': 'CREDIT',
  '366085': 'CREDIT',
  '366086': 'CREDIT',
  '366087': 'CREDIT',
  '366088': 'CREDIT',
  '366089': 'CREDIT',
  '366090': 'CREDIT',
  '366091': 'CREDIT',
  '366092': 'CREDIT',
  '366093': 'CREDIT',
  '366094': 'CREDIT',
  '366095': 'CREDIT',
  '366096': 'CREDIT',
  '366097': 'CREDIT',
  '366098': 'CREDIT',
  '366099': 'CREDIT',
  '366100': 'CREDIT',
  '366101': 'CREDIT',
  '366102': 'CREDIT',
  '366103': 'CREDIT',
  '366104': 'CREDIT',
  '366105': 'CREDIT',
  '366106': 'CREDIT',
  '366107': 'CREDIT',
  '366108': 'CREDIT',
  '366109': 'CREDIT',
  '366110': 'CREDIT',
  '366111': 'CREDIT',
  '366112': 'CREDIT',
  '366113': 'CREDIT',
  '366114': 'CREDIT',
  '366115': 'CREDIT',
  '366116': 'CREDIT',
  '366117': 'CREDIT',
  '366118': 'CREDIT',
  '366119': 'CREDIT',
  '366120': 'CREDIT',
  '366121': 'CREDIT',
  '366122': 'CREDIT',
  '366123': 'CREDIT',
  '366124': 'CREDIT',
  '366125': 'CREDIT',
  '366126': 'CREDIT',
  '366127': 'CREDIT',
  '366128': 'CREDIT',
  '366129': 'CREDIT',
  '366130': 'CREDIT',
  '366131': 'CREDIT',
  '366132': 'CREDIT',
  '366133': 'CREDIT',
  '366134': 'CREDIT',
  '366135': 'CREDIT',
  '366136': 'CREDIT',
  '366137': 'CREDIT',
  '366138': 'CREDIT',
  '366139': 'CREDIT',
  '366140': 'CREDIT',
  '366141': 'CREDIT',
  '366142': 'CREDIT',
  '366143': 'CREDIT',
  '366144': 'CREDIT',
  '366145': 'CREDIT',
  '366146': 'CREDIT',
  '366147': 'CREDIT',
  '366148': 'CREDIT',
  '366149': 'CREDIT',
  '366150': 'CREDIT',
  '366151': 'CREDIT',
  '366152': 'CREDIT',
  '366153': 'CREDIT',
  '366154': 'CREDIT',
  '366155': 'CREDIT',
  '366156': 'CREDIT',
  '366157': 'CREDIT',
  '366158': 'CREDIT',
  '366159': 'CREDIT',
  '366160': 'CREDIT',
  '366161': 'CREDIT',
  '366162': 'CREDIT',
  '366163': 'CREDIT',
  '366164': 'CREDIT',
  '366165': 'CREDIT',
  '366166': 'CREDIT',
  '366167': 'CREDIT',
  '366168': 'CREDIT',
  '366169': 'CREDIT',
  '366170': 'CREDIT',
  '366171': 'CREDIT',
  '366172': 'CREDIT',
  '366173': 'CREDIT',
  '366174': 'CREDIT',
  '366175': 'CREDIT',
  '366176': 'CREDIT',
  '366177': 'CREDIT',
  '366178': 'CREDIT',
  '366179': 'CREDIT',
  '366180': 'CREDIT',
  '366181': 'CREDIT',
  '366182': 'CREDIT',
  '366183': 'CREDIT',
  '366184': 'CREDIT',
  '366185': 'CREDIT',
  '366186': 'CREDIT',
  '366187': 'CREDIT',
  '366188': 'CREDIT',
  '366189': 'CREDIT',
  '366190': 'CREDIT',
  '366191': 'CREDIT',
  '366192': 'CREDIT',
  '366193': 'CREDIT',
  '366194': 'CREDIT',
  '366195': 'CREDIT',
  '366196': 'CREDIT',
  '366197': 'CREDIT',
  '366198': 'CREDIT',
  '366199': 'CREDIT',
  '366200': 'CREDIT',
  '366201': 'CREDIT',
  '366202': 'CREDIT',
  '366203': 'CREDIT',
  '366204': 'CREDIT',
  '366205': 'CREDIT',
  '366206': 'CREDIT',
  '366207': 'CREDIT',
  '366208': 'CREDIT',
  '366209': 'CREDIT',
  '366210': 'CREDIT',
  '366211': 'CREDIT',
  '366212': 'CREDIT',
  '366213': 'CREDIT',
  '366214': 'CREDIT',
  '366215': 'CREDIT',
  '366216': 'CREDIT',
  '366217': 'CREDIT',
  '366218': 'CREDIT',
  '366219': 'CREDIT',
  '366220': 'CREDIT',
  '366221': 'CREDIT',
  '366222': 'CREDIT',
  '366223': 'CREDIT',
  '366224': 'CREDIT',
  '366225': 'CREDIT',
  '366226': 'CREDIT',
  '366227': 'CREDIT',
  '366228': 'CREDIT',
  '366229': 'CREDIT',
  '366230': 'CREDIT',
  '366231': 'CREDIT',
  '366232': 'CREDIT',
  '366233': 'CREDIT',
  '366234': 'CREDIT',
  '366235': 'CREDIT',
  '366236': 'CREDIT',
  '366237': 'CREDIT',
  '366238': 'CREDIT',
  '366239': 'CREDIT',
  '366240': 'CREDIT',
  '366241': 'CREDIT',
  '366242': 'CREDIT',
  '366243': 'CREDIT',
  '366244': 'CREDIT',
  '366245': 'CREDIT',
  '366246': 'CREDIT',
  '366247': 'CREDIT',
  '366248': 'CREDIT',
  '366249': 'CREDIT',
  '366250': 'CREDIT',
  '366251': 'CREDIT',
  '366252': 'CREDIT',
  '366253': 'CREDIT',
  '366254': 'CREDIT',
  '366255': 'CREDIT',
  '366256': 'CREDIT',
  '366257': 'CREDIT',
  '366258': 'CREDIT',
  '366259': 'CREDIT',
  '366260': 'CREDIT',
  '366261': 'CREDIT',
  '366262': 'CREDIT',
  '366263': 'CREDIT',
  '366264': 'CREDIT',
  '366265': 'CREDIT',
  '366266': 'CREDIT',
  '366267': 'CREDIT',
  '366268': 'CREDIT',
  '366269': 'CREDIT',
  '366270': 'CREDIT',
  '366271': 'CREDIT',
  '366272': 'CREDIT',
  '366273': 'CREDIT',
  '366274': 'CREDIT',
  '366275': 'CREDIT',
  '366276': 'CREDIT',
  '366277': 'CREDIT',
  '366278': 'CREDIT',
  '366279': 'CREDIT',
  '366280': 'CREDIT',
  '366281': 'CREDIT',
  '366282': 'CREDIT',
  '366283': 'CREDIT',
  '366284': 'CREDIT',
  '366285': 'CREDIT',
  '366286': 'CREDIT',
  '366287': 'CREDIT',
  '366288': 'CREDIT',
  '366289': 'CREDIT',
  '366290': 'CREDIT',
  '366291': 'CREDIT',
  '366292': 'CREDIT',
  '366293': 'CREDIT',
  '366294': 'CREDIT',
  '366295': 'CREDIT',
  '366296': 'CREDIT',
  '366297': 'CREDIT',
  '366298': 'CREDIT',
  '366299': 'CREDIT',
  '366300': 'CREDIT',
  '366301': 'CREDIT',
  '366302': 'CREDIT',
  '366303': 'CREDIT',
  '366304': 'CREDIT',
  '366305': 'CREDIT',
  '366306': 'CREDIT',
  '366307': 'CREDIT',
  '366308': 'CREDIT',
  '366309': 'CREDIT',
  '366310': 'CREDIT',
  '366311': 'CREDIT',
  '366312': 'CREDIT',
  '366313': 'CREDIT',
  '366314': 'CREDIT',
  '366315': 'CREDIT',
  '366316': 'CREDIT',
  '366317': 'CREDIT',
  '366318': 'CREDIT',
  '366319': 'CREDIT',
  '366320': 'CREDIT',
  '366321': 'CREDIT',
  '366322': 'CREDIT',
  '366323': 'CREDIT',
  '366324': 'CREDIT',
  '366325': 'CREDIT',
  '366326': 'CREDIT',
  '366327': 'CREDIT',
  '366328': 'CREDIT',
  '366329': 'CREDIT',
  '366330': 'CREDIT',
  '366331': 'CREDIT',
  '366332': 'CREDIT',
  '366333': 'CREDIT',
  '366334': 'CREDIT',
  '366335': 'CREDIT',
  '366336': 'CREDIT',
  '366337': 'CREDIT',
  '366338': 'CREDIT',
  '366339': 'CREDIT',
  '366340': 'CREDIT',
  '366341': 'CREDIT',
  '366342': 'CREDIT',
  '366343': 'CREDIT',
  '366344': 'CREDIT',
  '366345': 'CREDIT',
  '366346': 'CREDIT',
  '366347': 'CREDIT',
  '366348': 'CREDIT',
  '366349': 'CREDIT',
  '366350': 'CREDIT',
  '366351': 'CREDIT',
  '366352': 'CREDIT',
  '366353': 'CREDIT',
  '366354': 'CREDIT',
  '366355': 'CREDIT',
  '366356': 'CREDIT',
  '366357': 'CREDIT',
  '366358': 'CREDIT',
  '366359': 'CREDIT',
  '366360': 'CREDIT',
  '366361': 'CREDIT',
  '366362': 'CREDIT',
  '366363': 'CREDIT',
  '366364': 'CREDIT',
  '366365': 'CREDIT',
  '366366': 'CREDIT',
  '366367': 'CREDIT',
  '366368': 'CREDIT',
  '366369': 'CREDIT',
  '366370': 'CREDIT',
  '366371': 'CREDIT',
  '366372': 'CREDIT',
  '366373': 'CREDIT',
  '366374': 'CREDIT',
  '366375': 'CREDIT',
  '366376': 'CREDIT',
  '366377': 'CREDIT',
  '366378': 'CREDIT',
  '366379': 'CREDIT',
  '366380': 'CREDIT',
  '366381': 'CREDIT',
  '366382': 'CREDIT',
  '366383': 'CREDIT',
  '366384': 'CREDIT',
  '366385': 'CREDIT',
  '366386': 'CREDIT',
  '366387': 'CREDIT',
  '366388': 'CREDIT',
  '366389': 'CREDIT',
  '366390': 'CREDIT',
  '366391': 'CREDIT',
  '366392': 'CREDIT',
  '366393': 'CREDIT',
  '366394': 'CREDIT',
  '366395': 'CREDIT',
  '366396': 'CREDIT',
  '366397': 'CREDIT',
  '366398': 'CREDIT',
  '366399': 'CREDIT',
  '366400': 'CREDIT',
  '366401': 'CREDIT',
  '366402': 'CREDIT',
  '366403': 'CREDIT',
  '366404': 'CREDIT',
  '366405': 'CREDIT',
  '366406': 'CREDIT',
  '366407': 'CREDIT',
  '366408': 'CREDIT',
  '366409': 'CREDIT',
  '366410': 'CREDIT',
  '366411': 'CREDIT',
  '366412': 'CREDIT',
  '366413': 'CREDIT',
  '366414': 'CREDIT',
  '366415': 'CREDIT',
  '366416': 'CREDIT',
  '366417': 'CREDIT',
  '366418': 'CREDIT',
  '366419': 'CREDIT',
  '366420': 'CREDIT',
  '366421': 'CREDIT',
  '366422': 'CREDIT',
  '366423': 'CREDIT',
  '366424': 'CREDIT',
  '366425': 'CREDIT',
  '366426': 'CREDIT',
  '366427': 'CREDIT',
  '366428': 'CREDIT',
  '366429': 'CREDIT',
  '366430': 'CREDIT',
  '366431': 'CREDIT',
  '366432': 'CREDIT',
  '366433': 'CREDIT',
  '366434': 'CREDIT',
  '366435': 'CREDIT',
  '366436': 'CREDIT',
  '366437': 'CREDIT',
  '366438': 'CREDIT',
  '366439': 'CREDIT',
  '366440': 'CREDIT',
  '366441': 'CREDIT',
  '366442': 'CREDIT',
  '366443': 'CREDIT',
  '366444': 'CREDIT',
  '366445': 'CREDIT',
  '366446': 'CREDIT',
  '366447': 'CREDIT',
  '366448': 'CREDIT',
  '366449': 'CREDIT',
  '366450': 'CREDIT',
  '366451': 'CREDIT',
  '366452': 'CREDIT',
  '366453': 'CREDIT',
  '366454': 'CREDIT',
  '366455': 'CREDIT',
  '366456': 'CREDIT',
  '366457': 'CREDIT',
  '366458': 'CREDIT',
  '366459': 'CREDIT',
  '366460': 'CREDIT',
  '366461': 'CREDIT',
  '366462': 'CREDIT',
  '366463': 'CREDIT',
  '366464': 'CREDIT',
  '366465': 'CREDIT',
  '366466': 'CREDIT',
  '366467': 'CREDIT',
  '366468': 'CREDIT',
  '366469': 'CREDIT',
  '366470': 'CREDIT',
  '366471': 'CREDIT',
  '366472': 'CREDIT',
  '366473': 'CREDIT',
  '366474': 'CREDIT',
  '366475': 'CREDIT',
  '366476': 'CREDIT',
  '366477': 'CREDIT',
  '366478': 'CREDIT',
  '366479': 'CREDIT',
  '366480': 'CREDIT',
  '366481': 'CREDIT',
  '366482': 'CREDIT',
  '366483': 'CREDIT',
  '366484': 'CREDIT',
  '366485': 'CREDIT',
  '366486': 'CREDIT',
  '366487': 'CREDIT',
  '366488': 'CREDIT',
  '366489': 'CREDIT',
  '366490': 'CREDIT',
  '366491': 'CREDIT',
  '366492': 'CREDIT',
  '366493': 'CREDIT',
  '366494': 'CREDIT',
  '366495': 'CREDIT',
  '366496': 'CREDIT',
  '366497': 'CREDIT',
  '366498': 'CREDIT',
  '366499': 'CREDIT',
  '366500': 'CREDIT',
  '366501': 'CREDIT',
  '366502': 'CREDIT',
  '366503': 'CREDIT',
  '366504': 'CREDIT',
  '366505': 'CREDIT',
  '366506': 'CREDIT',
  '366507': 'CREDIT',
  '366508': 'CREDIT',
  '366509': 'CREDIT',
  '366510': 'CREDIT',
  '366511': 'CREDIT',
  '366512': 'CREDIT',
  '366513': 'CREDIT',
  '366514': 'CREDIT',
  '366515': 'CREDIT',
  '366516': 'CREDIT',
  '366517': 'CREDIT',
  '366518': 'CREDIT',
  '366519': 'CREDIT',
  '366520': 'CREDIT',
  '366521': 'CREDIT',
  '366522': 'CREDIT',
  '366523': 'CREDIT',
  '366524': 'CREDIT',
  '366525': 'CREDIT',
  '366526': 'CREDIT',
  '366527': 'CREDIT',
  '366528': 'CREDIT',
  '366529': 'CREDIT',
  '366530': 'CREDIT',
  '366531': 'CREDIT',
  '366532': 'CREDIT',
  '366533': 'CREDIT',
  '366534': 'CREDIT',
  '366535': 'CREDIT',
  '366536': 'CREDIT',
  '366537': 'CREDIT',
  '366538': 'CREDIT',
  '366539': 'CREDIT',
  '366540': 'CREDIT',
  '366541': 'CREDIT',
  '366542': 'CREDIT',
  '366543': 'CREDIT',
  '366544': 'CREDIT',
  '366545': 'CREDIT',
  '366546': 'CREDIT',
  '366547': 'CREDIT',
  '366548': 'CREDIT',
  '366549': 'CREDIT',
  '366550': 'CREDIT',
  '366551': 'CREDIT',
  '366552': 'CREDIT',
  '366553': 'CREDIT',
  '366554': 'CREDIT',
  '366555': 'CREDIT',
  '366556': 'CREDIT',
  '366557': 'CREDIT',
  '366558': 'CREDIT',
  '366559': 'CREDIT',
  '366560': 'CREDIT',
  '366561': 'CREDIT',
  '366562': 'CREDIT',
  '366563': 'CREDIT',
  '366564': 'CREDIT',
  '366565': 'CREDIT',
  '366566': 'CREDIT',
  '366567': 'CREDIT',
  '366568': 'CREDIT',
  '366569': 'CREDIT',
  '366570': 'CREDIT',
  '366571': 'CREDIT',
  '366572': 'CREDIT',
  '366573': 'CREDIT',
  '366574': 'CREDIT',
  '366575': 'CREDIT',
  '366576': 'CREDIT',
  '366577': 'CREDIT',
  '366578': 'CREDIT',
  '366579': 'CREDIT',
  '366580': 'CREDIT',
  '366581': 'CREDIT',
  '366582': 'CREDIT',
  '366583': 'CREDIT',
  '366584': 'CREDIT',
  '366585': 'CREDIT',
  '366586': 'CREDIT',
  '366587': 'CREDIT',
  '366588': 'CREDIT',
  '366589': 'CREDIT',
  '366590': 'CREDIT',
  '366591': 'CREDIT',
  '366592': 'CREDIT',
  '366593': 'CREDIT',
  '366594': 'CREDIT',
  '366595': 'CREDIT',
  '366596': 'CREDIT',
  '366597': 'CREDIT',
  '366598': 'CREDIT',
  '366599': 'CREDIT',
  '366600': 'CREDIT',
  '366601': 'CREDIT',
  '366602': 'CREDIT',
  '366603': 'CREDIT',
  '366604': 'CREDIT',
  '366605': 'CREDIT',
  '366606': 'CREDIT',
  '366607': 'CREDIT',
  '366608': 'CREDIT',
  '366609': 'CREDIT',
  '366610': 'CREDIT',
  '366611': 'CREDIT',
  '366612': 'CREDIT',
  '366613': 'CREDIT',
  '366614': 'CREDIT',
  '366615': 'CREDIT',
  '366616': 'CREDIT',
  '366617': 'CREDIT',
  '366618': 'CREDIT',
  '366619': 'CREDIT',
  '366620': 'CREDIT',
  '366621': 'CREDIT',
  '366622': 'CREDIT',
  '366623': 'CREDIT',
  '366624': 'CREDIT',
  '366625': 'CREDIT',
  '366626': 'CREDIT',
  '366627': 'CREDIT',
  '366628': 'CREDIT',
  '366629': 'CREDIT',
  '366630': 'CREDIT',
  '366631': 'CREDIT',
  '366632': 'CREDIT',
  '366633': 'CREDIT',
  '366634': 'CREDIT',
  '366635': 'CREDIT',
  '366636': 'CREDIT',
  '366637': 'CREDIT',
  '366638': 'CREDIT',
  '366639': 'CREDIT',
  '366640': 'CREDIT',
  '366641': 'CREDIT',
  '366642': 'CREDIT',
  '366643': 'CREDIT',
  '366644': 'CREDIT',
  '366645': 'CREDIT',
  '366646': 'CREDIT',
  '366647': 'CREDIT',
  '366648': 'CREDIT',
  '366649': 'CREDIT',
  '366650': 'CREDIT',
  '366651': 'CREDIT',
  '366652': 'CREDIT',
  '366653': 'CREDIT',
  '366654': 'CREDIT',
  '366655': 'CREDIT',
  '366656': 'CREDIT',
  '366657': 'CREDIT',
  '366658': 'CREDIT',
  '366659': 'CREDIT',
  '366660': 'CREDIT',
  '366661': 'CREDIT',
  '366662': 'CREDIT',
  '366663': 'CREDIT',
  '366664': 'CREDIT',
  '366665': 'CREDIT',
  '366666': 'CREDIT',
  '366667': 'CREDIT',
  '366668': 'CREDIT',
  '366669': 'CREDIT',
  '366670': 'CREDIT',
  '366671': 'CREDIT',
  '366672': 'CREDIT',
  '366673': 'CREDIT',
  '366674': 'CREDIT',
  '366675': 'CREDIT',
  '366676': 'CREDIT',
  '366677': 'CREDIT',
  '366678': 'CREDIT',
  '366679': 'CREDIT',
  '366680': 'CREDIT',
  '366681': 'CREDIT',
  '366682': 'CREDIT',
  '366683': 'CREDIT',
  '366684': 'CREDIT',
  '366685': 'CREDIT',
  '366686': 'CREDIT',
  '366687': 'CREDIT',
  '366688': 'CREDIT',
  '366689': 'CREDIT',
  '366690': 'CREDIT',
  '366691': 'CREDIT',
  '366692': 'CREDIT',
  '366693': 'CREDIT',
  '366694': 'CREDIT',
  '366695': 'CREDIT',
  '366696': 'CREDIT',
  '366697': 'CREDIT',
  '366698': 'CREDIT',
  '366699': 'CREDIT',
  '366700': 'CREDIT',
  '366701': 'CREDIT',
  '366702': 'CREDIT',
  '366703': 'CREDIT',
  '366704': 'CREDIT',
  '366705': 'CREDIT',
  '366706': 'CREDIT',
  '366707': 'CREDIT',
  '366708': 'CREDIT',
  '366709': 'CREDIT',
  '366710': 'CREDIT',
  '366711': 'CREDIT',
  '366712': 'CREDIT',
  '366713': 'CREDIT',
  '366714': 'CREDIT',
  '366715': 'CREDIT',
  '366716': 'CREDIT',
  '366717': 'CREDIT',
  '366718': 'CREDIT',
  '366719': 'CREDIT',
  '366720': 'CREDIT',
  '366721': 'CREDIT',
  '366722': 'CREDIT',
  '366723': 'CREDIT',
  '366724': 'CREDIT',
  '366725': 'CREDIT',
  '366726': 'CREDIT',
  '366727': 'CREDIT',
  '366728': 'CREDIT',
  '366729': 'CREDIT',
  '366730': 'CREDIT',
  '366731': 'CREDIT',
  '366732': 'CREDIT',
  '366733': 'CREDIT',
  '366734': 'CREDIT',
  '366735': 'CREDIT',
  '366736': 'CREDIT',
  '366737': 'CREDIT',
  '366738': 'CREDIT',
  '366739': 'CREDIT',
  '366740': 'CREDIT',
  '366741': 'CREDIT',
  '366742': 'CREDIT',
  '366743': 'CREDIT',
  '366744': 'CREDIT',
  '366745': 'CREDIT',
  '366746': 'CREDIT',
  '366747': 'CREDIT',
  '366748': 'CREDIT',
  '366749': 'CREDIT',
  '366750': 'CREDIT',
  '366751': 'CREDIT',
  '366752': 'CREDIT',
  '366753': 'CREDIT',
  '366754': 'CREDIT',
  '366755': 'CREDIT',
  '366756': 'CREDIT',
  '366757': 'CREDIT',
  '366758': 'CREDIT',
  '366759': 'CREDIT',
  '366760': 'CREDIT',
  '366761': 'CREDIT',
  '366762': 'CREDIT',
  '366763': 'CREDIT',
  '366764': 'CREDIT',
  '366765': 'CREDIT',
  '366766': 'CREDIT',
  '366767': 'CREDIT',
  '366768': 'CREDIT',
  '366769': 'CREDIT',
  '366770': 'CREDIT',
  '366771': 'CREDIT',
  '366772': 'CREDIT',
  '366773': 'CREDIT',
  '366774': 'CREDIT',
  '366775': 'CREDIT',
  '366776': 'CREDIT',
  '366777': 'CREDIT',
  '366778': 'CREDIT',
  '366779': 'CREDIT',
  '366780': 'CREDIT',
  '366781': 'CREDIT',
  '366782': 'CREDIT',
  '366783': 'CREDIT',
  '366784': 'CREDIT',
  '366785': 'CREDIT',
  '366786': 'CREDIT',
  '366787': 'CREDIT',
  '366788': 'CREDIT',
  '366789': 'CREDIT',
  '366790': 'CREDIT',
  '366791': 'CREDIT',
  '366792': 'CREDIT',
  '366793': 'CREDIT',
  '366794': 'CREDIT',
  '366795': 'CREDIT',
  '366796': 'CREDIT',
  '366797': 'CREDIT',
  '366798': 'CREDIT',
  '366799': 'CREDIT',
  '366800': 'CREDIT',
  '366801': 'CREDIT',
  '366802': 'CREDIT',
  '366803': 'CREDIT',
  '366804': 'CREDIT',
  '366805': 'CREDIT',
  '366806': 'CREDIT',
  '366807': 'CREDIT',
  '366808': 'CREDIT',
  '366809': 'CREDIT',
  '366810': 'CREDIT',
  '366811': 'CREDIT',
  '366812': 'CREDIT',
  '366813': 'CREDIT',
  '366814': 'CREDIT',
  '366815': 'CREDIT',
  '366816': 'CREDIT',
  '366817': 'CREDIT',
  '366818': 'CREDIT',
  '366819': 'CREDIT',
  '366820': 'CREDIT',
  '366821': 'CREDIT',
  '366822': 'CREDIT',
  '366823': 'CREDIT',
  '366824': 'CREDIT',
  '366825': 'CREDIT',
  '366826': 'CREDIT',
  '366827': 'CREDIT',
  '366828': 'CREDIT',
  '366829': 'CREDIT',
  '366830': 'CREDIT',
  '366831': 'CREDIT',
  '366832': 'CREDIT',
  '366833': 'CREDIT',
  '366834': 'CREDIT',
  '366835': 'CREDIT',
  '366836': 'CREDIT',
  '366837': 'CREDIT',
  '366838': 'CREDIT',
  '366839': 'CREDIT',
  '366840': 'CREDIT',
  '366841': 'CREDIT',
  '366842': 'CREDIT',
  '366843': 'CREDIT',
  '366844': 'CREDIT',
  '366845': 'CREDIT',
  '366846': 'CREDIT',
  '366847': 'CREDIT',
  '366848': 'CREDIT',
  '366849': 'CREDIT',
  '366850': 'CREDIT',
  '366851': 'CREDIT',
  '366852': 'CREDIT',
  '366853': 'CREDIT',
  '366854': 'CREDIT',
  '366855': 'CREDIT',
  '366856': 'CREDIT',
  '366857': 'CREDIT',
  '366858': 'CREDIT',
  '366859': 'CREDIT',
  '366860': 'CREDIT',
  '366861': 'CREDIT',
  '366862': 'CREDIT',
  '366863': 'CREDIT',
  '366864': 'CREDIT',
  '366865': 'CREDIT',
  '366866': 'CREDIT',
  '366867': 'CREDIT',
  '366868': 'CREDIT',
  '366869': 'CREDIT',
  '366870': 'CREDIT',
  '366871': 'CREDIT',
  '366872': 'CREDIT',
  '366873': 'CREDIT',
  '366874': 'CREDIT',
  '366875': 'CREDIT',
  '366876': 'CREDIT',
  '366877': 'CREDIT',
  '366878': 'CREDIT',
  '366879': 'CREDIT',
  '366880': 'CREDIT',
  '366881': 'CREDIT',
  '366882': 'CREDIT',
  '366883': 'CREDIT',
  '366884': 'CREDIT',
  '366885': 'CREDIT',
  '366886': 'CREDIT',
  '366887': 'CREDIT',
  '366888': 'CREDIT',
  '366889': 'CREDIT',
  '366890': 'CREDIT',
  '366891': 'CREDIT',
  '366892': 'CREDIT',
  '366893': 'CREDIT',
  '366894': 'CREDIT',
  '366895': 'CREDIT',
  '366896': 'CREDIT',
  '366897': 'CREDIT',
  '366898': 'CREDIT',
  '366899': 'CREDIT',
  '366900': 'CREDIT',
  '366901': 'CREDIT',
  '366902': 'CREDIT',
  '366903': 'CREDIT',
  '366904': 'CREDIT',
  '366905': 'CREDIT',
  '366906': 'CREDIT',
  '366907': 'CREDIT',
  '366908': 'CREDIT',
  '366909': 'CREDIT',
  '366910': 'CREDIT',
  '366911': 'CREDIT',
  '366912': 'CREDIT',
  '366913': 'CREDIT',
  '366914': 'CREDIT',
  '366915': 'CREDIT',
  '366916': 'CREDIT',
  '366917': 'CREDIT',
  '366918': 'CREDIT',
  '366919': 'CREDIT',
  '366920': 'CREDIT',
  '366921': 'CREDIT',
  '366922': 'CREDIT',
  '366923': 'CREDIT',
  '366924': 'CREDIT',
  '366925': 'CREDIT',
  '366926': 'CREDIT',
  '366927': 'CREDIT',
  '366928': 'CREDIT',
  '366929': 'CREDIT',
  '366930': 'CREDIT',
  '366931': 'CREDIT',
  '366932': 'CREDIT',
  '366933': 'CREDIT',
  '366934': 'CREDIT',
  '366935': 'CREDIT',
  '366936': 'CREDIT',
  '366937': 'CREDIT',
  '366938': 'CREDIT',
  '366939': 'CREDIT',
  '366940': 'CREDIT',
  '366941': 'CREDIT',
  '366942': 'CREDIT',
  '366943': 'CREDIT',
  '366944': 'CREDIT',
  '366945': 'CREDIT',
  '366946': 'CREDIT',
  '366947': 'CREDIT',
  '366948': 'CREDIT',
  '366949': 'CREDIT',
  '366950': 'CREDIT',
  '366951': 'CREDIT',
  '366952': 'CREDIT',
  '366953': 'CREDIT',
  '366954': 'CREDIT',
  '366955': 'CREDIT',
  '366956': 'CREDIT',
  '366957': 'CREDIT',
  '366958': 'CREDIT',
  '366959': 'CREDIT',
  '366960': 'CREDIT',
  '366961': 'CREDIT',
  '366962': 'CREDIT',
  '366963': 'CREDIT',
  '366964': 'CREDIT',
  '366965': 'CREDIT',
  '366966': 'CREDIT',
  '366967': 'CREDIT',
  '366968': 'CREDIT',
  '366969': 'CREDIT',
  '366970': 'CREDIT',
  '366971': 'CREDIT',
  '366972': 'CREDIT',
  '366973': 'CREDIT',
  '366974': 'CREDIT',
  '366975': 'CREDIT',
  '366976': 'CREDIT',
  '366977': 'CREDIT',
  '366978': 'CREDIT',
  '366979': 'CREDIT',
  '366980': 'CREDIT',
  '366981': 'CREDIT',
  '366982': 'CREDIT',
  '366983': 'CREDIT',
  '366984': 'CREDIT',
  '366985': 'CREDIT',
  '366986': 'CREDIT',
  '366987': 'CREDIT',
  '366988': 'CREDIT',
  '366989': 'CREDIT',
  '366990': 'CREDIT',
  '366991': 'CREDIT',
  '366992': 'CREDIT',
  '366993': 'CREDIT',
  '366994': 'CREDIT',
  '366995': 'CREDIT',
  '366996': 'CREDIT',
  '366997': 'CREDIT',
  '366998': 'CREDIT',
  '366999': 'CREDIT',
  '369000': 'CREDIT',
  '369001': 'CREDIT',
  '369002': 'CREDIT',
  '369003': 'CREDIT',
  '369004': 'CREDIT',
  '369005': 'CREDIT',
  '369006': 'CREDIT',
  '369007': 'CREDIT',
  '369008': 'CREDIT',
  '369009': 'CREDIT',
  '369010': 'CREDIT',
  '369011': 'CREDIT',
  '369012': 'CREDIT',
  '369013': 'CREDIT',
  '369014': 'CREDIT',
  '369015': 'CREDIT',
  '369016': 'CREDIT',
  '369017': 'CREDIT',
  '369018': 'CREDIT',
  '369019': 'CREDIT',
  '369020': 'CREDIT',
  '369021': 'CREDIT',
  '369022': 'CREDIT',
  '369023': 'CREDIT',
  '369024': 'CREDIT',
  '369025': 'CREDIT',
  '369026': 'CREDIT',
  '369027': 'CREDIT',
  '369028': 'CREDIT',
  '369029': 'CREDIT',
  '369030': 'CREDIT',
  '369031': 'CREDIT',
  '369032': 'CREDIT',
  '369033': 'CREDIT',
  '369034': 'CREDIT',
  '369035': 'CREDIT',
  '369036': 'CREDIT',
  '369037': 'CREDIT',
  '369038': 'CREDIT',
  '369039': 'CREDIT',
  '369040': 'CREDIT',
  '369041': 'CREDIT',
  '369042': 'CREDIT',
  '369043': 'CREDIT',
  '369044': 'CREDIT',
  '369045': 'CREDIT',
  '369046': 'CREDIT',
  '369047': 'CREDIT',
  '369048': 'CREDIT',
  '369049': 'CREDIT',
  '369050': 'CREDIT',
  '369051': 'CREDIT',
  '369052': 'CREDIT',
  '369053': 'CREDIT',
  '369054': 'CREDIT',
  '369055': 'CREDIT',
  '369056': 'CREDIT',
  '369057': 'CREDIT',
  '369058': 'CREDIT',
  '369059': 'CREDIT',
  '369060': 'CREDIT',
  '369061': 'CREDIT',
  '369062': 'CREDIT',
  '369063': 'CREDIT',
  '369064': 'CREDIT',
  '369065': 'CREDIT',
  '369066': 'CREDIT',
  '369067': 'CREDIT',
  '369068': 'CREDIT',
  '369069': 'CREDIT',
  '369070': 'CREDIT',
  '369071': 'CREDIT',
  '369072': 'CREDIT',
  '369073': 'CREDIT',
  '369074': 'CREDIT',
  '369075': 'CREDIT',
  '369076': 'CREDIT',
  '369077': 'CREDIT',
  '369078': 'CREDIT',
  '369079': 'CREDIT',
  '369080': 'CREDIT',
  '369081': 'CREDIT',
  '369082': 'CREDIT',
  '369083': 'CREDIT',
  '369084': 'CREDIT',
  '369085': 'CREDIT',
  '369086': 'CREDIT',
  '369087': 'CREDIT',
  '369088': 'CREDIT',
  '369089': 'CREDIT',
  '369090': 'CREDIT',
  '369091': 'CREDIT',
  '369092': 'CREDIT',
  '369093': 'CREDIT',
  '369094': 'CREDIT',
  '369095': 'CREDIT',
  '369096': 'CREDIT',
  '369097': 'CREDIT',
  '369098': 'CREDIT',
  '369099': 'CREDIT',
  '369100': 'CREDIT',
  '369101': 'CREDIT',
  '369102': 'CREDIT',
  '369103': 'CREDIT',
  '369104': 'CREDIT',
  '369105': 'CREDIT',
  '369106': 'CREDIT',
  '369107': 'CREDIT',
  '369108': 'CREDIT',
  '369109': 'CREDIT',
  '369110': 'CREDIT',
  '369111': 'CREDIT',
  '369112': 'CREDIT',
  '369113': 'CREDIT',
  '369114': 'CREDIT',
  '369115': 'CREDIT',
  '369116': 'CREDIT',
  '369117': 'CREDIT',
  '369118': 'CREDIT',
  '369119': 'CREDIT',
  '369120': 'CREDIT',
  '369121': 'CREDIT',
  '369122': 'CREDIT',
  '369123': 'CREDIT',
  '369124': 'CREDIT',
  '369125': 'CREDIT',
  '369126': 'CREDIT',
  '369127': 'CREDIT',
  '369128': 'CREDIT',
  '369129': 'CREDIT',
  '369130': 'CREDIT',
  '369131': 'CREDIT',
  '369132': 'CREDIT',
  '369133': 'CREDIT',
  '369134': 'CREDIT',
  '369135': 'CREDIT',
  '369136': 'CREDIT',
  '369137': 'CREDIT',
  '369138': 'CREDIT',
  '369139': 'CREDIT',
  '369140': 'CREDIT',
  '369141': 'CREDIT',
  '369142': 'CREDIT',
  '369143': 'CREDIT',
  '369144': 'CREDIT',
  '369145': 'CREDIT',
  '369146': 'CREDIT',
  '369147': 'CREDIT',
  '369148': 'CREDIT',
  '369149': 'CREDIT',
  '369150': 'CREDIT',
  '369151': 'CREDIT',
  '369152': 'CREDIT',
  '369153': 'CREDIT',
  '369154': 'CREDIT',
  '369155': 'CREDIT',
  '369156': 'CREDIT',
  '369157': 'CREDIT',
  '369158': 'CREDIT',
  '369159': 'CREDIT',
  '369160': 'CREDIT',
  '369161': 'CREDIT',
  '369162': 'CREDIT',
  '369163': 'CREDIT',
  '369164': 'CREDIT',
  '369165': 'CREDIT',
  '369166': 'CREDIT',
  '369167': 'CREDIT',
  '369168': 'CREDIT',
  '369169': 'CREDIT',
  '369170': 'CREDIT',
  '369171': 'CREDIT',
  '369172': 'CREDIT',
  '369173': 'CREDIT',
  '369174': 'CREDIT',
  '369175': 'CREDIT',
  '369176': 'CREDIT',
  '369177': 'CREDIT',
  '369178': 'CREDIT',
  '369179': 'CREDIT',
  '369180': 'CREDIT',
  '369181': 'CREDIT',
  '369182': 'CREDIT',
  '369183': 'CREDIT',
  '369184': 'CREDIT',
  '369185': 'CREDIT',
  '369186': 'CREDIT',
  '369187': 'CREDIT',
  '369188': 'CREDIT',
  '369189': 'CREDIT',
  '369190': 'CREDIT',
  '369191': 'CREDIT',
  '369192': 'CREDIT',
  '369193': 'CREDIT',
  '369194': 'CREDIT',
  '369195': 'CREDIT',
  '369196': 'CREDIT',
  '369197': 'CREDIT',
  '369198': 'CREDIT',
  '369199': 'CREDIT',
  '369210': 'CREDIT',
  '369211': 'CREDIT',
  '369212': 'CREDIT',
  '369213': 'CREDIT',
  '369214': 'CREDIT',
  '369215': 'CREDIT',
  '369400': 'CREDIT',
  '369401': 'CREDIT',
  '369402': 'CREDIT',
  '369403': 'CREDIT',
  '369404': 'CREDIT',
  '369405': 'CREDIT',
  '369406': 'CREDIT',
  '369407': 'CREDIT',
  '369408': 'CREDIT',
  '369409': 'CREDIT',
  '369410': 'CREDIT',
  '369411': 'CREDIT',
  '369412': 'CREDIT',
  '369413': 'CREDIT',
  '369414': 'CREDIT',
  '369415': 'CREDIT',
  '369416': 'CREDIT',
  '369417': 'CREDIT',
  '369418': 'CREDIT',
  '369419': 'CREDIT',
  '369420': 'CREDIT',
  '369421': 'CREDIT',
  '369422': 'CREDIT',
  '369423': 'CREDIT',
  '369424': 'CREDIT',
  '369425': 'CREDIT',
  '369426': 'CREDIT',
  '369427': 'CREDIT',
  '369428': 'CREDIT',
  '369429': 'CREDIT',
  '369430': 'CREDIT',
  '369431': 'CREDIT',
  '369432': 'CREDIT',
  '369433': 'CREDIT',
  '369434': 'CREDIT',
  '369435': 'CREDIT',
  '369436': 'CREDIT',
  '369437': 'CREDIT',
  '369438': 'CREDIT',
  '369439': 'CREDIT',
  '369440': 'CREDIT',
  '369441': 'CREDIT',
  '369442': 'CREDIT',
  '369443': 'CREDIT',
  '369444': 'CREDIT',
  '369445': 'CREDIT',
  '369446': 'CREDIT',
  '369447': 'CREDIT',
  '369448': 'CREDIT',
  '369449': 'CREDIT',
  '369450': 'CREDIT',
  '369451': 'CREDIT',
  '369452': 'CREDIT',
  '369453': 'CREDIT',
  '369454': 'CREDIT',
  '369455': 'CREDIT',
  '369456': 'CREDIT',
  '369457': 'CREDIT',
  '369458': 'CREDIT',
  '369459': 'CREDIT',
  '369460': 'CREDIT',
  '369461': 'CREDIT',
  '369462': 'CREDIT',
  '369463': 'CREDIT',
  '369464': 'CREDIT',
  '369465': 'CREDIT',
  '369466': 'CREDIT',
  '369467': 'CREDIT',
  '369468': 'CREDIT',
  '369469': 'CREDIT',
  '369470': 'CREDIT',
  '369471': 'CREDIT',
  '369472': 'CREDIT',
  '369473': 'CREDIT',
  '369474': 'CREDIT',
  '369475': 'CREDIT',
  '369476': 'CREDIT',
  '369477': 'CREDIT',
  '369478': 'CREDIT',
  '369479': 'CREDIT',
  '369480': 'CREDIT',
  '369481': 'CREDIT',
  '369482': 'CREDIT',
  '369483': 'CREDIT',
  '369484': 'CREDIT',
  '369485': 'CREDIT',
  '369486': 'CREDIT',
  '369487': 'CREDIT',
  '369488': 'CREDIT',
  '369489': 'CREDIT',
  '369490': 'CREDIT',
  '369491': 'CREDIT',
  '369492': 'CREDIT',
  '369493': 'CREDIT',
  '369494': 'CREDIT',
  '369495': 'CREDIT',
  '369496': 'CREDIT',
  '369497': 'CREDIT',
  '369498': 'CREDIT',
  '369499': 'CREDIT',
  '369500': 'CREDIT',
  '369501': 'CREDIT',
  '369502': 'CREDIT',
  '369503': 'CREDIT',
  '369504': 'CREDIT',
  '369505': 'CREDIT',
  '369506': 'CREDIT',
  '369507': 'CREDIT',
  '369508': 'CREDIT',
  '369509': 'CREDIT',
  '369510': 'CREDIT',
  '369511': 'CREDIT',
  '369512': 'CREDIT',
  '369513': 'CREDIT',
  '369514': 'CREDIT',
  '369515': 'CREDIT',
  '369516': 'CREDIT',
  '369517': 'CREDIT',
  '369518': 'CREDIT',
  '369519': 'CREDIT',
  '369520': 'CREDIT',
  '369521': 'CREDIT',
  '369522': 'CREDIT',
  '369523': 'CREDIT',
  '369524': 'CREDIT',
  '369525': 'CREDIT',
  '369526': 'CREDIT',
  '369527': 'CREDIT',
  '369528': 'CREDIT',
  '369529': 'CREDIT',
  '369530': 'CREDIT',
  '369531': 'CREDIT',
  '369532': 'CREDIT',
  '369533': 'CREDIT',
  '369534': 'CREDIT',
  '369535': 'CREDIT',
  '369536': 'CREDIT',
  '369537': 'CREDIT',
  '369538': 'CREDIT',
  '369539': 'CREDIT',
  '369540': 'CREDIT',
  '369541': 'CREDIT',
  '369542': 'CREDIT',
  '369543': 'CREDIT',
  '369544': 'CREDIT',
  '369545': 'CREDIT',
  '369546': 'CREDIT',
  '369547': 'CREDIT',
  '369548': 'CREDIT',
  '369549': 'CREDIT',
  '369550': 'CREDIT',
  '369551': 'CREDIT',
  '369552': 'CREDIT',
  '369553': 'CREDIT',
  '369554': 'CREDIT',
  '369555': 'CREDIT',
  '369556': 'CREDIT',
  '369557': 'CREDIT',
  '369558': 'CREDIT',
  '369559': 'CREDIT',
  '369560': 'CREDIT',
  '369561': 'CREDIT',
  '369562': 'CREDIT',
  '369563': 'CREDIT',
  '369564': 'CREDIT',
  '369565': 'CREDIT',
  '369566': 'CREDIT',
  '369567': 'CREDIT',
  '369568': 'CREDIT',
  '369569': 'CREDIT',
  '369570': 'CREDIT',
  '369571': 'CREDIT',
  '369572': 'CREDIT',
  '369573': 'CREDIT',
  '369574': 'CREDIT',
  '369575': 'CREDIT',
  '369576': 'CREDIT',
  '369577': 'CREDIT',
  '369578': 'CREDIT',
  '369579': 'CREDIT',
  '369580': 'CREDIT',
  '369581': 'CREDIT',
  '369582': 'CREDIT',
  '369583': 'CREDIT',
  '369584': 'CREDIT',
  '369585': 'CREDIT',
  '369586': 'CREDIT',
  '369587': 'CREDIT',
  '369588': 'CREDIT',
  '369589': 'CREDIT',
  '369590': 'CREDIT',
  '369591': 'CREDIT',
  '369592': 'CREDIT',
  '369593': 'CREDIT',
  '369594': 'CREDIT',
  '369595': 'CREDIT',
  '369596': 'CREDIT',
  '369597': 'CREDIT',
  '369598': 'CREDIT',
  '369599': 'CREDIT',
  '369671': 'CREDIT',
  '374682': 'CREDIT',
  '374683': 'CREDIT',
  '374684': 'CREDIT',
  '374685': 'CREDIT',
  '374686': 'CREDIT',
  '374687': 'CREDIT',
  '374688': 'CREDIT',
  '374689': 'CREDIT',
  '374694': 'CREDIT',
  '374695': 'CREDIT',
  '374696': 'CREDIT',
  '374697': 'CREDIT',
  '374698': 'CREDIT',
  '376100': 'CREDIT',
  '376101': 'CREDIT',
  '376102': 'CREDIT',
  '376103': 'CREDIT',
  '376104': 'CREDIT',
  '376105': 'CREDIT',
  '376106': 'CREDIT',
  '376107': 'CREDIT',
  '376108': 'CREDIT',
  '376109': 'CREDIT',
  '376110': 'CREDIT',
  '376111': 'CREDIT',
  '376112': 'CREDIT',
  '376113': 'CREDIT',
  '376114': 'CREDIT',
  '376115': 'CREDIT',
  '376116': 'CREDIT',
  '376117': 'CREDIT',
  '376118': 'CREDIT',
  '376119': 'CREDIT',
  '376120': 'CREDIT',
  '376121': 'CREDIT',
  '376122': 'CREDIT',
  '376123': 'CREDIT',
  '376124': 'CREDIT',
  '376125': 'CREDIT',
  '376126': 'CREDIT',
  '376127': 'CREDIT',
  '376128': 'CREDIT',
  '376129': 'CREDIT',
  '376130': 'CREDIT',
  '376131': 'CREDIT',
  '376132': 'CREDIT',
  '376133': 'CREDIT',
  '376134': 'CREDIT',
  '376135': 'CREDIT',
  '376136': 'CREDIT',
  '376137': 'CREDIT',
  '376138': 'CREDIT',
  '376139': 'CREDIT',
  '376140': 'CREDIT',
  '376141': 'CREDIT',
  '376142': 'CREDIT',
  '376143': 'CREDIT',
  '376144': 'CREDIT',
  '376145': 'CREDIT',
  '376146': 'CREDIT',
  '376147': 'CREDIT',
  '376148': 'CREDIT',
  '376149': 'CREDIT',
  '376150': 'CREDIT',
  '376151': 'CREDIT',
  '376152': 'CREDIT',
  '376153': 'CREDIT',
  '376154': 'CREDIT',
  '376155': 'CREDIT',
  '376156': 'CREDIT',
  '376157': 'CREDIT',
  '376158': 'CREDIT',
  '376159': 'CREDIT',
  '376160': 'CREDIT',
  '376161': 'CREDIT',
  '376162': 'CREDIT',
  '376163': 'CREDIT',
  '376164': 'CREDIT',
  '376165': 'CREDIT',
  '376166': 'CREDIT',
  '376167': 'CREDIT',
  '376168': 'CREDIT',
  '376169': 'CREDIT',
  '376170': 'CREDIT',
  '376171': 'CREDIT',
  '376172': 'CREDIT',
  '376173': 'CREDIT',
  '376174': 'CREDIT',
  '376175': 'CREDIT',
  '376176': 'CREDIT',
  '376177': 'CREDIT',
  '376178': 'CREDIT',
  '376179': 'CREDIT',
  '376180': 'CREDIT',
  '376181': 'CREDIT',
  '376182': 'CREDIT',
  '376183': 'CREDIT',
  '376184': 'CREDIT',
  '376185': 'CREDIT',
  '376186': 'CREDIT',
  '376187': 'CREDIT',
  '376188': 'CREDIT',
  '376189': 'CREDIT',
  '376190': 'CREDIT',
  '376191': 'CREDIT',
  '376192': 'CREDIT',
  '376193': 'CREDIT',
  '376194': 'CREDIT',
  '376195': 'CREDIT',
  '376196': 'CREDIT',
  '376197': 'CREDIT',
  '376198': 'CREDIT',
  '376199': 'CREDIT',
  '376500': 'CREDIT',
  '376501': 'CREDIT',
  '376510': 'CREDIT',
  '376511': 'CREDIT',
  '376512': 'CREDIT',
  '376513': 'CREDIT',
  '376514': 'CREDIT',
  '376515': 'CREDIT',
  '376516': 'CREDIT',
  '376517': 'CREDIT',
  '376518': 'CREDIT',
  '376519': 'CREDIT',
  '377322': 'CREDIT',
  '377780': 'CREDIT',
  '377781': 'CREDIT',
  '377782': 'CREDIT',
  '377783': 'CREDIT',
  '377784': 'CREDIT',
  '377785': 'CREDIT',
  '377786': 'CREDIT',
  '377787': 'CREDIT',
  '377788': 'CREDIT',
  '377789': 'CREDIT',
  '377855': 'CREDIT',
  '377857': 'CREDIT',
  '377858': 'CREDIT',
  '377990': 'CREDIT',
  '377999': 'CREDIT',
  '379342': 'CREDIT',
  '379375': 'CREDIT',
  '379376': 'CREDIT',
  '379377': 'CREDIT',
  '379378': 'CREDIT',
  '379379': 'CREDIT',
  '379380': 'CREDIT',
  '379381': 'CREDIT',
  '379382': 'CREDIT',
  '379383': 'CREDIT',
  '379384': 'CREDIT',
  '379552': 'CREDIT',
  '379880': 'CREDIT',
  '379890': 'CREDIT',
  '400115': 'DEBIT',
  '400142': 'DEBIT',
  '400270': 'CREDIT',
  '400326': 'DEBIT',
  '400673': 'DEBIT',
  '400681': 'DEBIT',
  '401649': 'CREDIT',
  '401924': 'DEBIT',
  '402854': 'CREDIT',
  '404681': 'DEBIT',
  '404693': 'DEBIT',
  '404946': 'DEBIT',
  '405170': 'CREDIT',
  '405171': 'CREDIT',
  '405180': 'CREDIT',
  '405283': 'DEBIT',
  '405743': 'DEBIT',
  '405762': 'DEBIT',
  '406054': 'DEBIT',
  '406062': 'DEBIT',
  '406319': 'DEBIT',
  '406321': 'DEBIT',
  '406780': 'DEBIT',
  '406926': 'DEBIT',
  '407420': 'CREDIT',
  '407554': 'DEBIT',
  '407613': 'CREDIT',
  '407753': 'DEBIT',
  '408566': 'CREDIT',
  '409336': 'DEBIT',
  '409358': 'CREDIT',
  '409501': 'CREDIT',
  '409511': 'CREDIT',
  '409604': 'CREDIT',
  '409618': 'CREDIT',
  '409682': 'CREDIT',
  '409712': 'CREDIT',
  '409713': 'CREDIT',
  '409714': 'CREDIT',
  '409715': 'CREDIT',
  '409718': 'CREDIT',
  '409748': 'DEBIT',
  '409788': 'CREDIT',
  '409792': 'CREDIT',
  '409794': 'CREDIT',
  '409802': 'CREDIT',
  '409884': 'CREDIT',
  '409885': 'CREDIT',
  '409886': 'CREDIT',
  '410152': 'CREDIT',
  '412256': 'DEBIT',
  '413287': 'DEBIT',
  '414158': 'CREDIT',
  '414693': 'DEBIT',
  '415363': 'CREDIT',
  '415732': 'DEBIT',
  '416010': 'CREDIT',
  '416078': 'DEBIT',
  '416218': 'CREDIT',
  '416235': 'DEBIT',
  '416388': 'DEBIT',
  '418595': 'DEBIT',
  '420131': 'DEBIT',
  '420503': 'CREDIT',
  '420504': 'CREDIT',
  '420505': 'CREDIT',
  '420506': 'CREDIT',
  '420507': 'CREDIT',
  '420508': 'DEBIT',
  '420509': 'CREDIT',
  '420510': 'CREDIT',
  '420511': 'CREDIT',
  '420512': 'CREDIT',
  '420513': 'CREDIT',
  '420514': 'CREDIT',
  '420515': 'CREDIT',
  '420516': 'CREDIT',
  '420517': 'CREDIT',
  '420518': 'CREDIT',
  '420519': 'CREDIT',
  '420520': 'CREDIT',
  '420521': 'CREDIT',
  '420522': 'CREDIT',
  '420523': 'CREDIT',
  '420524': 'CREDIT',
  '420525': 'CREDIT',
  '420526': 'CREDIT',
  '420527': 'CREDIT',
  '420528': 'CREDIT',
  '420529': 'CREDIT',
  '420530': 'CREDIT',
  '420531': 'CREDIT',
  '420532': 'CREDIT',
  '420533': 'CREDIT',
  '420534': 'CREDIT',
  '420535': 'CREDIT',
  '420536': 'CREDIT',
  '420537': 'CREDIT',
  '420538': 'CREDIT',
  '420539': 'CREDIT',
  '420540': 'DEBIT',
  '420541': 'DEBIT',
  '420542': 'DEBIT',
  '420587': 'CREDIT',
  '421600': 'CREDIT',
  '421614': 'DEBIT',
  '421615': 'DEBIT',
  '421616': 'DEBIT',
  '421618': 'CREDIT',
  '421619': 'CREDIT',
  '421620': 'CREDIT',
  '421625': 'CREDIT',
  '421642': 'CREDIT',
  '421649': 'CREDIT',
  '421654': 'CREDIT',
  '421670': 'CREDIT',
  '421677': 'CREDIT',
  '421678': 'CREDIT',
  '421690': 'CREDIT',
  '421697': 'CREDIT',
  '421698': 'CREDIT',
  '421987': 'CREDIT',
  '421988': 'CREDIT',
  '421989': 'CREDIT',
  '421995': 'CREDIT',
  '421996': 'CREDIT',
  '421997': 'CREDIT',
  '421998': 'CREDIT',
  '421999': 'DEBIT',
  '422000': 'CREDIT',
  '422001': 'CREDIT',
  '422002': 'CREDIT',
  '422360': 'CREDIT',
  '422382': 'CREDIT',
  '422453': 'CREDIT',
  '422465': 'CREDIT',
  '422468': 'CREDIT',
  '422498': 'CREDIT',
  '422583': 'CREDIT',
  '422585': 'CREDIT',
  '422586': 'CREDIT',
  '422632': 'CREDIT',
  '422633': 'CREDIT',
  '422634': 'DEBIT',
  '422650': 'DEBIT',
  '422725': 'DEBIT',
  '424415': 'CREDIT',
  '424416': 'CREDIT',
  '424417': 'CREDIT',
  '424418': 'CREDIT',
  '424522': 'DEBIT',
  '424681': 'CREDIT',
  '424687': 'CREDIT',
  '424692': 'CREDIT',
  '424714': 'CREDIT',
  '424733': 'CREDIT',
  '424743': 'CREDIT',
  '425030': 'CREDIT',
  '425121': 'CREDIT',
  '425148': 'CREDIT',
  '425171': 'CREDIT',
  '425177': 'CREDIT',
  '425210': 'CREDIT',
  '425213': 'CREDIT',
  '425246': 'CREDIT',
  '425387': 'CREDIT',
  '425398': 'CREDIT',
  '425401': 'CREDIT',
  '425403': 'CREDIT',
  '425406': 'CREDIT',
  '425414': 'CREDIT',
  '425424': 'CREDIT',
  '425452': 'CREDIT',
  '425464': 'CREDIT',
  '425469': 'DEBIT',
  '425496': 'CREDIT',
  '425576': 'DEBIT',
  '425686': 'CREDIT',
  '425764': 'CREDIT',
  '425765': 'DEBIT',
  '425767': 'DEBIT',
  '425827': 'CREDIT',
  '426000': 'CREDIT',
  '426194': 'CREDIT',
  '426195': 'CREDIT',
  '426226': 'CREDIT',
  '426293': 'CREDIT',
  '426294': 'CREDIT',
  '426296': 'CREDIT',
  '426300': 'CREDIT',
  '426342': 'CREDIT',
  '426518': 'DEBIT',
  '426539': 'CREDIT',
  '426541': 'CREDIT',
  '426549': 'CREDIT',
  '426590': 'DEBIT',
  '426591': 'CREDIT',
  '426592': 'CREDIT',
  '426593': 'DEBIT',
  '426594': 'DEBIT',
  '426595': 'DEBIT',
  '426596': 'DEBIT',
  '426597': 'DEBIT',
  '426598': 'DEBIT',
  '426599': 'DEBIT',
  '426645': 'CREDIT',
  '426656': 'CREDIT',
  '426662': 'CREDIT',
  '426665': 'CREDIT',
  '426677': 'CREDIT',
  '426682': 'CREDIT',
  '426689': 'CREDIT',
  '426697': 'CREDIT',
  '426796': 'CREDIT',
  '426915': 'CREDIT',
  '426922': 'CREDIT',
  '426924': 'CREDIT',
  '426927': 'CREDIT',
  '427049': 'CREDIT',
  '427069': 'CREDIT',
  '427093': 'CREDIT',
  '427110': 'CREDIT',
  '427113': 'CREDIT',
  '427131': 'DEBIT',
  '427136': 'CREDIT',
  '427137': 'CREDIT',
  '427143': 'CREDIT',
  '427145': 'CREDIT',
  '427181': 'CREDIT',
  '427187': 'CREDIT',
  '427188': 'CREDIT',
  '427189': 'CREDIT',
  '427192': 'CREDIT',
  '427344': 'CREDIT',
  '427511': 'CREDIT',
  '427515': 'CREDIT',
  '427520': 'CREDIT',
  '427529': 'CREDIT',
  '427542': 'CREDIT',
  '427543': 'CREDIT',
  '427560': 'CREDIT',
  '427573': 'CREDIT',
  '427576': 'CREDIT',
  '427581': 'CREDIT',
  '427594': 'CREDIT',
  '427595': 'CREDIT',
  '427694': 'CREDIT',
  '427708': 'CREDIT',
  '427709': 'CREDIT',
  '427711': 'CREDIT',
  '427743': 'CREDIT',
  '427744': 'CREDIT',
  '427765': 'CREDIT',
  '427774': 'DEBIT',
  '427794': 'DEBIT',
  '427796': 'DEBIT',
  '427797': 'CREDIT',
  '427914': 'CREDIT',
  '427923': 'CREDIT',
  '427924': 'CREDIT',
  '427957': 'CREDIT',
  '427958': 'CREDIT',
  '428000': 'CREDIT',
  '428067': 'CREDIT',
  '428316': 'DEBIT',
  '428402': 'DEBIT',
  '428446': 'DEBIT',
  '428481': 'DEBIT',
  '428486': 'DEBIT',
  '428487': 'DEBIT',
  '428489': 'DEBIT',
  '428490': 'DEBIT',
  '428739': 'CREDIT',
  '428900': 'CREDIT',
  '428928': 'CREDIT',
  '428979': 'CREDIT',
  '428980': 'CREDIT',
  '428996': 'CREDIT',
  '428998': 'CREDIT',
  '429069': 'CREDIT',
  '429101': 'CREDIT',
  '429105': 'CREDIT',
  '429114': 'CREDIT',
  '429115': 'CREDIT',
  '429116': 'CREDIT',
  '429119': 'CREDIT',
  '429138': 'CREDIT',
  '429146': 'CREDIT',
  '429147': 'CREDIT',
  '429169': 'CREDIT',
  '429180': 'CREDIT',
  '429182': 'CREDIT',
  '429186': 'CREDIT',
  '429189': 'CREDIT',
  '429191': 'CREDIT',
  '429203': 'DEBIT',
  '429314': 'CREDIT',
  '429330': 'CREDIT',
  '429331': 'CREDIT',
  '429333': 'CREDIT',
  '429359': 'CREDIT',
  '429415': 'CREDIT',
  '429457': 'CREDIT',
  '429466': 'CREDIT',
  '429517': 'CREDIT',
  '429562': 'CREDIT',
  '429564': 'CREDIT',
  '429573': 'CREDIT',
  '429576': 'CREDIT',
  '429577': 'CREDIT',
  '429578': 'CREDIT',
  '429581': 'CREDIT',
  '429582': 'CREDIT',
  '429690': 'DEBIT',
  '429703': 'CREDIT',
  '429704': 'CREDIT',
  '429705': 'CREDIT',
  '429713': 'CREDIT',
  '429769': 'CREDIT',
  '429770': 'CREDIT',
  '429771': 'CREDIT',
  '429772': 'CREDIT',
  '430095': 'CREDIT',
  '430433': 'CREDIT',
  '430467': 'CREDIT',
  '430585': 'DEBIT',
  '430676': 'DEBIT',
  '430817': 'DEBIT',
  '430818': 'DEBIT',
  '430819': 'CREDIT',
  '430875': 'DEBIT',
  '430956': 'CREDIT',
  '431120': 'DEBIT',
  '431121': 'DEBIT',
  '431122': 'DEBIT',
  '431123': 'DEBIT',
  '431124': 'DEBIT',
  '431125': 'DEBIT',
  '431126': 'DEBIT',
  '431127': 'DEBIT',
  '431128': 'DEBIT',
  '431129': 'DEBIT',
  '431130': 'DEBIT',
  '431135': 'DEBIT',
  '431182': 'CREDIT',
  '431710': 'CREDIT',
  '431717': 'CREDIT',
  '431769': 'CREDIT',
  '432320': 'CREDIT',
  '432321': 'DEBIT',
  '432324': 'CREDIT',
  '432325': 'CREDIT',
  '432330': 'CREDIT',
  '432332': 'CREDIT',
  '432333': 'CREDIT',
  '432612': 'DEBIT',
  '432746': 'DEBIT',
  '432748': 'DEBIT',
  '432753': 'DEBIT',
  '433161': 'DEBIT',
  '433184': 'DEBIT',
  '433287': 'DEBIT',
  '433729': 'DEBIT',
  '434005': 'CREDIT',
  '434918': 'DEBIT',
  '435705': 'CREDIT',
  '435773': 'CREDIT',
  '435790': 'CREDIT',
  '436332': 'CREDIT',
  '436375': 'CREDIT',
  '436397': 'DEBIT',
  '436796': 'CREDIT',
  '438235': 'DEBIT',
  '438438': 'CREDIT',
  '438439': 'CREDIT',
  '438440': 'CREDIT',
  '438443': 'CREDIT',
  '438586': 'CREDIT',
  '438596': 'CREDIT',
  '438625': 'CREDIT',
  '438626': 'CREDIT',
  '438665': 'CREDIT',
  '438666': 'CREDIT',
  '438720': 'CREDIT',
  '438905': 'CREDIT',
  '438906': 'CREDIT',
  '439047': 'CREDIT',
  '439048': 'CREDIT',
  '439100': 'DEBIT',
  '439101': 'DEBIT',
  '439102': 'DEBIT',
  '439103': 'DEBIT',
  '439104': 'DEBIT',
  '439105': 'DEBIT',
  '439106': 'DEBIT',
  '439107': 'DEBIT',
  '439108': 'DEBIT',
  '439109': 'DEBIT',
  '439149': 'CREDIT',
  '439169': 'CREDIT',
  '439692': 'DEBIT',
  '440462': 'CREDIT',
  '440492': 'CREDIT',
  '440705': 'CREDIT',
  '440715': 'CREDIT',
  '443103': 'DEBIT',
  '443195': 'CREDIT',
  '443990': 'CREDIT',
  '444061': 'CREDIT',
  '445097': 'DEBIT',
  '445265': 'CREDIT',
  '445316': 'DEBIT',
  '445570': 'CREDIT',
  '445583': 'CREDIT',
  '445584': 'CREDIT',
  '445875': 'CREDIT',
  '445891': 'DEBIT',
  '446729': 'DEBIT',
};

export default data;
