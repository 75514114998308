import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import MerchantAPI, { GetPromotionCodeParams } from '../api/merchant';
import { BankDirectLimitExceededType, CheckoutType } from '../api/payment';
import SmartpayCoAPI, { GetMerchantParams } from '../api/smartpay-co';
import { getCheckoutSession } from './good';

export type Promotion = {
  code: string;
  active: boolean;
  minimumAmount: number;
  currency: 'jpy';
  discountAmount: number;
  discountPercentage: number;
  discountType: 'amount' | 'percentage';
  name: string;
  expiresAt: number;
};

export type Platform = undefined | 'web' | 'app';

export const initialState = {
  rememberMe: false,
  referrer: '',
  sessionIdWithSignature: '',
  loading: true,
  promotionCode: '',
  merchantName: '',
  merchantLogo: '',
  canUsePromotionCodes: false,
  checkoutType: undefined as CheckoutType | undefined,
  isDemoSite: false,
  promotion: undefined as Promotion | undefined,
  isTestSession: false,
  supportsApplePay: false,
  supportsGooglePay: false,
  loginURL: '',
  preferredPlatform: undefined as Platform,
  isTokenFlow: false,
  bankDirectLimitExceeded: undefined as BankDirectLimitExceededType,
};

type State = typeof initialState;
type MiscPairs = Partial<State>;

export const getPromotionCode = createAsyncThunk(
  'misc/getPromotionCode',
  async ({ sessionIdWithSignature, promotionCode }: GetPromotionCodeParams) => {
    const response = await MerchantAPI.getPromotionCode({
      sessionIdWithSignature,
      promotionCode,
    });

    if (response.data.data.length === 0) {
      throw new Error('Invalid code');
    }

    const payload = response.data.data[0];

    return {
      code: payload.code,
      active: payload.active,
      minimumAmount: payload.minimumAmount,
      currency: payload.currency,
      discountAmount: payload.coupon.discountAmount,
      discountType: payload.coupon.discountType,
      discountPercentage: payload.coupon.discountPercentage,
      name: payload.coupon.name,
      expiresAt: payload.expiresAt,
    };
  }
);

export const getMerchantLogo = createAsyncThunk(
  'misc/getMerchantLogo',
  async ({ anonymousMerchantId }: GetMerchantParams) => {
    const response = await SmartpayCoAPI.getMerchant({
      anonymousMerchantId,
    });

    const payload = response.data;

    return payload.logo.url;
  }
);

const miscSlice = createSlice({
  name: '@@checkout/misc',
  initialState,
  reducers: {
    updateMiscPairs(state, action: PayloadAction<MiscPairs>) {
      return {
        ...state,
        ...action.payload,
        ...(action.payload.sessionIdWithSignature && {
          isTestSession:
            action.payload.sessionIdWithSignature?.includes?.('test'),
          loading: true,
        }),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCheckoutSession.fulfilled, (state, action) => {
      state.loading = false;
      state.merchantName = action.payload.config.merchantName;
      state.checkoutType = action.payload.config.checkoutType;
      state.canUsePromotionCodes = action.payload.config.canUsePromotionCodes;
      state.supportsApplePay = action.payload.config.supportsApplePay;
      state.supportsGooglePay = action.payload.config.supportsGooglePay;
      state.isTokenFlow = !!action.payload.checkoutSession.token;
    });
    builder.addCase(getPromotionCode.fulfilled, (state, action) => {
      state.promotion = action.payload;
    });
    builder.addCase(getMerchantLogo.fulfilled, (state, action) => {
      state.merchantLogo = action.payload;
    });
  },
});

export const { updateMiscPairs } = miscSlice.actions;
export default miscSlice.reducer;
