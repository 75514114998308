import cx from 'classnames';
import usePaymentMethods from '../../hooks/use-payment-methods';
import MethodOption from './MethodOption';
import styles from './MethodSelector.module.scss';

const MethodSelector = ({
  onTriggerMethodSelector,
  isTestAccount,
  infoMessage,
}: {
  onTriggerMethodSelector: Function;
  infoMessage?: string;
  isTestAccount: boolean;
}) => {
  const { selectedPaymentMethod } = usePaymentMethods();

  return (
    <div className={cx(styles['method-select'], styles['is-collapsed'])}>
      <div id="payment_method_selector" className={styles['selected-method']}>
        <MethodOption
          paymentOption={selectedPaymentMethod}
          onClick={onTriggerMethodSelector}
          showArrow
          isTestAccount={isTestAccount}
        />
      </div>

      {infoMessage && (
        <div className={styles['info-wrapper']}>
          <p>{infoMessage}</p>
        </div>
      )}
      <p className={styles.note}>
        ※
        2024年5月8日以降、デビットカードおよびプリペイドカードでのお支払いはご利用いただけなくなります。
      </p>
      <p className={styles.note}>
        ※ 即時口座引き落としのご利用をご希望の方は
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          href="https://smartpay.co/faq/consumer/entry/what-is-smartpay-bank-direct/"
          target="_blank"
        >
          こちら
        </a>
        をご確認ください。
      </p>
    </div>
  );
};

export default MethodSelector;
