import { useLDClient } from 'launchdarkly-react-client-sdk';
import { LooseObject } from '../types';

export type LDClient = ReturnType<typeof useLDClient>;

type Context = LooseObject | undefined;

let ldClient: LDClient | undefined;
let userContext: Context;
let merchantContext: Context;

const store = {
  set(client: LDClient | undefined) {
    ldClient = client;
  },
  get() {
    return ldClient;
  },

  identify() {
    if (!ldClient) {
      return;
    }

    if (userContext && merchantContext) {
      ldClient.identify({
        kind: 'multi',
        user: userContext,
        merchant: merchantContext,
      });
    } else if (userContext) {
      ldClient.identify(userContext);
    }
  },
  identifyUser(user: Context) {
    userContext = user;

    this.identify();
  },
  identifyMerchant(merchant: Context) {
    merchantContext = merchant;

    this.identify();
  },
};

export default store;
