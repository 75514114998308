import type { MaskitoOptions } from '@maskito/core';
import { useMaskito } from '@maskito/react';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import React, {
  FormEvent,
  ForwardedRef,
  InputHTMLAttributes,
  KeyboardEventHandler,
  ReactNode,
  RefCallback,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import IconEyeClosed from '../../assets/icon-eye-closed.svg';
import IconEyeOpened from '../../assets/icon-eye-opened.svg';
import IconInfoGray from '../../assets/icon-info-gray.svg';
import Popup from '../Popup/Popup';
import styles from './TextInput.module.scss';

const emptyFn = () => {};

export type TextInputProps = {
  id?: string;
  type?: string;
  label?: string;
  labelCaption?: string;
  'aria-label'?: string;
  value?: string;
  readOnly?: boolean;
  placeholder?: string;
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FormEvent<HTMLInputElement>) => void;
  maxLength?: number;
  wrapperStyles?: React.CSSProperties;
  inputStyles?: React.CSSProperties;
  style?: React.CSSProperties;
  name: string;
  className?: string;
  pattern?: string;
  updatePairs?: Function;
  maskOption?: MaskitoOptions;
  errorMessage?: string;
  infoMessage?: string;
  tip?: ReactNode;
  shouldDisplayTipOnInit?: boolean;
  disabled?: boolean;
  isPasswordVisibleDefault?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  inputRef?: ForwardedRef<HTMLInputElement>;
} & Pick<
  InputHTMLAttributes<void>,
  'autoFocus' | 'autoComplete' | 'autoCapitalize' | 'autoCorrect'
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setRef = (ref: ForwardedRef<any> | undefined, element: any) => {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    ref(element);
  } else if (typeof ref === 'object') {
    // eslint-disable-next-line no-param-reassign
    ref.current = element;
  }
};

const MaskedInput = ({
  maskOption,
  inputRef,
  ...restProps
}: TextInputProps) => {
  const maskitoRefCallback = useMaskito({
    options: maskOption,
  });

  const refCallback: RefCallback<HTMLInputElement> = useCallback(
    (element) => {
      setRef(maskitoRefCallback, element);
      setRef(inputRef, element);
    },
    [inputRef, maskitoRefCallback]
  );

  return <input ref={refCallback} {...restProps} />;
};

const WrappedInput = ({
  maskOption,
  inputRef,
  ...restProps
}: TextInputProps) => {
  if (maskOption) {
    return (
      <MaskedInput maskOption={maskOption} inputRef={inputRef} {...restProps} />
    );
  }

  return <input ref={inputRef} {...restProps} />;
};

const usePasswordManagerHelper = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [passwordIconStyle, setPasswordIconStyle] = useState('');

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        if (
          window
            .getComputedStyle(inputRef.current)
            .getPropertyValue('background-image') !== 'none'
        ) {
          setPasswordIconStyle(styles['password-management']);
        }
      }
    }, 1000);
  }, [inputRef]);

  return { inputRef, passwordIconStyle };
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      type = 'text',
      label = '',
      labelCaption = '',
      name,
      value,
      placeholder = '',
      readOnly = false,
      onInput = emptyFn,
      onChange = emptyFn,
      onFocus = emptyFn,
      onBlur = emptyFn,
      maxLength,
      wrapperStyles,
      inputStyles,
      className,
      pattern,
      autoFocus = false,
      autoComplete,
      updatePairs,
      maskOption,
      errorMessage,
      infoMessage,
      tip,
      shouldDisplayTipOnInit = false,
      disabled,
      isPasswordVisibleDefault = false,
      ...props
    },
    ref
  ) => {
    const { inputRef: passwordManagerInputRef, passwordIconStyle } =
      usePasswordManagerHelper();
    const [isFocused, setIsFocused] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(
      isPasswordVisibleDefault
    );

    const passwordInputType = isPasswordVisible ? 'text' : 'password';

    const _onChange = useCallback(
      (event: FormEvent<HTMLInputElement>) => {
        if (updatePairs) {
          const { validity, name: _name, value: _value } = event.currentTarget;

          /**
           * If the date input received invalid input(ex: 1981/06/100) via keyboard
           * The change event will still trigger, but the value will be empty string
           * Use validity state to check is the input valid at the moment
           */
          if (type === 'date' && validity && !validity.valid) {
            return;
          }

          updatePairs({
            [_name]: _value,
          });
        }

        onChange?.(event);
      },
      [onChange, type, updatePairs]
    );

    const _onInput = useCallback(
      (event: FormEvent<HTMLInputElement>) => {
        onInput?.(event);
        _onChange(event);
      },
      [_onChange, onInput]
    );

    const _onFocus = useCallback(
      (event: FormEvent<HTMLInputElement>) => {
        setIsFocused(true);
        onFocus?.(event);
      },
      [onFocus]
    );

    const _onBlur = useCallback(
      (event: FormEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur?.(event);
      },
      [onBlur]
    );

    const onTogglePassword = useCallback(() => {
      Mixpanel.trackAction({
        action: 'Click',
        itemName: `Toggle Password Visibility - ${
          isPasswordVisible ? 'hidden' : 'visible'
        }`,
      });

      setIsPasswordVisible(!isPasswordVisible);
    }, [isPasswordVisible]);

    const refCallback: RefCallback<HTMLInputElement> = useCallback(
      (element) => {
        setRef(passwordManagerInputRef, element);
        setRef(ref, element);
      },
      [passwordManagerInputRef, ref]
    );

    return (
      <div className={cx(styles['input-box'], className)} style={wrapperStyles}>
        {label && (
          <label
            className={cx(
              isFocused ? styles.focused : '',
              errorMessage && styles.error,
              readOnly ? styles['read-only'] : ''
            )}
            htmlFor={`ip_${name}`}
          >
            {label}
            <span>{labelCaption}</span>
          </label>
        )}
        <div
          className={cx(
            styles['input-wrapper'],
            isFocused ? styles.focused : '',
            errorMessage ? styles.error : '',
            readOnly ? styles['read-only'] : '',
            disabled ? styles.disabled : ''
          )}
        >
          <WrappedInput
            id={`ip_${name}`}
            style={inputStyles}
            type={type === 'password' ? passwordInputType : type}
            name={name}
            value={value}
            readOnly={readOnly}
            onInput={_onInput}
            onChange={_onChange}
            onFocus={_onFocus}
            onBlur={_onBlur}
            placeholder={placeholder}
            className={cx(
              label ? '' : styles.raw,
              styles[name],
              passwordIconStyle
            )}
            maxLength={maxLength}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            pattern={pattern}
            maskOption={maskOption}
            inputRef={refCallback}
            disabled={disabled}
            {...props}
          />
          {tip && (
            <Popup
              className={styles.tip}
              trigger={
                <img
                  src={IconInfoGray}
                  width={20}
                  height={20}
                  loading="lazy"
                  alt="Tip"
                />
              }
              content={tip}
              position="top-left"
              shouldDisplayOnInit={shouldDisplayTipOnInit}
            />
          )}
          {type === 'password' && (
            <img
              id="ic_eye"
              className={cx(styles.eye, passwordIconStyle)}
              src={isPasswordVisible ? IconEyeOpened : IconEyeClosed}
              width={24}
              height={24}
              alt=""
              loading="lazy"
              onClick={onTogglePassword}
            />
          )}
        </div>
        {errorMessage && (
          <div
            id={`ip_${name}_error_message`}
            className={styles['error-wrapper']}
          >
            <p>{errorMessage}</p>
          </div>
        )}
        {infoMessage && (
          <div
            id={`ip_${name}_info_message`}
            className={styles['info-wrapper']}
          >
            <p>{infoMessage}</p>
          </div>
        )}
      </div>
    );
  }
);

export default TextInput;
