import axiosInstance from './axios-instance';

export type TokenParams = {
  tokenId: string;
};

const TokenAPI = {
  reject: ({ tokenId }: TokenParams) =>
    axiosInstance.put(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/tokens/${tokenId}/reject`
    ),
  approve: ({ tokenId }: TokenParams) =>
    axiosInstance.put(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/tokens/${tokenId}/approve`
    ),
};

export default TokenAPI;
