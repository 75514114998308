import cx from 'classnames';
import { ReactNode } from 'react';
import styles from './Modal.module.scss';

const Modal = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.modal, className)}>{children}</div>
    </div>
  );
};

export default Modal;
