import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC } from 'react';
import MainLayout from '../../components/Layout';
import styles from './ResultHostedScreen.module.scss';

const Failure: FC<RouteComponentProps> = () => {
  useTrackPageView();

  return (
    <div className="rwd-wrapper">
      <MainLayout
        hasBack={false}
        showFooter={false}
        className={styles.layout}
        logoHeight={35.95}
      >
        <div className={styles.content}>
          <h2>
            本当に申し訳なく思っています。
            <br />
            お支払いに失敗しました。
          </h2>
        </div>
      </MainLayout>
    </div>
  );
};

export default Failure;
