import { useMemo } from 'react';
import { BANK_ACCOUNT_ID } from '../redux/payment-method';
import useAppSelector from './use-app-selector';

const usePaymentMethods = () => {
  const { isBankDirectFeatureEnabled } = useAppSelector(
    (state) => state.featureFlag
  );

  const rawPaymentMethods = useAppSelector((state) => state.paymentMethod.list);
  const cards = useAppSelector((state) => state.paymentMethod.cards);
  const banks = useAppSelector((state) => state.paymentMethod.banks);

  const paymentMethods = rawPaymentMethods.filter((method) => {
    if (!isBankDirectFeatureEnabled && method.id === BANK_ACCOUNT_ID) {
      return false;
    }

    return true;
  });

  const defaultPaymentMethod = useMemo(
    () => paymentMethods.find((p) => p.kind === 'card'),
    [paymentMethods]
  );
  const hasDefaultPaymentMethod = !!defaultPaymentMethod;
  const selectedPaymentMethodId = useAppSelector(
    (state) => state.paymentMethod.selected
  );
  const selectedPaymentMethod = paymentMethods.find(
    (option) => option.id === selectedPaymentMethodId
  );

  return {
    selectedPaymentMethod,
    defaultPaymentMethod,
    hasDefaultPaymentMethod,
    paymentMethods,
    cards,
    banks,
  };
};

export default usePaymentMethods;
