import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useAppDispatch } from '../../..';
import logotypeSrc from '../../../assets/logotype.svg';
import Header from '../../../components/Header/Header';
import MainLayout from '../../../components/Layout';
import MerchantHeader from '../../../components/MerchantHeader/MerchantHeader';
import useAppSelector from '../../../hooks/use-app-selector';
import usePaymentMethods from '../../../hooks/use-payment-methods';
import useResponsive from '../../../hooks/use-responsive';
import { resumeAuthorizeOrder } from '../../../redux/payment-method';
import styles from './ChallengeScreen.module.scss';

const screen = 'Challenge';

const ChallengeScreen = ({
  authorizeResultHandler,
  onProcessing,
  onError,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authorizeResultHandler: (resultAction: any, theThunk: any) => void;
  onProcessing: () => void;
  onError: (error: Error) => void;
}) => {
  useTrackPageView({ screen });

  const { isMobile } = useResponsive();

  const dispatch = useAppDispatch();
  const { selectedPaymentMethod } = usePaymentMethods();
  const goods = useAppSelector((state) => state.good.data);
  const currentAction = useAppSelector((state) => state.paymentMethod.threeDS);
  const [loading, setLoading] = useState(true);

  const challengeWindowSize = window.innerWidth < 390 ? '01' : '02';
  // Set to any of the following:
  // '02': ['390px', '400px'] -  The default window size
  // '01': ['250px', '400px']
  // '03': ['500px', '600px']
  // '04': ['600px', '400px']
  // '05': ['100%', '100%']

  useEffect(() => {
    if (currentAction?.action) {
      setLoading(false);
    }
  }, [currentAction]);

  useLayoutEffect(() => {
    if (dispatch && goods.id && currentAction?.action) {
      const challenge = async () => {
        const configuration: CoreOptions = {
          locale: 'ja_JP',
          environment: process.env.REACT_APP_ENV === 'prod' ? 'live' : 'test',
          clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
          onAdditionalDetails: async (state) => {
            try {
              const threeDSResult = state.data?.details?.threeDSResult;
              const { transStatus } = JSON.parse(atob(threeDSResult));

              if (transStatus === 'U') {
                // U means unknown, when consumer clicks cancel
                setLoading(true);
              } else {
                onProcessing();
              }

              if (!threeDSResult) {
                throw new Error('unexpected_error');
              }

              const resultAction = await dispatch(
                resumeAuthorizeOrder({
                  orderID: goods.id || '',
                  paymentMethodID: selectedPaymentMethod?.id || '',
                  additionalDetail: {
                    threeDSResult,
                  },
                })
              );

              authorizeResultHandler(resultAction, resumeAuthorizeOrder);
            } catch (error) {
              onError(error);
            }
          },
        };

        const checkout = await AdyenCheckout(configuration);
        const threeDSConfiguration = {
          challengeWindowSize,
        };

        checkout
          .createFromAction(currentAction.action, threeDSConfiguration)
          .mount('#issuer-content');
      };

      challenge();
    }
  }, [
    dispatch,
    goods.id,
    currentAction,
    authorizeResultHandler,
    challengeWindowSize,
    onProcessing,
    onError,
    selectedPaymentMethod,
  ]);

  return (
    <>
      <div id="challenge" className={cx('rwd-wrapper')}>
        <aside>
          {isMobile ? (
            <Header
              logo={<img src={logotypeSrc} height={24} alt="" />}
              hasClose={false}
              hasBack={false}
            />
          ) : (
            <>
              <Header hasClose={false} hasBack={false} />
              <MerchantHeader />
            </>
          )}
        </aside>
        <MainLayout
          showLogo={!isMobile}
          hasBack={false}
          showFooter={false}
          className={styles.main}
        >
          <div className={styles['content-header']}>
            <h2>お客様のクレジットカード会社と本人確認を行います。</h2>
            <h3>接続に時間がかかる場合があります。</h3>
          </div>
          <div className={styles['issuer-container']}>
            <div
              id="issuer-content"
              className={styles[`challenge-window-size-${challengeWindowSize}`]}
            >
              {loading && (
                <div
                  data-testid="spinner"
                  className="adyen-checkout__spinner__wrapper"
                >
                  <div className="adyen-checkout__spinner adyen-checkout__spinner--large" />
                </div>
              )}
            </div>
          </div>
        </MainLayout>
      </div>
    </>
  );
};

export default ChallengeScreen;
