import { navigate } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import { ReactNode, useCallback, useRef } from 'react';
import IconBack from '../../assets/icon-back.svg';
import IconClose from '../../assets/icon-close.svg';
import useAppSelector from '../../hooks/use-app-selector';
import useResponsive from '../../hooks/use-responsive';
import { onRestartDemo } from '../../utils';
import styles from './Header.module.scss';

export const BtnBack = ({ className }: { className?: string }) => {
  const { isMobile } = useResponsive();
  const onBack = () => {
    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Go Back',
    });

    navigate(-1);
  };

  return (
    <button
      type="button"
      className={cx(styles.node, className)}
      onClick={onBack}
    >
      <img src={IconBack} alt={isMobile ? '戻る' : ''} width={24} height={24} />
      {!isMobile && <span className={styles.text}>戻る</span>}
    </button>
  );
};

const BtnClose = ({
  onClick,
  className,
}: {
  onClick?: () => void;
  className?: string;
}) => {
  const { isMobile } = useResponsive();

  return (
    <button
      type="button"
      className={cx(styles.node, className)}
      onClick={onClick}
    >
      <img
        src={IconClose}
        alt={isMobile ? 'キャンセル' : ''}
        width={24}
        height={24}
      />
      {!isMobile && <span className={styles.text}>キャンセル</span>}
    </button>
  );
};

const Placeholder = () => <div className={styles.placeholder} />;

const MerchantDisplay = ({
  logo,
  merchantName,
}: {
  logo?: ReactNode;
  merchantName: string;
}) => {
  const { logo: demoLogo, 'merchant-name': demoMerchantName } = useAppSelector(
    (state) => state.demo
  );

  if (demoLogo) {
    return (
      <img
        src={demoLogo as string}
        height={24}
        alt={(demoMerchantName || merchantName) as string}
      />
    );
  }

  return <h1>{demoMerchantName || logo || merchantName}</h1>;
};

const Header = ({
  hasClose = true,
  hasBack = true,
  mode = 'sidebar',
  logo,
}: {
  hasClose?: boolean;
  hasBack?: boolean;
  mode?: 'sidebar' | 'full-width';
  logo?: ReactNode;
}) => {
  const { isMobile } = useResponsive();
  const cancelURL = useAppSelector((state) => state.good.cancelURL);
  const { referrer, merchantName, isDemoSite } = useAppSelector(
    (state) => state.misc
  );
  const isRedirecting = useRef(false);

  const onRestart = useCallback(() => {
    onRestartDemo();
  }, []);

  const onRedirect = useCallback(() => {
    if (isRedirecting.current) {
      return;
    }

    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Close Checkout',
    });

    const target = cancelURL || referrer;

    if (
      (target && process.env.REACT_APP_ENV !== 'prod') ||
      (target && target.match(/^https?:\/\//))
    ) {
      isRedirecting.current = true;
      window.location.href = target;
    }
  }, [cancelURL, referrer]);

  const onClose = useCallback(() => {
    if (isDemoSite) {
      onRestart();
    } else {
      onRedirect();
    }
  }, [isDemoSite, onRestart, onRedirect]);

  let LeftNode: ReactNode;
  let RightNode: ReactNode;

  if (isMobile) {
    LeftNode = hasBack ? <BtnBack /> : <Placeholder />;
    RightNode = hasClose ? <BtnClose onClick={onClose} /> : <Placeholder />;
  } else if (mode === 'full-width') {
    if (hasClose && hasBack) {
      LeftNode = <BtnBack />;
      RightNode = <BtnClose onClick={onClose} />;
    } else if (hasClose) {
      LeftNode = <BtnClose onClick={onClose} />;
      RightNode = <Placeholder />;
    } else if (hasBack) {
      LeftNode = <BtnBack />;
      RightNode = <Placeholder />;
    } else {
      LeftNode = <Placeholder />;
      RightNode = <Placeholder />;
    }
  } else {
    // default: sidebar
    LeftNode = hasClose ? <BtnClose onClick={onClose} /> : <Placeholder />;
    RightNode = <Placeholder />;
  }

  return (
    <header className={styles.header}>
      {LeftNode}
      <MerchantDisplay logo={logo} merchantName={merchantName} />
      {RightNode}
    </header>
  );
};

export default Header;
