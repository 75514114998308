import { createSlice } from '@reduxjs/toolkit';

// kebab case here because these directly come from url params
export const initialState = {
  'merchant-name': '',
  logo: '',
  brand: '',
};

type State = typeof initialState;

const demoSlice = createSlice({
  name: '@@checkout/demo',
  initialState,
  reducers: {
    updateDemoPairs(state: State, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateDemoPairs } = demoSlice.actions;
export default demoSlice.reducer;
