import { AxiosResponse } from 'axios';
import randomstring from 'randomstring';
import collectBrowserInfo from '../utils/browser-info';
import axiosInstance from './axios-instance';
import publicAxiosInstance from './public-axios-instance';

export type AddPaymentMethodParams = {
  kind: 'card' | 'apple_pay' | 'google_pay';
  last4?: string;
  applePayToken?: string;
  googlePayToken?: string;
  brand?: string;
  holderName?: string;
  expiry?: string;
  numberToken?: string;
  securityCodeToken?: string;
  isDefault?: boolean;
};

export type GetCheckoutSessionParams = {
  sessionIdWithSignature: string;
};

export type ReserveDiscountParams = {
  orderID: string;
  promotionCode: string;
};

export type PrecheckOrderParams = {
  orderID: string;
};

export type AuthorizeOrderParams = {
  orderID: string;
  paymentMethodID: string;
  expectedDiscounts?: string[];
};

export type ResumeAuthorizeOrderParams = {
  orderID: string;
  paymentMethodID: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalDetail?: any;
};

export type UpdatePaymentMethodParams = {
  paymentMethodId: string;
  isDefault: boolean;
};

export type CardPaymentMethod = {
  id: string;
  kind: 'card' | 'apple_pay';
  details: {
    holderName: string;
    brand: string;
    bin: string;
    last4: string;
    expiryMonth: number;
    expiryYear: number;
  };
  isDefault: boolean;
  test: boolean;
  createdAt: number;
};

export type BankpayPaymentMethod = {
  id: string;
  kind: 'bank_account';
  details: {
    bankName: string;
    accountName: string;
  };
  isDefault: boolean;
  test: boolean;
  createdAt: number;
};

type PaymentMethod = CardPaymentMethod | BankpayPaymentMethod;
export type BankDirectLimitExceededType = undefined | 'ekyc' | 'normal';

export type PreCheckOrderResponse = {
  approved: boolean;
  ekycVerified: boolean;
  purchaseMade: boolean;
  rejectionReason:
    | 'past_due_charges'
    | 'insufficient_balance'
    | 'max_limit_exceeded'
    | 'ekyc_required';
  bankDirectAllowed: boolean;
  bankDirectLimitExceeded: BankDirectLimitExceededType;
};

type PrecheckOrderReturn = Promise<AxiosResponse<PreCheckOrderResponse>>;

type GetPaymentMethodReturn = Promise<AxiosResponse<PaymentMethod[]>>;

type ReserveDiscountReturn = Promise<
  AxiosResponse<{
    id: string;
    object: 'discount';
    amount: number;
    currency: string;
    createdAt: number;
  }>
>;

export type CustomerInfo = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  address: {
    line1: string;
    line2?: string;
    locality: string;
    administrativeArea: string;
    postalCode: string;
    country: string;
    line3?: string;
    line4?: string;
    line5?: string;
    subLocality?: string;
  };
  dateOfBirth: string;
  legalGender: string;
  phoneNumber: string;
  marketingOptIn: boolean;
};

export type ProductLineItem = {
  kind?: 'product';
  id: string;
  price: {
    id: string;
    active: boolean;
    amount: number;
    currency: string;
    product: {
      id: string;
      active: boolean;
      categories: string[];
      name: string;
      images: string[];
      description: string;
    };
  };
  quantity: number;
};

export type TaxLineItem = {
  kind?: 'tax';
  id: string;
  name: string;
  description?: string;
  currency: string;
  amount: number;
};

export type DiscountLineItem = {
  kind?: 'discount';
  id: string;
  name: string;
  description?: string;
  currency: string;
  amount: number;
};

export type Order = {
  id: string;
  amount: number;
  currency: string;
  expiresAt: number;
  lineItems: Array<ProductLineItem | TaxLineItem | DiscountLineItem>;
  coupon: number;
  shippingInfo: {
    address: {
      line1: string;
      line2: string;
      locality: string;
      postalCode: string;
      administrativeArea: string;
      country: string;
    };
    feeAmount: number;
    feeCurrency: string;
  };
  merchantId: string;
  status: string;
  publicRejectionCode?: string;
  test: boolean;
};

export type Session = {
  id: string;
  customerInfo: CustomerInfo;
  successUrl: string;
  cancelUrl: string;
  channel?: string;
  order?: Order;
  token?: {
    id: string;
    object: 'token';
    type: string;
    createdAt: number;
    reference: string;
    status: string;
    merchantId: string;
  };
};

export type NormalizedOrder = {
  id: string;
  amount: number;
  currency: string;
  expiresAt: number;
  lineItems: Array<ProductLineItem>;
  coupon: number;
  shippingInfo: {
    address: {
      line1: string;
      line2: string;
      locality: string;
      postalCode: string;
      administrativeArea: string;
      country: string;
    };
    feeAmount: number;
    feeCurrency: string;
  };
  status: string;
  test: boolean;
  merchantId: string;
  // Extra optional prop caculated at client side
  lineItemsTotalTaxAmount: number;
  lineItemsTotalDiscountAmount: number;
};

export type CheckoutType = 'webOnly';

type GetCheckoutSessionReturn = Promise<
  AxiosResponse<{
    config: {
      merchantName: string;
      canUsePromotionCodes: boolean;
      supportsApplePay: boolean;
      supportsGooglePay: boolean;
      checkoutType?: CheckoutType;
    };
    checkoutSession: Session;
  }>
>;

const getEpochByFive = () => {
  return Math.floor(new Date().getTime() / 5000);
};

const getAuthorizationIdempotencyKey = ({
  paymentMethodID,
}: {
  paymentMethodID: string;
}) => {
  return `${getEpochByFive()}-${paymentMethodID}`;
};

const getThreeDSOption = (options?: {
  isThreeDSEnabled?: boolean;
  isForceThreeDS?: boolean;
  isForceNoThreeDS?: boolean;
}) => {
  if (options?.isForceThreeDS) {
    return 'Required';
  }

  if (options?.isForceNoThreeDS) {
    return 'NotRequired';
  }

  return options?.isThreeDSEnabled ? 'Auto' : 'NotRequired';
};

const PaymentAPI = {
  getPaymentMethods: (): GetPaymentMethodReturn =>
    axiosInstance.get(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/payment-methods`
    ),
  addPaymentMethod: ({
    holderName,
    brand,
    expiry,
    numberToken,
    securityCodeToken,
    kind,
    last4,
    applePayToken,
    googlePayToken,
    isDefault,
  }: AddPaymentMethodParams) =>
    axiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/payment-methods`,
      {
        kind,
        details: {
          holderName,
          brand,
          expiry,
          numberToken,
          securityCodeToken,
          last4,
          applePayToken,
          googlePayToken,
        },
        isDefault,
      }
    ),
  getCheckoutSession: ({
    sessionIdWithSignature,
  }: GetCheckoutSessionParams): GetCheckoutSessionReturn =>
    publicAxiosInstance.get(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/v0/checkout-sessions/${sessionIdWithSignature}`
    ),
  reserveDiscount: ({
    orderID,
    promotionCode,
  }: ReserveDiscountParams): ReserveDiscountReturn =>
    axiosInstance.patch(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/orders/${orderID}`,
      {
        promotionCode,
      },
      {
        headers: {
          'Idempotency-Key': randomstring.generate(),
        },
      }
    ),
  precheckOrder: ({ orderID }: PrecheckOrderParams): PrecheckOrderReturn =>
    axiosInstance.get(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/orders/${orderID}/pre-check`
    ),
  authorizeOrder: (
    { orderID, paymentMethodID, expectedDiscounts }: AuthorizeOrderParams,
    options?: {
      isThreeDSEnabled?: boolean;
      isForceThreeDS?: boolean;
      isForceNoThreeDS?: boolean;
    }
  ) =>
    axiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/orders/${orderID}/authorizations`,
      {
        paymentMethod: paymentMethodID,
        paymentPlan: 'pay_in_three',
        expectedDiscounts,
        browserInfo: collectBrowserInfo(),
      },
      {
        headers: {
          'Idempotency-Key': getAuthorizationIdempotencyKey({
            paymentMethodID,
          }),
          'Require-3DS': getThreeDSOption(options),
        },
      }
    ),
  resumeAuthorizeOrder: ({
    orderID,
    paymentMethodID,
    additionalDetail,
  }: ResumeAuthorizeOrderParams) =>
    axiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/orders/${orderID}/authorizations/resume`,
      additionalDetail,
      {
        headers: {
          'Idempotency-Key': getAuthorizationIdempotencyKey({
            paymentMethodID,
          }),
        },
      }
    ),
  requestApplePaySession: () =>
    axiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/apple-pay-sessions`,
      {},
      {
        headers: {
          'Idempotency-Key': randomstring.generate(),
        },
      }
    ),
  updatePaymentMethod: ({
    paymentMethodId,
    isDefault,
  }: UpdatePaymentMethodParams) =>
    axiosInstance.patch(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/payment-methods/${paymentMethodId}`,
      { isDefault },
      {
        headers: {
          'Idempotency-Key': randomstring.generate(),
        },
      }
    ),
};

export default PaymentAPI;
