import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import sumBy from 'lodash.sumby';

import PaymentAPI, {
  DiscountLineItem,
  GetCheckoutSessionParams,
  NormalizedOrder,
  ProductLineItem,
  Session,
  TaxLineItem,
} from '../api/payment';
import ldStore from '../utils/launch-darkly';

const initialState: {
  loading: boolean;
  data: Partial<NormalizedOrder>;
  hasAppliedPromotionCode: boolean;
  successURL: string;
  cancelURL: string;
  merchantId: string;
  channel?: string;
} = {
  loading: true,
  data: {
    lineItemsTotalDiscountAmount: 0,
    lineItemsTotalTaxAmount: 0,
  },
  hasAppliedPromotionCode: false,
  successURL: '',
  cancelURL: '',
  merchantId: '',
  channel: undefined,
};

type State = typeof initialState;
export type GoodPairs = Partial<State>;

const normalizeOrder = (order: Partial<Session['order']>) => {
  return {
    ...order,
    lineItems: (order?.lineItems?.filter(
      (lineItem) => !lineItem.kind || lineItem.kind === 'product'
    ) || []) as Array<ProductLineItem>,
    lineItemsTotalTaxAmount: sumBy(
      (order?.lineItems?.filter((lineItem) => lineItem.kind === 'tax') ||
        []) as Array<TaxLineItem>,
      'amount'
    ),
    lineItemsTotalDiscountAmount: sumBy(
      (order?.lineItems?.filter((lineItem) => lineItem.kind === 'discount') ||
        []) as Array<DiscountLineItem>,
      'amount'
    ),
  };
};

const setVendorMerchantProps = (merchant: { name: string }) => {
  ldStore.identifyMerchant(
    merchant
      ? {
          kind: 'merchant',
          key: merchant.name,
          custom: {
            name: merchant.name,
          },
        }
      : undefined
  );
};

export const getCheckoutSession = createAsyncThunk(
  'good/getCheckoutSessionV2',
  async (params: GetCheckoutSessionParams) => {
    const response = await PaymentAPI.getCheckoutSession(params);

    return response.data;
  }
);

const goodSlice = createSlice({
  name: '@@checkout/good',
  initialState,
  reducers: {
    updateGoodPairs(state, action: PayloadAction<GoodPairs>) {
      return {
        ...state,
        ...action.payload,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    },
    setHasAppliedPromotionCode(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasAppliedPromotionCode: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCheckoutSession.fulfilled, (state, action) => {
      state.loading = false;

      const { order, token, successUrl, cancelUrl, channel } =
        action.payload.checkoutSession;
      const { merchantName } = action.payload.config;
      const tupleId = order?.id || token?.id;

      setVendorMerchantProps({ name: merchantName });

      if (!state.data.id || tupleId !== state.data.id) {
        state.successURL = successUrl;
        state.cancelURL = cancelUrl;
        state.channel = channel;

        if (order) {
          state.data = normalizeOrder(order);
        }
      }
    });
  },
});

export const { updateGoodPairs, setHasAppliedPromotionCode } =
  goodSlice.actions;
export default goodSlice.reducer;
