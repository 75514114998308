import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC } from 'react';
import Logotype from '../../assets/logotype.svg';
import Accordion from '../../components/Accordion/Accordion';
import Header from '../../components/Header/Header';
import faqs from '../../contentful/faqs.json';
import styles from './FAQScreen.module.scss';

const FAQScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  return (
    <div className="rwd-wrapper no-rwd">
      <Header
        logo={
          <img
            className={styles.logo}
            src={Logotype}
            alt="Smartpay"
            height={27}
          />
        }
        hasClose={false}
      />
      <div className={styles.wrapper}>
        <div className={styles.body}>
          <h1 className={styles.title}>よくあるご質問</h1>
          {faqs
            .sort((first, second) => {
              return first.order - second.order;
            })
            .map(({ title, answer }) => (
              <Accordion
                key={title}
                className={styles.accordion}
                title={title}
                onClickOpened={() => {
                  Mixpanel.trackAction({
                    action: 'Click',
                    itemName: title,
                  });
                }}
              >
                {documentToReactComponents(answer as Document)}
              </Accordion>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FAQScreen;
