import { GoogleOAuthProvider } from '@react-oauth/google';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import setupInterceptors from './api/setupInterceptors';
import './index.scss';
import configureStore from './redux/configure-store';
import reportWebVitals from './report-web-vitals';
import browserId from './utils/browser-id';

const { store, persistor } = configureStore();

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID || '',
    context: {
      kind: 'user',
      key: browserId,
      custom: {
        browserId,
      },
    },
  });

  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <GoogleOAuthProvider
          clientId={
            process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_ID ||
            '501201341068-kmu2dejoagagsa5r1pliukkduavhuu6d.apps.googleusercontent.com'
          }
        >
          <LDProvider>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </LDProvider>
        </GoogleOAuthProvider>
      </Provider>
    </StrictMode>,
    document.getElementById('root')
  );

  setupInterceptors(store);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
