import { createSlice } from '@reduxjs/toolkit';

/**
 * Priority of the feature flags:
 * 1. QS params
 * 2. LD flags
 * 3. Hard code default values
 */

export const NO_PREFIX = 'no-';

export const initialState = {
  isLoginViaAppFeatureEnabled: true,
  isBankDirectFeatureEnabled: true,
  isForcePlatformPromptFeatureEnabled: false,
  isThreeDSEnabled: true, // Controlled by LD
  isForceThreeDS: false,
  isForceNoThreeDS: false,
  isSSOGoogleEnabled: true,
  isSSOAppleEnabled: true,
};

// kebab case here because these directly come from url params
export const queryStringFeatureFlagMapping = {
  'feat-login-via-app': 'isLoginViaAppFeatureEnabled',
  'feat-bank-direct': 'isBankDirectFeatureEnabled',
  'feat-force-prefer-platform-prompt': 'isForcePlatformPromptFeatureEnabled',
  'feat-three-ds': 'isThreeDSEnabled',
  'feat-force-three-ds': 'isForceThreeDS',
  'feat-force-no-three-ds': 'isForceNoThreeDS',
  'feat-sso-google': 'isSSOGoogleEnabled',
  'feat-sso-apple': 'isSSOAppleEnabled',
};

export type AllowedQueryStringFeatureFlag =
  keyof typeof queryStringFeatureFlagMapping;

type State = typeof initialState;

const featureSlice = createSlice({
  name: '@@checkout/feature',
  initialState,
  reducers: {
    updateFeaturePairs(state: State, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateFeaturePairs } = featureSlice.actions;
export default featureSlice.reducer;
