import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { useCallback, useState } from 'react';
import { useAppDispatch } from '../..';
import { APIPayload } from '../../api/types';
import OTPLayout, {
  getErrorMessage,
} from '../../components/OTPLayout/OTPLayout';
import useAppSelector from '../../hooks/use-app-selector';
import useOnLogin from '../../hooks/use-on-login';
import { completeSignup, sendSingupOTP } from '../../redux/auth';

const PinCodeScreen = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();

  const signupId = useAppSelector((state) => state.auth.signupId);
  const ssoToken = useAppSelector((state) => state.auth.ssoToken);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { onLogin } = useOnLogin({
    onFailure: (errorCode) => setErrorMessage(getErrorMessage(errorCode)),
  });

  const onResendOtp = useCallback(
    (method) => signupId && dispatch(sendSingupOTP({ signupId, method })),
    [dispatch, signupId]
  );

  const onSubmit = useCallback(
    async ({ otpSecret, password }) => {
      Mixpanel.trackAction({
        action: 'Click',
        itemName: 'Submit',
      });

      if (password || ssoToken) {
        const signupResultAction = await dispatch(
          completeSignup({
            signupId,
            password,
            otpSecret,
          })
        );

        if (completeSignup.fulfilled.match(signupResultAction)) {
          await onLogin(signupResultAction.payload);
        } else {
          const payload = signupResultAction.payload as APIPayload;

          throw new Error(getErrorMessage(payload?.errorCode), {
            cause: payload?.details,
          });
        }
      }
    },
    [dispatch, ssoToken, onLogin, signupId]
  );

  return (
    <OTPLayout
      title="Smartpayにようこそ！"
      requestPassword={!ssoToken}
      onSubmit={onSubmit}
      onResendOtp={onResendOtp}
      errorMessage={errorMessage}
    />
  );
};

export default PinCodeScreen;
