type Dict = { [key: string]: string };

const ERROR_MESSAGES = {
  SHARED: {
    // The following errors don't have a specific BE code
    unknown: '申し訳ございません。エラーが発生しました。',
    'unknown-try-again':
      '申し訳ございません。エラーが発生しました。 あとでもう一度お試しください',
    'live.test.mismatch':
      '現行チェックアウトセッションでは、このアカウントを使用できません',
    'password.invalid': 'パスワードが正しくありません',
    'session.invalid':
      'チェックアウトセッションの取得に失敗しました / 無効なセッションIDです',
    'session.expired':
      '一定時間操作がない為、セキュリティ上の理由により自動的にログアウトしました。',
    'wrong-promotion-code': '入力されたコードは無効です。',
  } as Dict,
  LOGIN: {
    'request.invalid': 'メールアドレスが正しくありません',
    'consumers.signup.email-address-registered':
      'このメールアドレスはすでに登録されています',
    'connect.timeout': '接続エラー。 あとでもう一度お試しください',
    'invalid.email':
      '入力されたメールアドレスが無効であるか、一時的なものと認識されています。メールアドレスをご確認の上、再度お試しください。',
  } as Dict,
  PHONE: {
    'request.invalid': '電話番号が正しくありません',
    'consumers.signup.phone-number-registered':
      'この電話番号はすでに登録されています',
    'consumers.signup.max-otp-attempts':
      'ワンタイムパスワード（OTP）の誤りが一定回数を超えたため、ご利用いただけません。',
    'consumers.signup.invalid-state':
      '現在のログイン状態では、この操作ができません',
  } as Dict,
  PIN: {
    'request.invalid': '入力されたパスワードの文字列は規則に一致していません',
    'consumers.signup.incorrect-otp':
      'ワンタイムパスワード（OTP）の認証コードが正しくありません',
    'consumers.password-resets.incorrect-otp':
      'ワンタイムパスワード（OTP）の認証コードが正しくありません',
  } as Dict,
  '2FA': {
    'request.invalid': '申し訳ございません。エラーが発生しました。',
  } as Dict,
  PAYMENT: {
    'order.reserved-discount.not-found': 'クーポンの使用可能時間が切れました。',
    'order.confirmation.expired':
      'セッションが終了しました。もう一度チェックアウトをやり直してください。',
    'consumers.payment-methods.token-registered':
      'このカードは登録されています。',
    'consumers.payment-methods.card-expiry':
      '登録したカードの有効期限が切れているか間もなく切れます。有効期限を確認してください。',
    'consumers.payment-methods.invalid-payment-method-country':
      'Smartpayは日本国内でのみ利用可能です。',
    'consumers.payment-methods.invalid-payment-method-funding':
      'クレジットまたはデビットカードがご利用いただけます（デビットカードの利用は制限されている場合があります）',
    'consumers.payment-methods.invalid-payment-method-cvc-or-number':
      'カード番号、またはセキュリティコードが間違っています。',
    'consumers.payment-methods.invalid-payment-method-rejected':
      '選択されたお支払い方法はご利用いただけません。',
    'order.authorized': 'この注文はすでに承認されています',
    'promotion-code.notfound': '入力されたコードは存在しません。',
    'coupon.exceedmaxcount': '利用可能なクーポンがありません',
    'promotion-code.expired': 'コードの有効期限が切れています',
    'promotion-code.minamount': '最低注文金額を満たしていません',
    'promotion-code.alreadyused': 'このコードはお1人様1回限り有効です',
    'promotion-code.alreadyapplied': 'このコードは初回購入時のみ使用可能です',
  } as Dict,
};

export default ERROR_MESSAGES;
