import { navigate, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import React, { FC, FormEvent, useState } from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import { APIPayload } from '../../api/types';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import useAppSelector from '../../hooks/use-app-selector';
import { AuthPairs, updateAuthPairs, updatePhone } from '../../redux/auth';
import styles from './PhoneScreen.module.scss';

const PhoneScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();

  const onUpdateAuthPairs = (pairs: AuthPairs) =>
    dispatch(updateAuthPairs(pairs));

  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const signupId = useAppSelector((state) => state.auth.signupId);
  const phone = useAppSelector((state) => state.auth.phone);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (event: FormEvent<HTMLInputElement>) => {
    setErrorMessage('');
    dispatch(
      onUpdateAuthPairs({
        phone: event.currentTarget.value.replace(/\D/g, ''),
      })
    );
  };

  const onSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Submit',
    });

    setIsLoading(true);

    const resultAction = await dispatch(updatePhone({ signupId, phone }));

    if (updatePhone.fulfilled.match(resultAction)) {
      navigate('/pin', { replace: true });
    } else {
      setErrorMessage(
        ERROR_MESSAGES.PHONE[(resultAction.payload as APIPayload)?.errorCode] ||
          ERROR_MESSAGES.SHARED.unknown
      );
    }

    setIsLoading(false);
  };

  return (
    <div className={cx('rwd-wrapper', isTokenFlow ? 'token-flow' : '')}>
      <aside>
        <Header />
        <MerchantHeader />
      </aside>
      <MainLayout
        greetings="新規登録"
        desc1="携帯電話番号を入力してください。20秒以内にセキュリティーコードを送信します。"
      >
        <form className={styles.form} onSubmit={onSubmit}>
          <TextInput
            className={styles['phone-input']}
            name="phone"
            type="tel"
            aria-label="携帯電話番号"
            placeholder="08012345678"
            onChange={onChange}
            value={phone}
            maxLength={12}
            autoFocus
            errorMessage={errorMessage}
          />
          <Button
            loading={isLoading}
            id="btn_submit"
            type="submit"
            label="セキュリティーコードを送る"
            disabled={!(phone && phone.length >= 11)}
          />
        </form>
      </MainLayout>
    </div>
  );
};

export default PhoneScreen;
