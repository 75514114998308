import cx from 'classnames';
import { ReactNode, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './Popup.module.scss';

const Popup = ({
  className = '',
  content,
  trigger,
  hasMarginLeft = false,
  position = 'top-left',
  shouldDisplayOnInit = false,
}: {
  className?: string;
  content: ReactNode;
  trigger: JSX.Element;
  hasMarginLeft?: boolean;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  shouldDisplayOnInit?: boolean;
}) => {
  const [display, setDisplay] = useState(shouldDisplayOnInit);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setDisplay(false);
      }}
    >
      <div
        className={cx(
          styles.trigger,
          styles[position],
          className,
          hasMarginLeft ? styles['has-margin-left'] : '',
          display ? styles.display : ''
        )}
        onClick={() => setDisplay(true)}
      >
        <div className={styles.popup}>
          <div className={styles.inner}>{content}</div>
        </div>
        <div className={styles['trigger-item']}>
          {trigger}
          <span className={styles['arrow-border']} />
          <span className={styles.arrow} />
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Popup;
