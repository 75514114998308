import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { parse } from 'query-string';
import { FC } from 'react';
import MainLayout from '../../components/Layout';
import styles from './ResultHostedScreen.module.scss';

const Success: FC<RouteComponentProps> = () => {
  useTrackPageView({ trackDuration: false });

  const params = parse(window.location.search);

  return (
    <div className="rwd-wrapper">
      <MainLayout
        hasBack={false}
        showFooter={false}
        className={styles.layout}
        logoHeight={35.95}
      >
        <div className={styles.content}>
          <h2>
            おめでとうございます。
            <br />
            お支払いが完了しました。
          </h2>
          {params?.msg && <p>{params.msg}</p>}
        </div>
      </MainLayout>
    </div>
  );
};

export default Success;
