import cx from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import Loading from '../Loading';
import styles from './Button.module.scss';

export interface ButtonProps {
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'outline' | 'black-outline';
  iconType?: 'info' | 'warning' | 'none';
  label: string | ReactNode;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  inputRef?: React.Ref<HTMLButtonElement>;
}

const Button = ({
  id = '',
  type = 'button',
  variant = 'primary',
  iconType = 'none',
  label,
  loading = false,
  disabled = false,
  className,
  style,
  onClick = () => {},
  inputRef,
  ...props
}: ButtonProps): ReactElement => (
  <button
    id={id}
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={cx(
      styles.btn,
      styles[variant],
      styles[iconType],
      loading ? styles.loading : '',
      className
    )}
    onClick={onClick}
    disabled={disabled}
    ref={inputRef}
    {...props}
    style={style}
  >
    {loading ? <Loading size={24} /> : label}
  </button>
);

export default Button;
