import { BrowserInfo } from '@adyen/adyen-web/dist/types/types';
import getProp from 'lodash.get';

// https://github.com/Adyen/adyen-web/blob/main/packages/lib/src/utils/browserInfo.ts
export default function collectBrowserInfo(): BrowserInfo {
  const colorDepth = getProp(window, 'screen.colorDepth') || NaN;
  const javaEnabled = getProp(window, 'navigator.javaEnabled')
    ? window.navigator.javaEnabled()
    : false;
  const screenHeight = getProp(window, 'screen.height') || NaN;
  const screenWidth = getProp(window, 'screen.width') || NaN;
  const userAgent = getProp(window, 'navigator.userAgent') || '';

  // IE <+ 10 supports navigator.browserLanguage instead of navigator.language
  const language =
    getProp(window, 'navigator.language') ||
    getProp(window, 'navigator.browserLanguage') ||
    'en';
  const d = new Date();
  const timeZoneOffset = d.getTimezoneOffset();

  return {
    acceptHeader:
      // The server should override this value
      'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
    colorDepth,
    language,
    javaEnabled,
    screenHeight,
    screenWidth,
    userAgent,
    timeZoneOffset,
  };
}
