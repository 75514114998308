import { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import {
  FC,
  FormEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ERROR_MESSAGES from '../../api/error-messages';
import { APIPayload } from '../../api/types';
import srcSecurity from '../../assets/icon-security-check-primary.svg';
import useAppSelector from '../../hooks/use-app-selector';
import { sanitizeOTP } from '../../utils';
import client from '../../vendors/clientjs';
import Button from '../Form/Button';
import TextInput from '../Form/TextInput';
import { pinCodeMaskOption } from '../Form/utils';
import Header from '../Header/Header';
import MainLayout from '../Layout';
import MerchantHeader from '../MerchantHeader/MerchantHeader';
import Backdrop from '../Modal/Backdrop';
import Modal from '../Modal/Modal';
import PasswordDescription from '../PasswordDescription/PasswordDescription';
import PinResendButton from '../PinResendButton';
import styles from './OTPLayout.module.scss';

type OnSubmitParams = {
  otpSecret: string;
  password?: string;
};

type Props = {
  title?: string;
  requestPassword?: boolean;
  passwordDescription?: string;
  onSubmit: (arg0: OnSubmitParams) => void;
  onResendOtp: (method: string) => void;
  errorMessage?: string;
};

export const getErrorMessage = (errorCode: string) => {
  return (
    ERROR_MESSAGES['2FA'][errorCode] ||
    ERROR_MESSAGES.PIN[errorCode] ||
    ERROR_MESSAGES.SHARED.unknown
  );
};

const OTPLayout: FC<Props> = ({
  title = 'アカウント認証',
  requestPassword = false,
  passwordDescription = 'パスワードを作成してください。',
  onSubmit,
  onResendOtp,
  errorMessage: externalErrorMessage = '',
}) => {
  useTrackPageView();

  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const phoneLast4 = useAppSelector((state) => state.auth.phoneLast4);
  const testOTPSecret = useAppSelector((state) => state.auth.testOTPSecret);
  const [errorMessage, setErrorMessage] = useState();
  const [errorDetails, setErrorDetails] = useState<APIPayload['details']>([]);
  const [otpSecret, setOtpSecret] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPhoneNotFunctionalModal, setShowPhoneNotFunctionalModal] =
    useState(false);

  const _onResendOtp = useCallback(
    (method) => onResendOtp(method),
    [onResendOtp]
  );

  const _onSubmit = useCallback(
    async (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      setIsLoading(true);

      try {
        await onSubmit({
          otpSecret: sanitizeOTP(otpSecret),
          password,
        });

        setPassword(''); // cleanup
      } catch (error) {
        setErrorMessage(error.message);

        if (error.cause) {
          setErrorDetails(error.cause);
        }
      }

      setIsLoading(false);
    },
    [onSubmit, otpSecret, password]
  );

  const onUpdateOtpSecret = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      // Remove the error msg of the field
      setErrorDetails(
        errorDetails.filter((detail) => detail.path !== 'otpSecret')
      );

      setOtpSecret(event.currentTarget.value);
    },
    [errorDetails]
  );

  const onUpdatePassword = useCallback((event: FormEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  }, []);

  const onCallSupport = useCallback(() => {
    document.location.href = 'tel:05038166723';
  }, []);

  useEffect(() => {
    if (testOTPSecret) {
      setOtpSecret(testOTPSecret);
    }
  }, [testOTPSecret]);

  return (
    <div className={cx('rwd-wrapper', isTokenFlow ? 'token-flow' : '')}>
      <aside>
        <Header />
        <MerchantHeader />
      </aside>
      <MainLayout greetings={title}>
        <form className={styles.form} onSubmit={_onSubmit}>
          {requestPassword && (
            <div className={styles['input-box']}>
              <PasswordDescription desc={passwordDescription} />
              <TextInput
                name="password"
                type="password"
                value={password}
                aria-label="パスワード（英数字8文字以上）"
                onChange={onUpdatePassword}
                placeholder="パスワード（英数字8文字以上）"
                autoFocus
                isPasswordVisibleDefault
                {...(errorDetails.find(
                  (detail) => detail.path === 'password'
                ) && {
                  errorMessage,
                })}
              />
            </div>
          )}
          <div className={styles['otp-secret-box']}>
            <p>
              <em>•••••••{phoneLast4}</em>{' '}
              へ送信された6桁のセキュリティコードを入力してください。
            </p>
            <button
              type="button"
              className={styles['phone-not-functional']}
              onClick={() => setShowPhoneNotFunctionalModal(true)}
            >
              <img src={srcSecurity} alt="" width="16" height="16" />
              登録済みの電話番号は使用できませんか？
            </button>
            <TextInput
              name="otpSecret"
              pattern="\d{3}-\d{3}"
              onChange={onUpdateOtpSecret}
              value={otpSecret}
              aria-label="セキュリティコード"
              className={styles['otp-secret']}
              placeholder="888-888"
              maskOption={pinCodeMaskOption}
              autoComplete="one-time-code"
              {...(errorDetails.find(
                (detail) => detail.path === 'otpSecret'
              ) && {
                errorMessage,
              })}
              {...(testOTPSecret && {
                readOnly: true,
              })}
            />
            {errorDetails.length === 0 &&
              (externalErrorMessage || errorMessage) && (
                <div className={styles['error-wrapper']}>
                  <p>{externalErrorMessage || errorMessage}</p>
                </div>
              )}
            <PinResendButton onClick={_onResendOtp} />
          </div>
          <Button
            id="btn_submit"
            loading={isLoading}
            type="submit"
            label="次へ"
            disabled={
              (requestPassword && password.length < 8) || otpSecret.length !== 7
            }
          />
        </form>
      </MainLayout>
      {showPhoneNotFunctionalModal && (
        <>
          <Backdrop />
          <Modal>
            <div className={styles['phone-not-functional-modal']}>
              <h5 className={styles.title}>
                登録済みの電話番号は使用できませんか？
              </h5>
              <p className={styles.message}>
                携帯電話番号は、Smartpayアプリの設定画面から変更できます。
                <br />
                Smartpayに登録した携帯電話番号が使用できない場合は、カスタマーサポート（050-3537-4983）までお電話ください。
              </p>
              <div className={styles.actions}>
                {client.isMobile() && (
                  <Button
                    id="btn_call_support"
                    type="button"
                    label="カスタマーサポートに電話する"
                    variant="outline"
                    onClick={onCallSupport}
                    className={styles.action}
                  />
                )}
                <Button
                  id="btn_submit"
                  type="button"
                  label="OK"
                  onClick={() => setShowPhoneNotFunctionalModal(false)}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default OTPLayout;
