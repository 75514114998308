import { Session } from '../api/payment';
import { Promotion } from '../redux/misc';
import validatePromotion from './validate-promotion';

export default function getTotalAmount(
  goods: Partial<Session['order']>,
  hasAppliedPromotionCode: boolean,
  discountAmount: number,
  promotion?: Promotion
) {
  const { isValid } = validatePromotion(promotion, goods?.amount || 0);

  const totalAmount =
    Math.floor(goods?.amount || 0) -
    (hasAppliedPromotionCode && isValid && promotion ? discountAmount : 0);

  return {
    totalAmount,
    discountAmount,
    isValidPromotion: isValid,
  };
}
