import { useEffect } from 'react';
import { FormState, useForm } from 'react-hook-form';
import { ProfilePairs } from '../../redux/profile';
import validator from '../../utils/validator';

export const DOB_PLACEHOLDER = 'YYYY-MM-DD';

type FormValue = Record<
  | 'emailAddress'
  | 'lastName'
  | 'firstName'
  | 'lastNameKana'
  | 'firstNameKana'
  | 'gender'
  | 'postalCode'
  | 'state'
  | 'city'
  | 'address1'
  | 'address2'
  | 'dateOfBirth',
  string
>;

export const getIsFormValid = ({
  dirtyFields,
  errors,
}: Pick<FormState<FormValue>, 'dirtyFields' | 'errors'>) => {
  const dirtyFieldNames = Object.keys(dirtyFields);

  return (
    [
      'lastName',
      'firstName',
      'lastNameKana',
      'firstNameKana',
      'gender',
      'postalCode',
      'state',
      'city',
      'address1',
      'dateOfBirth',
    ].every((field) => dirtyFieldNames.includes(field)) &&
    Object.keys(errors).length === 0
  );
};

export default function useFormRegister(profile: Required<ProfilePairs>) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<FormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const lastNameRegister = register('lastName', {
    validate: validator.getRules([
      'mustNotBeEmptyOrSpace',
      'mustNotContainNonPrintableChars',
    ]),
  });
  const firstNameRegister = register('firstName', {
    validate: validator.getRules([
      'mustNotBeEmptyOrSpace',
      'mustNotContainNonPrintableChars',
    ]),
  });
  const lastNameKanaRegister = register('lastNameKana', {
    validate: validator.getRules([
      'mustNotBeEmptyOrSpace',
      'mustNotContainNonPrintableChars',
      'mustBeKatakanaOrSpaces',
    ]),
  });
  const firstNameKanaRegister = register('firstNameKana', {
    validate: validator.getRules([
      'mustNotBeEmptyOrSpace',
      'mustNotContainNonPrintableChars',
      'mustBeKatakanaOrSpaces',
    ]),
  });
  const genderRegister = register('gender', {
    validate: validator.getRules(['mustNotBeEmpty']),
  });
  const postalCodeRegister = register('postalCode', {
    setValueAs: (v) =>
      v.replace(/[０-９]/g, (s: string) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      ),
    validate: validator.getRules(['mustNotBeEmptyOrSpace', 'mustBePostalCode']),
  });
  const stateRegister = register('state', {
    validate: validator.getRules([
      'mustNotBeEmptyOrSpace',
      'mustNotContainSpace',
      'mustNotContainNonPrintableChars',
    ]),
  });
  const cityRegister = register('city', {
    validate: validator.getRules([
      'mustNotBeEmptyOrSpace',
      'mustNotContainSpace',
      'mustNotContainNonPrintableChars',
    ]),
  });
  const address1Register = register('address1', {
    validate: validator.getRules([
      'mustNotBeEmptyOrSpace',
      'mustNotContainSpace',
      'mustNotContainNonPrintableChars',
    ]),
  });
  const address2Register = register('address2', {
    validate: validator.getRules(['mustNotContainNonPrintableChars']),
  });
  const dateOfBirthRegister = register('dateOfBirth', {
    validate: validator.getRules(['mustNotBeEmpty', 'mustBeInValidDOBRange']),
  });

  useEffect(() => {
    (Object.keys(profile) as Array<keyof typeof profile>).forEach((key) => {
      if (profile[key]) {
        setValue(key, profile[key], {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    });

    watch([
      'lastName',
      'firstName',
      'lastNameKana',
      'firstNameKana',
      'gender',
      'postalCode',
      'state',
      'city',
      'address1',
      'address2',
      'dateOfBirth',
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lastNameRegister,
    dateOfBirthRegister,
    firstNameRegister,
    lastNameKanaRegister,
    firstNameKanaRegister,
    genderRegister,
    postalCodeRegister,
    stateRegister,
    cityRegister,
    address1Register,
    address2Register,
    errors,
    handleSubmit,
    setValue,
    dirtyFields,
  };
}
