import { navigate, RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { Message, sha256 } from 'js-sha256';
import { FC, FormEvent, useEffect, useState } from 'react';
import { useAppDispatch } from '../..';
import ERROR_MESSAGES from '../../api/error-messages';
import colorLogo from '../../assets/color-logo.svg';
import grayscaleLogo from '../../assets/grayscale-logo.svg';
import Button from '../../components/Form/Button';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import useAppSelector from '../../hooks/use-app-selector';
import usePaymentMethods from '../../hooks/use-payment-methods';
import { getMerchantLogo } from '../../redux/misc';
import { approveToken } from '../../redux/token';
import createEventHandler from '../../utils/create-event-handler';
import delayUIResponse from '../../utils/delay-ui-response';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import AddCardForm from '../PaymentScreen/AddCardForm';
import styles from './PaymentApprovalScreen.module.scss';

const PaymentApprovalScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();
  const { isTokenFlow, rememberMe, merchantName, merchantLogo } =
    useAppSelector((state) => state.misc);
  const token = useAppSelector((state) => state.token.data);
  const merchantId = token?.merchantId;

  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const { hasDefaultPaymentMethod } = usePaymentMethods();

  const onSubmitStart = createEventHandler(() => setIsProcessing(true));
  const onAddPaymentMethodError = createEventHandler(() =>
    setIsProcessing(false)
  );
  const onSubmitEnd = createEventHandler(() => {
    delayUIResponse(async () => {
      if (token?.id) {
        try {
          const approveTokenActionResult = await dispatch(
            approveToken({ tokenId: token.id })
          );

          if (approveToken.fulfilled.match(approveTokenActionResult)) {
            navigate('/payment-approval-success');
          } else {
            setErrorMessage(ERROR_MESSAGES.SHARED['unknown-try-again']);
          }
        } catch {
          setErrorMessage(ERROR_MESSAGES.SHARED['unknown-try-again']);
        } finally {
          setIsProcessing(false);
        }
      }
    });
  });

  const onSubmit = createEventHandler((e: FormEvent) => {
    e.preventDefault();
    setIsProcessing(true);
    onSubmitEnd();
  });

  useEffect(() => {
    if (merchantId) {
      dispatch(
        getMerchantLogo({ anonymousMerchantId: sha256(merchantId as Message) })
      );
    }
  }, [merchantId, dispatch]);

  return (
    <>
      <div>
        <div className={cx('rwd-wrapper', isTokenFlow ? 'token-flow' : '')}>
          <aside>
            <Header hasBack={!rememberMe} />
            <MerchantHeader />
          </aside>
          <MainLayout hasBack={!rememberMe}>
            <div className={styles.container}>
              <div className={styles.connection}>
                <div className={styles['background-light']} />
                <div className={styles['logo-group']}>
                  <div className={styles['logo-container']}>
                    <img
                      src={merchantLogo || grayscaleLogo}
                      alt={merchantName}
                      className={styles.logo}
                    />
                  </div>
                  <div className={styles['connect-dots']}>
                    <div className={styles.dot} />
                    <div className={styles.dot} />
                    <div className={styles.dot} />
                  </div>
                  <div className={styles['logo-container']}>
                    <img
                      src={colorLogo}
                      alt="Smartpay Logo"
                      className={styles.logo}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.bottom}>
                <h3 className={styles.title}>お支払いの承認</h3>
                <p className={styles.content}>このお支払いを承認しますか？</p>
                {errorMessage && (
                  <div className={styles['error-wrapper']}>
                    <p>{errorMessage}</p>
                  </div>
                )}
                {hasDefaultPaymentMethod ? (
                  <Button
                    id="btn_submit_approval"
                    loading={isProcessing}
                    disabled={isProcessing}
                    type="submit"
                    label="承認する"
                    className={styles.button}
                    onClick={onSubmit}
                  />
                ) : (
                  <AddCardForm
                    submitButtonLabel="承認する"
                    onSubmitStart={onSubmitStart}
                    onSubmitEnd={onSubmitEnd}
                    errorMessage={errorMessage}
                    onAddCardError={onAddPaymentMethodError}
                  />
                )}
              </div>
            </div>
          </MainLayout>
        </div>
      </div>
      {isProcessing && (
        <div className={styles.loading}>
          <LoadingScreen />
        </div>
      )}
    </>
  );
};

export default PaymentApprovalScreen;
