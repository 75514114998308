import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import transactionCompleted from '../../assets/transaction-completed.json';
import Button from '../../components/Form/Button';
import MainLayout from '../../components/Layout';
import Message from '../../components/Message/Message';
import useAppSelector from '../../hooks/use-app-selector';
import useUnloadStopper from '../../hooks/use-unload-stopper';
import { currencyFormatter, onRestartDemo } from '../../utils';
import getTotalAmount from '../../utils/get-total-amount';
import { getAmountForTheFirst } from '../../utils/installments';
import styles from './PaymentSuccessScreen.module.scss';

const REDIRECTING_TIMEOUT = 1500; // Automatically redirect after 1.5 secs

const PaymentSuccessScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const refBtnRedirect = useRef<HTMLButtonElement>(null);

  const { 'merchant-name': demoMerchantName } = useAppSelector(
    (state) => state.demo
  );
  const goods = useAppSelector((state) => state.good.data);
  const { referrer, merchantName, promotion, isDemoSite } = useAppSelector(
    (state) => state.misc
  );
  const successURL = useAppSelector((state) => state.good.successURL);
  const hasAppliedPromotionCode = useAppSelector(
    (state) => state.good.hasAppliedPromotionCode
  );
  const discountAmount = useAppSelector(
    (state) => state.paymentMethod.discountAmount
  );
  const [disabled, setDisabled] = useState(false);
  const isRedirecting = useRef(false);

  const { totalAmount } = getTotalAmount(
    goods,
    hasAppliedPromotionCode,
    discountAmount,
    promotion
  );

  const disableUnloadStopper = useUnloadStopper();

  const onRedirect = useCallback(() => {
    if (isRedirecting.current) {
      return;
    }

    Mixpanel.trackAction({
      action: 'Click',
      itemName: 'Redirect to Merchant',
    });

    // back to merchant's site
    const target = successURL || referrer;

    if (
      (target && process.env.REACT_APP_ENV !== 'prod') ||
      (target && target.match(/^https?:\/\//))
    ) {
      isRedirecting.current = true;
      setDisabled(true);
      disableUnloadStopper();
      window.location.href = target;
    }
  }, [disableUnloadStopper, referrer, successURL]);

  const onRestart = useCallback(() => {
    isRedirecting.current = true;
    setDisabled(true);
    onRestartDemo();
  }, []);

  useLayoutEffect(() => {
    refBtnRedirect?.current?.focus();

    const timer = setTimeout(() => {
      if (isDemoSite) {
        onRestart();
      } else {
        onRedirect();
      }
    }, REDIRECTING_TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  }, [isDemoSite, onRestart, onRedirect, refBtnRedirect]);

  const RedirectButton = () => (
    <Button
      type="button"
      className="btn"
      onClick={onRedirect}
      inputRef={refBtnRedirect}
      label={`${demoMerchantName || merchantName}に戻ります`}
      disabled={disabled}
    />
  );

  const RestartButton = () => (
    <Button
      type="button"
      className="btn"
      onClick={onRestart}
      inputRef={refBtnRedirect}
      label="一からやり直す"
      disabled={disabled}
    />
  );

  return (
    <div className="rwd-wrapper">
      <MainLayout
        hasBack={false}
        showFooter={false}
        className={styles.layout}
        logoHeight={35.95}
      >
        <div className={styles.content}>
          <div className={styles['lottie-wrapper']}>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: transactionCompleted,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={256}
              width={256}
              isClickToPauseDisabled
            />
            <h1>ご注文は進行中です</h1>
          </div>
          <div className={styles['information-block']}>
            <Message
              type="info"
              message="ページを閉じたりブラウザの戻るボタンを押さないでください。オンラインショップの注文完了ページを確認後に注文が完了します。"
            />
            <p className={styles.status}>
              <b
                className={cx(
                  'price-amount',
                  styles['first-installment-amount']
                )}
              >
                {currencyFormatter(getAmountForTheFirst(totalAmount))}
              </b>
              お支払い完了
            </p>
            {isDemoSite ? <RestartButton /> : <RedirectButton />}
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default PaymentSuccessScreen;
