import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ProfileAPI, { SubmitParams } from '../api/profile';
import { getCheckoutSession } from './good';

export type ProfileStateData = {
  emailAddress: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  postalCode: string;
  state: string;
  city: string;
  address1: string;
  address2: string;
  gender: string;
  dateOfBirth: string;
};

type State = {
  data: ProfileStateData;
  uniqueId: string;
  isPrefectureFieldReadOnly: boolean;
};

const initialState: State = {
  data: {
    emailAddress: '',
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    postalCode: '',
    state: '',
    city: '',
    address1: '',
    address2: '',
    gender: '',
    dateOfBirth: '',
  },
  uniqueId: '',
  isPrefectureFieldReadOnly: false,
};

export type ProfilePairs = Partial<State['data']>;

export const getProfile = createAsyncThunk('profile/get', async () => {
  const response = await ProfileAPI.get();

  return response.data;
});

export const submitProfile = createAsyncThunk(
  'profile/submit',
  async (params: SubmitParams, { rejectWithValue }) => {
    try {
      const response = await ProfileAPI.submit(params);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: '@@checkout/profile',
  initialState,
  reducers: {
    updateProfilePairs(state, action: PayloadAction<ProfilePairs>) {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    setIsPrefectureFieldReadOnly(state, action: PayloadAction<boolean>) {
      state.isPrefectureFieldReadOnly = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCheckoutSession.fulfilled, (state, action) => {
      const { id, customerInfo } = action.payload.checkoutSession;
      const { data } = state;

      if (state.uniqueId !== id) {
        state.data = {
          emailAddress: customerInfo?.emailAddress || data.emailAddress,
          lastName: customerInfo?.lastName || data.lastName,
          firstName: customerInfo?.firstName || data.firstName,
          firstNameKana: customerInfo?.firstNameKana || data.firstNameKana,
          lastNameKana: customerInfo?.lastNameKana || data.lastName,
          postalCode: customerInfo?.address?.postalCode || data.postalCode,
          state: customerInfo?.address?.administrativeArea || data.state,
          city: customerInfo?.address?.locality || data.city,
          address1: customerInfo?.address?.line1 || data.address1,
          address2: customerInfo?.address?.line2 || data.address2,
          gender: customerInfo?.legalGender || data.gender,
          dateOfBirth: customerInfo?.dateOfBirth || data.dateOfBirth,
        };

        state.uniqueId = id;
      }
    });
  },
});

export const { updateProfilePairs, setIsPrefectureFieldReadOnly } =
  profileSlice.actions;
export default profileSlice.reducer;
