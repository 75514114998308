import { PaymentAction as AdyenPaymentAction } from '@adyen/adyen-web/dist/types/types';
import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { RootState } from '..';
import PaymentAPI, {
  AddPaymentMethodParams,
  AuthorizeOrderParams,
  CardPaymentMethod,
  Order,
  PrecheckOrderParams,
  ReserveDiscountParams,
  ResumeAuthorizeOrderParams,
  UpdatePaymentMethodParams,
} from '../api/payment';
import CARD_DATA from '../utils/card-data';

export type Bankpay = {
  id: string;
  kind: string;
  lastError?: string;
};

export type Card = {
  id: string;
  kind: string;
  funding: 'CREDIT' | 'DEBIT';
  cardType: string;
  last4: string;
  lastError?: string;
};

export type PaymentMethod = Bankpay | Card;

export type CardPairs = Partial<Card>;

type State = {
  data: {
    [id: string]: PaymentMethod;
  };
  map: {
    [id: string]: PaymentMethod;
  };
  list: PaymentMethod[];
  cards: Card[];
  banks: Bankpay[];
  selected: string | null | undefined;
  hasBankAccount: boolean;
  discountAmount: number;
  discountId: string;
  getPaymentMethodError: SerializedError | null;
  threeDS?: {
    resultCode: string;
    action: AdyenPaymentAction;
  };
};

const initialState: State = {
  data: {},
  map: {},
  list: [],
  cards: [],
  banks: [],
  selected: null,
  hasBankAccount: false,
  discountAmount: 0,
  discountId: '',
  getPaymentMethodError: null,
  threeDS: undefined,
};

export const BANK_ACCOUNT_ID = 'bank-account-id';
export const SLICE_NAME = '@@checkout/card';

const UPDATE_PAYMENT_METHOD_STATUS = 'updatePaymentMethodStatus';
const START_RESUME_THREE_DS = 'startResumeThreeDS';

const updatePaymentMethodStatusAction = createAction<{
  paymentMethodId?: string;
  authorizeResult: Order;
}>(`${SLICE_NAME}/${UPDATE_PAYMENT_METHOD_STATUS}`);

const startResumeThreeDS = createAction(
  `${SLICE_NAME}/${START_RESUME_THREE_DS}`
);

export const getPaymentMethods = createAsyncThunk(
  'card/getPaymentMethods',
  async () => {
    const response = await PaymentAPI.getPaymentMethods();

    return response.data;
  }
);

export const addPaymentMethod = createAsyncThunk(
  'card/addPaymentMethod',
  async (params: AddPaymentMethodParams, { rejectWithValue }) => {
    try {
      const response = await PaymentAPI.addPaymentMethod(params);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePaymentMethod = createAsyncThunk(
  'card/updatePaymentMethod',
  async (params: UpdatePaymentMethodParams, { rejectWithValue }) => {
    try {
      const response = await PaymentAPI.updatePaymentMethod(params);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const reserveDiscount = createAsyncThunk(
  'card/reserveDiscount',
  async (params: ReserveDiscountParams, { rejectWithValue }) => {
    try {
      const response = await PaymentAPI.reserveDiscount(params);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const precheckOrder = createAsyncThunk(
  'card/precheckOrder',
  async (params: PrecheckOrderParams, { rejectWithValue }) => {
    try {
      const response = await PaymentAPI.precheckOrder(params);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const authorizeOrder = createAsyncThunk(
  'card/authorizeOrder',
  async (
    params: AuthorizeOrderParams,
    { rejectWithValue, dispatch, getState }
  ) => {
    const {
      featureFlag: { isThreeDSEnabled, isForceThreeDS, isForceNoThreeDS },
    } = getState() as RootState;

    try {
      const response = await PaymentAPI.authorizeOrder(params, {
        isThreeDSEnabled,
        isForceThreeDS,
        isForceNoThreeDS,
      });

      dispatch(
        updatePaymentMethodStatusAction({
          paymentMethodId: params.paymentMethodID,
          authorizeResult: response.data,
        })
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const resumeAuthorizeOrder = createAsyncThunk(
  'card/resumeAuthorizeOrder',
  async (params: ResumeAuthorizeOrderParams, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startResumeThreeDS());

      const response = await PaymentAPI.resumeAuthorizeOrder(params);

      dispatch(
        updatePaymentMethodStatusAction({
          authorizeResult: response.data,
        })
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const cardSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    selectPaymentMethod(state, action: PayloadAction<string | null>) {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      action.payload.forEach((pm) => {
        switch (pm.kind) {
          case 'card': {
            const paymentMethod: Card = {
              id: pm.id,
              kind: pm.kind,
              funding:
                CARD_DATA[(pm as CardPaymentMethod).details.bin] || 'CREDIT',
              cardType: (pm as CardPaymentMethod).details.brand,
              last4: (pm as CardPaymentMethod).details.last4,
            } as Card;

            state.data[paymentMethod.id] = paymentMethod;
            state.map[paymentMethod.id] = paymentMethod;

            if (!state.cards.find((item) => item.id === pm.id)) {
              state.list.push(paymentMethod);

              if (paymentMethod.funding === 'CREDIT') {
                state.cards.push(paymentMethod as Card);
              }
            }

            break;
          }
          case 'bank_account': {
            const paymentMethod: Bankpay = {
              id: pm.id,
              kind: pm.kind,
            } as Card;

            state.data[paymentMethod.id] = paymentMethod;
            state.map[paymentMethod.id] = paymentMethod;

            if (!state.banks.find((item) => item.id === pm.id)) {
              state.banks.push(paymentMethod);
            }

            break;
          }
          default:
            break;
        }
      });

      if (state.banks.length) {
        const paymentMethod = {
          id: BANK_ACCOUNT_ID,
          kind: 'bank_account',
        } as Bankpay;

        state.data[paymentMethod.id] = paymentMethod;

        if (!state.list.find((item) => item.id === paymentMethod.id)) {
          state.list.unshift(paymentMethod);
        }
      }

      // The first card is the default pm
      if (!state.selected) {
        state.selected = state.cards[0]?.id;
      }
    });
    builder.addCase(getPaymentMethods.rejected, (state, action) => {
      state.getPaymentMethodError = action.error;
    });
    builder.addCase(addPaymentMethod.fulfilled, (state, action) => {
      const newPaymentMethod = {
        id: action.payload.id,
        kind: 'card',
        cardType: action.payload.details.brand as string,
        last4: action.payload.details.last4,
        isDefault: false,
      };

      state.data[action.payload.id] = newPaymentMethod;
      state.list.push(newPaymentMethod);
    });
    builder.addCase(reserveDiscount.fulfilled, (state, action) => {
      state.discountAmount = action.payload.amount;
      state.discountId = action.payload.id;
    });
    builder.addCase(authorizeOrder.fulfilled, (state, action) => {
      state.threeDS = action.payload.threeDS;
    });
    builder.addCase(resumeAuthorizeOrder.fulfilled, (state, action) => {
      state.threeDS = action.payload.threeDS;
    });
    builder.addCase(updatePaymentMethodStatusAction, (state, action) => {
      const {
        paymentMethodId,
        authorizeResult: { status, publicRejectionCode },
      } = action.payload;

      // Mark the current payment method as selected
      if (paymentMethodId) {
        state.selected = paymentMethodId;
      }

      if (status === 'requires_authorization' && state.selected) {
        const mapItem = state.data[state.selected];
        const listItem = state.list.find(
          (method) => method.id === state.selected
        );

        if (mapItem) {
          mapItem.lastError = publicRejectionCode;
        }

        if (listItem) {
          listItem.lastError = publicRejectionCode;
        }
      }
    });
    builder.addCase(startResumeThreeDS, (state) => {
      state.threeDS = undefined;
    });
  },
});

export const { selectPaymentMethod } = cardSlice.actions;
export default cardSlice.reducer;
