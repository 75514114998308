import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Session } from '../api/payment';
import TokenAPI, { TokenParams } from '../api/token';
import { getCheckoutSession } from './good';

const initialState: {
  data: Session['token'] | undefined;
} = {
  data: undefined,
};

type State = typeof initialState;
export type GoodPairs = Partial<State>;

export const approveToken = createAsyncThunk(
  'token/approveToken',
  async (params: TokenParams) => {
    const response = await TokenAPI.approve(params);

    return response.data;
  }
);

export const rejectToken = createAsyncThunk(
  'token/rejectToken',
  async (params: TokenParams) => {
    const response = await TokenAPI.reject(params);

    return response.data;
  }
);

const tokenSlice = createSlice({
  name: '@@checkout/good',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCheckoutSession.fulfilled, (state, action) => {
      const { token } = action.payload.checkoutSession;

      state.data = token;
    });
  },
});

export default tokenSlice.reducer;
