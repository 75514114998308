import Mixpanel from '@smartpay/mixpanel';
import differenceInYears from 'date-fns/differenceInYears';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import subYears from 'date-fns/subYears';

export const rules = {
  mustNotBeEmpty: (val: string) => val !== '' || '必要項目です',
  mustNotBeEmptyOrSpace: (val: string) =>
    (!!val && !!val.trim()) || '必要項目です',
  mustNotContainSpace: (val: string) =>
    !val || /^\S*$/.test(val) || '空白(スペース)を入れないでください',
  mustNotContainNonPrintableChars: (val: string) =>
    // eslint-disable-next-line no-control-regex
    !val || !/[\x00-\x1F]/.test(val) || '入力に誤りがあります',
  mustBeKatakana: (val: string) =>
    !val ||
    /^([\u30A0-\u30FF]|[\uFF65-\uFF9F])*$/.test(val) ||
    'カタカナで入力してください',
  mustBeKatakanaOrSpaces: (val: string) =>
    !val ||
    /^([\u30A0-\u30FF]|[\uFF65-\uFF9F]|\s)*$/.test(val) ||
    'カタカナで入力してください',
  mustBeNumber: (val: string) =>
    !val || /^[0-9]+$/.test(val) || '数字で入力してください',
  mustHaveLengthOf11: (val: string) =>
    !val || val.length === 11 || '11桁で入力してください',
  mustBeChecked: (val: boolean) => val,
  mustBeInValidDOBRange: (val: string) => {
    if (
      val.length !== 10 ||
      (!isValid(parse(val, 'yyyy-MM-dd', new Date())) &&
        !isValid(parse(val, 'yyyy年MM月dd日', new Date())))
    ) {
      return '必要項目です';
    }

    if (isAfter(new Date(val), new Date())) {
      // future DOB
      return 'エラー：無効な日付です';
    }

    if (isBefore(new Date(val), subYears(new Date(), 110))) {
      // too old
      return '年齢制限の範囲外です';
    }

    if (
      differenceInYears(new Date(), parse(val, 'yyyy-MM-dd', new Date())) < 18
    ) {
      // underage user
      Mixpanel.trackAction({
        action: 'Click',
        itemName: 'Underage DOB',
      });

      return '18歳以上である必要があります';
    }

    return true;
  },
  mustBePostalCode: (val?: string) =>
    !val || /^\d{3}-\d{4}$/.test(val) || '7桁で入力してください',
};

export const dynamicRules = {
  mustHaveLengthOf: (length: number, prefix: string) => (val: string) =>
    !val ||
    val.length === length ||
    `${prefix || ''}${length}桁で入力してください`,
};

export type Rule = keyof typeof rules;
export type RuleWithParams = [keyof typeof dynamicRules, number, string];

const validator = {
  getRules: (_rules: Array<Rule | RuleWithParams>) =>
    _rules.reduce((final, rule: Rule | RuleWithParams) => {
      if (Array.isArray(rule)) {
        const [type, ...params] = rule;

        return {
          ...final,
          [`${type}${params.toString()}`]: dynamicRules[type](...params),
        };
      }

      return {
        ...final,
        [rule]: rules[rule],
      };
    }, {}),
};

export default validator;
