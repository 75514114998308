import { navigate } from '@reach/router';
import { useCallback } from 'react';
import { useAppDispatch } from '..';
import { ProfileReturn } from '../api/profile';
import {
  createSignupSession,
  getIsTestAccount,
  sendLoginOTP,
  updateAuthPairs,
} from '../redux/auth';
import { getPaymentMethods } from '../redux/payment-method';
import { getProfile } from '../redux/profile';
import { SNS } from '../screens/LoginScreen/LoginScreen';
import useAppSelector from './use-app-selector';
import usePrecheckOrder from './use-precheck-order';

type Params = {
  onSuccess?: () => void;
  onFailure?: (msg: string) => void;
};

export type LoginPayload = {
  accessToken?: string;
  loginAttemptId?: string;
  ssoToken?: string;
  anonymousId?: string;
  isTestAccount?: boolean;
};

const useOnLogin = ({ onSuccess, onFailure }: Params) => {
  const dispatch = useAppDispatch();
  const isTestSession = useAppSelector((state) => state.misc.isTestSession);
  const { onPrecheckOrder } = usePrecheckOrder();

  const onLogin = useCallback(
    async (
      {
        accessToken,
        loginAttemptId,
        ssoToken,
        anonymousId,
        isTestAccount,
      }: LoginPayload = {},
      options?: { service: SNS }
    ) => {
      if (loginAttemptId) {
        await dispatch(sendLoginOTP({ loginAttemptId, method: 'text' }));
        navigate('/2fa');

        return;
      }

      if (ssoToken && options?.service) {
        const signupResultAction = await dispatch(
          createSignupSession({
            ssoToken,
          })
        );

        if (createSignupSession.fulfilled.match(signupResultAction)) {
          if (signupResultAction.payload.isTestAccount !== isTestSession) {
            onFailure?.('live.test.mismatch');
          } else {
            // SSO, skips password screen
            navigate('/phone');
          }
        } else {
          navigate(`/associate/${options.service}`);
        }

        return;
      }

      if (!accessToken) {
        onFailure?.('unknown');

        return;
      }

      const fallbackedIsTestAccount =
        isTestAccount !== undefined
          ? isTestAccount
          : getIsTestAccount(accessToken);

      if (fallbackedIsTestAccount !== isTestSession) {
        onFailure?.('live.test.mismatch');

        return;
      }

      await dispatch(
        updateAuthPairs({
          anonymousId,
          accessToken,
          isTestAccount,
        })
      );

      const shouldContinue = await onPrecheckOrder({
        onError: () => {
          onFailure?.('unknown');
        },
      });

      if (!shouldContinue) {
        return;
      }

      await dispatch(getPaymentMethods());

      const profileResultAction = await dispatch(getProfile());

      if (getProfile.fulfilled.match(profileResultAction)) {
        const { status } =
          profileResultAction.payload as Awaited<ProfileReturn>['data'];

        onSuccess?.();

        if (status === 'completed') {
          navigate('/payment', { replace: true });
        } else {
          navigate('/profile', { replace: true });
        }
      } else {
        onFailure?.('unknown');
      }
    },
    [dispatch, isTestSession, onFailure, onPrecheckOrder, onSuccess]
  );

  return { onLogin };
};

export default useOnLogin;
