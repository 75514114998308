import { v5 as uuidv5 } from 'uuid';
import client from '../vendors/clientjs';
import { BROWSER_ID_LOCAL_STORAGE_KEY } from './constants';

const UUID_NAMESPACE = '4d40ac27-5e00-4d22-9652-ba87a9a89384';

// https://github.com/jackspirou/clientjs/blob/master/src/client.base.js#L166
const getBrowserCharacteristic = () => {
  const bar = '|';

  const userAgent = client.getBrowser() + bar + client.getOS();
  const screenPrint = client.getScreenPrint();
  const pluginList = client.getPlugins();
  const fontList = client.getFonts();
  const localStorage = client.isLocalStorage();
  const sessionStorage = client.isSessionStorage();
  const timeZone = client.getTimeZone();
  const language = client.getLanguage();
  const systemLanguage = client.getSystemLanguage();
  const cookies = client.isCookie();
  const canvasPrint = client.getCanvasPrint();

  return [
    userAgent,
    screenPrint,
    pluginList,
    fontList,
    localStorage,
    sessionStorage,
    timeZone,
    language,
    systemLanguage,
    cookies,
    canvasPrint,
  ].join(bar);
};

const existingBrowserId = client.isLocalStorage()
  ? localStorage.getItem(BROWSER_ID_LOCAL_STORAGE_KEY)
  : '';

const browserId =
  existingBrowserId ?? uuidv5(getBrowserCharacteristic(), UUID_NAMESPACE);

if (browserId && client.isLocalStorage()) {
  localStorage.setItem(BROWSER_ID_LOCAL_STORAGE_KEY, browserId);
}

export default browserId;
