import { ReactNode } from 'react';
import Button from '../Form/Button';
import Backdrop from '../Modal/Backdrop';
import Modal from '../Modal/Modal';
import styles from './Alert.module.scss';

const AlertModal = ({
  title,
  content,
  onDismiss,
  visible = false,
}: {
  title: string;
  content: ReactNode;
  onDismiss?: () => void;
  visible?: boolean;
}) => {
  if (!visible) {
    return null;
  }

  return (
    <>
      <Backdrop />
      <Modal className={styles.modal}>
        <h3 className={styles.heading}>{title}</h3>
        <div className={styles.desc}>{content}</div>
        <div className={styles.buttons}>
          <Button label="OK" onClick={onDismiss} />
        </div>
      </Modal>
    </>
  );
};

export default AlertModal;
