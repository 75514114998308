import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  createTransform,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer, { RootBaseState } from '.';
import { initialState as authInitialState } from './auth';
import { initialState as miscInitialState } from './misc';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === 'auth/login') {
      // Return null to not log the action to Sentry
      return null;
    }

    return action;
  },
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  transforms: [
    createTransform<unknown, unknown, RootBaseState, RootBaseState>(
      (inboundState, key) => {
        if (key === 'auth') {
          return {
            email: (inboundState as RootBaseState['auth']).email,
          };
        }

        if (key === 'misc') {
          return {
            rememberMe: (inboundState as RootBaseState['misc']).rememberMe,
            preferredPlatform: (inboundState as RootBaseState['misc'])
              .preferredPlatform,
          };
        }

        return inboundState;
      },
      (outBoundState, key) => {
        if (key === 'auth') {
          return {
            ...authInitialState,
            email: (outBoundState as RootBaseState['auth']).email,
          };
        }

        if (key === 'misc') {
          return {
            ...miscInitialState,
            rememberMe: (outBoundState as RootBaseState['misc']).rememberMe,
            preferredPlatform: (outBoundState as RootBaseState['misc'])
              .preferredPlatform,
          };
        }

        return outBoundState;
      }
    ),
  ],
  whitelist: ['auth', 'misc'],
};

export const persistedRootReducer = persistReducer(persistConfig, rootReducer);

const configuredStore = () => {
  const store = configureStore({
    reducer: persistedRootReducer,
    devTools: process.env.REACT_APP_ENV !== 'prod',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    enhancers:
      !!process.env.REACT_APP_SKIP_SENTRY_INIT ||
      !window.location.host.startsWith('checkout.smartpay.co')
        ? []
        : [sentryReduxEnhancer],
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configuredStore;
