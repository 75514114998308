import styles from './Modal.module.scss';

const Backdrop = ({
  onClick = () => {},
}: {
  onClick?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
}) => {
  return <div className={styles.backdrop} onClick={onClick} />;
};

export default Backdrop;
