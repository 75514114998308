import { Promotion } from '../redux/misc';

export default function validatePromotion(
  promotion: Promotion | undefined,
  orderAmount: number
) {
  let errorCode = '';
  let isValid = true;

  // if inactive
  if (!promotion?.active) {
    isValid = false;

    return { isValid, errorCode };
  }

  // if it has an expiry date and it's expired at this point
  if (promotion?.expiresAt && new Date(promotion.expiresAt) <= new Date()) {
    isValid = false;
    errorCode = 'promotion-code.expired';

    return { isValid, errorCode };
  }

  // if it has a minimum amount required and the actual amount is lower
  if (promotion?.minimumAmount && orderAmount < promotion.minimumAmount) {
    isValid = false;
    errorCode = 'promotion-code.minamount';

    return { isValid, errorCode };
  }

  return { isValid, errorCode };
}
