import { navigate } from '@reach/router';
import { useCallback } from 'react';
import { useAppDispatch } from '..';
import { updateFeaturePairs } from '../redux/feature-flag';
import { updateMiscPairs } from '../redux/misc';
import { precheckOrder } from '../redux/payment-method';
import useAppSelector from './use-app-selector';

const usePrecheckOrder = () => {
  const dispatch = useAppDispatch();
  const email = useAppSelector((state) => state.auth.email);
  const goods = useAppSelector((state) => state.good.data);
  const token = useAppSelector((state) => state.token.data);

  const onPrecheckOrder = useCallback(
    async ({ onError }: { onError?: Function }) => {
      let shouldContinue = false;

      if (goods.id) {
        // An order session
        // Always pass the e2e test email for an order session
        if (email === 'smartpay+test1@smartpay.co') {
          return true;
        }

        const precheckOrderResultAction = await dispatch(
          precheckOrder({ orderID: goods.id })
        );

        if (precheckOrder.fulfilled.match(precheckOrderResultAction)) {
          dispatch(
            updateMiscPairs({
              bankDirectLimitExceeded:
                precheckOrderResultAction.payload.bankDirectLimitExceeded,
            })
          );

          // Disable bank direct feature if it is dis-allowed in pre-check
          if (!precheckOrderResultAction.payload.bankDirectAllowed) {
            dispatch(updateFeaturePairs({ isBankDirectFeatureEnabled: false }));
          }

          if (!precheckOrderResultAction.payload.approved) {
            navigate('/limit-decline', {
              replace: true,
              state: precheckOrderResultAction.payload,
            });
          } else {
            shouldContinue = true;
          }
        } else {
          onError?.();
        }
      } else if (token?.id) {
        // A token session
        // Don't need to do pre-check
        shouldContinue = true;
      }

      return shouldContinue;
    },
    [dispatch, goods.id, token, email]
  );

  return { onPrecheckOrder };
};

export default usePrecheckOrder;
