import { AxiosResponse } from 'axios';
import axiosInstance from './axios-instance';
import { CustomerInfo } from './payment';

export type SubmitParams = {
  data: Omit<CustomerInfo, 'emailAddress' | 'phoneNumber'>;
};

export type ProfileReturn = Promise<
  AxiosResponse<{ country: 'jp'; status: 'completed' | 'incomplete' }>
>;

const ProfileAPI = {
  get: (): ProfileReturn =>
    axiosInstance.get(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/me/profile`
    ),
  submit: ({ data }: SubmitParams): ProfileReturn =>
    axiosInstance.put(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/me/profile`,
      data
    ),
};

export default ProfileAPI;
