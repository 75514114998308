import { AxiosResponse } from 'axios';
import publicAxiosInstance from './public-axios-instance';
import { OTP_METHOD } from './types';

export type CreateSignupSessionParams =
  | { emailAddress: string }
  | { ssoToken: string };

type UpdatePhoneParams = {
  signupId: string;
  phone: string;
  method: OTP_METHOD;
};

export type ResendSignupOTPParams = {
  signupId: string;
  method: OTP_METHOD;
};

export type ResendAssociateOTPParams = {
  associateAttemptId: string;
  method: OTP_METHOD;
};

export type CompleteSignupParams = {
  signupId: string;
  password?: string;
  otpSecret: string;
};

export type CreateAssociateAttemptParams = { ssoToken: string };

export type CreateAssociationParams = {
  associateAttemptId: string;
  otpSecret: string;
};

type CreateSignupReturn = Promise<AxiosResponse<{ id: string }>>;
type UpdatePasswordReturn = Promise<AxiosResponse<{ accessToken: string }>>;
type AssocaiteAttemptReturn = Promise<AxiosResponse<{ id: string }>>;
type CreateAssociationReturn = Promise<AxiosResponse<{ accessToken: string }>>;

const SignupAPI = {
  createSignupSession: (
    params: CreateSignupSessionParams
  ): CreateSignupReturn => {
    return publicAxiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/signups`,
      params
    );
  },
  updatePhone: ({ signupId, phone, method }: UpdatePhoneParams) =>
    publicAxiosInstance.patch(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/signups/${signupId}`,
      {
        phoneNumber: phone,
        otpDispatchMethod: method,
      }
    ),
  sendSingupOTP: ({ signupId, method }: ResendSignupOTPParams) =>
    publicAxiosInstance.post(
      // eslint-disable-next-line max-len
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/signups/${signupId}/retries`,
      {
        otpDispatchMethod: method,
      }
    ),
  completeSignup: ({
    signupId,
    password,
    otpSecret,
  }: CompleteSignupParams): UpdatePasswordReturn =>
    publicAxiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers`,
      {
        signupId,
        otpSecret,
        ...(password ? { password } : {}),
      },
      { withCredentials: true }
    ),
  createAssociateAttempt: ({
    ssoToken,
  }: CreateAssociateAttemptParams): AssocaiteAttemptReturn =>
    publicAxiosInstance.post(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/associate-attempt`,
      {
        ssoToken,
      }
    ),
  sendAssociateAttemptOTP: ({
    associateAttemptId,
    method,
  }: ResendAssociateOTPParams) =>
    publicAxiosInstance.post(
      // eslint-disable-next-line max-len
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/associate-attempt/${associateAttemptId}/retries`,
      {
        otpDispatchMethod: method,
      }
    ),
  createAssociation: ({
    associateAttemptId,
    otpSecret,
  }: CreateAssociationParams): CreateAssociationReturn =>
    publicAxiosInstance.patch(
      `${process.env.REACT_APP_PAYMENTS_API_URL}/consumers/associate-attempt/${associateAttemptId}`,
      {
        otpSecret,
      },
      { withCredentials: true }
    ),
};

export default SignupAPI;
